import React, { useEffect, useRef, useState, forwardRef } from "react";
import axios from "axios";
import { useDebounce } from "use-debounce";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  setSaveData,
  getGameOutsideInfo,
  setGameOutside,
  setGameName,
  setGameId,
  setGameType,
  setGameTypeRelation,
  setCover,
  setMedia,
  setDescription,
  setGameSeries,
  setGenres,
  setNewGenres,
  setThemes,
  setNewThemes,
  setPlayerPerspectives,
  setNewPlayerPerspectives,
  setGameModes,
  setNewGameModes,
  setGameAlternativeNames,
  setGameSocialLinks,
  setGameTags,
  setGameNewTags,
  setGameReleases,
  setGameCompanies,
  setGameNewCompanies,
} from "../store/features/addGameSlice";

import { ReactComponent as SettingIcon } from "../assets/icon/settingIcon.svg";
import { ReactComponent as CalendarIcon } from "../assets/icon/calendarIcon.svg";
import { ReactComponent as PenIcon } from "../assets/icon/penIcon.svg";
import { ReactComponent as CheckMarkIcon } from "../assets/icon/checkMarkIcon.svg";
import { ReactComponent as CloseIcon } from "../assets/icon/closeIcon.svg";
import { ReactComponent as ArrowChevron } from "../assets/icon/arrowChevron.svg";
import { ReactComponent as UploaderIcon } from "../assets/icon/uploaderIcon.svg";
import { ReactComponent as GameIcon } from "../assets/icon/gameIcon.svg";
import { ReactComponent as PlatformIcon } from "../assets/icon/platformIcon.svg";

import styles from "../scss/components/addGameForm.module.scss";

const url = process.env.REACT_APP_SERVER_API;
const proxy = process.env.REACT_APP_PROXY_URL;

const normalizeInput = (input) => {
  return (
    input
      .toLowerCase()
      /* .replace(/\s/g, "") // Удаляем пробелы */
      .replace(/-/g, "")
      .trim()
  ); // Удаляем дефисы
};

const adjustIndexes = (startIndex, indexes) => {
  const adjustedIndexes = [...indexes]; // Создаем копию массива индексов
  for (let i = startIndex; i < adjustedIndexes.length; i++) {
    adjustedIndexes[i]--; // Уменьшаем все индексы, начиная с startIndex, на единицу
  }
  return adjustedIndexes;
};
const ToggleSwitch = ({ title, isChecked, onChange }) => {
  return (
    <div className={styles.itemRelationsComponent__settingsItem}>
      <p>{title}:</p>
      <label className={styles.switch}>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={(e) => onChange(e.target.checked)}
        />
        <span className={`${styles.slider} ${styles.round}`} />
      </label>
    </div>
  );
};
const AddTitle = forwardRef(
  ({ gameTitle, setGameTitle, setGameTitleVerify, gameTitleVerify }, ref) => {
    const dispatch = useDispatch();
    const { gameName } = useSelector((state) => state.addGame);

    const [gameData, setGameData] = useState(null);
    const [gameDataOutside, setGameDataOutside] = useState(null);
    const [debouncedSearchTerm] = useDebounce(gameTitle, 1000);
    const [searchActive, setSearchActive] = useState(false);
    const [isExactMatch, setIsExactMatch] = useState(false);
    const [selectOutsideGame, setSelectOutsideGame] = useState(false);

    const handleSelectGame = (item) => {
      dispatch(setGameOutside(true));
      dispatch(setGameName(item.name));
      dispatch(setGameId(item.id));
      dispatch(getGameOutsideInfo());
      setGameTitle(item.name);
      setSelectOutsideGame(true);
    };

    const handleInputChange = (e) => {
      setGameTitle(e.target.value);
      if (e.target.value !== gameName) {
        dispatch(setGameOutside(false));
      }
    };
    const fetchData = async () => {
      try {
        const formattedTitle = normalizeInput(debouncedSearchTerm);
        const response = await axios.get(
          `${url}/api/games?filters[title][$contains]=${formattedTitle}&fields[0]=title&sort=title:asc&pagination[limit]=5`
        );
        if (response.data) {
          setGameData(response.data);
        }
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };
    const fetchGames = async () => {
      try {
        dispatch(setSaveData(true));
        const formattedTitle = normalizeInput(debouncedSearchTerm);
        const response = await axios.get(
          `${proxy}/api/get-external-resource-games`,
          {
            params: { value: formattedTitle },
          }
        );
    
        setGameDataOutside(response.data);
      } catch (error) {
        console.log(error.message);
      } finally {
        // После завершения запроса (успешно или с ошибкой) устанавливаем состояние в false
        dispatch(setSaveData(false));
      }
    };

    useEffect(() => {
      if (debouncedSearchTerm !== "" && gameData && gameData.data) {
        const formattedSearchTerm = debouncedSearchTerm.toLowerCase().trim();
        const matchingItem = gameData.data.find((item) => {
          const title =
            item.attributes && item.attributes.title
              ? item.attributes.title
              : "";
          return title.toLowerCase().trim() === formattedSearchTerm;
        });
        setIsExactMatch(!!matchingItem);
      }
    }, [debouncedSearchTerm, gameData]);

    useEffect(() => {
      if (searchActive && debouncedSearchTerm !== "") {
        fetchData();
        fetchGames();
        setGameName(null);
        setGameId(null);
        setGameType(null);
        setGameTypeRelation(null);
        setCover(null);
        setMedia(null);
        setDescription(null);
        setGameSeries(null);
        setGenres(null);
        setNewGenres(null);
        setThemes(null);
        setNewThemes(null);
        setPlayerPerspectives(null);
        setNewPlayerPerspectives(null);
        setGameModes(null);
        setNewGameModes(null);
        setGameAlternativeNames(null);
        setGameSocialLinks(null);
        setGameTags(null);
        setGameNewTags(null);
        setGameReleases(null);
        setGameCompanies(null);
        setGameNewCompanies(null);
      } else {
        setGameData(null);
      }
    }, [debouncedSearchTerm, searchActive]);
    useEffect(() => {
      if (debouncedSearchTerm === "") {
        setGameData(null);
      }
    }, [debouncedSearchTerm]);
    useEffect(() => {
      setIsExactMatch(isExactMatch);
      if (gameTitle && gameTitle.trim() !== "" && isExactMatch === false) {
        setGameTitleVerify(true);
      } else {
        setGameTitleVerify(false);
      }
    }, [gameTitle, isExactMatch]);

    const [filteredGameDataOutside, setFilteredGameDataOutside] = useState([]);
    useEffect(() => {
      if (
        gameDataOutside &&
        Array.isArray(gameDataOutside) &&
        gameData &&
        Array.isArray(gameData.data)
      ) {
        const gameDataIds = gameData.data.map((game) => game.id);
        const gameDataTitles = gameData.data
          .map((game) => game.attributes?.title?.toLowerCase().trim())
          .filter(Boolean);

        let filteredData = gameDataOutside.filter((item) => {
          const itemName = item.name ? item.name.toLowerCase().trim() : "";
          const isIdMatch = !gameDataIds.includes(item.id);
          const isTitleMatch = !gameDataTitles.includes(itemName);
          return isIdMatch && isTitleMatch;
        });

        if (gameTitle) {
          const formattedGameTitle = gameTitle.toLowerCase().trim();

          // Фильтруем данные в зависимости от значений isExactMatch и selectOutsideGame
          filteredData = filteredData.filter((item) => {
            const itemName = item.name ? item.name.toLowerCase().trim() : "";

            // Исключаем элементы, если isExactMatch тру и имя совпадает с gameTitle
            if (isExactMatch && itemName === formattedGameTitle) {
              return false;
            }

            // Исключаем элементы, если selectOutsideGame тру и имя совпадает с gameTitle
            if (selectOutsideGame && itemName === formattedGameTitle) {
              return false;
            }

            return true;
          });
        }

        setFilteredGameDataOutside(filteredData);
      }
    }, [gameData, gameDataOutside, isExactMatch, selectOutsideGame, gameTitle]);

    return (
      <div ref={ref} className={styles.item}>
        <span className={styles.sectionTitle}>
          <GameIcon />
          <span>Game title (single):</span>
        </span>
        <input
          className={styles.inputWrite}
          type="text"
          placeholder="Start typing..."
          value={gameTitle}
          onChange={handleInputChange}
          onClick={() => setSearchActive(true)}
          /*   onBlur={() => setSearchActive(false)} */
        />

        {gameData && gameData.data && (
          <div style={{ margin: "5px 0 0 15px" }}>
            <span className={styles.state}>
              {isExactMatch ? (
                <span className={styles.state__occupied}>
                  This name is occupied
                </span>
              ) : (
                <span className={styles.state__available}>
                  This name is available
                </span>
              )}
            </span>
            <div className={styles.gamesResultat}>
              {gameData && gameData.data && gameData.data.length > 0 && (
                <div className={styles.shadowList}>
                  <span className={styles.shadowList__title}>
                    Existing games (inside):
                  </span>
                  <ul className={styles.shadowList__menu}>
                    {gameData &&
                      gameData.data &&
                      gameData.data.map((item) => (
                        <li style={{ cursor: "default" }} key={item.id}>
                          {item.attributes.title}
                        </li>
                      ))}
                  </ul>
                </div>
              )}
              {filteredGameDataOutside.length > 0 && (
                <div className={styles.shadowList}>
                  <span className={styles.shadowList__title}>
                    Existing games (outside):
                  </span>
                  <ul className={styles.shadowList__menu}>
                    {filteredGameDataOutside.map((item) => (
                      <li onClick={() => handleSelectGame(item)} key={item.id}>
                        {item.name}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
);
const RelationToGameByType = ({
  selectGameTypeActive,
  selectTypeRelationToGame,
  setSelectTypeRelationToGame,
  setSelectTypeRelationToGameVerify,
  selectTypeRelationToGameVerify,
}) => {
  const [gameTypeRelationToGame, setGameTypeRelationToGame] = useState(null);
  const [genderRelationToGameSearch, setGenderRelationToGameSearch] =
    useState("");
  const [gameTypeRelationToGameActive, setGameTypeRelationToGameActive] =
    useState(false);
  const [debouncedSearchTypeRelationToGame] = useDebounce(
    genderRelationToGameSearch,
    1000
  );
  useEffect(() => {
    if (gameTypeRelationToGameActive && selectTypeRelationToGame === null) {
      const fetchData = async () => {
        try {
          const formattedSeach = normalizeInput(
            debouncedSearchTypeRelationToGame
          );
          const response = await axios.get(
            `${url}/api/games?filters[title][$contains]=${formattedSeach}&fields[0]=title&fields[1]=igdb_id&sort=title:asc&pagination[limit]=14`
          );
          setGameTypeRelationToGame(response.data);
        } catch (error) {
          console.error("Ошибка при получении данных:", error);
        }
      };
      fetchData();
    }
  }, [debouncedSearchTypeRelationToGame, gameTypeRelationToGameActive]);
  const handleTypeRelationChange = (e) => {
    const genderRelationToGameSearch = e.target.value.toLowerCase(); // Приведем введенное значение к нижнему регистру
    setGenderRelationToGameSearch(genderRelationToGameSearch);
    setGameTypeRelationToGameActive(true);

    // Проверяем, существует ли элемент в gameTypeRelationToGame, совпадающий с введенным значением
    const foundIndex = gameTypeRelationToGame?.data.findIndex((item) => {
      const itemName = item.attributes.name;
      return itemName && itemName.toLowerCase() === genderRelationToGameSearch;
    });

    // Если найден элемент, устанавливаем его в selectTypeRelationToGame
    if (foundIndex !== -1) {
      setSelectTypeRelationToGame(gameTypeRelationToGame.data[foundIndex]);
    } else {
      // В противном случае сбрасываем selectTypeRelationToGame
      setSelectTypeRelationToGame(null);
    }
  };
  const handleTypeRelationClick = () => {
    setGameTypeRelationToGameActive(!selectGameTypeActive);
  };
  const handleTypeRelationInputBlur = () => {
    if (
      (selectTypeRelationToGame &&
        genderRelationToGameSearch !== selectTypeRelationToGame.title) ||
      selectTypeRelationToGame === null
    ) {
      setGenderRelationToGameSearch("");
    }
    setTimeout(() => {
      setGameTypeRelationToGameActive(false);
    }, 100);
  };
  const handleItemClick = (item) => {
    setSelectTypeRelationToGame({
      id: item.id,
      title: item.attributes.title,
      igdbId: item.attributes.igdb_id,
    });
    setGenderRelationToGameSearch(item.attributes.title);
  };
  const handleRequestRemove = () => {
    setSelectTypeRelationToGame(null);
    setGenderRelationToGameSearch("");
    setTimeout(() => {
      setGameTypeRelationToGameActive(false);
    }, 100);
  };
  const { gameOutside, gameTypeRelation } = useSelector(
    (state) => state.addGame
  );

  useEffect(() => {
    if (gameOutside && gameTypeRelation) {
      setSelectTypeRelationToGame(gameTypeRelation);
      setGenderRelationToGameSearch(gameTypeRelation?.title || "Unknown");
    } else {
      setSelectTypeRelationToGame(null);
      setGenderRelationToGameSearch("");
    }
  }, [gameOutside, gameTypeRelation]);

  return (
    <div className={styles.item}>
      <span className={styles.sectionTitle}>
        <SettingIcon />
        <span>Related to Game:</span>
      </span>
      <div>
        <div
          className={`${styles.inputSelect} ${
            gameTypeRelationToGameActive ? styles.inputSelectActive : ""
          }`}
          onClick={handleTypeRelationClick}
        >
          <input
            type="text"
            placeholder="Choose from the list or type"
            onChange={handleTypeRelationChange}
            value={genderRelationToGameSearch}
            onBlur={handleTypeRelationInputBlur}
          />
          {selectTypeRelationToGame === null &&
          gameTypeRelationToGameActive === false ? (
            gameTypeRelationToGameActive ? (
              <CloseIcon onClick={handleRequestRemove} />
            ) : (
              <ArrowChevron />
            )
          ) : (
            <CloseIcon onClick={handleRequestRemove} />
          )}

          {gameTypeRelationToGame && gameTypeRelationToGame.data && (
            <div
              className={`${styles.inputSelect__dropDown} ${
                gameTypeRelationToGameActive ? styles.active : ""
              }`}
            >
              {gameTypeRelationToGame.data.length > 0 ? (
                <ul>
                  {gameTypeRelationToGame.data.map((item, index) => {
                    return (
                      <li onClick={() => handleItemClick(item)} key={item.id}>
                        {item.attributes.title}
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <p className={styles.dropDownListEmpty}>The list is empty</p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
const SelectType = forwardRef(
  (
    {
      selectGameType,
      setSelectGameType,
      selectTypeRelationToGame,
      setSelectTypeRelationToGame,
      selectGameTypeVerify,
      setSelectGameTypeVerify,
      selectTypeRelationToGameVerify,
      setSelectTypeRelationToGameVerify,
    },
    ref
  ) => {
    const [gameType, setGameType] = useState(null);
    const [gameTypeSearch, setTypeSearch] = useState("");
    const [selectGameTypeActive, setSelectGameTypeActive] = useState(false);
    const [debouncedSearchType] = useDebounce(gameTypeSearch, 100);
    const inputRef = useRef(null);
    useEffect(() => {
      if (selectGameTypeActive && selectGameType === null) {
        const fetchData = async () => {
          try {
            const formattedSeach = normalizeInput(debouncedSearchType);
            const response = await axios.get(
              `${url}/api/types-games?pagination[page]=1&pagination[pageSize]=100&filters[name][$containsi]=${formattedSeach}&fields[0]=name&fields[1]=gameSelection&sort=name:asc`
            );
            setGameType(response.data);
          } catch (error) {
            console.error("Ошибка при получении данных:", error);
          }
        };
        fetchData();
      }
    }, [debouncedSearchType, selectGameTypeActive]);
    const handleTypeChange = (e) => {
      setTypeSearch(e.target.value);
      setSelectGameTypeActive(true);
      if (
        selectGameType &&
        selectGameType.length > 0 &&
        e.target.value !== selectGameType[0].attributes.name
      ) {
        setSelectGameType(null);
      }
    };
    const handleSelectType = (item) => {
      setSelectGameType([item]);
      setSelectGameTypeActive(false);
      setTypeSearch(item.attributes.name);
    };
    const handleTypeClick = () => {
      setSelectGameTypeActive(!selectGameTypeActive);
    };
    const handleTypeInputBlur = () => {
      if (selectGameType === null) {
        setTypeSearch("");
      }
      setTimeout(() => {
        setSelectGameTypeActive(false);
      }, 100);
    };
    const handleCancelChoice = () => {
      setTypeSearch("");
      setSelectGameType(null);
      setSelectTypeRelationToGame(null);
      setTimeout(() => {
        setSelectGameTypeActive(true);
        inputRef.current.focus();
      }, 100);
    };

    const { gameOutside, type } = useSelector((state) => state.addGame);

    useEffect(() => {
      if (gameOutside && type && type.length > 0) {
        setSelectGameType(type);
        setTypeSearch(type[0]?.attributes?.title || "Unknown");
      } else {
        setSelectGameType(null);
        setTypeSearch("");
      }
    }, [gameOutside, type]);

    useEffect(() => {
      if (
        selectGameType &&
        selectGameType.length > 0 &&
        selectGameType[0].attributes.gameSelection === true &&
        selectTypeRelationToGame === null
      ) {
        setSelectGameTypeVerify(false);
      } else {
        setSelectGameTypeVerify(true);
      }
    }, [selectGameType, selectTypeRelationToGame]);

    return (
      <div ref={ref} className={styles.item}>
        <span className={styles.sectionTitle}>
          <SettingIcon />
          <span>Type:</span>
        </span>
        <div style={{ display: "flex", gap: "8px", flexDirection: "column" }}>
          <div
            className={`${styles.inputSelect} ${
              selectGameTypeActive ? styles.inputSelectActive : ""
            }`}
            onClick={handleTypeClick}
          >
            <input
              ref={inputRef}
              type="text"
              placeholder="Choose from the list or type"
              onChange={handleTypeChange}
              value={gameTypeSearch}
              onBlur={handleTypeInputBlur}
            />

            {selectGameType === null && selectGameTypeActive === false ? (
              selectGameTypeActive ? (
                <CloseIcon onClick={() => setTypeSearch("")} />
              ) : (
                <ArrowChevron />
              )
            ) : (
              <CloseIcon onClick={handleCancelChoice} />
            )}
            {selectGameType === null &&
              gameType &&
              Array.isArray(gameType.data) && (
                <div
                  className={`${styles.inputSelect__dropDown} ${
                    selectGameTypeActive ? styles.active : ""
                  }`}
                >
                  {gameType.data.length > 0 ? (
                    <ul>
                      {gameType.data.map((item, index) => {
                        const isSelected =
                          selectGameType &&
                          selectGameType.some((genre) => genre.id === item.id);

                        if (!isSelected) {
                          return (
                            <li
                              key={item.id}
                              onClick={() => handleSelectType(item)}
                            >
                              {item.attributes.name}
                            </li>
                          );
                        }
                        return (
                          <li
                            key={item.id}
                            style={{
                              color: " rgb(97, 97, 97)",
                              cursor: "default",
                            }}
                          >
                            {item.attributes.name}
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    <p className={styles.dropDownListEmpty}>
                      The list is empty
                    </p>
                  )}
                </div>
              )}
          </div>
          {selectGameType &&
          selectGameType[0] &&
          selectGameType[0].attributes.gameSelection === true ? (
            <div
              className={styles.toRow}
              style={{ marginLeft: "20px", overflow: "visible" }}
            >
              <div className={styles.itemTogetherRelations__ellips}>
                <div style={{ height: "calc(100% - 24px)" }}></div>
              </div>
              <RelationToGameByType
                selectTypeRelationToGameVerify={selectTypeRelationToGameVerify}
                setSelectTypeRelationToGameVerify={
                  setSelectTypeRelationToGameVerify
                }
                selectGameTypeActive={selectGameTypeActive}
                selectTypeRelationToGame={selectTypeRelationToGame}
                setSelectTypeRelationToGame={setSelectTypeRelationToGame}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
);
const UploaderCover = ({ image, setImage, imageVerify, setImageVerify }) => {
  const { gameOutside, cover, gameName } = useSelector(
    (state) => state.addGame
  );
  const [imageName, setImageName] = useState("");
  const [notMeetCondition, setNotMeetCondition] = useState(true);
  const fileInputRef = useRef(null);
  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const maxSize = 2 * 1024 * 1024; // 2 МБ
      if (
        !selectedFile.type.startsWith("image/") ||
        selectedFile.size > maxSize
      ) {
        setNotMeetCondition(false);
        return;
      }

      // Сохраняем файл в состоянии, не преобразуя его в Base64
      setImage(selectedFile);
      setImageName(selectedFile.name);
      setNotMeetCondition(true);
    }
  };
  const handleDeleteGameImage = () => {
    setImage(null);
    setImageName("");
    setNotMeetCondition(true);
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.value = null;
    }
  };
  const handleSelectCoverClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    if (gameOutside) {
      setImage(cover);
      setImageName("outsideGameImage.jpg");
    } else {
      setImage(null);
      setImageName("");
    }
  }, [gameOutside, cover]);

  /*  useEffect(() => {
    if (image !== null && imageVerify === false) {
      setImageVerify(true);
    } else {
      setImageVerify(false);
    }
  }, [image, notMeetCondition]); */

  return (
    <div className={styles.item}>
      <span className={styles.sectionTitle}>
        <SettingIcon />
        <span>Cover:</span>
      </span>
      <div className={styles.gameCover}>
        <label
          htmlFor="fileInput"
          className={!image ? styles.active : styles.hidden}
        >
          <input
            type="file"
            id="fileInput"
            onChange={handleImageChange}
            ref={fileInputRef}
          />
          <div style={notMeetCondition ? {} : { border: "  1px dashed red" }}>
            <UploaderIcon />
            <button onClick={handleSelectCoverClick}>Select images</button>
            <p style={notMeetCondition ? {} : { color: "red" }}>
              JPEG, JPG, PNG or GIF up to 2 MB
            </p>
          </div>
        </label>
        {image &&
          (() => {
            let imageUrl = "";

            if (typeof image === "string") {
              imageUrl = `https://${image}`;
            } else if (image instanceof File) {
              imageUrl = URL.createObjectURL(image);
            }

            return (
              <span>
                <img src={imageUrl} alt="" />
                <span>{imageName}</span>
                <CloseIcon onClick={handleDeleteGameImage} />
              </span>
            );
          })()}
      </div>
    </div>
  );
};
const AddDescription = ({
  setGameDescription,
  gameDescription,
  gameDescriptionVerify,
  setGameDescriptionVerify,
}) => {
  const { gameOutside, description } = useSelector((state) => state.addGame);
  const refDescription = useRef();
  const [selectGameDescriptionActive, setSelectGameDescriptionActive] =
    useState(false);
  const handleDescriptionChange = (e) => {
    setGameDescription(e.target.value);
  };
  const handleDescriptionTextareaBlur = () => {
    setSelectGameDescriptionActive(false);
  };
  useEffect(() => {
    if (gameOutside) {
      setGameDescription(description);
    } else {
      setGameDescription(null);
      if (refDescription.current) {
        refDescription.current.value = null;
      }
    }
  }, [gameOutside, description]);

  /*  useEffect(() => {
    if (gameDescription.trim() !== "") {
      setGameDescriptionVerify(true);
    } else {
      setGameDescriptionVerify(false);
    }
  }, [gameDescription]); */

  return (
    <div className={styles.item}>
      <span className={styles.sectionTitle}>
        <SettingIcon />
        <span>Description:</span>
      </span>
      <div
        style={
          selectGameDescriptionActive
            ? { border: " 1px solid rgb(242, 242, 242)" }
            : {}
        }
        className={styles.gameDescription}
      >
        <textarea
          ref={refDescription}
          onBlur={handleDescriptionTextareaBlur}
          onChange={handleDescriptionChange}
          onClick={() => setSelectGameDescriptionActive(true)}
          placeholder="Start typing..."
          value={gameDescription || ""}
          rows="10"
        ></textarea>
      </div>
    </div>
  );
};
const SelectGenres = ({
  selectGameGenres,
  setSelectGameGenres,
  selectGameGenresVerify,
  setSelectGameGenresVerify,
}) => {
  const { gameOutside, genres, newGenres } = useSelector(
    (state) => state.addGame
  );

  const [gameGenres, setGameGenres] = useState(null);
  const [gameGenresSearch, setGenreseSearch] = useState("");
  const [selectGameGenresActive, setSelectGameGenresActive] = useState(false);
  const [debouncedSearchGenres] = useDebounce(gameGenresSearch, 1000);
  useEffect(() => {
    if (selectGameGenresActive) {
      const fetchData = async () => {
        try {
          const formattedSeach = normalizeInput(debouncedSearchGenres);
          const response = await axios.get(
            `${url}/api/genres?filters[name][$contains]=${formattedSeach}&fields[0]=name&sort=name:asc`
          );
          setGameGenres(response.data);
        } catch (error) {
          console.error("Ошибка при получении данных:", error);
        }
      };
      fetchData();
    }
  }, [debouncedSearchGenres, selectGameGenresActive]);

  const handleGenresChange = (e) => {
    setGenreseSearch(e.target.value);
    setSelectGameGenresActive(true);
  };
  const handleSelectGenre = (item) => {
    if (!selectGameGenres) {
      setSelectGameGenres([item]);
    } else {
      setSelectGameGenres([...selectGameGenres, item]);
    }
    setSelectGameGenresActive(false);
  };
  const handleRemoveGenre = (index) => {
    const updatedGenres = [...selectGameGenres];
    updatedGenres.splice(index, 1);
    setSelectGameGenres(updatedGenres);
  };
  const handleGenresClick = () => {
    setSelectGameGenresActive(!selectGameGenresActive);
  };
  const handleGenresInputBlur = () => {
    setTimeout(() => {
      setSelectGameGenresActive(false);
    }, 100);
  };

  useEffect(() => {
    const validGenres = Array.isArray(genres) ? genres : [];
    const validNewGenres = Array.isArray(newGenres) ? newGenres : [];

    if (gameOutside) {
      setSelectGameGenres([...validGenres, ...validNewGenres]);
    } else {
      setSelectGameGenres([]);
    }
  }, [gameOutside, genres, newGenres]);

  /*   useEffect(() => {
    if (selectGameGenres !== null && selectGameGenres.length >= 1) {
      setSelectGameGenresVerify(true);
    } else {
      setSelectGameGenresVerify(false);
    }
  }, [selectGameGenres]); */

  return (
    <div className={styles.item}>
      <span className={styles.sectionTitle}>
        <SettingIcon />
        <span>Genres:</span>
      </span>
      <div className={styles.itemSelectSection}>
        <div>
          <div
            className={`${styles.inputSelect} ${
              selectGameGenresActive ? styles.inputSelectActive : ""
            }`}
            onClick={handleGenresClick}
          >
            <input
              type="text"
              placeholder="Select a few from the list"
              onChange={handleGenresChange}
              value={gameGenresSearch}
              onBlur={handleGenresInputBlur}
              /*  onFocus={() => setSelectGameGenresActive(true)} */
            />

            {selectGameGenresActive ? (
              <CloseIcon onClick={() => setGenreseSearch("")} />
            ) : (
              <ArrowChevron />
            )}

            {gameGenres && Array.isArray(gameGenres.data) && (
              <div
                className={`${styles.inputSelect__dropDown} ${
                  selectGameGenresActive ? styles.active : ""
                }`}
              >
                {gameGenres.data.length > 0 ? (
                  <ul>
                    {gameGenres.data.map((item, index) => {
                      const isSelected =
                        selectGameGenres &&
                        selectGameGenres.some((genre) => genre.id === item.id);

                      if (!isSelected) {
                        return (
                          <li
                            key={item.id}
                            onClick={() => handleSelectGenre(item)}
                          >
                            {item.attributes.name}
                          </li>
                        );
                      }
                      return (
                        <li
                          key={item.id}
                          style={{
                            color: " rgb(97, 97, 97)",
                            cursor: "default",
                          }}
                        >
                          {item.attributes.name}
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <p className={styles.dropDownListEmpty}>The list is empty</p>
                )}
              </div>
            )}
          </div>
        </div>

        <div className={styles.itemSelectElements}>
          {selectGameGenres &&
            selectGameGenres.map((item, index) => (
              <span key={index}>
                <p>{item?.attributes?.name}</p>
                <CloseIcon onClick={() => handleRemoveGenre(index)} />
              </span>
            ))}
        </div>
      </div>
    </div>
  );
};
const SelectThemes = ({
  selectGameThemes,
  setSelectGameThemes,
  setSelectGameThemesVerify,
  selectGameThemesVerify,
}) => {
  const { gameOutside, themes, newThemes } = useSelector(
    (state) => state.addGame
  );

  const [gameThemes, setGameThemes] = useState(null);
  const [gameThemesSearch, setThemeseSearch] = useState("");
  const [selectGameThemesActive, setSelectGameThemesActive] = useState(false);
  const [debouncedSearchThemes] = useDebounce(gameThemesSearch, 1000);
  useEffect(() => {
    if (selectGameThemesActive) {
      const fetchData = async () => {
        try {
          const formattedSeach = normalizeInput(debouncedSearchThemes);
          const response = await axios.get(
            `${url}/api/themes?filters[name][$contains]=${formattedSeach}&fields[0]=name&sort=name:asc`
          );
          setGameThemes(response.data);
        } catch (error) {
          console.error("Ошибка при получении данных:", error);
        }
      };
      fetchData();
    }
  }, [debouncedSearchThemes, selectGameThemesActive]);

  const handleThemesChange = (e) => {
    setThemeseSearch(e.target.value);
    setSelectGameThemesActive(true);
  };
  const handleSelectThemes = (item) => {
    if (!selectGameThemes) {
      setSelectGameThemes([item]);
    } else {
      setSelectGameThemes([...selectGameThemes, item]);
    }
    setSelectGameThemesActive(false);
  };
  const handleRemoveThemes = (index) => {
    const updatedThemes = [...selectGameThemes];
    updatedThemes.splice(index, 1);
    setSelectGameThemes(updatedThemes);
  };
  const handleThemesClick = () => {
    setSelectGameThemesActive(!selectGameThemesActive);
  };
  const handleThemesInputBlur = () => {
    setTimeout(() => {
      setSelectGameThemesActive(false);
    }, 100);
  };

  useEffect(() => {
    const validTheme = Array.isArray(themes) ? themes : [];
    const validNewTheme = Array.isArray(newThemes) ? newThemes : [];

    if (gameOutside) {
      setSelectGameThemes([...validTheme, ...validNewTheme]);
    } else {
      setSelectGameThemes([]);
    }
  }, [gameOutside, themes, newThemes]);

  /*   useEffect(() => {
    if (selectGameThemes !== null && selectGameThemes.length >= 1) {
      setSelectGameThemesVerify(true);
    } else {
      setSelectGameThemesVerify(false);
    }
  }, [selectGameThemes]); */

  return (
    <div className={styles.item}>
      <span className={styles.sectionTitle}>
        <SettingIcon />
        <span>Themes:</span>
      </span>
      <div className={styles.itemSelectSection}>
        <div>
          <div
            className={`${styles.inputSelect} ${
              selectGameThemesActive ? styles.inputSelectActive : ""
            }`}
            onClick={handleThemesClick}
          >
            <input
              type="text"
              placeholder="Select a few from the list"
              onChange={handleThemesChange}
              value={gameThemesSearch}
              onBlur={handleThemesInputBlur}
              /*  onFocus={() => setSelectGameThemesActive(true)} */
            />

            {selectGameThemesActive ? (
              <CloseIcon onClick={() => setThemeseSearch("")} />
            ) : (
              <ArrowChevron />
            )}

            {gameThemes && Array.isArray(gameThemes.data) && (
              <div
                className={`${styles.inputSelect__dropDown} ${
                  selectGameThemesActive ? styles.active : ""
                }`}
              >
                {gameThemes.data.length > 0 ? (
                  <ul>
                    {gameThemes.data.map((item, index) => {
                      const isSelected =
                        selectGameThemes &&
                        selectGameThemes.some((them) => them.id === item.id);

                      if (!isSelected) {
                        return (
                          <li
                            key={item.id}
                            onClick={() => handleSelectThemes(item)}
                          >
                            {item.attributes.name}
                          </li>
                        );
                      }
                      return (
                        <li
                          key={item.id}
                          style={{
                            color: " rgb(97, 97, 97)",
                            cursor: "default",
                          }}
                        >
                          {item.attributes.name}
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <p className={styles.dropDownListEmpty}>The list is empty</p>
                )}
              </div>
            )}
          </div>
        </div>

        <div className={styles.itemSelectElements}>
          {selectGameThemes &&
            selectGameThemes.map((item, index) => (
              <span key={index}>
                <p>{item.attributes.name}</p>
                <CloseIcon onClick={() => handleRemoveThemes(index)} />
              </span>
            ))}
        </div>
      </div>
    </div>
  );
};
const SelectPlayerRerspectives = ({
  selectGamePlayerRerspectives,
  setSelectGamePlayerRerspectives,
  setSelectGamePlayerRerspectivesVerify,
  selectGamePlayerRerspectivesVerify,
}) => {
  const { gameOutside, playerPerspectives, newPlayerPerspectives } =
    useSelector((state) => state.addGame);

  const [gamePlayerRerspectives, setGamePlayerRerspectives] = useState(null);
  const [gamePlayerRerspectivesSearch, setPlayerRerspectiveseSearch] =
    useState("");
  const [
    selectGamePlayerRerspectivesActive,
    setSelectGamePlayerRerspectivesActive,
  ] = useState(false);
  const [debouncedSearchPlayerRerspectives] = useDebounce(
    gamePlayerRerspectivesSearch,
    1000
  );
  useEffect(() => {
    if (selectGamePlayerRerspectivesActive) {
      const fetchData = async () => {
        try {
          const formattedSeach = normalizeInput(
            debouncedSearchPlayerRerspectives
          );
          const response = await axios.get(
            `${url}/api/player-perspectives?filters[name][$contains]=${formattedSeach}&fields[0]=name&sort=name:asc`
          );
          setGamePlayerRerspectives(response.data);
        } catch (error) {
          console.error("Ошибка при получении данных:", error);
        }
      };
      fetchData();
    }
  }, [debouncedSearchPlayerRerspectives, selectGamePlayerRerspectivesActive]);

  const handlePlayerRerspectivesChange = (e) => {
    setPlayerRerspectiveseSearch(e.target.value);
    setSelectGamePlayerRerspectivesActive(true);
  };
  const handleSelectPlayerRerspectives = (item) => {
    if (!selectGamePlayerRerspectives) {
      setSelectGamePlayerRerspectives([item]);
    } else {
      setSelectGamePlayerRerspectives([...selectGamePlayerRerspectives, item]);
    }
    setSelectGamePlayerRerspectivesActive(false);
  };
  const handleRemovePlayerRerspectives = (index) => {
    const updatedPlayerRerspectives = [...selectGamePlayerRerspectives];
    updatedPlayerRerspectives.splice(index, 1);
    setSelectGamePlayerRerspectives(updatedPlayerRerspectives);
  };
  const handlePlayerRerspectivesClick = () => {
    setSelectGamePlayerRerspectivesActive(!selectGamePlayerRerspectivesActive);
  };
  const handlePlayerRerspectivesInputBlur = () => {
    setTimeout(() => {
      setSelectGamePlayerRerspectivesActive(false);
    }, 100);
  };

  useEffect(() => {
    const validPlayerPerspectives = Array.isArray(playerPerspectives)
      ? playerPerspectives
      : [];
    const validNewPlayerPerspectives = Array.isArray(newPlayerPerspectives)
      ? newPlayerPerspectives
      : [];

    if (gameOutside) {
      setSelectGamePlayerRerspectives([
        ...validPlayerPerspectives,
        ...validNewPlayerPerspectives,
      ]);
    } else {
      setSelectGamePlayerRerspectives([]);
    }
  }, [gameOutside, playerPerspectives, newPlayerPerspectives]);

  /*  useEffect(() => {
    if (selectGamePlayerRerspectives !== null && selectGamePlayerRerspectives.length >= 1) {
      setSelectGamePlayerRerspectivesVerify(true);
    } else {
      setSelectGamePlayerRerspectivesVerify(false);
    }
  }, [selectGamePlayerRerspectives]); */

  return (
    <div className={styles.item}>
      <span className={styles.sectionTitle}>
        <SettingIcon />
        <span>Player Perspectives:</span>
      </span>
      <div className={styles.itemSelectSection}>
        <div>
          <div
            className={`${styles.inputSelect} ${
              selectGamePlayerRerspectivesActive ? styles.inputSelectActive : ""
            }`}
            onClick={handlePlayerRerspectivesClick}
          >
            <input
              type="text"
              placeholder="Select a few from the list"
              onChange={handlePlayerRerspectivesChange}
              value={gamePlayerRerspectivesSearch}
              onBlur={handlePlayerRerspectivesInputBlur}
              /*  onFocus={() => setSelectGamePlayerRerspectivesActive(true)} */
            />

            {selectGamePlayerRerspectivesActive ? (
              <CloseIcon onClick={() => setPlayerRerspectiveseSearch("")} />
            ) : (
              <ArrowChevron />
            )}

            {gamePlayerRerspectives &&
              Array.isArray(gamePlayerRerspectives.data) && (
                <div
                  className={`${styles.inputSelect__dropDown} ${
                    selectGamePlayerRerspectivesActive ? styles.active : ""
                  }`}
                >
                  {gamePlayerRerspectives.data.length > 0 ? (
                    <ul>
                      {gamePlayerRerspectives.data.map((item, index) => {
                        const isSelected =
                          selectGamePlayerRerspectives &&
                          selectGamePlayerRerspectives.some(
                            (genre) => genre.id === item.id
                          );

                        if (!isSelected) {
                          return (
                            <li
                              key={item.id}
                              onClick={() =>
                                handleSelectPlayerRerspectives(item)
                              }
                            >
                              {item.attributes.name}
                            </li>
                          );
                        }
                        return (
                          <li
                            key={item.id}
                            style={{
                              color: " rgb(97, 97, 97)",
                              cursor: "default",
                            }}
                          >
                            {item.attributes.name}
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    <p className={styles.dropDownListEmpty}>
                      The list is empty
                    </p>
                  )}
                </div>
              )}
          </div>
        </div>

        <div className={styles.itemSelectElements}>
          {selectGamePlayerRerspectives &&
            selectGamePlayerRerspectives.map((item, index) => (
              <span key={index}>
                <p>{item.attributes.name}</p>
                <CloseIcon
                  onClick={() => handleRemovePlayerRerspectives(index)}
                />
              </span>
            ))}
        </div>
      </div>
    </div>
  );
};
const SelectGameMode = ({
  selectGameGameMode,
  setSelectGameGameMode,
  selectGameGameModeVerify,
  setSelectGameGameModeVerify,
}) => {
  const { gameOutside, gameModes, newGameModes } = useSelector(
    (state) => state.addGame
  );

  const [gameGameMode, setGameGameMode] = useState(null);
  const [gameGameModeSearch, setGameModeeSearch] = useState("");
  const [selectGameGameModeActive, setSelectGameGameModeActive] =
    useState(false);
  const [debouncedSearchGameMode] = useDebounce(gameGameModeSearch, 1000);
  useEffect(() => {
    if (selectGameGameModeActive) {
      const fetchData = async () => {
        try {
          const formattedSeach = normalizeInput(debouncedSearchGameMode);
          const response = await axios.get(
            `${url}/api/game-modes?filters[name][$contains]=${formattedSeach}&fields[0]=name&sort=name:asc`
          );
          setGameGameMode(response.data);
        } catch (error) {
          console.error("Ошибка при получении данных:", error);
        }
      };
      fetchData();
    }
  }, [debouncedSearchGameMode, selectGameGameModeActive]);

  const handleGameModeChange = (e) => {
    setGameModeeSearch(e.target.value);
    setSelectGameGameModeActive(true);
  };
  const handleSelectGameMode = (item) => {
    if (!selectGameGameMode) {
      setSelectGameGameMode([item]);
    } else {
      setSelectGameGameMode([...selectGameGameMode, item]);
    }
    setSelectGameGameModeActive(false);
  };
  const handleRemoveGameMode = (index) => {
    const updatedGameMode = [...selectGameGameMode];
    updatedGameMode.splice(index, 1);
    setSelectGameGameMode(updatedGameMode);
  };
  const handleGameModeClick = () => {
    setSelectGameGameModeActive(!selectGameGameModeActive);
  };
  const handleGameModeInputBlur = () => {
    setTimeout(() => {
      setSelectGameGameModeActive(false);
    }, 100);
  };
  useEffect(() => {
    const validGameModes = Array.isArray(gameModes) ? gameModes : [];
    const validNewGameModes = Array.isArray(newGameModes) ? newGameModes : [];

    if (gameOutside) {
      setSelectGameGameMode([...validGameModes, ...validNewGameModes]);
    } else {
      setSelectGameGameMode([]);
    }
  }, [gameOutside, gameModes, newGameModes]);

  /*   useEffect(() => {
    if (selectGameGameMode !== null && selectGameGameMode.length >= 1) {
      setSelectGameGameModeVerify(true);
    } else {
      setSelectGameGameModeVerify(false);
    }
  }, [selectGameGameMode]); */

  return (
    <div className={styles.item}>
      <span className={styles.sectionTitle}>
        <SettingIcon />
        <span>Game Modes:</span>
      </span>
      <div className={styles.itemSelectSection}>
        <div>
          <div
            className={`${styles.inputSelect} ${
              selectGameGameModeActive ? styles.inputSelectActive : ""
            }`}
            onClick={handleGameModeClick}
          >
            <input
              type="text"
              placeholder="Select a few from the list"
              onChange={handleGameModeChange}
              value={gameGameModeSearch}
              onBlur={handleGameModeInputBlur}
              /*  onFocus={() => setSelectGameGameModeActive(true)} */
            />

            {selectGameGameModeActive ? (
              <CloseIcon onClick={() => setGameModeeSearch("")} />
            ) : (
              <ArrowChevron />
            )}

            {gameGameMode && Array.isArray(gameGameMode.data) && (
              <div
                className={`${styles.inputSelect__dropDown} ${
                  selectGameGameModeActive ? styles.active : ""
                }`}
              >
                {gameGameMode.data.length > 0 ? (
                  <ul>
                    {gameGameMode.data.map((item, index) => {
                      const isSelected =
                        selectGameGameMode &&
                        selectGameGameMode.some(
                          (genre) => genre.id === item.id
                        );

                      if (!isSelected) {
                        return (
                          <li
                            key={item.id}
                            onClick={() => handleSelectGameMode(item)}
                          >
                            {item.attributes.name}
                          </li>
                        );
                      }
                      return (
                        <li
                          key={item.id}
                          style={{
                            color: " rgb(97, 97, 97)",
                            cursor: "default",
                          }}
                        >
                          {item.attributes.name}
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <p className={styles.dropDownListEmpty}>The list is empty</p>
                )}
              </div>
            )}
          </div>
        </div>

        <div className={styles.itemSelectElements}>
          {selectGameGameMode &&
            selectGameGameMode.map((item, index) => (
              <span key={index}>
                <p>{item.attributes.name}</p>
                <CloseIcon onClick={() => handleRemoveGameMode(index)} />
              </span>
            ))}
        </div>
      </div>
    </div>
  );
};
const AddAlternativeNames = forwardRef(
  (
    {
      alternativeNames,
      setAlternativeNames,
      setAlternativeNamesVerify,
      alternativeNamesVerify,
    },
    ref
  ) => {
    const { gameOutside, gameAlternativeNames } = useSelector(
      (state) => state.addGame
    );

    const [completedItems, setCompletedItems] = useState([]);
    const [uncompletedItems, setUncompletedItems] = useState([]);

    const [regionData, setRegionData] = useState({ data: [] });
    const [addAlternativeNames, setAddAlternativeNames] = useState(false);
    const [regionSearch, setRegionSearch] = useState("");
    const regionInputs = useRef(uncompletedItems.map(() => React.createRef()));

    const [regionSearchActive, setRegionSearchActive] = useState(null);
    const [debouncedRegionSearch] = useDebounce(regionSearch, 1000);
    const [
      editedUncompletedItemNameActive,
      setEditedUncompletedItemNameActive,
    ] = useState(null);
    const [editedUncompletedItemIndex, setEditedUncompletedItemIndex] =
      useState(null);
    const [editedUncompletedItemName, setEditedUncompletedItemName] =
      useState("");
    const [isMouseOverList, setIsMouseOverList] = useState(false);
    const [validationFlags, setValidationFlags] = useState({});
    useEffect(() => {
      if (regionSearchActive !== null) {
        const fetchData = async () => {
          try {
            const formattedSeach = normalizeInput(debouncedRegionSearch);
            const response = await axios.get(
              `${url}/api/regions?filters[name][$contains]=${formattedSeach}&fields[0]=name&fields[1]=code&sort=name:asc`
            );
            setRegionData(response.data);
          } catch (error) {
            console.error("Ошибка при получении данных:", error);
          } finally {
          }
        };

        fetchData();
      }
    }, [debouncedRegionSearch, regionSearchActive]);
    const handleRegionChange = (e, index) => {
      if (!e || !e.target) {
        return;
      }
      setRegionSearch(e.target.value);
      setRegionSearchActive(index);
    };
    const handleAddNewItemPress = (event) => {
      if (event.key === "Enter") {
        const inputText = event.target.value.trim();
        if (inputText !== "") {
          const newItem = {
            name: inputText,
            region: null,
          };
          setUncompletedItems([...uncompletedItems, newItem]);
          setAddAlternativeNames(false);
          event.target.value = "";
          event.target.blur();
        }
      }
    };
    const handleUncompletedItemInputChange = (index, event) => {
      setEditedUncompletedItemIndex(index);
      setEditedUncompletedItemName(event.target.value);

      const newItems = [...uncompletedItems];
      newItems[index].name = event.target.value;
      setUncompletedItems(newItems);
    };
    const handleUncompletedItemsDeleteItem = (index) => {
      setUncompletedItems((prevItems) =>
        prevItems.filter((_, i) => i !== index)
      );
      setValidationFlags((prevFlags) => {
        const newFlags = { ...prevFlags };
        delete newFlags[index]; // Удаление флагов для текущего индекса

        // Необходимо обновить индексы для оставшихся флагов, так как индекс массива изменился
        Object.keys(newFlags).forEach((key) => {
          if (parseInt(key) > index) {
            newFlags[key - 1] = newFlags[key];
            delete newFlags[key];
          }
        });

        return newFlags;
      });
    };
    const moveToCompleted = (index) => {
      const item = uncompletedItems[index];

      const invalidRegion = item.region === null;
      const emptyName = item.name === "";

      // Обновляем флаги валидации для текущего индекса
      setValidationFlags((prev) => ({
        ...prev,
        [index]: { isRegionInvalid: invalidRegion, isNameEmpty: emptyName },
      }));

      if (invalidRegion || emptyName) {
        return;
      }

      const newUncompletedItems = [
        ...uncompletedItems.slice(0, index),
        ...uncompletedItems.slice(index + 1),
      ];
      const newCompletedItems = [...completedItems, item];
      setUncompletedItems(newUncompletedItems);
      setCompletedItems(newCompletedItems);

      setValidationFlags((prev) => {
        const newFlags = { ...prev };
        delete newFlags[index];
        return newFlags;
      });
    };
    const handleRegionSelectClick = (region, itemIndex) => {
      const newRegion = {
        id: region.id,
        name: region.attributes.name,
        code: region.attributes.code,
      };
      setUncompletedItems((items) =>
        items.map((item, index) =>
          index === itemIndex ? { ...item, region: newRegion } : item
        )
      );
    };
    const toggleRegionSearchActiveState = (index) => {
      if (regionSearchActive === index) {
        setRegionSearchActive(null);
      } else {
        setRegionSearchActive(index);
      }
    };
    const toggleEditedUncompletedItemNameActiveState = (index) => {
      if (editedUncompletedItemNameActive === index) {
        setEditedUncompletedItemNameActive(null);
      } else {
        setEditedUncompletedItemNameActive(index);
      }
    };
    const handleCompletedItemsDeleteItem = (index) => {
      setCompletedItems((prevItems) => prevItems.filter((_, i) => i !== index));
    };
    const moveToUncompleted = (index) => {
      const item = completedItems[index];
      const newCompletedItems = [
        ...completedItems.slice(0, index),
        ...completedItems.slice(index + 1),
      ];
      const newUncompletedItems = [...uncompletedItems, item];
      setCompletedItems(newCompletedItems);
      setUncompletedItems(newUncompletedItems);
    };
    const handleRemoveRegion = (index) => {
      setUncompletedItems((items) =>
        items.map((item, itemIndex) =>
          itemIndex === index ? { ...item, region: null } : item
        )
      );
    };
    const handleRegionInputBlur = () => {
      setRegionSearch("");
      setTimeout(() => {
        if (!isMouseOverList) {
          setRegionSearchActive(null);
        }
      }, 0);
    };
    const handleRegionInputClick = (item, index) => {
      if (item.region === null && regionSearch !== "") {
        setRegionSearchActive(index);
      } else {
        setRegionSearchActive(null);
      }
      setTimeout(() => {
        toggleRegionSearchActiveState(index);
      }, 0);
    };

    useEffect(() => {
      if (gameOutside) {
        const validGameAlternativeNames = Array.isArray(gameAlternativeNames)
          ? gameAlternativeNames
          : [];
        setUncompletedItems(validGameAlternativeNames);
        setCompletedItems([]);
        // Обновление regionInputs
        regionInputs.current = validGameAlternativeNames.map((el, index) => {
          const inputRef = React.createRef();
          inputRef.current = el;
          return inputRef;
        });
      } else {
        setUncompletedItems([]);
        setCompletedItems([]);
        regionInputs.current = [];
      }
    }, [gameOutside, gameAlternativeNames]);

    useEffect(() => {
      if (uncompletedItems.length === 0) {
        setAlternativeNames(completedItems);
        setAlternativeNamesVerify(true);
      } else {
        setAlternativeNames(null);
        setAlternativeNamesVerify(false);
      }
    }, [completedItems, uncompletedItems, setAlternativeNames]);

    const filteredRegions = regionData.data.filter((item) => {
      const isCompleted =
        uncompletedItems.some(
          (completedItem) =>
            completedItem.region && completedItem.region.id === item.id
        ) ||
        completedItems.some(
          (completedItem) => completedItem.region.id === item.id
        );
      return !isCompleted;
    });

    return (
      <div ref={ref} className={styles.item}>
        <span className={styles.sectionTitle}>
          <SettingIcon />
          <span>Alternative Names:</span>
        </span>
        <div className={styles.itemTogetherRelations}>
          <input
            className={`${styles.inputWrite} ${
              addAlternativeNames ? styles.inputWriteActive : ""
            }`}
            type="text"
            placeholder="Start typing..."
            onBlur={() => setAddAlternativeNames(false)}
            onClick={() => setAddAlternativeNames(!addAlternativeNames)}
            onKeyPress={handleAddNewItemPress}
          />
          <div className={styles.itemTogetherRelations__items}>
            {completedItems.map((item, index) => {
              return (
                <div key={index} className={styles.toRow}>
                  <div className={styles.itemTogetherRelations__ellips}>
                    <div></div>
                    {index !== 0 && <span></span>}
                  </div>
                  <div className={styles.itemTogetherRelations__item}>
                    <div
                      className={styles.itemTogetherRelations__title}
                      readOnly
                    >
                      <input type="text" readOnly value={item.name} />
                    </div>
                    <span
                      className={styles.itemTogetherRelations__separate}
                    ></span>

                    <div
                      className={styles.itemTogetherRelations__region}
                      readOnly
                    >
                      <div>
                        <input type="text" readOnly value={item.region.code} />
                      </div>
                    </div>

                    <div className={styles.tools}>
                      <span onClick={() => moveToUncompleted(index)}>
                        <PenIcon />
                      </span>
                      <span
                        onClick={() => handleCompletedItemsDeleteItem(index)}
                      >
                        <CloseIcon />
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
            {uncompletedItems.map((item, index) => {
              return (
                <div
                  key={index}
                  className={styles.toRow}
                  style={{ overflow: "visible" }}
                >
                  <div className={styles.itemTogetherRelations__ellips}>
                    <div></div>
                    {(completedItems.length > 0 || index !== 0) && (
                      <span></span>
                    )}
                  </div>
                  <div className={styles.itemTogetherRelations__item}>
                    <div className={styles.itemTogetherRelations__title}>
                      <input
                        style={{
                          border:
                            editedUncompletedItemNameActive === index
                              ? "1px solid rgb(97, 97, 97)"
                              : validationFlags[index]?.isNameEmpty &&
                                uncompletedItems.name !== ""
                              ? "1px solid red"
                              : "1px solid transparent",
                        }}
                        type="text"
                        value={
                          index === setEditedUncompletedItemIndex
                            ? setEditedUncompletedItemName
                            : item.name
                        }
                        placeholder="Specify the name"
                        onBlur={() => setEditedUncompletedItemNameActive(null)}
                        onClick={() =>
                          toggleEditedUncompletedItemNameActiveState(index)
                        }
                        onChange={(event) =>
                          handleUncompletedItemInputChange(index, event)
                        }
                      />
                    </div>
                    <span
                      className={styles.itemTogetherRelations__separate}
                    ></span>
                    <div
                      className={`${styles.itemTogetherRelations__region} ${
                        regionSearchActive === index
                          ? styles.itemTogetherRelations__regionActive
                          : ""
                      }`}
                    >
                      <div>
                        <input
                          type="text"
                          placeholder="Region"
                          value={
                            item.region
                              ? item.region.name
                              : index === regionSearchActive
                              ? regionSearch
                              : ""
                          }
                          onChange={(e) => handleRegionChange(e, index)}
                          ref={(el) => (regionInputs.current[index] = el)}
                          readOnly={!!(item.region && item.region.name)}
                          onBlur={handleRegionInputBlur}
                          onClick={() => handleRegionInputClick(item, index)}
                          style={{
                            border:
                              validationFlags[index]?.isRegionInvalid &&
                              uncompletedItems.region !== null
                                ? "1px solid red"
                                : "1px solid transparent",
                          }}
                        />
                        {item.region !== null ? (
                          <CloseIcon
                            onClick={() => handleRemoveRegion(index)}
                          />
                        ) : (
                          <ArrowChevron
                            onClick={() => handleRegionInputClick(item, index)}
                          />
                        )}
                      </div>
                      {regionSearchActive === index && regionData?.data && (
                        <div
                          onMouseEnter={() => setIsMouseOverList(true)}
                          onMouseLeave={() => setIsMouseOverList(false)}
                        >
                          {filteredRegions && filteredRegions.length > 0 ? (
                            <ul>
                              {filteredRegions.map((item) => (
                                <li
                                  key={item.id}
                                  onClick={() => {
                                    handleRegionSelectClick(item, index);
                                    setRegionSearchActive(false);
                                  }}
                                >
                                  {item.attributes.name}
                                </li>
                              ))}
                            </ul>
                          ) : (
                            <p
                              style={{ marginTop: "15px" }}
                              className={styles.dropDownListEmpty}
                            >
                              The list is empty
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                    <div className={styles.tools}>
                      <span onClick={() => moveToCompleted(index)}>
                        <CheckMarkIcon />
                      </span>
                      <span
                        onClick={() => handleUncompletedItemsDeleteItem(index)}
                      >
                        <CloseIcon />
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
);
const AddTags = ({ addTags, setAddTags }) => {
  const [tagsData, setTagsData] = useState(null);
  const [tagsSearch, setTagsSearch] = useState("");
  const [selectTagsActive, setSelectTagsActive] = useState(false);
  const [debouncedSearchTags] = useDebounce(tagsSearch, 1000);
  useEffect(() => {
    if (selectTagsActive) {
      const fetchData = async () => {
        try {
          const formattedSeach = normalizeInput(debouncedSearchTags);
          const response = await axios.get(
            `${url}/api/tags?filters[name][$contains]=${formattedSeach}&fields[0]=name&sort=name:asc&pagination[limit]=14`
          );
          setTagsData(response.data);
        } catch (error) {
          console.error("Ошибка при получении данных:", error);
        }
      };
      fetchData();
    }
  }, [debouncedSearchTags, selectTagsActive]);
  const handleTagsChange = (e) => {
    setTagsSearch(e.target.value);
    setSelectTagsActive(true);
  };
  const handleSelectTags = (item) => {
    if (!addTags) {
      setAddTags([item]);
    } else {
      setAddTags([...addTags, item]);
    }
    setSelectTagsActive(false);
  };
  const handleTagsKeyPress = (e) => {
    if (e.key === "Enter") {
      const searchText = e.target.value.trim();
      const exactMatch = tagsData.data.find(
        (item) => item.attributes.name.trim() === searchText
      );

      // Проверяем, существует ли тег с таким же именем или id в addTags
      const tagAlreadyExists = addTags.some(
        (tag) =>
          tag.attributes.name.trim() === searchText || tag.id === exactMatch?.id
      );

      if (!tagAlreadyExists) {
        if (exactMatch) {
          setAddTags((prevTags) => [...prevTags, exactMatch]);
        } else {
          // Создаем новый элемент с id равным null
          setAddTags((prevTags) => [
            ...prevTags,
            { id: null, attributes: { name: searchText } },
          ]);
        }
      }
      setSelectTagsActive(false);
      setTagsSearch("");
    }
  };
  const handleRemoveTags = (index) => {
    const updatedGenres = [...addTags];
    updatedGenres.splice(index, 1);
    setAddTags(updatedGenres);
  };
  const handleTagsClick = () => {
    setSelectTagsActive(!selectTagsActive);
  };
  const handleTagsInputBlur = () => {
    setTimeout(() => {
      setSelectTagsActive(false);
    }, 100);
  };
  const { gameOutside, gameTags, gameNewTags } = useSelector(
    (state) => state.addGame
  );
  useEffect(() => {
    const validGameTags = Array.isArray(gameTags) ? gameTags : [];
    const validGameNewTags = Array.isArray(gameNewTags) ? gameNewTags : [];

    if (gameOutside) {
      setAddTags((prevTags) => {
        return [...validGameNewTags, ...validGameTags];
      });
    } else {
      setAddTags([]);
    }
  }, [gameOutside, gameTags, gameNewTags]);

  return (
    <div className={styles.item}>
      <span className={styles.sectionTitle}>
        <SettingIcon />
        <span>Tags:</span>
      </span>
      <div className={styles.itemSelectSection}>
        <div>
          <div
            className={`${styles.inputSelect} ${
              selectTagsActive ? styles.inputSelectActive : ""
            }`}
            onClick={handleTagsClick}
          >
            <input
              type="text"
              placeholder="Select a few from the list"
              onChange={handleTagsChange}
              value={tagsSearch}
              onBlur={handleTagsInputBlur}
              onKeyPress={handleTagsKeyPress}
              maxLength={15}
            />
            {selectTagsActive ? (
              <CloseIcon onClick={() => setTagsSearch("")} />
            ) : (
              <ArrowChevron />
            )}
            {tagsData && Array.isArray(tagsData.data) && (
              <div
                className={`${styles.inputSelect__dropDown} ${
                  selectTagsActive ? styles.active : ""
                }`}
              >
                {tagsData.data.length > 0 ? (
                  <ul>
                    {tagsData.data.map((item, index) => {
                      const isSelected =
                        addTags &&
                        addTags.some((genre) => genre.id === item.id);

                      if (!isSelected) {
                        return (
                          <li
                            key={item.id}
                            onClick={() => handleSelectTags(item)}
                          >
                            {item.attributes.name}
                          </li>
                        );
                      }
                      return (
                        <li
                          key={item.id}
                          style={{
                            color: " rgb(97, 97, 97)",
                            cursor: "default",
                          }}
                        >
                          {item.attributes.name}
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <p className={styles.dropDownListEmpty}>The list is empty</p>
                )}
              </div>
            )}
          </div>
        </div>

        <div className={styles.itemSelectElements}>
          {addTags &&
            addTags.map((item, index) => (
              <span key={index}>
                <p>{item.attributes.name}</p>
                <CloseIcon onClick={() => handleRemoveTags(index)} />
              </span>
            ))}
        </div>
      </div>
    </div>
  );
};
const SelectFromListItems = ({
  index,
  storageSpace,
  setStorageSpace,
  params,
  elementTitle,
}) => {
  const [storageDataFromApi, setStorageDataFromApi] = useState([]);
  const [searchResult, setSearchResult] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [debouncedSearch] = useDebounce(searchResult, 1000);
  useEffect(() => {
    if (searchActive) {
      const fetchData = async () => {
        try {
          const formattedSearch = normalizeInput(debouncedSearch);

          // Разделяем params на две части: до и после 'formattedSearch'
          const parts = params.split("${formattedSearch}");
          const firstPartLink = parts[0];
          const lastPartLink = parts[1];
          // Создаем окончательный URL, вставляя formattedSearch между двумя частями
          const apiUrl = `${url}${firstPartLink}${formattedSearch}${lastPartLink}`;
          const response = await axios.get(apiUrl);
          setStorageDataFromApi(response.data);
        } catch (error) {
          console.error("Ошибка при получении данных:", error);
        }
      };
      fetchData();
    }
  }, [debouncedSearch, searchActive]);

  const handleItemChange = (e) => {
    setSearchResult(e.target.value);
    setSearchActive(true);
  };
  const handleSelectItem = (item) => {
    setStorageSpace((current) =>
      current.map((selection, idx) => {
        if (idx === index) {
          const platforms = selection.platforms ? [...selection.platforms] : [];
          if (!platforms.find((platformItem) => platformItem.id === item.id)) {
            platforms.push(item);
          }
          return { ...selection, platforms };
        }
        return selection;
      })
    );
    setSearchActive(false);
  };
  const handleRemoveItem = (idx) => {
    setStorageSpace((prevStorageSpace) => {
      const updatedStorageSpace = [...prevStorageSpace];
      updatedStorageSpace[index] = {
        ...updatedStorageSpace[index],
        platforms: updatedStorageSpace[index].platforms.filter((_, i) => {
          return i !== idx;
        }),
      };
      return updatedStorageSpace;
    });
  };
  const handleItemClick = () => {
    setSearchActive(!searchActive);
  };
  const handleInputBlur = () => {
    setTimeout(() => {
      setSearchActive(false);
    }, 100);
  };

  /*  const handleItemKeyPress = (e) => {
    if (e.key === "Enter") {
      const searchText = e.target.value.trim().toLowerCase();
      const exactMatch = companiesData.data.find(
        (item) => item.attributes.name.trim().toLowerCase() === searchText
      );
  
      if (exactMatch) {
        // Проверяем, существует ли найденный элемент в uncompletedItems или completedItems
        const isInUncompletedItems = uncompletedItems.some(
          (item) => item.company.id === exactMatch.id
        );
        const isInCompletedItems = completedItems.some(
          (item) => item.company.id === exactMatch.id
        );
  
        // Если элемента нет ни в uncompletedItems, ни в completedItems, добавляем его в uncompletedItems
        if (!isInUncompletedItems && !isInCompletedItems) {
          setUncompletedItems((prevItems) => [...prevItems, { company: exactMatch, switch: { publisher: false, mainDeveloper: false, portingDeveloper: false, supportingDeveloper: false } }]);
          setCompaniesSearch("");
          setSelectCompaniesActive(false);
        }
      }
    }
  }; */
  return (
    <div className={styles.item}>
      <span className={styles.sectionTitle}>
        <SettingIcon />
        <span>{elementTitle}:</span>
      </span>
      <div className={styles.itemSelectSection}>
        <div>
          <div
            className={`${styles.inputSelect} ${
              searchActive ? styles.inputSelectActive : ""
            }`}
            onClick={handleItemClick}
          >
            <input
              type="text"
              placeholder="Select a few from the list"
              onChange={handleItemChange}
              value={searchResult}
              onBlur={handleInputBlur}
            />
            {searchActive ? (
              <CloseIcon onClick={() => setSearchResult("")} />
            ) : (
              <ArrowChevron />
            )}

            {storageDataFromApi && Array.isArray(storageDataFromApi.data) && (
              <div
                className={`${styles.inputSelect__dropDown} ${
                  searchActive ? styles.active : ""
                }`}
              >
                {storageDataFromApi.data.length > 0 ? (
                  <ul>
                    {storageDataFromApi.data.map((item, index) => {
                      const isSelected =
                        storageSpace &&
                        storageSpace.some((storageItem) => {
                          return storageItem.platforms.some(
                            (platform) => platform.id === item.id
                          );
                        });
                      return (
                        <li
                          key={item.id}
                          onClick={() => handleSelectItem(item, index)}
                          /*  style={{
                            color: isSelected ? "rgb(97, 97, 97)" : "",
                            cursor: isSelected ? "default" : "pointer",
                          }} */
                        >
                          {item.attributes.name}
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <p className={styles.dropDownListEmpty}>The list is empty</p>
                )}
              </div>
            )}
          </div>
        </div>
        <div className={styles.itemSelectElements}>
          {storageSpace[index].platforms &&
            storageSpace[index].platforms.map((platform, idx) => (
              <span
                key={idx}
                style={
                  platform.attributes.color
                    ? { background: platform.attributes.color }
                    : {}
                }
              >
                <p>{platform.attributes.name}</p>
                <CloseIcon onClick={() => handleRemoveItem(idx)} />
              </span>
            ))}
        </div>
      </div>
    </div>
  );
};
const AddCompanies = forwardRef(
  (
    {
      setAddCompanies,
      addCompanies,
      addCompaniesVerify,
      setAddCompaniesVerify,
    },
    ref
  ) => {
    const { gameOutside, gameCompanies, gameNewCompanies } = useSelector(
      (state) => state.addGame
    );
    const [completedItems, setCompletedItems] = useState([]);
    const [uncompletedItems, setUncompletedItems] = useState([]);

    //-------- Search and Add Companies -------\\\\
    const [unmovableItems, setUnmovableItems] = useState([]);
    const [companiesData, setCompaniesData] = useState(null);
    const [companiesSearch, setCompaniesSearch] = useState("");
    const [selectCompaniesActive, setSelectCompaniesActive] = useState(false);
    const [debouncedSearchThemes] = useDebounce(companiesSearch, 1000);
    useEffect(() => {
      if (selectCompaniesActive) {
        const fetchData = async () => {
          try {
            const formattedSearch = normalizeInput(debouncedSearchThemes);
            const response = await axios.get(
              `${url}/api/companies?filters[name][$contains]=${formattedSearch}&fields[0]=name&sort=name:asc`
            );
            setCompaniesData(response.data);
          } catch (error) {
            console.error("Ошибка при получении данных:", error);
          }
        };
        fetchData();
      }
    }, [debouncedSearchThemes, selectCompaniesActive]);
    const handleCompanyChange = (e) => {
      setCompaniesSearch(e.target.value);
      setSelectCompaniesActive(true);
    };
    const handleRemoveUncompletedCompany = (index) => {
      const updatedThemes = [...uncompletedItems];
      updatedThemes.splice(index, 1);
      setUncompletedItems(updatedThemes);
    };
    const handleRemoveCompletedCompany = (index) => {
      const updatedThemes = [...completedItems];
      updatedThemes.splice(index, 1);
      setCompletedItems(updatedThemes);
    };
    const handleCompanyClick = () => {
      setSelectCompaniesActive(!selectCompaniesActive);
    };
    const handleCompanyInputBlur = () => {
      setTimeout(() => {
        setSelectCompaniesActive(false);
      }, 100);
    };
    const handleSelectCompany = (item) => {
      // Проверяем, существует ли найденный элемент в uncompletedItems или completedItems
      const isInUncompletedItems = uncompletedItems.some(
        (existingItem) => existingItem.company.id === item.id
      );
      const isInCompletedItems = completedItems.some(
        (existingItem) => existingItem.company.id === item.id
      );

      // Если элемента нет ни в uncompletedItems, ни в completedItems, добавляем его в uncompletedItems
      if (!isInUncompletedItems && !isInCompletedItems) {
        setUncompletedItems((prevItems) => [
          ...prevItems,
          {
            company: item,
            switch: [
              { state: false, title: "Publisher" },
              { state: false, title: "Main Dev" },
              { state: false, title: "Supporting Dev" },
              { state: false, title: "Porting Dev" },
            ],
            platforms: [],
          },
        ]);
        setCompaniesSearch("");
        setSelectCompaniesActive(false);
      }
    };
    const handleSearchCompanyKeyPress = (e) => {
      if (e.key === "Enter") {
        const searchText = e.target.value.trim().toLowerCase();
        const exactMatch = companiesData.data.find(
          (item) => item.attributes.name.trim().toLowerCase() === searchText
        );

        if (exactMatch) {
          // Проверяем, существует ли найденный элемент в uncompletedItems или completedItems
          const isInUncompletedItems = uncompletedItems.some(
            (item) => item.company.id === exactMatch.id
          );
          const isInCompletedItems = completedItems.some(
            (item) => item.company.id === exactMatch.id
          );

          // Если элемента нет ни в uncompletedItems, ни в completedItems, добавляем его в uncompletedItems
          if (!isInUncompletedItems && !isInCompletedItems) {
            setUncompletedItems((prevItems) => [
              ...prevItems,
              {
                company: exactMatch,
                switch: {
                  publisher: false,
                  mainDeveloper: false,
                  portingDeveloper: false,
                  supportingDeveloper: false,
                },
              },
            ]);
            setCompaniesSearch("");
            setSelectCompaniesActive(false);
          }
        }
      }
    };
    const handleToggleSwitchChange = (companyIndex, switchIndex, checked) => {
      setUncompletedItems((prevItems) => {
        return prevItems.map((item, index) => {
          if (index === companyIndex) {
            return {
              ...item,
              switch: item.switch.map((switchItem, i) => {
                if (i === switchIndex) {
                  return {
                    ...switchItem,
                    state: checked,
                  };
                }
                return switchItem;
              }),
            };
          }
          return item;
        });
      });
    };
    const moveToCompleted = (index) => {
      const item = uncompletedItems[index];

      // if (item.platforms.length === 0) {
      //   // Если платформы отсутствуют, добавляем индекс элемента в unmovableItems
      //   if (!unmovableItems.includes(index)) {
      //     setUnmovableItems((prevItems) => [...prevItems, index]);
      //   }
      //   return; // Завершаем выполнение функции после добавления индекса в unmovableItems
      // }

      // Если платформы присутствуют, удаляем индекс элемента из unmovableItems
      setUnmovableItems((prevItems) =>
        prevItems.filter((itemIndex) => itemIndex !== index)
      );

      // Перемещаем элемент в completedItems
      const newUncompletedItems = [
        ...uncompletedItems.slice(0, index),
        ...uncompletedItems.slice(index + 1),
      ];
      const newCompletedItems = [...completedItems, item];
      setUncompletedItems(newUncompletedItems);
      setCompletedItems(newCompletedItems);

      // Перестраиваем индексы в массиве unmovableItems
      setUnmovableItems((prevItems) => adjustIndexes(index, prevItems));
    };
    const moveToUncompleted = (index) => {
      const item = completedItems[index];

      const newCompletedItems = [
        ...completedItems.slice(0, index),
        ...completedItems.slice(index + 1),
      ];
      const newUncompletedItems = [...uncompletedItems, item];
      setCompletedItems(newCompletedItems);
      setUncompletedItems(newUncompletedItems);
    };
    const handleCompletedItemsDeleteItem = (index) => {
      setCompletedItems((prevItems) => prevItems.filter((_, i) => i !== index));
    };
    useEffect(() => {
      const validGameCompanies = Array.isArray(gameCompanies)
        ? gameCompanies
        : [];
      const validGameNewCompanies = Array.isArray(gameNewCompanies)
        ? gameNewCompanies
        : [];

      if (gameOutside) {
        setUncompletedItems([...validGameCompanies, ...validGameNewCompanies]);
        setCompletedItems([]);
      } else {
        setUncompletedItems([]);
        setCompletedItems([]);
      }
    }, [gameOutside, gameCompanies, gameNewCompanies]);

    useEffect(() => {
      if (uncompletedItems.length === 0) {
        setAddCompanies(completedItems);
        setAddCompaniesVerify(true);
      } else {
        setAddCompanies(null);
        setAddCompaniesVerify(false);
      }
    }, [completedItems, uncompletedItems, addCompanies]);

    return (
      <div ref={ref} className={styles.item}>
        <span className={styles.sectionTitle}>
          <SettingIcon />
          <span>Companies:</span>
        </span>
        <div className={styles.itemTogetherRelations}>
          <div
            className={`${styles.inputSelect} ${
              selectCompaniesActive ? styles.inputSelectActive : ""
            }`}
            onClick={handleCompanyClick}
          >
            <input
              type="text"
              placeholder="Select a few from the list"
              onChange={handleCompanyChange}
              value={companiesSearch}
              onBlur={handleCompanyInputBlur}
              onKeyPress={handleSearchCompanyKeyPress}
            />
            {selectCompaniesActive ? (
              <CloseIcon onClick={() => setCompaniesSearch("")} />
            ) : (
              <ArrowChevron />
            )}
            {companiesData &&
              companiesData.data &&
              Array.isArray(companiesData.data) && (
                <div
                  className={`${styles.inputSelect__dropDown} ${
                    selectCompaniesActive ? styles.active : ""
                  }`}
                >
                  {companiesData.data.length > 0 ? (
                    <ul>
                      {companiesData.data.map((item, index) => {
                        const isInUncompletedItems =
                          uncompletedItems &&
                          uncompletedItems.some(
                            (selectedItem) =>
                              selectedItem.company.id === item.id
                          );
                        const isInCompletedItems =
                          completedItems &&
                          completedItems.some(
                            (selectedItem) =>
                              selectedItem.company.id === item.id
                          );

                        const isSelected =
                          isInUncompletedItems || isInCompletedItems;

                        if (!isSelected) {
                          return (
                            <li
                              key={item.id}
                              onClick={() => handleSelectCompany(item)}
                            >
                              {item.attributes.name}
                            </li>
                          );
                        } else {
                          return (
                            <li
                              key={item.id}
                              style={{
                                color: " rgb(97, 97, 97)",
                                cursor: "default",
                              }}
                            >
                              {item.attributes.name}
                            </li>
                          );
                        }
                      })}
                    </ul>
                  ) : (
                    <p className={styles.dropDownListEmpty}>
                      The list is empty
                    </p>
                  )}
                </div>
              )}
          </div>
          <div className={styles.itemTogetherRelations__items}>
            {completedItems.map((item, index) => {
              return (
                <div key={index} className={styles.toRow}>
                  <div className={styles.itemTogetherRelations__ellips}>
                    <div></div>
                    {index !== 0 && <span></span>}
                  </div>
                  <div className={styles.itemRelationsComponent}>
                    <div className={styles.itemRelationsComponent__header}>
                      <p>{item.company.attributes.name}</p>
                      <div className={styles.tools}>
                        <span onClick={() => moveToUncompleted(index)}>
                          <PenIcon  />
                        </span>
                        <span onClick={() =>
                              handleCompletedItemsDeleteItem(index)
                            }>
                          <CloseIcon
                            
                          />
                        </span>
                      </div>
                    </div>
                    <div className={styles.itemRelationsComponent__settings}>
                      {item.switch.map((switchItem, switchIndex) => (
                        <ToggleSwitch
                          key={switchIndex}
                          title={switchItem.title}
                          isChecked={switchItem.state}
                          onChange={(checked) =>
                            handleToggleSwitchChange(
                              index,
                              switchIndex,
                              checked
                            )
                          }
                        />
                      ))}
                    </div>
                    <div
                      className={styles.twoLineItems}
                      style={{ padding: "0 15px" }}
                    >
                      <div className={styles.item}>
                        <span className={styles.sectionTitle}>
                          <PlatformIcon />
                          <span>Platforms:</span>
                        </span>

                        <div className={styles.itemSelectSection}>
                          <div className={styles.itemSelectElements}>
                            {item.platforms.map((plat) => {
                              return (
                                <span
                                  key={plat.id}
                                  style={
                                    plat.attributes.color
                                      ? { background: plat.attributes.color }
                                      : {}
                                  }
                                >
                                  <p>{plat.attributes.name}</p>
                                </span>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            {uncompletedItems.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{ overflow: "visible" }}
                  className={`${styles.toRow}
                
                `}

                  // ${unmovableItems.some((itemIndex) => itemIndex === index) &&
                  //   item.platforms.length < 1
                  //     ? ` ${styles.companyError}`
                  //     : ""
                  // }
                >
                  <div className={styles.itemTogetherRelations__ellips}>
                    <div></div>
                    {(completedItems.length > 0 || index !== 0) && (
                      <span></span>
                    )}
                  </div>
                  <div className={styles.itemRelationsComponent}>
                    <div className={styles.itemRelationsComponent__header}>
                      <p>{item.company.attributes.name}</p>
                      <div className={styles.tools}>
                        <span  onClick={() => moveToCompleted(index)}>
                          <CheckMarkIcon
                           
                          />
                        </span>
                        <span  onClick={() => handleRemoveCompletedCompany(index)}>
                          <CloseIcon
                           
                          />
                        </span>
                      </div>
                    </div>
                    <div className={styles.itemRelationsComponent__settings}>
                      {item.switch.map((switchItem, switchIndex) => (
                        <ToggleSwitch
                          key={switchIndex}
                          title={switchItem.title}
                          isChecked={switchItem.state}
                          onChange={(checked) =>
                            handleToggleSwitchChange(
                              index,
                              switchIndex,
                              checked
                            )
                          }
                        />
                      ))}
                    </div>
                    <div
                      className={styles.twoLineItems}
                      style={{ padding: "0 15px" }}
                    >
                      <SelectFromListItems
                        key={index}
                        index={index}
                        storageSpace={uncompletedItems}
                        setStorageSpace={setUncompletedItems}
                        params="/api/platforms?filters[name][$contains]=${formattedSearch}&fields[0]=name&fields[1]=color&sort=name:asc"
                        elementTitle="Platforms"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
);
const AddSocialLink = forwardRef(
  (
    { socialLinks, setSocialLinks, socialLinksVerify, setSocialLinksVerify },
    ref
  ) => {
    const { gameOutside, gameSocialLinks } = useSelector(
      (state) => state.addGame
    );

    const [completedItems, setCompletedItems] = useState([]);
    const [uncompletedItems, setUncompletedItems] = useState([]);
    const [socialData, setSocialData] = useState({ data: [] });
    const [addSocialLink, setAddSocialLink] = useState(false);
    const [socialSearch, setSocialSearch] = useState("");
    const socialInputs = useRef(uncompletedItems.map(() => React.createRef()));
    const [socialSearchActive, setSocialSearchActive] = useState(null);
    const [debouncedSocialSearch] = useDebounce(socialSearch, 1000);
    const [
      editedUncompletedItemNameActive,
      setEditedUncompletedItemNameActive,
    ] = useState(null);
    const [editedUncompletedItemIndex, setEditedUncompletedItemIndex] =
      useState(null);
    const [editedUncompletedItemName, setEditedUncompletedItemName] =
      useState("");
    const [isMouseOverList, setIsMouseOverList] = useState(false);
    const [validationFlags, setValidationFlags] = useState({});
    useEffect(() => {
      if (socialSearchActive !== null) {
        const fetchData = async () => {
          try {
            const formattedSeach = normalizeInput(debouncedSocialSearch);
            const response = await axios.get(
              `${url}/api/socials?filters[name][$contains]=${formattedSeach}&fields[0]=name&sort=name:asc`
            );
            setSocialData(response.data);
          } catch (error) {
            console.error("Ошибка при получении данных:", error);
          } finally {
          }
        };

        fetchData();
      }
    }, [debouncedSocialSearch, socialSearchActive]);

    const handleSocialChange = (e, index) => {
      if (!e || !e.target) {
        return;
      }
      setSocialSearch(e.target.value);
      setSocialSearchActive(index);
    };
    const handleAddNewItemPress = (event) => {
      if (event.key === "Enter") {
        const inputText = event.target.value.trim();
        if (inputText !== "") {
          const newItem = {
            name: inputText,
            social: null,
          };
          setUncompletedItems([...uncompletedItems, newItem]);
          setAddSocialLink(false);
          event.target.value = "";
          event.target.blur();
        }
      }
    };
    const handleUncompletedItemInputChange = (index, event) => {
      setEditedUncompletedItemIndex(index);
      setEditedUncompletedItemName(event.target.value);

      const newItems = [...uncompletedItems];
      newItems[index].name = event.target.value;
      setUncompletedItems(newItems);
    };
    const handleUncompletedItemsDeleteItem = (index) => {
      setUncompletedItems((prevItems) =>
        prevItems.filter((_, i) => i !== index)
      );
      setValidationFlags((prevFlags) => {
        const newFlags = { ...prevFlags };
        delete newFlags[index]; // Удаление флагов для текущего индекса

        // Необходимо обновить индексы для оставшихся флагов, так как индекс массива изменился
        Object.keys(newFlags).forEach((key) => {
          if (parseInt(key) > index) {
            newFlags[key - 1] = newFlags[key];
            delete newFlags[key];
          }
        });

        return newFlags;
      });
    };
    const moveToCompleted = (index) => {
      const item = uncompletedItems[index];

      const invalidSocial = item.social === null;
      const emptyName = item.name === "";

      // Обновляем флаги валидации для текущего индекса
      setValidationFlags((prev) => ({
        ...prev,
        [index]: { isSocialInvalid: invalidSocial, isNameEmpty: emptyName },
      }));

      if (invalidSocial || emptyName) {
        return;
      }

      const newUncompletedItems = [
        ...uncompletedItems.slice(0, index),
        ...uncompletedItems.slice(index + 1),
      ];
      const newCompletedItems = [...completedItems, item];
      setUncompletedItems(newUncompletedItems);
      setCompletedItems(newCompletedItems);

      setValidationFlags((prev) => {
        const newFlags = { ...prev };
        delete newFlags[index];
        return newFlags;
      });
    };
    const handleSocialSelectClick = (item, itemIndex) => {
      const newItem = {
        id: item.id,
        name: item.attributes.name,
      };
      setUncompletedItems((items) =>
        items.map((item, index) =>
          index === itemIndex ? { ...item, social: newItem } : item
        )
      );
    };
    const toggleSocialSearchActiveState = (index) => {
      if (socialSearchActive === index) {
        setSocialSearchActive(null);
      } else {
        setSocialSearchActive(index);
      }
    };
    const toggleEditedUncompletedItemNameActiveState = (index) => {
      if (editedUncompletedItemNameActive === index) {
        setEditedUncompletedItemNameActive(null);
      } else {
        setEditedUncompletedItemNameActive(index);
      }
    };
    const handleCompletedItemsDeleteItem = (index) => {
      setCompletedItems((prevItems) => prevItems.filter((_, i) => i !== index));
    };
    const moveToUncompleted = (index) => {
      const item = completedItems[index];
      const newCompletedItems = [
        ...completedItems.slice(0, index),
        ...completedItems.slice(index + 1),
      ];
      const newUncompletedItems = [...uncompletedItems, item];
      setCompletedItems(newCompletedItems);
      setUncompletedItems(newUncompletedItems);
    };
    const handleRemoveSocial = (index) => {
      setUncompletedItems((items) =>
        items.map((item, itemIndex) =>
          itemIndex === index ? { ...item, social: null } : item
        )
      );
    };
    const handleSocialInputBlur = () => {
      setSocialSearch("");
      setTimeout(() => {
        if (!isMouseOverList) {
          setSocialSearchActive(null);
        }
      }, 0);
    };
    const handleSocialInputClick = (item, index) => {
      if (item.social === null && socialSearch !== "") {
        setSocialSearchActive(index);
      } else {
        setSocialSearchActive(null);
      }
      setTimeout(() => {
        toggleSocialSearchActiveState(index);
      }, 0);
    };
    const filteredSocials = socialData.data.filter((item) => {
      const isCompleted =
        uncompletedItems.some(
          (completedItem) =>
            completedItem.social && completedItem.social.id === item.id
        ) ||
        completedItems.some(
          (completedItem) => completedItem.social.id === item.id
        );
      return !isCompleted;
    });

    useEffect(() => {
      if (gameOutside) {
        const validData = Array.isArray(gameSocialLinks) ? gameSocialLinks : [];

        setUncompletedItems(validData);
        setCompletedItems([]);

        // Обновление socialInputs
        socialInputs.current = validData.map((el, index) => {
          const inputRef = React.createRef();
          inputRef.current = el;
          return inputRef;
        });
      } else {
        setUncompletedItems([]);
        setCompletedItems([]);

        socialInputs.current = [];
      }
    }, [gameOutside, gameSocialLinks]);

    useEffect(() => {
      if (uncompletedItems.length === 0) {
        setSocialLinks(completedItems);
        setSocialLinksVerify(true);
      } else {
        setSocialLinks(null);
        setSocialLinksVerify(true);
      }
    }, [completedItems, uncompletedItems, setSocialLinks]);
    return (
      <div ref={ref} className={styles.item}>
        <span className={styles.sectionTitle}>
          <SettingIcon />
          <span>Social Links:</span>
        </span>
        <div className={styles.itemTogetherRelations}>
          <input
            className={`${styles.inputWrite} ${
              addSocialLink ? styles.inputWriteActive : ""
            }`}
            type="text"
            placeholder="Start typing..."
            onBlur={() => setAddSocialLink(false)}
            onClick={() => setAddSocialLink(!addSocialLink)}
            onKeyPress={handleAddNewItemPress}
          />
          <div className={styles.itemTogetherRelations__items}>
            {completedItems.map((item, index) => {
              return (
                <div key={index} className={styles.toRow}>
                  <div className={styles.itemTogetherRelations__ellips}>
                    <div></div>
                    {index !== 0 && <span></span>}
                  </div>
                  <div className={styles.itemTogetherRelations__item}>
                    <div
                      className={styles.itemTogetherRelations__title}
                      readOnly
                    >
                      <input type="text" readOnly value={item.name} />
                    </div>
                    <span
                      className={styles.itemTogetherRelations__separate}
                    ></span>

                    <div
                      className={styles.itemTogetherRelations__region}
                      readOnly
                    >
                      <div>
                        <input type="text" readOnly value={item.social.name} />
                      </div>
                    </div>

                    <div className={styles.tools}>
                      <span onClick={() => moveToUncompleted(index)}>
                        <PenIcon />
                      </span>
                      <span
                        onClick={() => handleCompletedItemsDeleteItem(index)}
                      >
                        <CloseIcon />
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
            {uncompletedItems.map((item, index) => {
              return (
                <div
                  key={index}
                  className={styles.toRow}
                  style={{ overflow: "visible" }}
                >
                  <div className={styles.itemTogetherRelations__ellips}>
                    <div></div>
                    {(completedItems.length > 0 || index !== 0) && (
                      <span></span>
                    )}
                  </div>
                  <div className={styles.itemTogetherRelations__item}>
                    <div className={styles.itemTogetherRelations__title}>
                      <input
                        style={{
                          border:
                            editedUncompletedItemNameActive === index
                              ? "1px solid rgb(97, 97, 97)"
                              : validationFlags[index]?.isNameEmpty &&
                                uncompletedItems.name !== ""
                              ? "1px solid red"
                              : "1px solid transparent",
                        }}
                        type="text"
                        value={
                          index === setEditedUncompletedItemIndex
                            ? setEditedUncompletedItemName
                            : item.name
                        }
                        placeholder="Link"
                        onBlur={() => setEditedUncompletedItemNameActive(null)}
                        onClick={() =>
                          toggleEditedUncompletedItemNameActiveState(index)
                        }
                        onChange={(event) =>
                          handleUncompletedItemInputChange(index, event)
                        }
                      />
                    </div>
                    <span
                      className={styles.itemTogetherRelations__separate}
                    ></span>
                    <div
                      className={`${styles.itemTogetherRelations__region} ${
                        socialSearchActive === index
                          ? styles.itemTogetherRelations__regionActive
                          : ""
                      }`}
                    >
                      <div>
                        <input
                          type="text"
                          placeholder="Platform"
                          value={
                            item.social
                              ? item.social.name
                              : index === socialSearchActive
                              ? socialSearch
                              : ""
                          }
                          onChange={(e) => handleSocialChange(e, index)}
                          ref={(el) => (socialInputs.current[index] = el)}
                          readOnly={!!(item.social && item.social.name)}
                          onBlur={handleSocialInputBlur}
                          onClick={() => handleSocialInputClick(item, index)}
                          style={{
                            border:
                              validationFlags[index]?.isSocialInvalid &&
                              uncompletedItems.social !== null
                                ? "1px solid red"
                                : "1px solid transparent",
                          }}
                        />
                        {item.social !== null ? (
                          <CloseIcon
                            onClick={() => handleRemoveSocial(index)}
                          />
                        ) : (
                          <ArrowChevron
                            onClick={() => handleSocialInputClick(item, index)}
                          />
                        )}
                      </div>
                      {socialSearchActive === index && socialData?.data && (
                        <div
                          onMouseEnter={() => setIsMouseOverList(true)}
                          onMouseLeave={() => setIsMouseOverList(false)}
                        >
                          {filteredSocials && filteredSocials.length > 0 ? (
                            <ul>
                              {filteredSocials.map((item) => (
                                <li
                                  key={item.id}
                                  onClick={() => {
                                    handleSocialSelectClick(item, index);
                                    setSocialSearchActive(false);
                                  }}
                                >
                                  {item.attributes.name}
                                </li>
                              ))}
                            </ul>
                          ) : (
                            <p
                              style={{ marginTop: "15px" }}
                              className={styles.dropDownListEmpty}
                            >
                              The list is empty
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                    <div className={styles.tools}>
                      <span onClick={() => moveToCompleted(index)}>
                        <CheckMarkIcon />
                      </span>
                      <span
                        onClick={() => handleUncompletedItemsDeleteItem(index)}
                      >
                        <CloseIcon />
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
);
const AddStoreLink = forwardRef(
  (
    { storeLinks, setStoreLinks, storeLinksVerify, setStoreLinksVerify },
    ref
  ) => {
    const [completedItems, setCompletedItems] = useState([]);
    const [uncompletedItems, setUncompletedItems] = useState([]);
    const [storesData, setStoresData] = useState({ data: [] });
    const [addStoresLink, setAddStoresLink] = useState(false);
    const [storesSearch, setStoresSearch] = useState("");
    const storesInputs = useRef(uncompletedItems.map(() => React.createRef()));
    const [storesSearchActive, setStoresSearchActive] = useState(null);
    const [debouncedStoresSearch] = useDebounce(storesSearch, 1000);
    const [
      editedUncompletedItemNameActive,
      setEditedUncompletedItemNameActive,
    ] = useState(null);
    const [editedUncompletedItemIndex, setEditedUncompletedItemIndex] =
      useState(null);
    const [editedUncompletedItemName, setEditedUncompletedItemName] =
      useState("");
    const [isMouseOverList, setIsMouseOverList] = useState(false);
    const [validationFlags, setValidationFlags] = useState({});
    useEffect(() => {
      if (storesSearchActive !== null) {
        const fetchData = async () => {
          try {
            const formattedSeach = normalizeInput(debouncedStoresSearch);
            const response = await axios.get(
              `${url}/api/stores?filters[name][$contains]=${formattedSeach}&fields[0]=name&sort=name:asc`
            );
            setStoresData(response.data);
          } catch (error) {
            console.error("Ошибка при получении данных:", error);
          } finally {
          }
        };

        fetchData();
      }
    }, [debouncedStoresSearch, storesSearchActive]);

    const handleStoresChange = (e, index) => {
      if (!e || !e.target) {
        return;
      }
      setStoresSearch(e.target.value);
      setStoresSearchActive(index);
    };
    const handleAddNewItemPress = (event) => {
      if (event.key === "Enter") {
        const inputText = event.target.value.trim();
        if (inputText !== "") {
          const newItem = {
            name: inputText,
            stores: null,
          };
          setUncompletedItems([...uncompletedItems, newItem]);
          setAddStoresLink(false);
          event.target.value = "";
          event.target.blur();
        }
      }
    };
    const handleUncompletedItemInputChange = (index, event) => {
      setEditedUncompletedItemIndex(index);
      setEditedUncompletedItemName(event.target.value);

      const newItems = [...uncompletedItems];
      newItems[index].name = event.target.value;
      setUncompletedItems(newItems);
    };
    const handleUncompletedItemsDeleteItem = (index) => {
      setUncompletedItems((prevItems) =>
        prevItems.filter((_, i) => i !== index)
      );
      setValidationFlags((prevFlags) => {
        const newFlags = { ...prevFlags };
        delete newFlags[index]; // Удаление флагов для текущего индекса

        // Необходимо обновить индексы для оставшихся флагов, так как индекс массива изменился
        Object.keys(newFlags).forEach((key) => {
          if (parseInt(key) > index) {
            newFlags[key - 1] = newFlags[key];
            delete newFlags[key];
          }
        });

        return newFlags;
      });
    };
    const moveToCompleted = (index) => {
      const item = uncompletedItems[index];

      const invalidStores = item.stores === null;
      const emptyName = item.name === "";

      // Обновляем флаги валидации для текущего индекса
      setValidationFlags((prev) => ({
        ...prev,
        [index]: { isStoresInvalid: invalidStores, isNameEmpty: emptyName },
      }));

      if (invalidStores || emptyName) {
        return;
      }

      const newUncompletedItems = [
        ...uncompletedItems.slice(0, index),
        ...uncompletedItems.slice(index + 1),
      ];
      const newCompletedItems = [...completedItems, item];
      setUncompletedItems(newUncompletedItems);
      setCompletedItems(newCompletedItems);

      setValidationFlags((prev) => {
        const newFlags = { ...prev };
        delete newFlags[index];
        return newFlags;
      });
    };
    const handleStoresSelectClick = (item, itemIndex) => {
      const newItem = {
        id: item.id,
        name: item.attributes.name,
      };
      setUncompletedItems((items) =>
        items.map((item, index) =>
          index === itemIndex ? { ...item, stores: newItem } : item
        )
      );
    };
    const toggleStoresSearchActiveState = (index) => {
      if (storesSearchActive === index) {
        setStoresSearchActive(null);
      } else {
        setStoresSearchActive(index);
      }
    };
    const toggleEditedUncompletedItemNameActiveState = (index) => {
      if (editedUncompletedItemNameActive === index) {
        setEditedUncompletedItemNameActive(null);
      } else {
        setEditedUncompletedItemNameActive(index);
      }
    };
    const handleCompletedItemsDeleteItem = (index) => {
      setCompletedItems((prevItems) => prevItems.filter((_, i) => i !== index));
    };
    const moveToUncompleted = (index) => {
      const item = completedItems[index];
      const newCompletedItems = [
        ...completedItems.slice(0, index),
        ...completedItems.slice(index + 1),
      ];
      const newUncompletedItems = [...uncompletedItems, item];
      setCompletedItems(newCompletedItems);
      setUncompletedItems(newUncompletedItems);
    };
    const handleRemoveStores = (index) => {
      setUncompletedItems((items) =>
        items.map((item, itemIndex) =>
          itemIndex === index ? { ...item, stores: null } : item
        )
      );
    };
    const handleStoresInputBlur = () => {
      setStoresSearch("");
      setTimeout(() => {
        if (!isMouseOverList) {
          setStoresSearchActive(null);
        }
      }, 0);
    };
    const handleStoresInputClick = (item, index) => {
      if (item.stores === null && storesSearch !== "") {
        setStoresSearchActive(index);
      } else {
        setStoresSearchActive(null);
      }
      setTimeout(() => {
        toggleStoresSearchActiveState(index);
      }, 0);
    };
    const filteredStores = storesData.data.filter((item) => {
      const isCompleted =
        uncompletedItems.some(
          (completedItem) =>
            completedItem.stores && completedItem.stores.id === item.id
        ) ||
        completedItems.some(
          (completedItem) => completedItem.stores.id === item.id
        );
      return !isCompleted;
    });
    useEffect(() => {
      if (uncompletedItems.length === 0) {
        setStoreLinks(completedItems);
        setStoreLinksVerify(true);
      } else {
        setStoreLinks(null);
        setStoreLinksVerify(false);
      }
    }, [completedItems, uncompletedItems, setStoreLinks]);
    return (
      <div ref={ref} className={styles.item}>
        <span className={styles.sectionTitle}>
          <SettingIcon />
          <span>Store Links:</span>
        </span>
        <div className={styles.itemTogetherRelations}>
          <input
            className={`${styles.inputWrite} ${
              addStoresLink ? styles.inputWriteActive : ""
            }`}
            type="text"
            placeholder="Start typing..."
            onBlur={() => setAddStoresLink(false)}
            onClick={() => setAddStoresLink(!addStoresLink)}
            onKeyPress={handleAddNewItemPress}
          />
          <div className={styles.itemTogetherRelations__items}>
            {completedItems.map((item, index) => {
              return (
                <div key={index} className={styles.toRow}>
                  <div className={styles.itemTogetherRelations__ellips}>
                    <div></div>
                    {index !== 0 && <span></span>}
                  </div>
                  <div className={styles.itemTogetherRelations__item}>
                    <div
                      className={styles.itemTogetherRelations__title}
                      readOnly
                    >
                      <input type="text" readOnly value={item.name} />
                    </div>
                    <span
                      className={styles.itemTogetherRelations__separate}
                    ></span>

                    <div
                      className={styles.itemTogetherRelations__region}
                      readOnly
                    >
                      <div>
                        <input type="text" readOnly value={item.stores.name} />
                      </div>
                    </div>

                    <div className={styles.tools}>
                      <span onClick={() => moveToUncompleted(index)}>
                        <PenIcon />
                      </span>
                      <span
                        onClick={() => handleCompletedItemsDeleteItem(index)}
                      >
                        <CloseIcon />
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
            {uncompletedItems.map((item, index) => {
              return (
                <div
                  key={index}
                  className={styles.toRow}
                  style={{ overflow: "visible" }}
                >
                  <div className={styles.itemTogetherRelations__ellips}>
                    <div></div>
                    {(completedItems.length > 0 || index !== 0) && (
                      <span></span>
                    )}
                  </div>
                  <div className={styles.itemTogetherRelations__item}>
                    <div className={styles.itemTogetherRelations__title}>
                      <input
                        style={{
                          border:
                            editedUncompletedItemNameActive === index
                              ? "1px solid rgb(97, 97, 97)"
                              : validationFlags[index]?.isNameEmpty &&
                                uncompletedItems.name !== ""
                              ? "1px solid red"
                              : "1px solid transparent",
                        }}
                        type="text"
                        value={
                          index === setEditedUncompletedItemIndex
                            ? setEditedUncompletedItemName
                            : item.name
                        }
                        placeholder="Link"
                        onBlur={() => setEditedUncompletedItemNameActive(null)}
                        onClick={() =>
                          toggleEditedUncompletedItemNameActiveState(index)
                        }
                        onChange={(event) =>
                          handleUncompletedItemInputChange(index, event)
                        }
                      />
                    </div>
                    <span
                      className={styles.itemTogetherRelations__separate}
                    ></span>
                    <div
                      className={`${styles.itemTogetherRelations__region} ${
                        storesSearchActive === index
                          ? styles.itemTogetherRelations__regionActive
                          : ""
                      }`}
                    >
                      <div>
                        <input
                          type="text"
                          placeholder="Platform"
                          value={
                            item.stores
                              ? item.stores.name
                              : index === storesSearchActive
                              ? storesSearch
                              : ""
                          }
                          onChange={(e) => handleStoresChange(e, index)}
                          ref={(el) => (storesInputs.current[index] = el)}
                          readOnly={!!(item.stores && item.stores.name)}
                          onBlur={handleStoresInputBlur}
                          onClick={() => handleStoresInputClick(item, index)}
                          style={{
                            border:
                              validationFlags[index]?.isStoresInvalid &&
                              uncompletedItems.stores !== null
                                ? "1px solid red"
                                : "1px solid transparent",
                          }}
                        />
                        {item.stores !== null ? (
                          <CloseIcon
                            onClick={() => handleRemoveStores(index)}
                          />
                        ) : (
                          <ArrowChevron
                            onClick={() => handleStoresInputClick(item, index)}
                          />
                        )}
                      </div>
                      {storesSearchActive === index && storesData?.data && (
                        <div
                          onMouseEnter={() => setIsMouseOverList(true)}
                          onMouseLeave={() => setIsMouseOverList(false)}
                        >
                          {filteredStores && filteredStores.length > 0 ? (
                            <ul>
                              {filteredStores.map((item) => (
                                <li
                                  key={item.id}
                                  onClick={() => {
                                    handleStoresSelectClick(item, index);
                                    setStoresSearchActive(false);
                                  }}
                                >
                                  {item.attributes.name}
                                </li>
                              ))}
                            </ul>
                          ) : (
                            <p
                              style={{ marginTop: "15px" }}
                              className={styles.dropDownListEmpty}
                            >
                              The list is empty
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                    <div className={styles.tools}>
                      <span onClick={() => moveToCompleted(index)}>
                        <CheckMarkIcon />
                      </span>
                      <span
                        onClick={() => handleUncompletedItemsDeleteItem(index)}
                      >
                        <CloseIcon />
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
);
const SelectSeries = ({
  selectSeries,
  setSelectSeries,
  setSelectSeriesVerify,
  selectSeriesVerify,
}) => {
  const [seriesData, setSeriesData] = useState(null);
  const [seriesSearch, setSeriesSearch] = useState("");
  const [selectSeriesActive, setSelectSeriesActive] = useState(false);
  const [debouncedSearchSeries] = useDebounce(seriesSearch, 1000);
  useEffect(() => {
    if (selectSeriesActive) {
      const fetchData = async () => {
        try {
          const formattedSeach = normalizeInput(debouncedSearchSeries);
          const response = await axios.get(
            `${url}/api/series-s?filters[name][$contains]=${formattedSeach}&fields[0]=name&pagination[limit]=14`
          );
          setSeriesData(response.data);
        } catch (error) {
          console.error("Ошибка при получении данных:", error);
        }
      };
      fetchData();
    }
  }, [debouncedSearchSeries, selectSeriesActive]);
  const handleSeriesChange = (e) => {
    const inputValue = e.target.value.toLowerCase().trim();
    setSeriesSearch(inputValue);
    // Если selectSeries не пустой и введенное значение не совпадает с его именем, устанавливаем selectSeries в null
    if (
      selectSeries &&
      inputValue !== selectSeries.attributes.name.toLowerCase().trim()
    ) {
      setSelectSeries(null);
    }
    setTimeout(() => {
      setSelectSeriesActive(true);
    }, 100);
  };
  const handleTagsInputBlur = () => {
    if (
      (selectSeries && seriesSearch !== selectSeries.attributes.name) ||
      selectSeries === null
    ) {
      setSeriesSearch("");
    }
    setTimeout(() => {
      setSelectSeriesActive(false);
    }, 100);
  };
  const handleSeriesClick = (item) => {
    setSelectSeries(item);
  };
  const handleRequestRemove = () => {
    if (selectSeries && seriesSearch) {
      setSelectSeries(null);
    }

    setSeriesSearch("");
  };
  const handleSeriesKeyPress = (e) => {
    if (e.key === "Enter") {
      const searchText = e.target.value.toLowerCase().trim();
      const exactMatch = seriesData.data.find(
        (item) => item.attributes.name.toLowerCase().trim() === searchText
      );

      if (!exactMatch) {
        // Если нет точного совпадения, не выполняем никаких действий
        return;
      }

      // Проверяем, существует ли элемент в selectSeries
      const alreadyExists = selectSeries && selectSeries.id === exactMatch.id;

      if (!alreadyExists) {
        // Если элемент не существует в selectSeries, добавляем его
        setSelectSeries(exactMatch);
      }

      setSelectSeriesActive(false);
    }
  };
  useEffect(() => {
    if (selectSeries && selectSeries.attributes.name) {
      setSeriesSearch(selectSeries.attributes.name);
    }
  }, [selectSeries]);
  const { gameOutside, gameSeries } = useSelector((state) => state.addGame);
  useEffect(() => {
    if (
      gameOutside &&
      gameSeries &&
      typeof gameSeries === "object" &&
      !Array.isArray(gameSeries)
    ) {
      setSelectSeries(gameSeries);
      const seriesName = gameSeries?.attributes?.name || "";
      setSeriesSearch(seriesName);
    } else {
      setSelectSeries(null);
      setSeriesSearch("");
    }
  }, [gameOutside, gameSeries]);

  /* useEffect(() => {
    if (selectSeries) {
      if (selectSeries.attributes && selectSeries.attributes.name) {
        setSeriesSearch(selectSeries.attributes.name);
      }
      setSelectSeriesVerify(true);
    } else {
      setSelectSeriesVerify(false);
    }
  }, [selectSeries]); */

  return (
    <div className={styles.item}>
      <span className={styles.sectionTitle}>
        <SettingIcon />
        <span>Series (single):</span>
      </span>
      <div>
        <div
          className={`${styles.inputSelect} ${
            selectSeriesActive ? styles.inputSelectActive : ""
          }`}
        >
          <input
            type="text"
            placeholder="Choose from the list or type"
            onChange={handleSeriesChange}
            value={seriesSearch}
            onBlur={handleTagsInputBlur}
            onKeyPress={handleSeriesKeyPress}
            onClick={() => setSelectSeriesActive(true)}
          />

          {selectSeries === null && selectSeriesActive === false ? (
            selectSeriesActive ? (
              <CloseIcon onClick={handleRequestRemove} />
            ) : (
              <ArrowChevron />
            )
          ) : (
            <CloseIcon onClick={handleRequestRemove} />
          )}

          {seriesData && Array.isArray(seriesData.data) && (
            <div
              className={`${styles.inputSelect__dropDown} ${
                selectSeriesActive ? styles.active : ""
              }`}
            >
              {seriesData.data.length > 0 ? (
                <ul>
                  {seriesData.data.map((item, index) => {
                    const isSelected =
                      Array.isArray(selectSeries) &&
                      selectSeries.some((selected) => selected.id === item.id);

                    if (!isSelected) {
                      return (
                        <li
                          key={item.id}
                          onClick={() => handleSeriesClick(item)}
                        >
                          {item.attributes.name}
                        </li>
                      );
                    }
                    return (
                      <li
                        key={item.id}
                        style={{
                          color: " rgb(97, 97, 97)",
                          cursor: "default",
                        }}
                      >
                        {item.attributes.name}
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <p className={styles.dropDownListEmpty}>The list is empty</p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
const CustomDatePicker = ({ onChange, itemDate }) => {
  const [startDate, setStartDate] = useState(getValidDate(itemDate));
  const currentYear = new Date().getFullYear();
  const placeholderText = `01 January ${currentYear}`;

  useEffect(() => {
    const validDate = getValidDate(itemDate);
    setStartDate(validDate);
  }, [itemDate]);

  const handleDateChange = (date) => {
    const validDate = getValidDate(date);
    if (validDate) {
      setStartDate(validDate);
      onChange(validDate);
    }
  };

  function getValidDate(date) {
    // Если дата пустая или имеет недопустимое значение, возвращаем null
    if (!date || isNaN(new Date(date).getTime())) {
      return null;
    }

    // Преобразуем дату в объект Date
    const parsedDate = new Date(date);
    return isNaN(parsedDate.getTime()) ? null : parsedDate;
  }

  return (
    <div className="datepicker-container">
      <DatePicker
        selected={startDate}
        onChange={handleDateChange}
        dateFormat="dd MMMM yyyy"
        placeholderText={placeholderText}
        showYearDropdown
        scrollableMonthYearDropdown
        isClearable
      />
      <button
        className="icon-button"
        styles={{ "--background": "none" }}
        onClick={() => setStartDate(new Date())}
      >
        <CalendarIcon />
      </button>
    </div>
  );
};
const ReleasesUncompletedCard = ({
  setUncompletedItems,
  uncompletedItems,
  dataItem,
  completedItems,
  setCompletedItems,
  dataItemIndex,
}) => {
  const [regionData, setRegionData] = useState([]);
  const [releaseStatuses, setReleaseStatuses] = useState([]);
  const [regionSearch, setRegionSearch] = useState("");
  const [releaseStatusesSearch, setReleaseStatusesSearch] = useState("");
  const [regionSearchActive, setRegionSearchActive] = useState(null);
  const regionInputs = useRef(uncompletedItems.map(() => React.createRef()));
  const releaseDataInputs = useRef(
    uncompletedItems.map(() => React.createRef())
  );
  const [isMouseOverList, setIsMouseOverList] = useState(false);
  const [validationFlags, setValidationFlags] = useState({});
  const [selectReleaseStatusesActive, setSelectReleaseStatusesActive] =
    useState(null);
  const [debouncedSearchRegion] = useDebounce(regionSearch, 1000);
  const [debouncedSearchReleaseStatuses] = useDebounce(
    releaseStatusesSearch,
    1000
  );
  const [technologyData, setTechnologyData] = useState([]);
  const [technologySearch, setTechnologySearch] = useState("");
  const [technologySearchActive, setTechnologySearchActive] = useState(null);
  const technologiesInputs = useRef(
    uncompletedItems.map(() => React.createRef())
  );
  const [debouncedSearchTechnology] = useDebounce(technologySearch, 1000);

  useEffect(() => {
    if (regionSearchActive !== null) {
      const fetchData = async () => {
        try {
          const formattedSearch = normalizeInput(debouncedSearchRegion);
          const response = await axios.get(
            `${url}/api/regions?filters[name][$contains]=${formattedSearch}&fields[0]=name&fields[1]=code&fields[2]=igdbId&sort=name:asc`
          );
          setRegionData(response.data);
        } catch (error) {
          console.error("Ошибка при получении данных:", error);
        }
      };
      fetchData();
    }
  }, [debouncedSearchRegion, regionSearchActive]);
  useEffect(() => {
    if (selectReleaseStatusesActive !== null) {
      const fetchData = async () => {
        try {
          const formattedSearch = normalizeInput(
            debouncedSearchReleaseStatuses
          );
          const response = await axios.get(
            `${url}/api/release-statuses?filters[name][$contains]=${formattedSearch}&fields[0]=name&fields[1]=igdbId&sort=name:asc`
          );
          setReleaseStatuses(response.data);
        } catch (error) {
          console.error("Ошибка при получении данных:", error);
        }
      };
      fetchData();
    }
  }, [debouncedSearchReleaseStatuses, selectReleaseStatusesActive]);
  useEffect(() => {
    if (technologySearchActive !== null) {
      const fetchData = async () => {
        try {
          const formattedSearch = normalizeInput(debouncedSearchRegion);
          const response = await axios.get(
            `${url}/api/technologies?filters[name][$contains]=${formattedSearch}&fields[0]=name&sort=name:asc`
          );
          setTechnologyData(response.data);
        } catch (error) {
          console.error("Ошибка при получении данных:", error);
        }
      };
      fetchData();
    }
  }, [debouncedSearchTechnology, technologySearchActive]);

  const handleAddRelease = () => {
    const newRelease = {
      date: null,
      region: null,
      releaseData: null,
    };
    const updatedUncompletedItems = uncompletedItems.map((item, index) => {
      if (index === dataItemIndex) {
        return {
          ...item,
          releases: [...item.releases, newRelease],
        };
      }
      return item;
    });
    setUncompletedItems(updatedUncompletedItems);
  };
  const handleAddTechnologies = () => {
    const newTechnology = {
      technology: null,
      version: null,
    };
    const updatedUncompletedItems = uncompletedItems.map((item, index) => {
      if (index === dataItemIndex) {
        return {
          ...item,
          technologies: [...item.technologies, newTechnology],
        };
      }
      return item;
    });
    setUncompletedItems(updatedUncompletedItems);
  };

  const handleDateChange = (index, date) => {
    const newDataItems = [...uncompletedItems];
    newDataItems[dataItemIndex].releases[index].date = date;
    setUncompletedItems(newDataItems);
  };

  const handleRegionSelectClick = (region, itemIndex, releaseIndex) => {
    const newRegion = {
      igdbId: null,
      id: region.id,
      name: region.attributes.name,
      code: region.attributes.code,
    };

    setUncompletedItems((items) =>
      items.map((item, idx) =>
        idx === itemIndex
          ? {
              ...item,
              releases: item.releases.map((release, idx) =>
                idx === releaseIndex
                  ? { ...release, region: newRegion }
                  : release
              ),
            }
          : item
      )
    );
  };
  const toggleRegionSearchActiveState = (index) => {
    if (regionSearchActive === index) {
      setRegionSearchActive(null);
    } else {
      setRegionSearchActive(index);
    }
  };
  const handleRegionChange = (e, index) => {
    if (!e || !e.target) {
      return;
    }
    setRegionSearch(e.target.value);
    setRegionSearchActive(index);
  };
  const handleRemoveRegion = (itemIndex, releaseIndex) => {
    setUncompletedItems((items) =>
      items.map((item, idx) =>
        idx === itemIndex
          ? {
              ...item,
              releases: item.releases.map((release, idx) =>
                idx === releaseIndex ? { ...release, region: null } : release
              ),
            }
          : item
      )
    );
  };
  const handleRegionInputBlur = () => {
    setRegionSearch("");
    setTimeout(() => {
      if (!isMouseOverList) {
        setRegionSearchActive(null);
      }
    }, 0);
  };
  const handleRegionInputClick = (item, index) => {
    if (item.region === null && regionSearch !== "") {
      setRegionSearchActive(index);
    } else {
      setRegionSearchActive(null);
    }
    setTimeout(() => {
      toggleRegionSearchActiveState(index);
    }, 0);
  };

  const handleReleaseStatuseSelectClick = (status, itemIndex, statusIndex) => {
    const newStatus = {
      id: status.id,
      name: status.attributes.name,
    };
    setUncompletedItems((items) =>
      items.map((item, idx) =>
        idx === itemIndex
          ? {
              ...item,
              releases: item.releases.map((release, idx) =>
                idx === statusIndex
                  ? { ...release, releaseData: newStatus }
                  : release
              ),
            }
          : item
      )
    );
  };
  const handleRemoveReleaseStatuse = (itemIndex, releaseIndex) => {
    setUncompletedItems((items) =>
      items.map((item, idx) =>
        idx === itemIndex
          ? {
              ...item,
              releases: item.releases.map((release, idx) =>
                idx === releaseIndex
                  ? { ...release, releaseData: null }
                  : release
              ),
            }
          : item
      )
    );
  };
  const handleReleaseStatuseInputBlur = () => {
    setReleaseStatusesSearch("");
    setTimeout(() => {
      if (!isMouseOverList) {
        setSelectReleaseStatusesActive(null);
      }
    }, 0);
  };
  const handleReleaseStatuseChange = (e, index) => {
    if (!e || !e.target) {
      return;
    }
    setReleaseStatusesSearch(e.target.value);
    setSelectReleaseStatusesActive(index);
  };
  const toggleReleaseStatuseSearchActiveState = (index) => {
    if (selectReleaseStatusesActive === index) {
      setSelectReleaseStatusesActive(null);
    } else {
      setSelectReleaseStatusesActive(index);
    }
  };
  const handleReleaseStatuseInputClick = (item, index) => {
    if (item.releaseData === null && releaseStatusesSearch !== "") {
      setSelectReleaseStatusesActive(index);
    } else {
      setSelectReleaseStatusesActive(null);
    }
    setTimeout(() => {
      toggleReleaseStatuseSearchActiveState(index);
    }, 0);
  };

  const handleTechnologySelectClick = (item, itemIndex, technologiesIndex) => {
    const newItem = {
      id: item.id,
      name: item.attributes.name,
    };

    setUncompletedItems((items) =>
      items.map((item, idx) =>
        idx === itemIndex
          ? {
              ...item,
              technologies: item.technologies.map((item, idx) =>
                idx === technologiesIndex
                  ? { ...item, technology: newItem }
                  : item
              ),
            }
          : item
      )
    );
  };
  const toggleTechnologySearchActiveState = (index) => {
    if (technologySearchActive === index) {
      setTechnologySearchActive(null);
    } else {
      setTechnologySearchActive(index);
    }
  };
  const handleTechnologyChange = (e, index) => {
    if (!e || !e.target) {
      return;
    }
    setTechnologySearch(e.target.value);
    setTechnologySearchActive(index);
  };
  const handleRemoveTechnology = (itemIndex, technologyIndex) => {
    setUncompletedItems((items) =>
      items.map((item, idx) =>
        idx === itemIndex
          ? {
              ...item,
              technologies: item.technologies.map((item, idx) =>
                idx === technologyIndex ? { ...item, technology: null } : item
              ),
            }
          : item
      )
    );
  };
  const handleTechnologyInputBlur = () => {
    setTechnologySearch("");
    setTimeout(() => {
      if (!isMouseOverList) {
        setTechnologySearchActive(null);
      }
    }, 0);
  };
  const handleTechnologyInputClick = (item, index) => {
    if (item.technologies === null && technologySearch !== "") {
      setTechnologySearchActive(index);
    } else {
      setTechnologySearchActive(null);
    }
    setTimeout(() => {
      toggleTechnologySearchActiveState(index);
    }, 0);
  };

  const handleVersionChange = (index, event, dataItemIndex) => {
    if (Array.isArray(uncompletedItems)) {
      const newUncompletedItems = uncompletedItems.map((item, idx) => {
        if (idx === dataItemIndex) {
          const newTechnologies = item.technologies.map((tech, techIndex) => {
            if (techIndex === index) {
              return { ...tech, version: event.target.value };
            }
            return tech;
          });
          return { ...item, technologies: newTechnologies };
        }
        return item;
      });
      setUncompletedItems(newUncompletedItems);
    } else {
      console.error("is not an array");
    }
  };

  const handleRemoveItemInTechnology = (dataItemIndex, techIndex) => {
    if (Array.isArray(uncompletedItems)) {
      const newUncompletedItems = uncompletedItems.map((item, idx) => {
        if (idx === dataItemIndex) {
          const newTechnologies = item.technologies.filter(
            (_, index) => index !== techIndex
          );
          return { ...item, technologies: newTechnologies };
        }
        return item;
      });
      setUncompletedItems(newUncompletedItems);
    } else {
      console.error("uncompletedItems is not an array");
    }
  };
  const handleRemoveItemInReleases = (dataItemIndex, techIndex) => {
    if (Array.isArray(uncompletedItems)) {
      const newUncompletedItems = uncompletedItems.map((item, idx) => {
        if (idx === dataItemIndex) {
          const newReleases = item.releases.filter(
            (_, index) => index !== techIndex
          );
          return { ...item, releases: newReleases };
        }
        return item;
      });
      setUncompletedItems(newUncompletedItems);
    } else {
      console.error("uncompletedItems is not an array");
    }
  };
  const handleRemoveDataItem = (dataItemIndex) => {
    if (Array.isArray(uncompletedItems)) {
      const newUncompletedItems = uncompletedItems.filter(
        (_, index) => index !== dataItemIndex
      );
      setUncompletedItems(newUncompletedItems);
    } else {
      console.error("is not an array");
    }
  };
  const [incompleteIndices, setIncompleteIndices] = useState([]);
  const moveItemToCompleted = (dataItemIndex) => {
    if (
      Array.isArray(uncompletedItems) &&
      uncompletedItems.length > dataItemIndex
    ) {
      const itemToMove = uncompletedItems[dataItemIndex];

      // Получаем индексы неполных релизов и технологий
      const incompleteReleasesIndices = itemToMove.releases
        .map((release, index) =>
          !release.region && !release.date && !release.releaseData ? index : -1
        )
        .filter((index) => index !== -1);

      const incompleteTechnologiesIndices = itemToMove.technologies
        .map((tech, index) => (!tech.technology && !tech.version ? index : -1))
        .filter((index) => index !== -1);

      if (
        incompleteReleasesIndices.length === 0 &&
        incompleteTechnologiesIndices.length === 0
      ) {
        // Все данные заполнены, можно переместить элемент
        const newUncompletedItems = uncompletedItems.filter(
          (_, index) => index !== dataItemIndex
        );
        const newCompletedItems = [...completedItems, itemToMove];

        // Обновляем состояния
        setUncompletedItems(newUncompletedItems);
        setCompletedItems(newCompletedItems);
        // Удаляем индекс из incompleteIndices, если он там есть
        setIncompleteIndices((prevIndices) =>
          prevIndices.filter((item) => item.itemIndex !== dataItemIndex)
        );
      } else {
        // Если данные неполные, выводим предупреждение и обновляем индексы неполных элементов
        setIncompleteIndices([
          {
            itemIndex: dataItemIndex,
            releases: incompleteReleasesIndices,
            technologies: incompleteTechnologiesIndices,
          },
        ]);
      }
    } else {
      console.error("Invalid index");
    }
  };

  return (
    <div className={styles.toRow}>
      <div className={styles.itemTogetherRelations__ellips}>
        <div></div>
        {(completedItems.length > 0 || dataItemIndex !== 0) && <span></span>}
      </div>
      <div className={styles.itemRelationsComponent}>
        <div className={styles.itemRelationsComponent__header}>
          <div className={styles.itemRelationsComponent__titleChange}>
            <input
              type="text"
              value={dataItem.platform.attributes.name}
              placeholder="Add new platform"
              readOnly
            />
            <span
              style={{
                backgroundColor: dataItem.platform.attributes.color
                  ? dataItem.platform.attributes.color
                  : "rgba(28, 29, 27, 0.47)",
              }}
            >
              {dataItem.platform.attributes.name}
            </span>
          </div>
          <div className={styles.tools}>
            <span onClick={() => moveItemToCompleted(dataItemIndex)}>
              <CheckMarkIcon />
            </span>
            <span onClick={() => handleRemoveDataItem(dataItemIndex)}>
              <CloseIcon />
            </span>
          </div>
        </div>
        <div className={styles.item}>
          <span className={styles.sectionTitle}>
            <SettingIcon />
            <span>Releases:</span>
          </span>
          <div className={styles.itemRelationsComponent__items}>
            {dataItem.releases.length > 0 &&
              dataItem.releases.map((item, index) => {
                const incompleteData = incompleteIndices.find(
                  (inc) => inc.itemIndex === dataItemIndex
                );
                const isIncomplete = incompleteData
                  ? incompleteData.releases.includes(index)
                  : false;

                return (
                  <div
                    style={{
                      background: isIncomplete ? "rgb(117 19 19 / 58%)" : "",
                    }}
                    key={index}
                    className={styles.itemRelationsComponent__parameters}
                  >
                    <div className={styles.changeDate}>
                      <CustomDatePicker
                        onChange={(date) => handleDateChange(index, date)}
                        itemDate={item.date}
                      />
                    </div>

                    <span
                      className={styles.itemTogetherRelations__separate}
                    ></span>

                    <div
                      className={`${styles.itemTogetherRelations__region} ${
                        regionSearchActive === index
                          ? styles.itemTogetherRelations__regionActive
                          : ""
                      }`}
                    >
                      <div>
                        <input
                          type="text"
                          placeholder="Region"
                          value={
                            item.region
                              ? item.region.name
                              : index === regionSearchActive
                              ? regionSearch
                              : ""
                          }
                          onChange={(e) => handleRegionChange(e, index)}
                          ref={(el) => (regionInputs.current[index] = el)}
                          readOnly={!!(item.region && item.region.name)}
                          onBlur={handleRegionInputBlur}
                          onClick={() => handleRegionInputClick(item, index)}
                          style={{
                            border:
                              validationFlags[index]?.isRegionInvalid &&
                              uncompletedItems.region !== null
                                ? "1px solid red"
                                : "1px solid transparent",
                          }}
                        />
                        {item.region !== null ? (
                          <CloseIcon
                            onClick={() =>
                              handleRemoveRegion(dataItemIndex, index)
                            }
                          />
                        ) : (
                          <ArrowChevron
                            onClick={() => handleRegionInputClick(item, index)}
                          />
                        )}
                      </div>
                      {regionSearchActive === index && regionData?.data && (
                        <div
                          onMouseEnter={() => setIsMouseOverList(true)}
                          onMouseLeave={() => setIsMouseOverList(false)}
                        >
                          {regionData.data.length > 0 ? (
                            <ul>
                              {regionData.data.map((region, regionIndex) => (
                                <li
                                  key={region.id}
                                  onClick={() => {
                                    handleRegionSelectClick(
                                      region,
                                      dataItemIndex,
                                      index
                                    );
                                    setRegionSearchActive(false);
                                  }}
                                >
                                  {region.attributes.name}
                                </li>
                              ))}
                            </ul>
                          ) : (
                            <p
                              style={{ marginTop: "15px" }}
                              className={styles.dropDownListEmpty}
                            >
                              The list is empty
                            </p>
                          )}
                        </div>
                      )}
                    </div>

                    <span
                      className={styles.itemTogetherRelations__separate}
                    ></span>

                    <div
                      className={`${styles.itemTogetherRelations__region} ${
                        selectReleaseStatusesActive === index
                          ? styles.itemTogetherRelations__regionActive
                          : ""
                      }`}
                    >
                      <div>
                        <input
                          type="text"
                          placeholder="Special status"
                          value={
                            item.releaseData
                              ? item.releaseData.name
                              : index === selectReleaseStatusesActive
                              ? releaseStatusesSearch
                              : ""
                          }
                          onChange={(e) => handleReleaseStatuseChange(e, index)}
                          ref={(el) => (releaseDataInputs.current[index] = el)}
                          readOnly={
                            !!(item.releaseData && item.releaseData.name)
                          }
                          onBlur={handleReleaseStatuseInputBlur}
                          onClick={() =>
                            handleReleaseStatuseInputClick(item, index)
                          }
                          style={{
                            border:
                              validationFlags[index]?.isRegionInvalid &&
                              uncompletedItems.releaseData !== null
                                ? "1px solid red"
                                : "1px solid transparent",
                          }}
                        />
                        {item.releaseData !== null ? (
                          <CloseIcon
                            onClick={() =>
                              handleRemoveReleaseStatuse(dataItemIndex, index)
                            }
                          />
                        ) : (
                          <ArrowChevron
                            onClick={() =>
                              handleReleaseStatuseInputClick(item, index)
                            }
                          />
                        )}
                      </div>
                      {selectReleaseStatusesActive === index &&
                        releaseStatuses?.data && (
                          <div
                            onMouseEnter={() => setIsMouseOverList(true)}
                            onMouseLeave={() => setIsMouseOverList(false)}
                          >
                            {releaseStatuses.data.length > 0 ? (
                              <ul>
                                {releaseStatuses.data.map(
                                  (status, statusIndex) => (
                                    <li
                                      key={status.id}
                                      onClick={() => {
                                        handleReleaseStatuseSelectClick(
                                          status,
                                          dataItemIndex,
                                          index
                                        );
                                        setSelectReleaseStatusesActive(false);
                                      }}
                                    >
                                      {status.attributes.name}
                                    </li>
                                  )
                                )}
                              </ul>
                            ) : (
                              <p
                                style={{ marginTop: "15px" }}
                                className={styles.dropDownListEmpty}
                              >
                                The list is empty
                              </p>
                            )}
                          </div>
                        )}
                    </div>

                    <div className={styles.tools}>
                      <span
                        onClick={() =>
                          handleRemoveItemInReleases(dataItemIndex, index)
                        }
                      >
                        <CloseIcon />
                      </span>
                    </div>
                  </div>
                );
              })}
            <button
              className={styles.addNewRelation}
              onClick={handleAddRelease}
            >
              + Add Release
            </button>
          </div>
        </div>

        <div className={styles.item}>
          <span className={styles.sectionTitle}>
            <SettingIcon />
            <span>Technologies:</span>
          </span>
          <div className={styles.itemRelationsComponent__items}>
            {dataItem.technologies.length > 0 &&
              dataItem.technologies.map((item, index) => {
                const incompleteData = incompleteIndices.find(
                  (inc) => inc.itemIndex === dataItemIndex
                );
                const isIncomplete = incompleteData
                  ? incompleteData.technologies.includes(index)
                  : false;
                return (
                  <div
                    style={{
                      background: isIncomplete ? "rgb(117 19 19 / 58%)" : "",
                    }}
                    key={index}
                    className={styles.itemRelationsComponent__parameters}
                  >
                    <div
                      className={`${styles.itemTogetherRelations__region} ${
                        technologySearchActive === index
                          ? styles.itemTogetherRelations__regionActive
                          : ""
                      }`}
                    >
                      <div>
                        <input
                          type="text"
                          placeholder="Select technology"
                          value={
                            item.technology
                              ? item.technology.name
                              : index === technologySearchActive
                              ? technologySearch
                              : ""
                          }
                          onChange={(e) => handleTechnologyChange(e, index)}
                          ref={(el) => (technologiesInputs.current[index] = el)}
                          readOnly={
                            !!(item.technologies && item.technologies.name)
                          }
                          onBlur={handleTechnologyInputBlur}
                          onClick={() =>
                            handleTechnologyInputClick(item, index)
                          }
                          style={{
                            width: "360px",
                          }}
                          /* style={{
                          border:
                            validationFlags[index]?.isRegionInvalid &&
                            uncompletedItems.region !== null
                              ? "1px solid red"
                              : "1px solid transparent",
                        }} */
                        />
                        {item.technology !== null ? (
                          <CloseIcon
                            onClick={() =>
                              handleRemoveTechnology(dataItemIndex, index)
                            }
                          />
                        ) : (
                          <ArrowChevron
                            onClick={() =>
                              handleTechnologyInputClick(item, index)
                            }
                          />
                        )}
                      </div>
                      {technologySearchActive === index &&
                        technologyData?.data && (
                          <div
                            onMouseEnter={() => setIsMouseOverList(true)}
                            onMouseLeave={() => setIsMouseOverList(false)}
                          >
                            {technologyData.data.length > 0 ? (
                              <ul>
                                {technologyData.data.map((item) => (
                                  <li
                                    key={item.id}
                                    onClick={() => {
                                      handleTechnologySelectClick(
                                        item,
                                        dataItemIndex,
                                        index
                                      );
                                      setTechnologySearchActive(false);
                                    }}
                                  >
                                    {item.attributes.name}
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              <p
                                style={{ marginTop: "15px" }}
                                className={styles.dropDownListEmpty}
                              >
                                The list is empty
                              </p>
                            )}
                          </div>
                        )}
                    </div>
                    <span
                      className={styles.itemTogetherRelations__separate}
                    ></span>
                    <div
                      className={`${styles.itemTogetherRelations__region} ${
                        technologySearchActive === index
                          ? styles.itemTogetherRelations__regionActive
                          : ""
                      }`}
                    >
                      <div>
                        <input
                          type="text"
                          value={item.version || ""}
                          placeholder="Version"
                          onChange={(event) =>
                            handleVersionChange(index, event, dataItemIndex)
                          }
                        />
                      </div>
                    </div>

                    <div className={styles.tools}>
                      <span
                        onClick={() =>
                          handleRemoveItemInTechnology(dataItemIndex, index)
                        }
                      >
                        <CloseIcon />
                      </span>
                    </div>
                  </div>
                );
              })}

            <button
              className={styles.addNewRelation}
              onClick={handleAddTechnologies}
            >
              + Add Release
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
const ReleasesCompletedCard = ({
  setUncompletedItems,
  uncompletedItems,
  dataItem,
  completedItems,
  setCompletedItems,
  dataItemIndex,
}) => {
  const moveItemToUncompleted = (dataItemIndex) => {
    // Проверяем, является ли completedItems массивом
    if (
      Array.isArray(completedItems) &&
      completedItems.length > dataItemIndex
    ) {
      // Извлекаем элемент из completedItems
      const itemToMove = completedItems[dataItemIndex];

      // Удаляем элемент из completedItems
      const newCompletedItems = completedItems.filter(
        (_, index) => index !== dataItemIndex
      );

      // Добавляем извлеченный элемент в uncompletedItems
      const newUncompletedItems = [...uncompletedItems, itemToMove];

      // Обновляем состояния
      setUncompletedItems(newUncompletedItems);
      setCompletedItems(newCompletedItems);
    } else {
      console.error("Invalid index");
    }
  };

  return (
    <div className={styles.toRow}>
      <div className={styles.itemTogetherRelations__ellips}>
        <div></div>
        {dataItemIndex !== 0 && <span></span>}
      </div>
      <div className={styles.itemRelationsComponent}>
        <div className={styles.itemRelationsComponent__header}>
          <div className={styles.itemRelationsComponent__titleChange}>
            <input
              type="text"
              value={dataItem.platform.attributes.name}
              placeholder="Add new platform"
              readOnly
            />
            <span
              style={{
                backgroundColor: dataItem.platform.attributes.color
                  ? dataItem.platform.attributes.color
                  : "rgba(28, 29, 27, 0.47)",
              }}
            >
              {dataItem.platform.attributes.name}
            </span>
          </div>
          <div className={styles.tools}>
            <span onClick={() => moveItemToUncompleted(dataItemIndex)}>
              <PenIcon />
            </span>
            <span>
              <CloseIcon />
            </span>
          </div>
        </div>
        <div className={styles.item}>
          <span className={styles.sectionTitle}>
            <SettingIcon />
            <span>Releases:</span>
          </span>
          <div className={styles.itemRelationsComponent__items}>
            {dataItem.releases && dataItem.releases.length > 0 ? (
              dataItem.releases.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={styles.itemRelationsComponent__parameters}
                  >
                    <div className={styles.changeDate} readOnly>
                      <DatePicker
                        selected={item.date ? item.date : ""}
                        dateFormat="dd MMMM yyyy"
                        readOnly
                      />
                      <button className="icon-button">
                        <CalendarIcon />
                      </button>
                    </div>
                    <span
                      className={styles.itemTogetherRelations__separate}
                    ></span>
                    <div
                      className={`${styles.itemTogetherRelations__region} `}
                      readOnly
                    >
                      <div>
                        <input
                          type="text"
                          placeholder="Region"
                          readOnly
                          value={item.region ? item.region.name : "WW"}
                        />
                      </div>
                    </div>

                    <span
                      className={styles.itemTogetherRelations__separate}
                    ></span>

                    <div
                      className={`${styles.itemTogetherRelations__region}
              `}
                      readOnly
                    >
                      <div>
                        <input
                          type="text"
                          placeholder="Special status"
                          value={
                            item.releaseData
                              ? item.releaseData.name
                              : "No status"
                          }
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className={styles.item}>
          <span className={styles.sectionTitle}>
            <SettingIcon />
            <span>Technologies:</span>
          </span>
          <div className={styles.itemRelationsComponent__items}>
            {dataItem.technologies && dataItem.technologies.length > 0 ? (
              dataItem.technologies.map((item, index) => (
                <div
                  key={index}
                  className={styles.itemRelationsComponent__parameters}
                >
                  <div
                    className={`${styles.itemTogetherRelations__region}  `}
                    readOnly
                  >
                    <div>
                      <input
                        type="text"
                        placeholder="Select technology"
                        value={
                          item.technology
                            ? item.technology.name
                            : "No technology"
                        }
                        readOnly
                        style={{
                          width: "360px",
                        }}
                      />
                    </div>
                  </div>
                  <span
                    className={styles.itemTogetherRelations__separate}
                  ></span>
                  <div
                    className={`${styles.itemTogetherRelations__region}  `}
                    readOnly
                  >
                    <div>
                      <input
                        type="text"
                        placeholder="Version"
                        value={item.version ? item.version : "No version"}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const AddReleases = forwardRef(
  (
    { addReleases, setAddReleases, addReleasesVerify, setAddReleasesVerify },
    ref
  ) => {
    const { gameOutside, gameReleases } = useSelector((state) => state.addGame);
    const [completedItems, setCompletedItems] = useState([]);
    const [uncompletedItems, setUncompletedItems] = useState([]);
    const [platformsData, setPlatformsData] = useState(null);
    const [platformSearch, setPlatformSearch] = useState("");
    const [selectPlatformActive, setSelectPlatformActive] = useState(false);
    const [debouncedSearchPlatform] = useDebounce(platformSearch, 1000);
    useEffect(() => {
      if (selectPlatformActive) {
        const fetchData = async () => {
          try {
            const formattedSearch = normalizeInput(debouncedSearchPlatform);
            const response = await axios.get(
              `${url}/api/platforms?filters[name][$contains]=${formattedSearch}&fields[0]=name&fields[1]=igdbId&fields[2]=color&sort=name:asc`
            );
            setPlatformsData(response.data);
          } catch (error) {
            console.error("Ошибка при получении данных:", error);
          }
        };
        fetchData();
      }
    }, [debouncedSearchPlatform, selectPlatformActive]);

    const handlePlatformChange = (e) => {
      setPlatformSearch(e.target.value);
      setSelectPlatformActive(true);
    };
    const handlePlatformClick = () => {
      setSelectPlatformActive(!selectPlatformActive);
    };
    const handlePlatformInputBlur = () => {
      setTimeout(() => {
        setSelectPlatformActive(false);
      }, 100);
    };
    const handleSelectPlatform = (item) => {
      // Проверяем, существует ли найденный элемент в uncompletedItems или completedItems
      const isInUncompletedItems = uncompletedItems.some(
        (existingItem) => existingItem.platform.id === item.id
      );
      const isInCompletedItems = completedItems.some(
        (existingItem) => existingItem.platform.id === item.id
      );

      // Если элемента нет ни в uncompletedItems, ни в completedItems, добавляем его в uncompletedItems
      if (!isInUncompletedItems && !isInCompletedItems) {
        setUncompletedItems((prevItems) => [
          ...prevItems,
          {
            platform: item,
            releases: [],
            technologies: [],
          },
        ]);
        setPlatformSearch("");
        setSelectPlatformActive(false);
      }
    };
    const handleSearchPlatformKeyPress = (e) => {
      if (e.key === "Enter") {
        const searchText = e.target.value.trim().toLowerCase();
        const exactMatch = platformsData.data.find(
          (item) => item.attributes.name.trim().toLowerCase() === searchText
        );

        if (exactMatch) {
          // Проверяем, существует ли найденный элемент в uncompletedItems или completedItems
          const isInUncompletedItems = uncompletedItems.some(
            (item) => item.platform.id === exactMatch.id
          );
          const isInCompletedItems = completedItems.some(
            (item) => item.platform.id === exactMatch.id
          );

          // Если элемента нет ни в uncompletedItems, ни в completedItems, добавляем его в uncompletedItems
          if (!isInUncompletedItems && !isInCompletedItems) {
            setUncompletedItems((prevItems) => [
              ...prevItems,
              {
                platform: exactMatch,
                releases: [],
                technologies: [],
              },
            ]);
            setPlatformSearch("");
            setSelectPlatformActive(false);
          }
        }
      }
    };

    useEffect(() => {
      if (uncompletedItems.length === 0) {
        setAddReleases(completedItems);
        setAddReleasesVerify(true);
      } else {
        setAddReleases(null);
        setAddReleasesVerify(false);
      }
    }, [completedItems, uncompletedItems, addReleases]);

    useEffect(() => {
      if (gameOutside) {
        const validGameAlternativeNames = Array.isArray(gameReleases)
          ? gameReleases
          : [];
        setUncompletedItems(validGameAlternativeNames);
        setCompletedItems([]);
      } else {
        setUncompletedItems([]);
        setCompletedItems([]);
      }
    }, [gameOutside, gameReleases]);

    return (
      <div ref={ref} className={styles.item}>
        <span className={styles.sectionTitle}>
          <SettingIcon />
          <span>Platform and Releases:</span>
        </span>
        <div className={styles.itemTogetherRelations}>
          <div
            className={`${styles.inputSelect} ${
              selectPlatformActive ? styles.inputSelectActive : ""
            }`}
            onClick={handlePlatformClick}
          >
            <input
              type="text"
              placeholder="Select a few from the list"
              onChange={handlePlatformChange}
              value={platformSearch}
              onBlur={handlePlatformInputBlur}
              onKeyPress={handleSearchPlatformKeyPress}
            />
            {selectPlatformActive ? (
              <CloseIcon onClick={() => setPlatformSearch("")} />
            ) : (
              <ArrowChevron />
            )}
            {platformsData &&
              platformsData.data &&
              Array.isArray(platformsData.data) && (
                <div
                  className={`${styles.inputSelect__dropDown} ${
                    selectPlatformActive ? styles.active : ""
                  }`}
                >
                  {platformsData.data.length > 0 ? (
                    <ul>
                      {platformsData.data.map((item, index) => {
                        const isInUncompletedItems =
                          uncompletedItems &&
                          uncompletedItems.some(
                            (selectedItem) =>
                              selectedItem.platform.id === item.id
                          );
                        const isInCompletedItems =
                          completedItems &&
                          completedItems.some(
                            (selectedItem) =>
                              selectedItem.platform.id === item.id
                          );

                        const isSelected =
                          isInUncompletedItems || isInCompletedItems;

                        if (!isSelected) {
                          return (
                            <li
                              key={item.id}
                              onClick={() => handleSelectPlatform(item)}
                            >
                              {item.attributes.name}
                            </li>
                          );
                        } else {
                          return (
                            <li
                              key={item.id}
                              style={{
                                color: " rgb(97, 97, 97)",
                                cursor: "default",
                              }}
                            >
                              {item.attributes.name}
                            </li>
                          );
                        }
                      })}
                    </ul>
                  ) : (
                    <p className={styles.dropDownListEmpty}>
                      The list is empty
                    </p>
                  )}
                </div>
              )}
          </div>
          <div className={styles.itemTogetherRelations__items}>
            {completedItems.map((item, index) => {
              return (
                <ReleasesCompletedCard
                  key={index}
                  uncompletedItems={uncompletedItems}
                  setUncompletedItems={setUncompletedItems}
                  dataItem={item}
                  dataItemIndex={index}
                  completedItems={completedItems}
                  setCompletedItems={setCompletedItems}
                />
              );
            })}
            {uncompletedItems.map((item, index) => {
              return (
                <ReleasesUncompletedCard
                  key={index}
                  uncompletedItems={uncompletedItems}
                  setUncompletedItems={setUncompletedItems}
                  dataItem={item}
                  dataItemIndex={index}
                  completedItems={completedItems}
                  setCompletedItems={setCompletedItems}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }
);

const AddGameForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userDataString =
    localStorage.getItem("jwt") || sessionStorage.getItem("jwt");
  const authToken = JSON.parse(userDataString);

  const igdbGameId = useSelector((state) => state.addGame.gameId);

  const [gameTitle, setGameTitle] = useState("");
  const [gameTitleVerify, setGameTitleVerify] = useState(false);

  const [selectGameType, setSelectGameType] = useState(null);
  const [selectTypeRelationToGame, setSelectTypeRelationToGame] =
    useState(null);
  const [selectGameTypeVerify, setSelectGameTypeVerify] = useState(false);
  const [selectTypeRelationToGameVerify, setSelectTypeRelationToGameVerify] =
    useState(false);
  const [image, setImage] = useState(null);

  const gameMedia = useSelector((state) => state.addGame.media);
  const [imageVerify, setImageVerify] = useState(false);
  const [gameDescription, setGameDescription] = useState("");
  const [gameDescriptionVerify, setGameDescriptionVerify] = useState(false);

  const { userId } = useSelector((state) => state.myProfile);
  const uploaderID = userId;

  const [selectGameGenres, setSelectGameGenres] = useState(null);
  const [selectGameGenresVerify, setSelectGameGenresVerify] = useState(false);

  const [selectGameThemes, setSelectGameThemes] = useState(null);
  const [selectGameThemesVerify, setSelectGameThemesVerify] = useState(false);

  const [selectSeries, setSelectSeries] = useState(null);
  const [selectSeriesVerify, setSelectSeriesVerify] = useState(false);

  const [selectGameGameMode, setSelectGameGameMode] = useState(null);
  const [selectGameGameModeVerify, setSelectGameGameModeVerify] =
    useState(false);
  const [selectGamePlayerRerspectives, setSelectGamePlayerRerspectives] =
    useState(null);
  const [
    selectGamePlayerRerspectivesVerify,
    setSelectGamePlayerRerspectivesVerify,
  ] = useState(false);

  const [alternativeNames, setAlternativeNames] = useState(null);
  const [alternativeNamesVerify, setAlternativeNamesVerify] = useState(false);

  const [addCompanies, setAddCompanies] = useState(null);
  const [addCompaniesVerify, setAddCompaniesVerify] = useState(false);

  const [addReleases, setAddReleases] = useState(null);
  const [addReleasesVerify, setAddReleasesVerify] = useState(false);

  const [storeLinks, setStoreLinks] = useState(null);
  const [storeLinksVerify, setStoreLinksVerify] = useState(false);

  const [socialLinks, setSocialLinks] = useState(null);
  const [socialLinksVerify, setSocialLinksVerify] = useState(false);

  const [addTags, setAddTags] = useState([]);

  const storeLinksRef = useRef(null);
  const addTitleRef = useRef(null);
  const socialLinksRef = useRef(null);
  const addReleasesRef = useRef(null);
  const addCompaniesRef = useRef(null);
  const alternativeNamesRef = useRef(null);
  const selectGameTypeRef = useRef(null);

  const scrollToFirstInvalid = () => {
    const checks = [
      { name: "Add Title", verify: gameTitleVerify, ref: addTitleRef },
      {
        name: "Select Game Type",
        verify: selectGameTypeVerify,
        ref: selectGameTypeRef,
      },
      {
        name: "Alternative Names",
        verify: alternativeNamesVerify,
        ref: alternativeNamesRef,
      },
      { name: "Add Releases", verify: addReleasesVerify, ref: addReleasesRef },
      {
        name: "Add Companies",
        verify: addCompaniesVerify,
        ref: addCompaniesRef,
      },
      { name: "Store Links", verify: storeLinksVerify, ref: storeLinksRef },
      { name: "Social Links", verify: socialLinksVerify, ref: socialLinksRef },
    ];

    //checks.forEach(check => console.log(`${check.name} verified: ${check.verify}`));
    const firstInvalid = checks.find((check) => !check.verify);

    if (firstInvalid) {
      if (firstInvalid.ref.current) {
        firstInvalid.ref.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      } else {
        console.log(`Error DOM element.`);
      }
    } else {
      //console.log('No needed');
      return true;
    }
  };

  const handleUpload = async (input) => {
    const formData = new FormData();

    // Проверка, является ли input объектом File
    if (input instanceof File) {
      formData.append("files", input);
    }
    // Проверка, является ли input строкой (предполагается URL)
    else if (typeof input === "string") {
      try {
        // Проверка и добавление https:// к URL, если отсутствует
        let url = input;
        if (!/^https?:\/\//i.test(url)) {
          url = "https://" + url;
        }

        // Загрузка изображения по URL и преобразование в Blob
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Failed to fetch image from URL: ${response.status}`);
        }
        const myBlob = await response.blob();
        formData.append("files", myBlob, "outsideGameImage.jpg");
      } catch (error) {
        return null;
      }
    }
    // Если input не является ни File, ни строкой
    else {
      return null;
    }

    try {
      // Попытка отправки файла на сервер
      const response = await fetch(`${url}/api/upload`, {
        method: "POST",
        headers: { Authorization: `Bearer ${encodeURIComponent(authToken)}` },
        body: formData,
      });

      // Проверка ответа сервера
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `Server responded with ${response.status}: ${errorText}`
        );
      }

      const responseData = await response.json();

      // Проверка данных ответа
      if (responseData.length > 0 && responseData[0].id) {
        return responseData[0].id;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };
  const uploadCover = async (input) => {
    const cover = input ? await handleUpload(input) : null;
    return cover;
  };
  const uploadMedia = async (input) => {
    // Если input - объект с полем url, извлекаем это поле
    const url = input && input.url ? input.url : null;
    const mediaId = url ? await handleUpload(url) : null;
    return mediaId;
  };
  const processMedia = async (media) => {
    if (!media || media.length === 0) {
      return []; // Возвращаем пустой массив вместо объекта
    }

    // Создаем массив промисов
    const mediaPromises = media.map(async (item) => {
      return await uploadMedia(item);
    });

    // Ждем завершения всех промисов
    const processedMedia = await Promise.all(mediaPromises);

    return processedMedia;
  };
  const createNewGame = async ({ id, igdbId, title }) => {
    if (id) {
      return id;
    }

    const postDataInfo = {
      data: {
        title: title || "",
        igdb_id: igdbId || null,
        uploader: { connect: [uploaderID] },
        entityStatus: 6,
      },
    };

    try {
      const response = await fetch(`${url}/api/games`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${encodeURIComponent(authToken)}`,
        },
        body: JSON.stringify(postDataInfo),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `Server responded with ${response.status}: ${errorText}`
        );
      }

      const responseData = await response.json();
      if (responseData && responseData?.data && responseData?.data?.id) {
        return responseData?.data?.id;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Failed to create new game:", error);
      return null;
    }
  };
  const createNewCompany = async (addCompanies) => {
    if (!addCompanies || addCompanies.length === 0) {
      return null;
    }
  
    const processedCompanies = await Promise.all(
      addCompanies.map(async (item) => {
        let companyId = item.company?.id;
  
        // Если companyId равен null, создаем новую компанию через POST запрос
        if (companyId === null) {
          const postDataInfo = {
            data: {
              name: item.company?.attributes?.name || '',
              altName:item.company?.attributes?.name || '',
            },
          };
  
          try {
            const response = await fetch(`${url}/api/companies`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${encodeURIComponent(authToken)}`,
              },
              body: JSON.stringify(postDataInfo),
            });
  
            if (!response.ok) {
              const errorText = await response.text();
              throw new Error(
                `Server responded with ${response.status}: ${errorText}`
              );
            }
  
            const responseData = await response.json();
            if (responseData && responseData?.data && responseData?.data?.id) {
              companyId = responseData?.data?.id;
            } 
          } catch (error) {
            console.error('Failed to create new company:', error);
            return null;
          }
        }
  
        // Проверяем наличие данных для switchData
        const hasEnoughData = item.switch && item.switch.length >= 4;
        const switchData = item.switch || [];
        
        return {
          name: companyId ? { id: companyId } : null,
          publisher: hasEnoughData ? switchData[0]?.state || false : false,
          mainDeveloper: hasEnoughData ? switchData[1]?.state || false : false,
          supportingDeveloper: hasEnoughData ? switchData[2]?.state || false : false,
          portingDeveloper: hasEnoughData ? switchData[3]?.state || false : false,
          platforms: item.platforms?.map((plat) => plat.id) || [],
        };
      })
    );

    return processedCompanies;
  };
  const createNewPlatform = async (addReleases) => {
    if (!addReleases || addReleases.length === 0) {
      return [];
    }
  
    return await Promise.all(
      addReleases.map(async (item) => {
        let platformId = item?.platform?.id;
  
        // Если platformId равен null, делаем POST-запрос для создания новой платформы
        if (!platformId) {
          const postDataInfo = {
            data: {
              igdbId: item.platform?.attributes?.igdbId || null,
              name: item.platform?.attributes?.name || '',
            },
          };
  
          try {
            const response = await fetch(`${url}/api/platforms`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${encodeURIComponent(authToken)}`,
              },
              body: JSON.stringify(postDataInfo),
            });
  
            if (!response.ok) {
              const errorText = await response.text();
              throw new Error(
                `Server responded with ${response.status}: ${errorText}`
              );
            }
  
            const responseData = await response.json();
            platformId = responseData?.data?.id;
          } catch (error) {
            console.error('Failed to create new platform:', error);
            throw error; // Прекращаем выполнение при ошибке
          }
        }
  
        // Формируем объект для возвращения
        return {
          platform: platformId ? { id: platformId } : null,
          releases: item?.releases.map((rel) => ({
            date: rel?.date
              ? new Date(rel.date).toISOString().split('T')[0]
              : null,
            region: rel?.region?.id || null,
            release_status: rel?.releaseData?.id || null,
          })),
          technologies: item?.technologies.map((tech) => ({
            version: tech?.version || null,
            name: tech?.technology?.id || null,
          })),
        };
      })
    );
  };
  
  const handleSubmit = async (e, uploaderId) => {
    e.preventDefault();
    if (!scrollToFirstInvalid()) {
      return;
    }
    dispatch(setSaveData(true))
    const alternativeNamesFormatted = alternativeNames
      ? alternativeNames.map((item) => ({
          altName: item.name,
          region: item.region ? item.region.id : null,
        }))
      : [];
    const storeLinksFormatted = storeLinks
      ? storeLinks.map((item) => ({
          link: item.name,
          store: item.stores ? item.stores.id : null,
        }))
      : [];
    const socialLinksFormatted = socialLinks
      ? socialLinks.map((item) => ({
          link: item.name,
          name: item.social ? item.social.id : null,
        }))
      : [];

    const numericTagIds = addTags.filter((item) => typeof item.id === "number");
    const otherTags = addTags.filter((item) => typeof item.id !== "number");

    const numericGenresIds = selectGameGenres.filter(
      (item) => typeof item.id === "number"
    );
    const otherGenres = selectGameGenres.filter(
      (item) => typeof item.id !== "number"
    );

    const numericThemeIds = selectGameThemes.filter(
      (item) => typeof item.id === "number"
    );
    const otherTheme = selectGameThemes.filter(
      (item) => typeof item.id !== "number"
    );

    const numericGameModesIds = selectGameGameMode.filter(
      (item) => typeof item.id === "number"
    );
    const otherGameModes = selectGameGameMode.filter(
      (item) => typeof item.id !== "number"
    );

    const numericPlayerPerspectivesIds = selectGamePlayerRerspectives.filter(
      (item) => typeof item.id === "number"
    );
    const otherPlayerPerspectives = selectGamePlayerRerspectives.filter(
      (item) => typeof item.id !== "number"
    );

    const existingSeries =
      typeof selectSeries?.id === "number" ? selectSeries : null;
    const newSeries =
      typeof selectSeries?.id !== "number" ? selectSeries : null;

    const postDataInfo = {
      data: {
        title: gameTitle,
        description: gameDescription || null,
        type:
          (selectGameType?.length > 0 ? selectGameType[0].id : null) || null,
        gameRalation: selectTypeRelationToGame
          ? await createNewGame(selectTypeRelationToGame)
          : null,
        series: existingSeries ? existingSeries?.id : null,
        genres:
          { connect: numericGenresIds.map((item) => ({ id: item?.id })) } ||
          null,
        themes:
          { connect: numericThemeIds.map((item) => ({ id: item?.id })) } ||
          null,
        gameModes:
          { connect: numericGameModesIds.map((item) => ({ id: item?.id })) } ||
          null,
        playerPerspectives:
          {
            connect: numericPlayerPerspectivesIds.map((item) => ({
              id: item?.id,
            })),
          } || null,
        tags:
          { connect: numericTagIds.map((item) => ({ id: item?.id })) } || null,
        igdb_id: igdbGameId || null,
        uploader: { connect: [uploaderID] },
        AlternativeNames: alternativeNamesFormatted || null,
        storeLinks: storeLinksFormatted || null,
        socialLinks: socialLinksFormatted || null,
        platformReleases: addReleases ? await createNewPlatform (addReleases) : null,
        company: addCompanies ? await createNewCompany(addCompanies) : null,
        coverHeader: image ? await uploadCover(image) : null,
        cover: image ? await uploadCover(image) : null,
        media: gameMedia ? await processMedia(gameMedia) : null,
        entityStatus: 1,
        assets: null,
        gameRalationMains: null,
      },
    };

    axios
      .post(`${url}/api/games`, postDataInfo, {
        headers: {
          Authorization: `Bearer ${encodeURIComponent(authToken)}`,
        },
      })
      .then((response) => {
        const gameId = response.data.data.id;

        const postSeriesRequests = () => {
          if (newSeries && !existingSeries) {
            const newSeriesData = {
              data: {
                name: newSeries?.attributes?.name,
                games: gameId,
              },
            };

            return axios.post(`${url}/api/series-s`, newSeriesData, {
              headers: {
                Authorization: `Bearer ${encodeURIComponent(authToken)}`,
              },
            });
          }
          return Promise.resolve();
        };
        const postTagRequests = otherTags.map((tag) => {
          const newTagsData = {
            data: {
              name: tag.attributes.name || "Unknown",
              assets: null,
              tool: null,
              games: gameId,
            },
          };
          return axios.post(`${url}/api/tags`, newTagsData, {
            headers: {
              Authorization: `Bearer ${encodeURIComponent(authToken)}`,
            },
          });
        });
        const postGenresRequests = otherGenres.map((item) => {
          const newGenresData = {
            data: {
              name: item?.attributes?.name || "Unknown",
              games: gameId,
            },
          };
          return axios.post(`${url}/api/genres`, newGenresData, {
            headers: {
              Authorization: `Bearer ${encodeURIComponent(authToken)}`,
            },
          });
        });
        const postThemesRequests = otherTheme.map((item) => {
          const newThemesData = {
            data: {
              name: item?.attributes?.name,
              games: gameId,
            },
          };
          return axios.post(`${url}/api/themes`, newThemesData, {
            headers: {
              Authorization: `Bearer ${encodeURIComponent(authToken)}`,
            },
          });
        });
        const postPlayerPerspectivesRequests = otherPlayerPerspectives.map(
          (item) => {
            const newPlayerPerspectivesData = {
              data: {
                name: item?.attributes?.name,
                games: gameId,
              },
            };
            return axios.post(
              `${url}/api/player-perspectives`,
              newPlayerPerspectivesData,
              {
                headers: {
                  Authorization: `Bearer ${encodeURIComponent(authToken)}`,
                },
              }
            );
          }
        );
        const postGameModesRequests = otherGameModes.map((item) => {
          const newGameModesData = {
            data: {
              name: item?.attributes?.name,
              games: gameId,
            },
          };
          return axios.post(`${url}/api/game-modes`, newGameModesData, {
            headers: {
              Authorization: `Bearer ${encodeURIComponent(authToken)}`,
            },
          });
        });

        return Promise.all([
          postSeriesRequests(),
          ...postTagRequests,
          ...postGenresRequests,
          ...postPlayerPerspectivesRequests,
          ...postGameModesRequests,
          ...postThemesRequests,
        ])
          .then((responses) => {
            return gameId;
          })
          .then((gameId) => {
            navigate(gameId ? `/game/${gameTitle}?id=${gameId}` : "/");
            dispatch(setGameOutside(false));
            setGameName(null);
            setGameId(null);
            setGameType(null);
            setGameTypeRelation(null);
            setCover(null);
            setMedia(null);
            setDescription(null);
            setGameSeries(null);
            setGenres(null);
            setNewGenres(null);
            setThemes(null);
            setNewThemes(null);
            setPlayerPerspectives(null);
            setNewPlayerPerspectives(null);
            setGameModes(null);
            setNewGameModes(null);
            setGameAlternativeNames(null);
            setGameSocialLinks(null);
            setGameTags(null);
            setGameNewTags(null);
            setGameReleases(null);
            setGameCompanies(null);
            setGameNewCompanies(null);
            dispatch(setSaveData(false))
          });
      })
      .catch((error) => {
        console.error(
          "Ошибка при отправке данных:",
          JSON.stringify(error.config.data, null, 2)
        );
      });
  };

  return (
    <div className={styles.addGame}>
      <div className="container">
        <div className={styles.addGame__wrapper}>
          <div className={styles.addGame__header}>
            <p className={styles.addGame__title}>Common info</p>
            <button onClick={handleSubmit} className={!scrollToFirstInvalid() ? styles.noSubmit : "" }>Save</button>
          </div>
          <div className={styles.addGame__items}>
            <AddTitle
              ref={addTitleRef}
              gameTitle={gameTitle}
              setGameTitle={setGameTitle}
              setGameTitleVerify={setGameTitleVerify}
              gameTitleVerify={gameTitleVerify}
            />
            <SelectType
              ref={selectGameTypeRef}
              selectGameType={selectGameType}
              setSelectGameType={setSelectGameType}
              selectTypeRelationToGame={selectTypeRelationToGame}
              setSelectTypeRelationToGame={setSelectTypeRelationToGame}
              selectGameTypeVerify={selectGameTypeVerify}
              setSelectGameTypeVerify={setSelectGameTypeVerify}
              selectTypeRelationToGameVerify={selectTypeRelationToGameVerify}
              setSelectTypeRelationToGameVerify={
                setSelectTypeRelationToGameVerify
              }
            />
            <UploaderCover
              image={image}
              setImage={setImage}
              imageVerify={imageVerify}
              setImageVerify={setImageVerify}
            />
            <AddDescription
              gameDescription={gameDescription}
              setGameDescription={setGameDescription}
              gameDescriptionVerify={gameDescriptionVerify}
              setGameDescriptionVerify={setGameDescriptionVerify}
            />
            <AddAlternativeNames
              ref={alternativeNamesRef}
              alternativeNamesVerify={alternativeNamesVerify}
              setAlternativeNamesVerify={setAlternativeNamesVerify}
              alternativeNames={alternativeNames}
              setAlternativeNames={setAlternativeNames}
            />
            <div className={styles.twoLineItems}>
              <SelectThemes
                selectGameThemes={selectGameThemes}
                setSelectGameThemes={setSelectGameThemes}
                selectGameThemesVerify={selectGameThemesVerify}
                setSelectGameThemesVerify={setSelectGameThemesVerify}
              />
              <SelectGenres
                setSelectGameGenres={setSelectGameGenres}
                selectGameGenres={selectGameGenres}
                selectGameGenresVerify={selectGameGenresVerify}
                setSelectGameGenresVerify={setSelectGameGenresVerify}
              />
            </div>
            <SelectSeries
              selectSeries={selectSeries}
              setSelectSeries={setSelectSeries}
              selectSeriesVerify={selectSeriesVerify}
              setSelectSeriesVerify={setSelectSeriesVerify}
            />
            <div className={styles.twoLineItems}>
              <SelectPlayerRerspectives
                selectGamePlayerRerspectives={selectGamePlayerRerspectives}
                setSelectGamePlayerRerspectives={
                  setSelectGamePlayerRerspectives
                }
                selectGamePlayerRerspectivesVerify={
                  selectGamePlayerRerspectivesVerify
                }
                setSelectGamePlayerRerspectivesVerify={
                  setSelectGamePlayerRerspectivesVerify
                }
              />
              <SelectGameMode
                selectGameGameMode={selectGameGameMode}
                setSelectGameGameMode={setSelectGameGameMode}
                selectGameGameModeVerify={selectGameGameModeVerify}
                setSelectGameGameModeVerify={setSelectGameGameModeVerify}
              />
            </div>
            <AddReleases
              ref={addReleasesRef}
              setAddReleases={setAddReleases}
              addReleases={addReleases}
              addReleasesVerify={addReleasesVerify}
              setAddReleasesVerify={setAddReleasesVerify}
            />
            <AddCompanies
              ref={addCompaniesRef}
              addCompanies={addCompanies}
              setAddCompanies={setAddCompanies}
              setAddCompaniesVerify={setAddCompaniesVerify}
              addCompaniesVerify={addCompaniesVerify}
            />
            <AddStoreLink
              ref={storeLinksRef}
              storeLinks={storeLinks}
              setStoreLinks={setStoreLinks}
              storeLinksVerify={storeLinksVerify}
              setStoreLinksVerify={setStoreLinksVerify}
            />
            <AddSocialLink
              ref={socialLinksRef}
              socialLinks={socialLinks}
              setSocialLinks={setSocialLinks}
              socialLinksVerify={socialLinksVerify}
              setSocialLinksVerify={setSocialLinksVerify}
            />
            <div className={styles.twoLineItems}>
              <AddTags addTags={addTags} setAddTags={setAddTags} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddGameForm;
