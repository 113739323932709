import { createSlice } from "@reduxjs/toolkit";

const url = process.env.REACT_APP_SERVER_API;
const proxy = process.env.REACT_APP_PROXY_URL;

const assetsItemsSlice = createSlice({
  name: "assetsItems",
  initialState: {
    assetsCount: 0,
    viewPosition: 0,
  },
  reducers: {
    setAssetsCount: (state, action) => {
      state.assetsCount = action.payload;
    },

    setViewPosition: (state, action) => {
      state.viewPosition = action.payload;
    },
  },
});

export const { setAssetsCount, setViewPosition } = assetsItemsSlice.actions;
export default assetsItemsSlice.reducer;
