import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getUserInfo } from "../../store/features/authorizationSlice";

import Header from "../Header";
import AddGameForm from "../AddGameForm";
import Footer from "../Footer";
import LoaderSpinners from "../mui/LoaderSpinners";

const AddNewGames = () => {
  const dispatch = useDispatch();
  const isAuthorization = useSelector(
    (state) => state.authorization.isAuthorization
  );
  const saveData = useSelector((state) => state.addGame.saveData);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getUserInfo()).finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (saveData) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [saveData]);

  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <>
      {isAuthorization ? (
        <div className="main">
          <Header />
          <div className="main__contant">
            <AddGameForm />
          </div>
          <Footer />
          {saveData ? <LoaderSpinners /> : null}
        </div>
      ) : (
        <Navigate to="/login" replace />
      )}
    </>
  );
};

export default AddNewGames;
