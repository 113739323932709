import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import "@splidejs/splide/dist/css/splide.min.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import ProgressiveImage from "react-progressive-graceful-image";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setSearchRequestData,
  setSelectCategory,
  setInputArea,
} from "../store/features/searchSlice";

import { ReactComponent as CrossIcon } from "../assets/icon/crossIcon.svg";
import { ReactComponent as CloseIcon } from "../assets/icon/closeIcon.svg";
import { ReactComponent as ViewIcon } from "../assets/icon/viewIcon.svg";
import { ReactComponent as BookmarkIcon } from "../assets/icon/bookmarkIcon.svg";
import { ReactComponent as ArrowSeparatorIcon } from "../assets/icon/arrowSeparatorIcon.svg";
import { ReactComponent as ForestIcon } from "../assets/icon/forestIcon.svg";
import { ReactComponent as EditIcon } from "../assets/icon/editIcon.svg";
import { ReactComponent as ListIcon } from "../assets/icon/listIcon.svg";
import { ReactComponent as CircleInfoIcon } from "../assets/icon/circleInfoIcon.svg";
import { ReactComponent as MessageIcon } from "../assets/icon/messageIcon.svg";
import { ReactComponent as ImageIcon } from "../assets/icon/imageIcon.svg";
import { ReactComponent as DownloadIcon } from "../assets/icon/downloadIcon.svg";
import { ReactComponent as SpeakerIcon } from "../assets/icon/speakerIcon.svg";

import styles from "../scss/components/modalDetailItem.module.scss";

const imageUrl = process.env.REACT_APP_IMAGE_URL;

const ReportModal = ({ handleReportToogle }) => {
  return (
    <div className={styles.reportModal}>
      <div
        onClick={handleReportToogle}
        className={styles.reportModal__wrapper}
      ></div>
      <svg
        onClick={handleReportToogle}
        className={styles.reportModal__closeMb}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.68807 0L0 1.68807L10.3119 12L0 22.3118L1.68807 24L12 13.688L22.3118 24L24 22.3118L13.688 12L24 1.68807L22.3118 0L12 10.3119L1.68807 0Z"
          fill="white"
        />
      </svg>
      <div className={styles.reportModal__contant}>
        <p className={styles.reportModal__title}>Report for the broken link</p>
        <div className={styles.reportModal__actions}>
          <div>
            <span>Describe the problem</span>
            <div>
              <textarea name="" id="" cols="30" rows="10"></textarea>
            </div>
          </div>
          <button className={styles.reportModal__actionSend}>Send</button>
          <button>Log in</button>
        </div>

        <svg
          onClick={handleReportToogle}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.68807 0L0 1.68807L10.3119 12L0 22.3118L1.68807 24L12 13.688L22.3118 24L24 22.3118L13.688 12L24 1.68807L22.3118 0L12 10.3119L1.68807 0Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  );
};

const ImageCarousel = ({ images, assetName, setImageOpen, onSelect }) => {
  const mainRef = useRef(null);
  const thumbnailRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const mainSplide = mainRef.current?.splide;
    const thumbnailSplide = thumbnailRef.current?.splide;

    if (mainSplide && thumbnailSplide) {
      mainSplide.sync(thumbnailSplide);
      thumbnailSplide.sync(mainSplide);

      const updateCurrentSlide = (index) => setCurrentSlide(index);

      mainSplide.on("mounted move", () => {
        updateCurrentSlide(mainSplide.index);
      });

      thumbnailSplide.on("mounted move", () => {
        updateCurrentSlide(thumbnailSplide.index);
      });
    }

    return () => {
      if (mainSplide) {
        mainSplide.off("mounted move");
      }
      if (thumbnailSplide) {
        thumbnailSplide.off("mounted move");
      }
    };
  }, []);

  const goToSlide = (index) => {
    mainRef.current.splide.go(index);
  };

  return (
    <div className={styles.carousel}>
      <div className={styles.sliderFor}>
        <Splide
          ref={mainRef}
          options={{
            type: "fade",
            heightRatio: 0.5,
            pagination: false,
            arrows: false,
            cover: true,
            breakpoints: {
              767: {
                pagination: false,
              },
            },
          }}
          className={styles.sliderFor__main}
        >
          {images.map((item, index) => {
            return (
              <SplideSlide onClick={() => setImageOpen(true)} key={index}>
                <ProgressiveImage
                  src={`${imageUrl}${item?.attributes.url}`}
                  placeholder={`${imageUrl}${item?.attributes.url}`}
                >
                  {(src, loading) => (
                    <div
                      style={{
                        backgroundImage: `url(${src})`,
                        filter: loading ? "blur(70px)" : "none",
                        transition: "filter 0.3s ease-out",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <img src={src} alt={assetName || "Unknown game"} />
                    </div>
                  )}
                </ProgressiveImage>
              </SplideSlide>
            );
          })}
        </Splide>
        <div className={styles.customPagination}>
          {images.map((_, index) => (
            <button
              key={index}
              className={` ${
                currentSlide === index
                  ? styles.customPagination__customDotActive
                  : ""
              }`}
              onClick={() => goToSlide(index)}
            />
          ))}
        </div>
      </div>

      <div className={styles.sliderNav}>
        <button
          className={`${styles.carousel__leftArrow} ${
            currentSlide === 0 ? styles.carousel__noActiveBtn : ""
          }`}
          onClick={() => thumbnailRef.current.go("<")}
        >
          <ArrowSeparatorIcon />
        </button>
        <button
          className={`${styles.carousel__rightArrow} ${
            currentSlide === images?.length - 1
              ? styles.carousel__noActiveBtn
              : ""
          }`}
          onClick={() => thumbnailRef.current.go(">")}
        >
          <ArrowSeparatorIcon />
        </button>

        <Splide
          ref={thumbnailRef}
          className={styles.sliderNav__thumbnail}
          options={{
            fixedWidth: 80,
            fixedHeight: 80,
            isNavigation: true,
            gap: 16,
            focus: "center",
            pagination: false,
            cover: true,
            breakpoints: {
              600: {
                fixedWidth: 80,
                fixedHeight: 80,
              },
            },
          }}
        >
          {images.map((item, index) => (
            <SplideSlide key={index}>
              <img
                src={`${imageUrl}${item?.attributes.url}`}
                alt={assetName || "Unknown game"}
              />
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </div>
  );
};
const NoImageToShow = () => {
  return (
    <div className={styles.noImages}>
      <ForestIcon />
      <div>
        <h3>No Images to show</h3>
        <p>The author did not add any pictures to this post.</p>
      </div>
    </div>
  );
};
const NoItems = ({ current }) => {
  const displayText = current || "argument";
  return (
    <div className={styles.noItems}>
      <p>No {displayText} provided.</p>
    </div>
  );
};
const AssetDescription = ({ description }) => {
  return (
    <div className={styles.description}>
      <h2>Description</h2>
      <span></span>
      <div className={styles.description__content}>
        <p>{description}</p>
      </div>
    </div>
  );
};

const AssetTags = ({ tags }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleItemClick = async (item) => {
    setTimeout(() => {
      navigate(`/search?query=${item?.attributes?.name}&category=all`);
    }, 0);
    dispatch(setInputArea(""));
    dispatch(setSearchRequestData(null));
    dispatch(setSelectCategory(null));
  };
  return (
    <div className={styles.tags}>
      <h2>Tags</h2>
      <div className={styles.tags__items}>
        {tags.map((item) => {
          return (
            <div
              key={item.id}
              onClick={() => handleItemClick(item)}
              className={styles.tags__item}
            >
              <span>{item?.attributes?.name}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
const AssetHeader = ({ title }) => {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    if (window.history.length <= 1) {
      navigate("/");
    } else {
      navigate(-1);
    }
  }, [navigate]);

  return (
    <div className={styles.assetHeader}>
      <h2>{title}</h2>
      <div className={styles.assetHeader__info}>
        <span>
          <ViewIcon />
          <p>123</p>
        </span>
        <span>
          <BookmarkIcon />
          <p>234</p>
        </span>
        <span>
          <DownloadIcon />
          <p>456</p>
        </span>
        <span>
          <CloseIcon onClick={handleClick} />
        </span>
      </div>
    </div>
  );
};
const MainInfo = ({
  game,
  platform,
  technologies,
  category,
  subcategory,
  author,
  license,
}) => {
  const categoryAttributes = category?.data?.attributes || {};
  const subcategoryAttributes = subcategory?.data?.attributes || {};
  const group = [];
  if (categoryAttributes.name) {
    group.push({ name: categoryAttributes.name, mark: "category" });
  }
  if (subcategoryAttributes.name) {
    group.push({ name: subcategoryAttributes.name, mark: "subcategory" });
  }

  const filteredCompanies = game?.attributes?.company.filter(
    (item) => item.publisher === true
  );
  const publishersCompany = filteredCompanies
    ? filteredCompanies
        .map((item) => {
          const companyName = item?.name?.data?.attributes?.name;
          const companyPlatforms = item?.platforms?.data || [];

          const matchedPlatforms = companyPlatforms
            .filter((companyPlatform) =>
              platform.some(
                (modelPlatform) => modelPlatform.id === companyPlatform.id
              )
            )
            .map((companyPlatform) => {
              const modelPlatform = platform.find(
                (modelPlatform) => modelPlatform.id === companyPlatform.id
              );
              return {
                platformId: modelPlatform.id,
                platformName: modelPlatform.attributes.altName,
              };
            });

          return {
            companyName,
            platforms: matchedPlatforms,
          };
        })
        .filter((item) => item.platforms.length > 0)
    : [];

  const [isHovered, setIsHovered] = useState(false);

  const [publishersCompanyActive, setPublishersCompanyActive] = useState(false);
  const [technologiesActive, setTechnologiesActive] = useState(false);
  const [groupActive, setGroupActive] = useState(false);
  const [authorActive, setAuthorActive] = useState(false);
  const [platformActive, setPlatformActive] = useState(false);

  const [publishersCompanyHeight, setPublishersCompanyHeight] = useState("0px");
  const [technologiesHeight, setTechnologiesHeight] = useState("0px");
  const [groupHeight, setGroupHeight] = useState("0px");
  const [authorHeight, setAuthorHeight] = useState("0px");
  const [platformHeight, setPlatformHeight] = useState("0px");

  const publishersCompanyRef = useRef(null);
  const technologiesRef = useRef(null);
  const groupRef = useRef(null);
  const authorRef = useRef(null);
  const platformRef = useRef(null);

  useEffect(() => {
    setPublishersCompanyHeight(
      publishersCompanyActive
        ? `${publishersCompanyRef.current.scrollHeight}px`
        : "0px"
    );
  }, [publishersCompanyActive]);
  useEffect(() => {
    setTechnologiesHeight(
      technologiesActive ? `${technologiesRef.current.scrollHeight}px` : "0px"
    );
  }, [technologiesActive]);
  useEffect(() => {
    setGroupHeight(groupActive ? `${groupRef.current.scrollHeight}px` : "0px");
  }, [groupActive]);
  useEffect(() => {
    setAuthorHeight(
      authorActive ? `${authorRef.current.scrollHeight}px` : "0px"
    );
  }, [authorActive]);
  useEffect(() => {
    setPlatformHeight(
      platformActive ? `${platformRef.current.scrollHeight}px` : "0px"
    );
  }, [platformActive]);

  return (
    <div className={styles.mainInfo}>
      <ul>
        <li>
          <div className={styles.mainInfo__title}>
            <h4>Game</h4>
          </div>
          <div>
            <Link
              to={
                {
                  pathname: `/game/${
                    game?.attributes?.title || "no-game-title"
                  } `,
                  search: `?id=${game?.id}`,
                } || "#"
              }
            >
              {game?.attributes?.title}
            </Link>
          </div>
        </li>
        <li className={`${styles.moreParam__platform}`}>
          <div className={styles.mainInfo__title}>
            <h4>Platform</h4>
          </div>
          <div className={`${styles.moreParam}`}>
            {platform.length > 0 ? (
              <>
                <div className={styles.moreParam__header}>
                  <Link
                    style={{
                      backgroundColor: platform[0]?.attributes?.color || "",
                      color: platform[0]?.attributes?.color
                        ? "#F2F2F2"
                        : "rgba(33, 34, 33, 1)",
                    }}
                    to="#"
                  >
                    {platform[0].attributes?.name || "Unknown"}
                  </Link>
                  <ArrowSeparatorIcon
                    style={{
                      transform: platformActive ? "rotate(270deg)" : "",
                      display: platform.length <= 1 ? "none" : "block",
                    }}
                    onClick={() => setPlatformActive(!platformActive)}
                  />
                </div>
                {platform.length > 1 && (
                  <div
                    className={`${styles.moreParam__body}`}
                    style={{
                      maxHeight: platformHeight,
                      transition: "max-height 0.3s ease",
                    }}
                    ref={platformRef}
                  >
                    {platform.slice(1).map((item, index) => (
                      <Link
                        style={{
                          backgroundColor: item?.attributes?.color || "",
                          color: item?.attributes?.color
                            ? "#F2F2F2"
                            : "rgba(33, 34, 33, 1)",
                        }}
                        key={item?.id}
                        to="/"
                      >
                        {item?.attributes?.name || "Unknown"}
                      </Link>
                    ))}
                  </div>
                )}
              </>
            ) : (
              <span className={styles.mainInfo__noInfo}>-</span>
            )}
          </div>
        </li>
        <li>
          <div className={styles.mainInfo__title}>
            <h4>Technology</h4>
          </div>
          <div className={`${styles.moreParam}`}>
            {technologies.length > 0 ? (
              <>
                <div className={styles.moreParam__header}>
                  <Link to="#">
                    {technologies[0]?.attributes?.name || "Unknown"}
                  </Link>
                  <ArrowSeparatorIcon
                    style={{
                      transform: technologiesActive ? "rotate(270deg)" : "",
                      display: technologies.length <= 1 ? "none" : "block",
                    }}
                    onClick={() => setTechnologiesActive(!technologiesActive)}
                  />
                </div>
                {technologies.length > 1 && (
                  <div
                    className={`${styles.moreParam__body}`}
                    style={{
                      maxHeight: technologiesHeight,
                      transition: "max-height 0.3s ease",
                    }}
                    ref={technologiesRef}
                  >
                    {technologies.slice(1).map((item, index) => (
                      <Link key={item?.id} to="/">
                        {item?.attributes?.name || "Unknown"}
                        {index < technologies.length - 2 && ", "}
                      </Link>
                    ))}
                  </div>
                )}
              </>
            ) : (
              <span className={styles.mainInfo__noInfo}>-</span>
            )}
          </div>
        </li>
        <li>
          <div className={styles.mainInfo__title}>
            <h4>Group</h4>
          </div>
          <div className={`${styles.moreParam}`}>
            {group.length > 0 ? (
              <>
                <div className={styles.moreParam__header}>
                  <Link to="#">{group[0].name || "Unknown"}</Link>
                  <ArrowSeparatorIcon
                    style={{
                      transform: groupActive ? "rotate(270deg)" : "",
                      display: group.length <= 1 ? "none" : "block",
                    }}
                    onClick={() => setGroupActive(!groupActive)}
                  />
                </div>
                {group.length > 1 && (
                  <div
                    className={`${styles.moreParam__body}`}
                    style={{
                      maxHeight: groupHeight,
                      transition: "max-height 0.3s ease",
                    }}
                    ref={groupRef}
                  >
                    {group.slice(1).map((item, index) => (
                      <Link key={item?.id || index} to="/">
                        {item?.name || "Unknown"}
                        {index < group.length - 2 && ", "}
                      </Link>
                    ))}
                  </div>
                )}
              </>
            ) : (
              <span className={styles.mainInfo__noInfo}>-</span>
            )}
          </div>
        </li>
        <li>
          <div className={styles.mainInfo__title}>
            <h4>Copyrights</h4>
          </div>
          <div className={`${styles.moreParam}`}>
            {publishersCompany.length > 0 ? (
              <>
                <div className={styles.moreParam__header}>
                  <Link to="#">
                    {publishersCompany[0].companyName || "Unknown"} (
                    {publishersCompany[0].platforms.map((item, index) => (
                      <React.Fragment key={item.platformId}>
                        {item.platformName || "unkn"}
                        {index < publishersCompany[0].platforms.length - 1 &&
                          ", "}
                      </React.Fragment>
                    ))}
                    )
                  </Link>

                  <ArrowSeparatorIcon
                    style={{
                      transform: publishersCompanyActive
                        ? "rotate(270deg)"
                        : "",
                      display: publishersCompany.length <= 1 ? "none" : "block",
                    }}
                    onClick={() =>
                      setPublishersCompanyActive(!publishersCompanyActive)
                    }
                  />
                </div>
                {publishersCompany.length > 1 && (
                  <div
                    className={`${styles.moreParam__body}`}
                    style={{
                      maxHeight: publishersCompanyHeight,
                      transition: "max-height 0.3s ease",
                    }}
                    ref={publishersCompanyRef}
                  >
                    {publishersCompany.slice(1).map((item, index) => (
                      <Link key={item?.id || index} to="/">
                        {item?.companyName || "Unknown"} (
                        {item.platforms.map((platform, idx) => (
                          <React.Fragment key={platform.platformId}>
                            {platform.platformName || "Unknown"}
                            {idx < item.platforms.length - 1 && ", "}
                          </React.Fragment>
                        ))}
                        )
                      </Link>
                    ))}
                  </div>
                )}
              </>
            ) : (
              <span className={styles.mainInfo__noInfo}>-</span>
            )}
          </div>
        </li>
        <li>
          <div className={styles.mainInfo__title}>
            <h4>Author</h4>
          </div>
          <div className={`${styles.moreParam}`}>
            {author.length > 0 ? (
              <>
                <div className={styles.moreParam__header}>
                  <Link to="/">
                    {author[0].name?.data?.attributes?.name || "Unknown"}
                  </Link>
                  <ArrowSeparatorIcon
                    style={{
                      transform: authorActive ? "rotate(270deg)" : "",
                      display: author.length <= 1 ? "none" : "block",
                    }}
                    onClick={() => setAuthorActive(!authorActive)}
                  />
                </div>
                {author.length > 1 && (
                  <div
                    className={`${styles.moreParam__body}`}
                    style={{
                      maxHeight: authorHeight,
                      transition: "max-height 0.3s ease",
                    }}
                    ref={authorRef}
                  >
                    {author.slice(1).map((item, index) => (
                      <Link key={item?.id} to="/">
                        {item?.name?.data?.attributes?.name || "Unknown"}
                        {index < author.length - 2 && ", "}
                      </Link>
                    ))}
                  </div>
                )}
              </>
            ) : (
              <span className={styles.mainInfo__noInfo}>-</span>
            )}
          </div>
        </li>
        <li>
          <div className={styles.mainInfo__title}>
            <h4>License</h4>
          </div>
          <div className={styles.circleMoreHover}>
            <div className={styles.circleMoreHover__main}>
              <Link to="/">{license?.attributes?.name || "Unknown"}</Link>
              <CircleInfoIcon
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              />
            </div>
            <div
              className={`${
                isHovered ? styles.circleMoreHover__hoverContent : styles.hidden
              }`}
            >
              <p>{license?.attributes?.description || "No description"}</p>
              <span>{license?.attributes?.type || "Unknown"}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};
const Interaction = () => {
  return (
    <div className={styles.interaction}>
      <button className={styles.interaction__btnDownload}>
        <div>
          <span>Download • 126 Mb</span>
          <DownloadIcon />
        </div>
      </button>
      <button className={styles.interaction__report}>
        <SpeakerIcon />
        <span>Report for the broken link</span>
      </button>
    </div>
  );
};
const Properties = ({
  acquired,
  uploader,
  quality,
  textureResolution,
  texturesType,
  armature,
  format,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const [formatActive, setFormatActive] = useState(false);
  const [formatHeight, setFormatHeight] = useState("0px");
  const formatRef = useRef(null);

  let texture = [];
  if (texturesType?.attributes?.name) {
    texture.push({
      name: texturesType?.attributes?.name,
      mark: "textureResolution",
    });
  }
  if (textureResolution) {
    texture.push({ name: textureResolution.slice(1), mark: "texturesType" });
  }

  useEffect(() => {
    setFormatHeight(
      formatActive ? `${formatRef.current.scrollHeight}px` : "0px"
    );
  }, [formatActive]);
  return (
    <div className={styles.properties}>
      <div>
        <h2>Properties</h2>
      </div>
      <div className={styles.properties__items}>
        <span className={styles.properties__split}></span>
        <ul>
          <li className={`${styles.moreParam__format}`}>
            <div className={styles.properties__title}>
              <h4>Format</h4>
            </div>
            <div className={`${styles.moreParam}`}>
              {format.length > 0 ? (
                <>
                  <div className={styles.moreParam__header}>
                    <Link to="#">
                      {format[0].attributes?.name || "Unknown"}
                    </Link>
                    <ArrowSeparatorIcon
                      style={{
                        transform: formatActive ? "rotate(270deg)" : "",
                        display: format.length <= 1 ? "none" : "block",
                      }}
                      onClick={() => setFormatActive(!formatActive)}
                    />
                  </div>
                  {format.length > 1 && (
                    <div
                      className={`${styles.moreParam__body}`}
                      style={{
                        maxHeight: formatHeight,
                        transition: "max-height 0.3s ease",
                      }}
                      ref={formatRef}
                    >
                      {format.slice(1).map((item, index) => (
                        <Link key={item?.id} to="/">
                          {item?.attributes?.name || "Unknown"}
                        </Link>
                      ))}
                    </div>
                  )}
                </>
              ) : (
                <span className={styles.properties__noInfo}>-</span>
              )}
            </div>
          </li>
          <li>
            <div className={styles.mainInfo__title}>
              <h4>Armature</h4>
            </div>
            <div>
              <span>{armature ? "True" : "False"}</span>
            </div>
          </li>
          <li>
            <div className={styles.properties__title}>
              <h4>Textures</h4>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: "6px" }}>
              {texture.length > 0 ? (
                texture.map((item, index) => {
                  return (
                    <span key={index}>
                      {item.name || "-"}{" "}
                      {index < texture.length - 1 && texture.length > 1
                        ? ", "
                        : ""}
                    </span>
                  );
                })
              ) : (
                <span>-</span>
              )}
            </div>
          </li>

          <li>
            <div className={styles.properties__title}>
              <h4>Quality</h4>
            </div>
            <div className={styles.circleMoreHover}>
              <div className={styles.circleMoreHover__main}>
                <Link to="/">{quality?.attributes?.name || "Unknown"}</Link>
                <CircleInfoIcon
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                />
              </div>
              <div
                className={`${
                  isHovered
                    ? styles.circleMoreHover__hoverContent
                    : styles.hidden
                }`}
              >
                <p>{quality?.attributes?.amount || "No description"}</p>
              </div>
            </div>
          </li>
        </ul>

        <span className={styles.properties__split}></span>

        <ul>
          <li>
            <div className={styles.properties__title}>
              <h4>Acquired</h4>
            </div>
            <div>
              <span>{acquired || "-"}</span>
            </div>
          </li>
          <li>
            <div className={styles.properties__title}>
              <h4>Uploader</h4>
            </div>
            <div>
              <span>{uploader?.data?.attributes?.username || "-"}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

const OpenSingleImage = ({ assetTitle, images, setImageOpen }) => {
  return (
    <div className={styles.openSingleImage}>
      <div
        className={styles.openSingleImage__overlay}
        onClick={() => setImageOpen(false)}
      />
      <button
        onClick={() => setImageOpen(false)}
        className={styles.openSingleImage__close}
      >
        <CrossIcon />
      </button>
      <div
        className={styles.openSingleImage__items}
        onClick={() => setImageOpen(false)}
      >
        {images &&
          images.map((item, index) => (
            <div key={index} className={styles.openSingleImage__item}>
              <ProgressiveImage
                src={`${imageUrl}${item?.attributes.url}`}
                placeholder={`${imageUrl}${item?.attributes.url}`}
              >
                {(src, loading) => (
                  <img
                    src={src}
                    alt={assetTitle || "Unknown"}
                    style={{
                      filter: loading ? "blur(70px)" : "none",
                      transition: "filter 0.1s ease-out",
                    }}
                  />
                )}
              </ProgressiveImage>
            </div>
          ))}
      </div>
    </div>
  );
};

const MemoizedImageCarousel = memo(ImageCarousel);
const MemoizedAssetDescription = memo(AssetDescription);
const MemoizedAssetTags = memo(AssetTags);
const MemoizedAssetHeader = memo(AssetHeader);
const MemoizedMainInfo = memo(MainInfo);
const MemoizedProperties = memo(Properties);
const MemoizedOpenSingleImage = memo(OpenSingleImage);

const ModalDetailItem = ({ assetData }) => {
  const [openInfo, setOpenInfo] = useState(false);
  const [openInfoTab, setOpenInfoTab] = useState(null);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1024) {
        setOpenInfoTab("info");
      } else {
        setOpenInfoTab(null);
      }
    };

    // Set the initial value based on the current window width
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleToggleInfo = (tab) => {
    if (tab === openInfoTab) {
      setOpenInfo((prevOpenInfo) => !prevOpenInfo);
    } else {
      setOpenInfoTab(tab);
      setOpenInfo(true);
    }
  };
  const handleChooseTab = (tab) => {
    if (tab !== openInfoTab) {
      setOpenInfoTab(tab);
    }
  };

  const [imageOpen, setImageOpen] = useState(false);
  useEffect(() => {
    if (imageOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [imageOpen]);

  const { isUploader } = useSelector((state) => state.checker);

  return (
    <div
      className={styles.modalDetailItem}
      style={{
        "--backgroundImage":
          assetData?.attributes?.mainImage &&
          assetData?.attributes?.files?.data &&
          Array.isArray(assetData.attributes.files.data) &&
          assetData.attributes.files.data.length > 0 &&
          assetData?.attributes?.files?.data[0]?.attributes?.url
            ? `url(${imageUrl}${assetData?.attributes?.files?.data[0]?.attributes?.url})`
            : "none",
      }}
    >
      <div className="container">
        <div
          className={`${styles.modalDetailItem__wrapper} ${
            openInfo ? styles.modalDetailItem__wrapperActive : ""
          }`}
        >
          <div
            className={styles.leftPart}
            style={{
              transform: openInfo ? "none" : "translateX(25%)",
              ...(window.innerWidth <= 1024 && {
                transform: openInfo ? "none" : "translateX(0%)",
              }),
            }}
          >
            <MemoizedAssetHeader title={assetData?.attributes?.name} />

            {assetData &&
            assetData.attributes &&
            assetData.attributes.files &&
            assetData.attributes.files.data ? (
              <MemoizedImageCarousel
                images={assetData.attributes.files.data}
                assetName={assetData.attributes.name}
                setImageOpen={setImageOpen}
              />
            ) : (
              <NoImageToShow />
            )}

            <div className="mobContent">
              <div className={styles.statistics}>
                <span>
                  <ViewIcon />
                  <p>123</p>
                </span>
                <span>
                  <BookmarkIcon />
                  <p>234</p>
                </span>
                <span>
                  <DownloadIcon />
                  <p>456</p>
                </span>
              </div>
            </div>

            <div className="webContent">
              {assetData?.attributes?.description ? (
                <MemoizedAssetDescription
                  description={assetData.attributes.description}
                />
              ) : (
                <NoItems current="description" />
              )}
            </div>

            <div className="webContent">
              {assetData?.attributes.tags?.data.length > 0 ? (
                <MemoizedAssetTags tags={assetData?.attributes.tags?.data} />
              ) : (
                <NoItems current="tags" />
              )}
            </div>
          </div>

          <div
            className={styles.callPanel}
            style={{
              transition: openInfo
                ? "none"
                : "opacity 1s cubic-bezier(0.0, 0, 0.0, 1) 0.3s",
              opacity: openInfo ? "0" : "1",
            }}
          >
            <Link
              to={
                {
                  pathname: `/game/${
                    assetData?.attributes?.games?.data[0]?.attributes?.title ||
                    "no-game-title"
                  }`,
                  search: `?id=${
                    assetData?.attributes?.games?.data[0]?.id || "no-game-id"
                  }`,
                } || "#"
              }
            >
              {assetData?.attributes?.games?.data[0]?.attributes?.cover ? (
                <span>
                  <ProgressiveImage
                    src={`${imageUrl}${assetData?.attributes?.games?.data[0]?.attributes?.cover?.data?.attributes?.url}`}
                    placeholder={`${imageUrl}${assetData?.attributes?.games?.data[0]?.attributes?.cover?.data?.attributes?.url}`}
                  >
                    {(src, loading) => (
                      <img
                        src={src}
                        alt={
                          assetData?.attributes?.games?.data[0]?.attributes
                            ?.title || "Unknown"
                        }
                        style={{
                          filter: loading ? "blur(70px)" : "none",
                          transition: "filter 0.1s ease-out",
                        }}
                      />
                    )}
                  </ProgressiveImage>
                </span>
              ) : (
                <span className={styles.noGameImage}>
                  <ImageIcon />
                </span>
              )}

              <p>
                {assetData?.attributes?.games?.data[0]?.attributes?.title ||
                  "Unknown"}
              </p>
            </Link>
            <div>
              <span onClick={() => handleToggleInfo("info")}>
                <CircleInfoIcon />
              </span>
              <p>Information</p>
            </div>
            <div>
              <span onClick={() => handleToggleInfo("comments")}>
                <MessageIcon />
              </span>
              <p>Comments</p>
            </div>
            <div>
              <span onClick={() => handleToggleInfo("history")}>
                <ListIcon />
              </span>
              <p>History</p>
            </div>
            <button className={styles.callPanel__download}>
              <span>
                <DownloadIcon />
              </span>
              <p>Download</p>
            </button>
            <div style={{ display: !isUploader ? "none" : "" }}>
              <span>
                <EditIcon className={styles.callPanel__edit} />
              </span>
              <p>Edit</p>
            </div>
          </div>

          <div
            className={`${styles.rightPart} ${
              openInfo ? styles.rightPartOpen : ""
            }`}
          >
            <button
              className={styles.closeInfoBtn}
              onClick={() => setOpenInfo(false)}
              style={{ opacity: openInfo ? "1" : "0" }}
            >
              <CrossIcon />
            </button>

            <div className={`${styles.headerPanel}`}>
              <Link
                to={
                  {
                    pathname: `/game/${
                      assetData?.attributes?.games?.data[0]?.attributes
                        ?.title || "no-game-title"
                    }`,
                    search: `?id=${
                      assetData?.attributes?.games?.data[0]?.id || "no-game-id"
                    }`,
                  } || "#"
                }
                className={styles.headerPanel__game}
              >
                {assetData?.attributes?.games?.data[0]?.attributes
                  ?.coverHeader ? (
                  <>
                    <ProgressiveImage
                      src={`${imageUrl}${assetData?.attributes?.games?.data[0]?.attributes?.coverHeader?.data?.attributes?.url}`}
                      placeholder={`${imageUrl}${assetData?.attributes?.games?.data[0]?.attributes?.coverHeader?.data?.attributes?.url}`}
                    >
                      {(src, loading) => (
                        <img
                          src={src}
                          alt={
                            assetData?.attributes?.games?.data[0]?.attributes
                              ?.title || "Unknown"
                          }
                          style={{
                            filter: loading ? "blur(70px)" : "none",
                            transition: "filter 0.1s ease-out",
                          }}
                        />
                      )}
                    </ProgressiveImage>

                    <p>
                      {assetData?.attributes?.games?.data[0]?.attributes
                        ?.title || "Unknown"}
                    </p>
                  </>
                ) : (
                  <>
                    <span className={styles.noGameImage}>
                      <ImageIcon />
                      <span>No image</span>
                    </span>
                    <p>
                      {assetData?.attributes?.games?.data[0]?.attributes
                        ?.title || "Unknown"}
                    </p>
                  </>
                )}
              </Link>

              <div className={styles.headerPanel__tools}>
                <div
                  className={
                    openInfoTab === "info" ? styles.headerPanel__activeTool : ""
                  }
                >
                  <span onClick={() => handleChooseTab("info")}>
                    <CircleInfoIcon />
                  </span>
                  <p>Information</p>
                </div>
                <div
                  className={
                    openInfoTab === "comments"
                      ? styles.headerPanel__activeTool
                      : ""
                  }
                >
                  <span onClick={() => handleChooseTab("comments")}>
                    <MessageIcon />
                  </span>
                  <p>Comments</p>
                </div>
                <div
                  className={
                    openInfoTab === "history"
                      ? styles.headerPanel__activeTool
                      : ""
                  }
                >
                  <span onClick={() => handleChooseTab("history")}>
                    <ListIcon />
                  </span>
                  <p>History</p>
                </div>
                <div style={{ display: !isUploader ? "none" : "" }}>
                  <span>
                    <EditIcon className={styles.callPanel__edit} />
                  </span>
                  <p>Edit</p>
                </div>
              </div>
            </div>

            {openInfoTab === "info" && (
              <div>
                <MemoizedMainInfo
                  game={assetData?.attributes?.games?.data[0] || null}
                  platform={assetData?.attributes?.platforms?.data || null}
                  technologies={
                    assetData?.attributes?.technologies?.data || null
                  }
                  category={assetData?.attributes?.category || null}
                  subcategory={assetData?.attributes?.subcategory || null}
                  author={assetData?.attributes?.author || null}
                  license={assetData?.attributes?.license?.data || null}
                />

                <Interaction />
                <MemoizedProperties
                  texturesType={assetData?.attributes?.texturesType?.data}
                  textureResolution={assetData?.attributes?.textureResolution}
                  quality={assetData?.attributes?.modelQuality?.data}
                  acquired={assetData?.attributes?.RipOrExtracted}
                  uploader={assetData?.attributes?.uploader}
                  armature={assetData?.attributes?.hasSkeleton}
                  format={assetData?.attributes?.formats?.data || null}
                />
              </div>
            )}
            {openInfoTab === "comments" && <div>comments</div>}
            {openInfoTab === "history" && <div>history</div>}

            <div className={`tableContent mobContent`}>
              {assetData?.attributes?.description ? (
                <MemoizedAssetDescription
                  description={assetData.attributes.description}
                />
              ) : (
                <NoItems current="description" />
              )}
            </div>
          </div>

          <div className={`tableContent mobContent`}>
            {assetData?.attributes.tags?.data.length > 0 ? (
              <MemoizedAssetTags tags={assetData?.attributes.tags?.data} />
            ) : (
              <div
                style={{
                  width: "100%",
                  ...(window.innerWidth <= 1024 && {
                    margin: "0 34px",
                  }),
                  ...(window.innerWidth <= 767 && {
                    margin: "0 14px",
                  }),
                }}
              >
                <NoItems current="tags" />
              </div>
            )}
          </div>
        </div>
        {imageOpen && (
          <MemoizedOpenSingleImage
            assetTitle={assetData?.attributes?.name}
            images={assetData?.attributes?.files?.data}
            setImageOpen={setImageOpen}
          />
        )}
      </div>
    </div>
  );
};

export default memo(ModalDetailItem);

// const [isReport, setIsReport] = useState(false);
// const handleReportToogle = () => {
//   setIsReport(!isReport);
// };
// {isReport && <ReportModal handleReportToogle={handleReportToogle} />}
