import React, { useState, useEffect, useCallback } from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { getUserInfo } from "./store/features/authorizationSlice";
import { getMyInfo } from "./store/features/myProfileSlice";
import { fetchHeaderData } from "./store/features/headerDataSlice";

import "./scss/app.scss";

import Home from "./components/pages/Home";
import Tech from "./components/pages/Tech";
import Formats from "./components/pages/Formats";
import Tools from "./components/pages/Tools";
import Animations from "./components/pages/Animations";
import Guides from "./components/pages/Guides";
import Fanart from "./components/pages/Fanart";
import Models from "./components/pages/Models";
import ModalDetail from "./components/pages/ModalDetail";
import UploadResource from "./components/pages/UploadResource";
import Games from "./components/pages/Games";
import GameDetail from "./components/pages/GameDetail";
import AddNewGames from "./components/pages/AddNewGames";
import SearchResult from "./components/pages/SearchResult";
import NotFound from "./components/pages/NotFound";
import AuthLogin from "./components/pages/AuthLogin";
import ProfileMe from "./components/pages/ProfileMe";
import Registration from "./components/pages/Registration";
import Login from "./components/pages/Login";

const proxy = process.env.REACT_APP_PROXY_URL;

function App() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [hasDispatched, setHasDispatched] = useState(false);
  useEffect(() => {
    if (pathname !== "/me" && !hasDispatched) {
      dispatch(getUserInfo());
      setHasDispatched(true);
    }
  }, [pathname, dispatch, hasDispatched]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    dispatch(fetchHeaderData());
  }, []);

  const isAuthorization = useSelector(
    (state) => state.authorization.isAuthorization
  );
 
  return (
    <div className="App">
      <Routes>
        <Route path="*" element={<NotFound />}></Route>
        <Route path="/" element={<Home />}></Route>
        <Route path="/technologies" element={<Tech />}></Route>
        <Route path="/formats" element={<Formats />}></Route>
        <Route path="/tools" element={<Tools />}></Route>
        <Route path="/animations" element={<Animations />}></Route>
        <Route path="/guides" element={<Guides />}></Route>
        <Route path="/fanarts" element={<Fanart />}></Route>

        <Route path="/models" element={<Models />}></Route>
        <Route path="/models/:modelId" element={<ModalDetail />}></Route>
        <Route path="/uploadResource" element={<UploadResource />}></Route>

        <Route path="/games" element={<Games />}></Route>
        <Route path="/game/:gameId" element={<GameDetail />}></Route>
        <Route path="/add-game" element={<AddNewGames />}></Route>

        <Route path="/me" element={<ProfileMe />}></Route>

        <Route path="/search" element={<SearchResult />} />

        <Route path="/connect/:providerName/redirect" element={<AuthLogin />} />
        <Route
          path="/login"
          element={isAuthorization ? <Navigate to="/" replace /> : <Login />}
        />
        <Route
          path="/registration"
          element={
            isAuthorization ? <Navigate to="/" replace /> : <Registration />
          }
        />
      </Routes>
    </div>
  );
}

export default App;
