import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import { ReactComponent as CheckMarkIconReg } from "../../assets/icon/chechMarkIconReg.svg";
import { ReactComponent as CloseIcon } from "../../assets/icon/closeIcon.svg";
import { ReactComponent as BurgerIcon } from "../../assets/icon/burgerIcon.svg";

import styles from "../../scss/components/mui/widgetPageNavigation.module.scss";

const WidgetPageNavigation = () => {
  const location = useLocation();
  const { navigation } = useSelector((state) => state.headerData);
  const mainRef = useRef();
  const menuRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [menuHeight, setMenuHeight] = useState(0);

  const toggleMenu = () => {
    if (isOpen) {
      setMenuHeight(0);
      setTimeout(() => {
        setIsOpen(false);
      }, 350);
    } else {
      setIsOpen(true);
      setMenuHeight(menuRef.current.scrollHeight);
    }
  };
  // Эффект для обработки клика вне элемента
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        mainRef.current &&
        !mainRef.current.contains(event.target)
      ) {
        setMenuHeight(0);
        setTimeout(() => {
          setIsOpen(false);
        }, 350);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const activeNavItem = navigation.find(
    (item) => location.pathname === item.url
  );

  return (
    <div className={`${styles.navigation} ${isOpen ? styles.active : ""}`}>
      <div
        className={styles.navigation__menu}
        ref={menuRef}
        style={{
          height: `${menuHeight + 12}px `,
          transition: "height 0.35s ease",
        }}
      >
        <ul>
          {navigation
            ? navigation.map((item, index) => {
                return (
                  <Link
                    key={index}
                    to={item.url}
                    className={` ${
                      location.pathname === item.url
                        ? styles.navigation__activeItem
                        : ""
                    }`}
                  >
                    <CheckMarkIconReg />
                    <span>{item.title || "no title"}</span>
                  </Link>
                );
              })
            : null}
        </ul>
        <span className={styles.navigation__split}>
          <span></span>
        </span>
      </div>
      {navigation ? (
        <button
          ref={mainRef}
          onClick={toggleMenu}
          className={styles.navigation__main}
        >
          {activeNavItem && (
            <span>{(navigation && activeNavItem.title) || "no title"}</span>
          )}
          {isOpen ? <CloseIcon /> : <BurgerIcon />}
        </button>
      ) : null}
    </div>
  );
};

export default WidgetPageNavigation;
