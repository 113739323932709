import { configureStore } from "@reduxjs/toolkit";

import searchSlice from "./features/searchSlice";
import authorizationSlice from "./features/authorizationSlice";
import registrationSlice from "./features/registrationSlice";
import addGameSlice from "./features/addGameSlice";
import headerDataReducer from "./features/headerDataSlice";
import myProfileSlice from "./features/myProfileSlice";
import checkerSlice from "./features/checkerSlice";
import gamesItemsSlice from "./features/gamesItemsSlice";
import assetsItemsSlice from "./features/assetsItemsSlice";

const store = configureStore({
  reducer: {
    search: searchSlice,
    authorization: authorizationSlice,
    registration: registrationSlice,
    addGame: addGameSlice,
    headerData: headerDataReducer,
    myProfile: myProfileSlice,
    checker: checkerSlice,
    gamesItems: gamesItemsSlice,
    assetsItems: assetsItemsSlice,
  },
});

export default store;
