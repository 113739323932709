import { createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { setAuthorization } from "../features/authorizationSlice";

const url = process.env.REACT_APP_SERVER_API;
const proxy = process.env.REACT_APP_PROXY_URL;
const registerUserLaw = process.env.REACT_APP_REGISTER_USER_LAW;

const registrationSlice = createSlice({
  name: "registration",
  initialState: {
    username: "",
    password: "",
    repeatPassword: "",
    customUserId: "",
    email: "",

    isBusyName: false,
    isBusyCustomUserId: false,
    isUsedMail: false,
    isApprovedPassword: false,
  },
  reducers: {
    setUsername: (state, action) => {
      state.username = action.payload;
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    },
    setRepeatPassword: (state, action) => {
      state.repeatPassword = action.payload;
    },
    setCustomUserId: (state, action) => {
      state.customUserId = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setIsBusyName: (state, action) => {
      state.isBusyName = action.payload;
    },
    setIsBusyCustomUserId: (state, action) => {
      state.isBusyCustomUserId = action.payload;
    },
    setIsUsedMail: (state, action) => {
      state.isUsedMail = action.payload;
    },
    setIsApprovedPassword: (state, action) => {
      state.isApprovedPassword = action.payload;
    },
  },
});

export const {
  setUsername,
  setPassword,
  setCustomUserId,
  setEmail,
  setRepeatPassword,
  setIsUsedMail,
  setIsBusyName,
  setIsBusyCustomUserId,
  setIsApprovedPassword,
} = registrationSlice.actions;
export default registrationSlice.reducer;

export const checkEmail =
  ({ email }) =>
  async (dispatch, getState) => {
    try {
      const response = await axios.get(`${proxy}/api/verify-email/${email}`);

      const { response: jwtToken } = response.data;
      const decodedData = jwtDecode(jwtToken);

      dispatch(setIsUsedMail(decodedData.exists));
    } catch (error) {
      console.error("Ошибка при запросе к прокси-серверу:", error.message);
    }
  };

export const checkCustomUserId =
  ({ customUserId }) =>
  async (dispatch, getState) => {
    try {
      const response = await axios.get(
        `${proxy}/api/verify-customUserId/${customUserId}`
      );

      const { response: jwtToken } = response.data;
      const decodedData = jwtDecode(jwtToken);

      dispatch(setIsBusyCustomUserId(decodedData.exists));
    } catch (error) {
      console.error("Ошибка при запросе к прокси-серверу:", error.message);
    }
  };

export const registerUser = () => {
  return async (dispatch, getState) => {
    const {
      username,
      password,
      email,
      isBusyCustomUserId,
      isUsedMail,
      isApprovedPassword,
    } = getState().registration;
    const userID = getState().registration.customUserId;

    if (!username || !password || !email || !userID) {
      return;
    }
    if (!isBusyCustomUserId && !isUsedMail && !isApprovedPassword) {
      return;
    }

    try {
      const response = await axios.post(
        `${url}/api/auth/local/register`,
        {
          username,
          userID,
          email,
          password,
        },
        {
          headers: {
            Authorization: `Bearer ${registerUserLaw}`,
          },
        }
      );

      sessionStorage.setItem("jwt", JSON.stringify(response.data.jwt));
      localStorage.removeItem("jwt");
      window.location.href = "/";
      dispatch(setAuthorization(true));
    } catch (error) {
      if (error.response) {
        console.log("An error occurred:", error.response.data);
        if (
          error.response.status === 400 &&
          error.response.data.error.name === "ApplicationError"
        ) {
          console.warn("Email or Username are already taken");
        }
      } else if (error.request) {
        console.log("No response received:", error.request);
      } else {
        console.log("Error in setting up the request:", error.message);
      }
    }
  };
};

// export const registerUser = () => {
//   return async (dispatch, getState) => {
//     const { username, password, email } = getState().registration;

//     if (!username || !password || !email) {
//       console.warn("All fields are required");
//       return;
//     }
//     console.log({
//       username,
//       password,
//       email,
//     });

//     try {
//       const response = await axios.post(
//         `${url}/api/auth/local/register`,
//         {
//           username,
//           password,
//           email,
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${encodeURIComponent(registerUserLaw)}`,
//           },
//         }
//       );

//       console.log("User profile", response.data.user);
//       console.log("User token", response.data.jwt);

//       if (response.data.success) {
//         console.log("Регистрация успешна");
//       } else {
//         console.warn("Ошибка регистрации: данные пользователя не созданы");
//       }
//     } catch (error) {
//       console.error("Ошибка при запросе:", error.message);
//     }
//   };
// };
