import React, { memo } from "react";
import styles from "../../scss/components/pages/models.module.scss";

import Header from "../Header";
import ModelsItems from "../ModelsItems";
import Footer from "../Footer";
import WidgetPageNavigation from "../mui/WidgetPageNavigation";

const Models = () => {
  return (
    <div className="main">
      <Header />
      <div  className="main__contant">
        <ModelsItems />
      </div>
      <Footer />
      <WidgetPageNavigation />
    </div>
  );
};

export default memo(Models);
