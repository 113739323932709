import React, { useEffect } from "react";

import styles from "../../scss/components/pages/searchResult.module.scss";

import Header from "../Header";
import SearchResultItems from "../SearchResultItems";
import Footer from "../Footer";
import WidgetPageNavigation from "../mui/WidgetPageNavigation";

const SearchResult = () => {

  return (
    <div className="main">
      <Header />
      <div
        style={{
          marginBottom: "0",
        }}
        className="main__contant"
      >
        <SearchResultItems />
      </div>
      <Footer />
      <WidgetPageNavigation />
    </div>
  );
};

export default SearchResult;
