import React, { memo } from "react";

import Header from "../Header";
import Footer from "../Footer";
import GamesItems from "../GamesItems";
import WidgetPageNavigation from "../mui/WidgetPageNavigation";

const Games = () => {
  return (
    <div className="main">
      <Header />
      <div className="main__contant">
        <GamesItems />
      </div>
      <Footer />
      <WidgetPageNavigation />
    </div>
  );
};

export default memo(Games);
