import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import Masonry from "react-masonry-css";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import {
  setGamesCount,
  setViewPosition,
} from "../store/features/gamesItemsSlice";

import { ReactComponent as CheckMarkIconReg } from "../assets/icon/chechMarkIconReg.svg";
import { ReactComponent as ArrowSeparatorIcon } from "../assets/icon/arrowSeparatorIcon.svg";

import WidgetToUp from "./mui/WidgetToUp";
import SkeletonMosaryItem from "./mui/SkeletonMosaryItem";
import GameCard from "./mui/masaryCard/GameCard";

import styles from "../scss/components/gamesItems.module.scss";

const proxy = process.env.REACT_APP_PROXY_URL;

const GamesItems = () => {
  const dispatch = useDispatch();

  const sortParam = ["Relevance", "Most Recent", "Popular"];
  const { gamesCount, viewPosition } = useSelector((state) => state.gamesItems);

  /*     useEffect(() => {
    if (viewPosition !== 0) {
      const threshold = 5; // Пороговое значение в пикселях, которое определяет, что пользователь достиг нужной позиции
      const intervalId = setInterval(() => {
        const currentScroll = window.scrollY;

        // Проверяем, находится ли пользователь в пределах заданного порога от нужной позиции
        if (Math.abs(currentScroll - viewPosition) > threshold) {
          window.scrollTo({
            top: viewPosition,
            behavior: "smooth",
          });
        } else {
          // Если пользователь достиг нужной позиции, прекращаем контроль
          clearInterval(intervalId);
        }
      }, 100); // Проверяем позицию каждые 100 миллисекунд

      // Очищаем интервал при размонтировании компонента
      return () => clearInterval(intervalId);
    }
  }, [viewPosition]); */

  const InfoHeader = () => {
    const [sortActive, setSortActive] = useState(false);
    const [selectedSortParam, setSelectedSortParam] = useState(sortParam[0]);
    const dropDownRef = useRef(null);
    const toggleRef = useRef(null);
    const handleSelect = (item) => {
      setSelectedSortParam(item);
      setSortActive(false);
    };
    const toogleSort = () => {
      setSortActive(!sortActive);
    };

    // Эффект для обработки клика вне элемента
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          dropDownRef.current &&
          !dropDownRef.current.contains(event.target) &&
          toggleRef.current &&
          !toggleRef.current.contains(event.target)
        ) {
          setSortActive(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    return (
      <div className={styles.infoHeader}>
        <span className={styles.infoHeader__count}>
          {gamesCount || 0} Games
        </span>
        <div
          ref={toggleRef}
          onClick={() => setSortActive(!sortActive)}
          className={`${styles.infoHeader__sort} ${
            sortActive ? styles.infoHeader__dropDownActive : ""
          }`}
        >
          <span>{selectedSortParam}</span>
          <ArrowSeparatorIcon />
        </div>
        {sortActive && (
          <div ref={dropDownRef} className={styles.infoHeader__sortDropDown}>
            <ul>
              {sortParam.map((item) => {
                const isActive = item === selectedSortParam;
                return (
                  <li
                    key={item}
                    onClick={() => handleSelect(item)}
                    className={`${
                      isActive ? styles.infoHeader__sortActive : ""
                    }`}
                  >
                    <CheckMarkIconReg />
                    <span>{item}</span>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    );
  };

  const InfiniteMasary = () => {
    const breakpointColumnsObj = {
      default: 4,
      1224: 3,
      767: 2,
    };
    const [data, setData] = useState([]);

    const [page, setPage] = useState(1);
    const pageSize = 50;
    const [hasMore, setHasMore] = useState(true);
    const [noElements, setNoElements] = useState(false);

    const fetchData = async () => {
      try {
        const response = await axios.get(`${proxy}/api/get-games-data`, {
          params: { page, pageSize },
        });

        const dataEncrypted = response.data.dataEncrypted;
        const decodedData = jwtDecode(dataEncrypted);

        const newData = decodedData.data.games;

        const count = decodedData.data.gamesCount;
        if (count !== gamesCount) {
          dispatch(setGamesCount(count));
        }

        if (newData.length < pageSize) {
          setHasMore(false);
        }
        if (data && data.length >= 0) {
          setNoElements(true);
        } else {
          setNoElements(false);
        }

        setData((prevData) => [
          ...new Map(
            [...prevData, ...newData].map((item) => [item.gameId, item])
          ).values(),
        ]);

        setPage((prevPage) => prevPage + 1);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };
 
    useEffect(() => {
      fetchData();
    }, []);

    return (
      <div className={styles.infiniteMasary}>
        <div className={styles.infiniteScrollContainer}>
          <InfiniteScroll
            dataLength={data?.length || 0}
            next={fetchData}
            hasMore={hasMore}
            scrollThreshold={0.6}
            endMessage={
              <p
                className={`${styles.masonry__noMoreItems} ${
                  noElements && data && data.length <= 0 ? styles.hidden : ""
                }`}
              >
                No more items
              </p>
            }
            style={{
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              gap: "40px",
            }}
          >
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className={`${styles.masonry} ${
                !noElements ? styles.skeleton : ""
              }`}
              columnClassName={`${styles.masonry__column} `}
            >
              {!noElements ? (
                Array.from({ length: 21 }, (_, index) => (
                  <SkeletonMosaryItem key={index} />
                ))
              ) : data && data.length > 0 ? (
                data.map((item) => (
                  <GameCard
                    /*   onClick={() => dispatch(setViewPosition(window.scrollY))} */
                    key={item.gameId}
                    gameId={item.gameId}
                    gameTitle={item.gameTitle}
                    gameImage={item.gameImage}
                    companyImage={item.companyImage}
                    developerType={item.developerType}
                  />
                ))
              ) : (
                <span className={styles.masonry__noMoreItems}>NoItems</span>
              )}
            </Masonry>
          </InfiniteScroll>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.gamesItems}>
      <div className="container">
        <div className={styles.gamesItems__wrapper}>
          <InfoHeader />
          <InfiniteMasary />
        </div>
        <WidgetToUp />
      </div>
    </div>
  );
};

export default GamesItems;
