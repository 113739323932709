import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import axios from "axios";
import ProgressiveImage from "react-progressive-graceful-image";

import backgroundTools from "../assets/backgroundTools.png";
import { ReactComponent as ArrowSeparatorIcon } from "../assets/icon/arrowSeparatorIcon.svg";

import styles from "../scss/components/techCarousel.module.scss";

const url = process.env.REACT_APP_SERVER_API;
const imageUrl = process.env.REACT_APP_IMAGE_URL;

const TechCarousel = () => {
  const [techData, setTechData] = useState([]);
  const [page, setPage] = useState(1);
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef();

  useEffect(() => {
    const fetchData = () => {
      axios
        .get(
          `${url}/api/technologies?pagination[page]=${page}&pagination[pageSize]=100&fields[0]=name&populate[logo][fields][0]=url&populate[logo][fields][1]=name`
        )
        .then((res) => {
          const newTech = res.data.data;
          if (newTech.length > 0) {
            setTechData((prevData) => {
              const newTechIds = newTech.map((t) => t.id);
              const filteredData = prevData.filter(
                (prevTech) => !newTechIds.includes(prevTech.id)
              );
              return [...filteredData, ...newTech];
            });
            if (newTech.length === 100) {
              setPage((prevPage) => prevPage + 1);
            }
          }
        })
        .catch((error) => {
          console.error("Ошибка при запросе данных:", error);
        });
    };
    fetchData();
  }, [page]);
  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: null,
    prevArrow: null,
    draggable: true,
    swipe: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 2,
          dots: false,
          infinite: false,
          swipe: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.25,
          slidesToScroll: 1,
          swipe: true,
          /* initialSlide: 2, */
        },
      },
    ],
    afterChange: (current) => {
      setCurrentSlide(current);
    },
  };

  const items = techData.slice(0, 4).map((item, index) => (
    <div className={styles.item} key={index}>
      <div>
        <p>{item?.attributes?.name}</p>
      </div>
      <ProgressiveImage src={backgroundTools} placeholder={backgroundTools}>
        {(src, loading) => (
          <img
            src={src}
            alt={item?.attributes?.name || "noImage"}
            style={{
              filter: loading ? "blur(70px)" : "none",
              transition: "filter 0.3s ease-out",
            }}
          />
        )}
      </ProgressiveImage>
    </div>
  ));

  return (
    <div className={items.length > 0 ? styles.tech : styles.hidden}>
      <div className="container">
        <div className={styles.tech__wrapper}>
          <div className={styles.header}>
            <h2 className={styles.title}>Technology</h2>
            <Link className={styles.more} to="/">
              <p>See all</p>
              <ArrowSeparatorIcon />
            </Link>
          </div>
          <div className={styles.carousel}>
            {items.length > 0 ? (
              <Slider
                ref={sliderRef}
                className={styles.carousel}
                {...sliderSettings}
              >
                {items}
              </Slider>
            ) : (
              <div className={styles.loadingContainer}></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechCarousel;
