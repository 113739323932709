import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

const proxy = process.env.REACT_APP_PROXY_URL;

const addGameSlice = createSlice({
  name: "addGame",
  initialState: {
    saveData: false,
    gameOutside: false,
    gameName: null,
    gameId: null,
    type: null,
    gameTypeRelation: null,
    cover: null,
    media: null,
    description: null,
    gameSeries: null,
    themes: null,
    newThemes: null,
    genres: null,
    newGenres: null,
    playerPerspectives: null,
    newPlayerPerspectives: null,
    gameModes: null,
    newGameModes: null,
    gameReleases: null,
    gameCompanies: null,
    gameNewCompanies: null,
    gameAlternativeNames: null,
    gameStoreLinks: null,
    gameSocialLinks: null,
    gameTags: null,
    gameNewTags: null,
  },
  reducers: {
    setSaveData: (state, action) => {
      state.saveData = action.payload;
    },
    setGameOutside: (state, action) => {
      state.gameOutside = action.payload;
    },
    setGameName: (state, action) => {
      state.gameName = action.payload;
    },
    setGameId: (state, action) => {
      state.gameId = action.payload;
    },
    setCover: (state, action) => {
      state.cover = action.payload;
    },
    setMedia: (state, action) => {
      state.media = action.payload;
    },
    setDescription: (state, action) => {
      state.description = action.payload;
    },
    setGenres: (state, action) => {
      state.genres = action.payload;
    },
    setNewGenres: (state, action) => {
      state.newGenres = action.payload;
    },
    setThemes: (state, action) => {
      state.themes = action.payload;
    },
    setNewThemes: (state, action) => {
      state.newThemes = action.payload;
    },
    setPlayerPerspectives: (state, action) => {
      state.playerPerspectives = action.payload;
    },
    setNewPlayerPerspectives: (state, action) => {
      state.newPlayerPerspectives = action.payload;
    },
    setGameModes: (state, action) => {
      state.gameModes = action.payload;
    },
    setNewGameModes: (state, action) => {
      state.newGameModes = action.payload;
    },
    setGameAlternativeNames: (state, action) => {
      state.gameAlternativeNames = action.payload;
    },
    setGameSocialLinks: (state, action) => {
      state.gameSocialLinks = action.payload;
    },
    setGameTags: (state, action) => {
      state.gameTags = action.payload;
    },
    setGameNewTags: (state, action) => {
      state.gameNewTags = action.payload;
    },
    setGameReleases: (state, action) => {
      state.gameReleases = action.payload;
    },
    setGameCompanies: (state, action) => {
      state.gameCompanies = action.payload;
    },
    setGameNewCompanies: (state, action) => {
      state.gameNewCompanies = action.payload;
    },
    setGameType: (state, action) => {
      state.type = action.payload;
    },
    setGameTypeRelation: (state, action) => {
      state.gameTypeRelation = action.payload;
    },
    setGameSeries: (state, action) => {
      state.gameSeries = action.payload;
    },
  },
});

export const {
  setSaveData,
  setGameOutside,
  setGameName,
  setGameId,
  setGameType,
  setGameTypeRelation,
  setCover,
  setMedia,
  setDescription,
  setGameSeries,
  setGenres,
  setNewGenres,
  setThemes,
  setNewThemes,
  setPlayerPerspectives,
  setNewPlayerPerspectives,
  setGameModes,
  setNewGameModes,
  setGameAlternativeNames,
  setGameSocialLinks,
  setGameTags,
  setGameNewTags,
  setGameReleases,
  setGameCompanies,
  setGameNewCompanies,
} = addGameSlice.actions;

export default addGameSlice.reducer;

export const getGameOutsideInfo = () => async (dispatch, getState) => {
  const { gameId } = getState().addGame;

  try {
    dispatch(setSaveData(true));
    const response = await axios.get(
      `${proxy}/api/get-external-resource-game-info`,
      { params: { gameId } }
    );
    const jwtToken = response.data;
    const decodedData = jwtDecode(jwtToken);

    dispatch(setGameName(decodedData?.data?.data?.gameName || "Unknown hame"));
    dispatch(setCover(decodedData?.data?.data?.cover));
    dispatch(setMedia(decodedData?.data?.data?.media));
    dispatch(setDescription(decodedData?.data?.data?.description));
    dispatch(setGenres(decodedData?.data?.data?.genres));
    dispatch(setNewGenres(decodedData?.data?.data?.newGenres));
    dispatch(setThemes(decodedData?.data?.data?.themes));
    dispatch(setNewThemes(decodedData?.data?.data?.newThemes));
    dispatch(
      setPlayerPerspectives(decodedData?.data?.data?.playerPerspectives)
    );
    dispatch(
      setNewPlayerPerspectives(decodedData?.data?.data?.newPlayerPerspectives)
    );
    dispatch(setGameModes(decodedData?.data?.data?.gameModes));
    dispatch(setNewGameModes(decodedData?.data?.data?.newGameModes));
    dispatch(
      setGameAlternativeNames(decodedData?.data?.data?.alternativeNames)
    );
    dispatch(setGameSocialLinks(decodedData?.data?.data?.socialLinks));
    dispatch(setGameTags(decodedData?.data?.data?.tags));
    dispatch(setGameNewTags(decodedData?.data?.data?.newTags));
    dispatch(setGameReleases(decodedData?.data?.data?.releases));
    dispatch(setGameCompanies(decodedData?.data?.data?.companies));
    dispatch(setGameNewCompanies(decodedData?.data?.data?.newCompanies));
    dispatch(setGameType(decodedData?.data?.data?.type));
    dispatch(setGameTypeRelation(decodedData.data.data.gameRelation));
    dispatch(setGameSeries(decodedData?.data?.data?.series));
  } catch (error) {
    console.error("Ошибка при обработке данных:", error.message);
  } finally {
    dispatch(setSaveData(false));
  }
};
