import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Masonry from "react-masonry-css";
import InfiniteScroll from "react-infinite-scroll-component";
import { jwtDecode } from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";

import { setDraftWorksCount } from "../../store/features/myProfileSlice";

import { ReactComponent as ArrowChevron } from "../../assets/icon/arrowChevron.svg";
import { ReactComponent as DisplayColumn } from "../../assets/icon/displayColumn.svg";
import { ReactComponent as BurgerIcon } from "../../assets/icon/burgerIcon.svg";
import { ReactComponent as SearchIcon } from "../../assets/icon/search.svg";
import { ReactComponent as CheckMarkIconReg } from "../../assets/icon/chechMarkIconReg.svg";

import SkeletonMosaryItem from "../mui/SkeletonMosaryItem";
import GameCard from "../mui/masaryCard/GameCard";
import AssetCard from "../mui/masaryCard/AssetCard";

import styles from "../../scss/components/my-profile-sections/draft.module.scss";

const proxy = process.env.REACT_APP_PROXY_URL;

const Draft = () => {
  const dispatch = useDispatch();
  const { draftWorks, navTab } = useSelector((state) => state.myProfile);
  const isAuthorization = useSelector(
    (state) => state.authorization.isAuthorization
  );

  const FilterSearch = () => {
    const characters = [
      "All",
      "Games",
      "Models",
      "Tech",
      "Formats",
      "Tools",
      "Animation",
      "Guides",
    ];
    const [charactersActive, setCharactersActive] = useState(false);
    const handleCharactersToogle = () => {
      setCharactersActive(!charactersActive);
    };

    return (
      <div className={styles.filterSearch}>
        <div className={styles.filterSearch__search}>
          <input type="text" placeholder="Filter publications" />
          <ul>
            <li>
              <CheckMarkIconReg />
              <p>13123</p>
            </li>
            <li>
              <CheckMarkIconReg />
              <p>13123</p>
            </li>
            <li>
              <CheckMarkIconReg />
              <p>13123</p>
            </li>
          </ul>
        </div>

        <div className={styles.filterSearch__selectResourse}>
          <div onClick={handleCharactersToogle}>
            <SearchIcon />
            <span>All</span>
            <ArrowChevron />
          </div>
          <ul className={`${charactersActive ? styles.active : ""}`}>
            <li>
              <CheckMarkIconReg />
              <p>13123</p>
            </li>
            <li>
              <CheckMarkIconReg />
              <p>13123</p>
            </li>
            <li>
              <CheckMarkIconReg />
              <p>13123</p>
            </li>
            <li>
              <CheckMarkIconReg />
              <p>13123</p>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const MasaryHeader = () => {
    return (
      <div
        className={`${styles.masaryHeader} ${
          draftWorks.count <= 0 ? styles.hidden : ""
        }`}
      >
        <div className={styles.masaryHeader__wrapper}>
          <span className={styles.masaryHeader__count}>
            {draftWorks.count} Publications
          </span>
          <div className={styles.masaryHeader__content}>
            <FilterSearch />
            <div className={styles.masaryHeader__display}>
              <DisplayColumn />
              <BurgerIcon />
            </div>
          </div>
        </div>
      </div>
    );
  };
  const NoItems = () => {
    return (
      <div className={styles.noItems}>
        <span>No publications yet</span>
        <button>Create Publication</button>
      </div>
    );
  };
  const InfiniteMasary = () => {
    const breakpointColumnsObj = {
      default: 4,
      1224: 3,
      767: 2,
    };
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const pageSize = 12;
    const [hasMore, setHasMore] = useState(true);
    const [noElements, setNoElements] = useState(false);

    const fetchData = async () => {
      const storedToken =
        localStorage.getItem("jwt") || sessionStorage.getItem("jwt");
      const token = storedToken ? JSON.parse(storedToken) : null;

      if (!token) {
        console.error("Token is missing");
        return;
      }

      try {
        const response = await axios.get(`${proxy}/api/get-my-draft-work`, {
          params: { page, pageSize, token },
        });

        const dataEncrypted = response.data.dataEncrypted;
        const decodedData = jwtDecode(dataEncrypted);
        const newData = decodedData.data.newData;

        const count = decodedData.data.countItems;
        if (count !== navTab.count) {
          dispatch(setDraftWorksCount({ count }));
        }

        if (newData.length < pageSize) {
          setHasMore(false);
        }
        if (data && data.length >= 0) {
          setNoElements(true);
        } else {
          setNoElements(false);
        }

        setData((prevData) => {
          const existingEntries = new Set(
            prevData.map((item) => `${item.id}-${item.mark}`)
          );
          const filteredNewData = newData.filter(
            (item) => !existingEntries.has(`${item.id}-${item.mark}`)
          );

          const combinedData = [...prevData, ...filteredNewData];

          combinedData.sort(
            (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
          );

          return combinedData;
        });

        setPage((prevPage) => prevPage + 1);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    useEffect(() => {
      fetchData();
    }, []);

    return (
      <div className={styles.infiniteMasary}>
        <div className={styles.infiniteScrollContainer}>
          <InfiniteScroll
            dataLength={data?.length || 0}
            next={fetchData}
            hasMore={hasMore}
            scrollThreshold={0.6}
            endMessage={
              <p
                className={`${styles.masonry__noMoreItems} ${
                  noElements && data.length <= 0 ? styles.hidden : ""
                }`}
              >
                No more items
              </p>
            }
            style={{
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              gap: "40px",
            }}
          >
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className={`${styles.masonry} ${
                !noElements ? styles.skeleton : ""
              }`}
              columnClassName={`${styles.masonry__column} `}
            >
              {!noElements ? (
                Array.from({ length: 21 }, (_, index) => (
                  <SkeletonMosaryItem key={index} />
                ))
              ) : data && data.length > 0 ? (
                data.map((item, index) => (
                  <React.Fragment key={index}>
                    {item.mark === "game" ? (
                      <GameCard
                        gameId={item.id}
                        gameTitle={item.name}
                        gameImage={item.image}
                        companyImage={item.developer.companyImage}
                        developerType={item.developer.developerType}
                      />
                    ) : item.mark === "asset" ? (
                      <AssetCard
                        assetTitle={item.name}
                        assetId={item.id}
                        assetImage={item.image}
                        uploaderName={item.uploader.username}
                        uploaderImage={item.uploader.avatar}
                      />
                    ) : null}
                  </React.Fragment>
                ))
              ) : (
                <NoItems />
              )}
            </Masonry>
          </InfiniteScroll>
        </div>
      </div>
    );
  };

  return (
    <>
      <MasaryHeader />
      <InfiniteMasary />
    </>
  );
};

export default Draft;
