import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const url = process.env.REACT_APP_SERVER_API;
const proxy = process.env.REACT_APP_PROXY_URL;

const searchSlice = createSlice({
  name: "search",
  initialState: {
    inputArea: "",
    selectedCategory: null,
    searchRequestData: [],
    searchRequestDataCount: null,
    requestPage: 1,
    requestPageSize: 5,
    hasMore: true,
    noElements: false,
  },
  reducers: {
    setInputArea: (state, action) => {
      state.inputArea = action.payload;
    },
    setSelectCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    setSearchRequestData: (state, action) => {
      state.searchRequestData = action.payload;
    },
    setSearchRequestDataCount: (state, action) => {
      state.searchRequestDataCount = action.payload;
    },
    setRequestPage: (state, action) => {
      state.requestPage = action.payload;
    },
    setHasMore: (state, action) => {
      state.hasMore = action.payload;
    },
    setNoElements: (state, action) => {
      state.noElements = action.payload;
    },
  },
  extraReducers: (builder) => {
    /* builder.addCase(fetchTestHeaderSearchData.fulfilled, (state, action) => {
      state.searchRequestData = action.payload;
    }); */
  },
});

export const {
  setInputArea,
  setSelectCategory,
  setSearchRequestData,
  setSearchRequestDataCount,
  setRequestPage,
  setHasMore,
  setNoElements,
} = searchSlice.actions;
export default searchSlice.reducer;
