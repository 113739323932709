import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ProgressiveImage from "react-progressive-graceful-image";
import "@splidejs/splide/dist/css/splide.min.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import {
  setSearchRequestData,
  setSelectCategory,
  setInputArea,
} from "../store/features/searchSlice";

import { ReactComponent as ImageIcon } from "../assets/icon/imageIcon.svg";
import { ReactComponent as ArrowSeparatorIcon } from "../assets/icon/arrowSeparatorIcon.svg";
import { ReactComponent as ViewIcon } from "../assets/icon/viewIcon.svg";
import { ReactComponent as BookmarkIcon } from "../assets/icon/bookmarkIcon.svg";
import { ReactComponent as CircleInfoIcon } from "../assets/icon/circleInfoIcon.svg";
import { ReactComponent as MessageIcon } from "../assets/icon/messageIcon.svg";
import { ReactComponent as ListIcon } from "../assets/icon/listIcon.svg";
import { ReactComponent as EditIcon } from "../assets/icon/editIcon.svg";
import { ReactComponent as CrossIcon } from "../assets/icon/crossIcon.svg";
import { ReactComponent as ForestIcon } from "../assets/icon/forestIcon.svg";
import { ReactComponent as CloseIcon } from "../assets/icon/closeIcon.svg";
import { ReactComponent as ProcessorIcon } from "../assets/icon/processorIcon.svg";
import { ReactComponent as FormatIcon } from "../assets/icon/formatIcon.svg";
import { ReactComponent as ToolIcon } from "../assets/icon/toolIcon.svg";
import { ReactComponent as HumanIcon } from "../assets/icon/humanIcon.svg";
import { ReactComponent as CubIcon } from "../assets/icon/cubIcon.svg";
import { ReactComponent as TutorialIcon } from "../assets/icon/tutorialIcon.svg";

import styles from "../scss/components/gameDetailItem.module.scss";

const imageUrl = process.env.REACT_APP_IMAGE_URL;

const ImageCarousel = ({ images, gameName, setImageOpen, onSelect }) => {
  const mainRef = useRef(null);
  const thumbnailRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const mainSplide = mainRef.current?.splide;
    const thumbnailSplide = thumbnailRef.current?.splide;

    if (mainSplide && thumbnailSplide) {
      mainSplide.sync(thumbnailSplide);
      thumbnailSplide.sync(mainSplide);

      const updateCurrentSlide = (index) => setCurrentSlide(index);

      mainSplide.on("mounted move", () => {
        updateCurrentSlide(mainSplide.index);
      });

      thumbnailSplide.on("mounted move", () => {
        updateCurrentSlide(thumbnailSplide.index);
      });
    }

    return () => {
      if (mainSplide) {
        mainSplide.off("mounted move");
      }
      if (thumbnailSplide) {
        thumbnailSplide.off("mounted move");
      }
    };
  }, []);

  const goToSlide = (index) => {
    mainRef.current.splide.go(index);
  };

  return (
    <div className={styles.carousel}>
      <div className={styles.sliderFor}>
        <Splide
          ref={mainRef}
          options={{
            type: "fade",
            heightRatio: 0.5,
            pagination: false,
            arrows: false,
            cover: true,
            breakpoints: {
              767: {
                pagination: false,
              },
            },
          }}
          className={styles.sliderFor__main}
        >
          {images.map((item, index) => {
            return (
              <SplideSlide onClick={() => setImageOpen(true)} key={index}>
                <ProgressiveImage
                  src={`${imageUrl}${item?.attributes.url}`}
                  placeholder={`${imageUrl}${item?.attributes.url}`}
                >
                  {(src, loading) => {
                    return (
                      <div
                        style={{
                          backgroundImage: `url(${src})`,
                          filter: loading ? "blur(70px)" : "none",
                          transition: "filter 0.3s ease-out",
                        }}
                      >
                        <img src={src} alt={gameName || "Unknown game"} />
                      </div>
                    );
                  }}
                </ProgressiveImage>
              </SplideSlide>
            );
          })}
        </Splide>
        <div className={styles.customPagination}>
          {images.map((_, index) => (
            <button
              key={index}
              className={` ${
                currentSlide === index
                  ? styles.customPagination__customDotActive
                  : ""
              }`}
              onClick={() => goToSlide(index)}
            />
          ))}
        </div>
      </div>

      <div className={styles.sliderNav}>
        <button
          className={`${styles.carousel__leftArrow} ${
            currentSlide === 0 ? styles.carousel__noActiveBtn : ""
          }`}
          onClick={() => thumbnailRef.current.go("<")}
        >
          <ArrowSeparatorIcon />
        </button>
        <button
          className={`${styles.carousel__rightArrow} ${
            currentSlide === images?.length - 1
              ? styles.carousel__noActiveBtn
              : ""
          }`}
          onClick={() => thumbnailRef.current.go(">")}
        >
          <ArrowSeparatorIcon />
        </button>

        <Splide
          ref={thumbnailRef}
          className={styles.sliderNav__thumbnail}
          options={{
            fixedWidth: 80,
            fixedHeight: 80,
            isNavigation: true,
            gap: 16,
            focus: "center",
            pagination: false,
            cover: true,
            breakpoints: {
              600: {
                fixedWidth: 80,
                fixedHeight: 80,
              },
            },
          }}
        >
          {images.map((item, index) => (
            <SplideSlide key={index}>
              <ProgressiveImage
                src={`${imageUrl}${item?.attributes.url}`}
                placeholder={`${imageUrl}${item?.attributes.url}`}
              >
                {(src, loading) => (
                  <div
                    style={{
                      backgroundImage: `url(${src})`,
                      filter: loading ? "blur(70px)" : "none",
                      transition: "filter 0.3s ease-out",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img src={src} alt={gameName || "Unknown game"} />
                  </div>
                )}
              </ProgressiveImage>
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </div>
  );
};

const NoImageToShow = () => {
  return (
    <div className={styles.noImages}>
      <ForestIcon />
      <div>
        <h3>No Images to show</h3>
        <p>The author did not add any pictures to this post.</p>
      </div>
    </div>
  );
};

const GameHeader = ({ title }) => {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    if (window.history.length <= 1) {
      navigate("/");
    } else {
      navigate(-1);
    }
  }, [navigate]);

  return (
    <div className={styles.gameHeader}>
      <h2>{title}</h2>
      <div className={styles.gameHeader__info}>
        <span>
          <ViewIcon />
          <p>123</p>
        </span>
        <span>
          <BookmarkIcon />
          <p>234</p>
        </span>
        <span>
          <CloseIcon onClick={handleClick} />
        </span>
      </div>
    </div>
  );
};
const GameResources = ({ assets, releases }) => {
  const filterTechnologies = Array.from(
    releases.reduce((techSet, release) => {
      release.technologies.forEach((tech) => {
        const techAttributes = tech.name.data;
        if (techAttributes) {
          techSet.add(`${techAttributes.id}:${techAttributes.attributes.name}`);
        }
      });
      return techSet;
    }, new Set())
  ).map((item) => {
    const [id, name] = item.split(":");
    return { id: Number(id), name };
  });
  const technologyRelations =
    filterTechnologies.length > 100 ? "99+" : filterTechnologies.length || 0;
  const assetRelations =
    assets?.data?.length > 100 ? "99+" : assets?.data?.length || 0;

  return (
    <div className={styles.resources}>
      <h2>Resources</h2>
      <div className={styles.resources__content}>
        <ul>
          <li
            className={
              technologyRelations !== 0 ? styles.resources__active : ""
            }
          >
            <ProcessorIcon />
            <p>Tech</p>
            <span>{technologyRelations}</span>
          </li>
          <li>
            <FormatIcon />
            <p>Formats</p>
            <span></span>
          </li>
          <li>
            <ToolIcon />
            <p>Tools</p>
            <span></span>
          </li>
          <li className={assetRelations !== 0 ? styles.resources__active : ""}>
            <CubIcon />
            <p>Models</p>
            <span>{assetRelations}</span>
          </li>
          <li>
            <HumanIcon />
            <p>Animations</p>
            <span></span>
          </li>
          <li>
            <TutorialIcon />
            <p>Guides</p>
            <span></span>
          </li>
        </ul>
      </div>
    </div>
  );
};

const GameDescription = ({ description }) => {
  return (
    <div className={styles.description}>
      <h2>Description</h2>
      <span></span>
      <div className={styles.description__content}>
        <p>{description}</p>
      </div>
    </div>
  );
};
const NoItems = ({ current }) => {
  const displayText = current || "argument";
  return (
    <div className={styles.noItems}>
      <p>No {displayText} provided.</p>
    </div>
  );
};
const GameTags = ({ tags }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleItemClick = async (item) => {
    setTimeout(() => {
      navigate(`/search?query=${item?.attributes?.name}&category=all`);
    }, 0);
    dispatch(setInputArea(""));
    dispatch(setSearchRequestData(null));
    dispatch(setSelectCategory(null));
  };
  return (
    <div className={styles.tags}>
      <h2>Tags</h2>
      <div className={styles.tags__items}>
        {tags.map((item) => {
          return (
            <div
              key={item.id}
              onClick={() => handleItemClick(item)}
              className={styles.tags__item}
            >
              <span>{item?.attributes?.name}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
const MainInfo = ({ type, mainGame, series, company }) => {
  const [isHovered, setIsHovered] = useState(false);
  const typeName = type?.attributes?.name;
  const normalizedTypeName = typeName?.toLowerCase().replace(/\s+/g, "").trim();
  const typeMark = normalizedTypeName === "maingame" ? "Base Game" : typeName;

  const [developerActive, setDeveloperActive] = useState(false);
  const [publisherActive, setPublisherActive] = useState(false);
  const [supportingDevActive, setSupportingDevActive] = useState(false);
  const [portingDevActive, setPortingDevActive] = useState(false);

  const [developerHeight, setDeveloperHeight] = useState("0px");
  const [publisherHeight, setPublisherHeight] = useState("0px");
  const [supportingDevHeight, setSupportingDevHeight] = useState("0px");
  const [portingDevHeight, setPortingDevHeight] = useState("0px");

  const developerRef = useRef(null);
  const publisherRef = useRef(null);
  const supportingDevRef = useRef(null);
  const portingDevRef = useRef(null);

  useEffect(() => {
    setDeveloperHeight(
      developerActive ? `${developerRef.current.scrollHeight}px` : "0px"
    );
  }, [developerActive]);

  useEffect(() => {
    setPublisherHeight(
      publisherActive ? `${publisherRef.current.scrollHeight}px` : "0px"
    );
  }, [publisherActive]);
  useEffect(() => {
    if (supportingDevRef.current) {
      setSupportingDevHeight(
        supportingDevActive
          ? `${supportingDevRef.current.scrollHeight}px`
          : "0px"
      );
    }
  }, [supportingDevActive]);

  useEffect(() => {
    setPortingDevHeight(
      portingDevActive ? `${portingDevRef.current.scrollHeight}px` : "0px"
    );
  }, [portingDevActive]);

  let mainDevelopers = [];
  let publishers = [];
  let supportingDevelopers = [];
  let portingDevelopers = [];

  company.forEach((company) => {
    if (company.mainDeveloper) {
      mainDevelopers.push(company);
    }
    if (company.publisher) {
      publishers.push(company);
    }
    if (company.supportingDeveloper) {
      supportingDevelopers.push(company);
    }
    if (company.portingDeveloper) {
      portingDevelopers.push(company);
    }
  });

  return (
    <div className={styles.mainInfo}>
      <ul>
        <li
          className={
            normalizedTypeName === "maingame"
              ? styles.mainInfo__markMain
              : styles.mainInfo__markUsual
          }
        >
          <div className={styles.mainInfo__title}>
            <h4>{typeMark || "unknown"}</h4>
          </div>
          <div>
            <Link
              to={{
                pathname: `/game/${mainGame?.attributes?.title}`,
                search: `?id=${mainGame?.id}`,
              }}
            >
              {mainGame?.attributes?.title || "-"}
            </Link>
          </div>
        </li>
        <li>
          <div className={styles.mainInfo__title}>
            <h4>Series</h4>
          </div>
          <div>
            <Link
            /* to={{
              pathname: `/exemple/${series.attributes.name}`,
              search: `?id=${series.id}`,
            }} */
            >
              {series?.attributes.name || "-"}
            </Link>
          </div>
        </li>
        <li>
          <div className={styles.mainInfo__title}>
            <h4>Developer</h4>
          </div>
          <div className={`${styles.moreParam}`}>
            {mainDevelopers.length > 0 ? (
              <>
                <div className={styles.moreParam__header}>
                  <Link to="/">
                    {mainDevelopers[0]?.name?.data?.attributes?.name ||
                      "Unknown"}
                  </Link>
                  <ArrowSeparatorIcon
                    style={{
                      transform: developerActive ? "rotate(270deg)" : "",
                      display: mainDevelopers.length <= 1 ? "none" : "block",
                    }}
                    onClick={() => setDeveloperActive(!developerActive)}
                  />
                </div>
                {mainDevelopers.length > 1 && (
                  <div
                    className={`${styles.moreParam__body}`}
                    style={{
                      maxHeight: developerHeight,
                      transition: "max-height 0.3s ease",
                    }}
                    ref={developerRef}
                  >
                    {mainDevelopers.slice(1).map((developer, index) => (
                      <Link key={developer.id} to="/">
                        {developer?.name?.data?.attributes?.name || "Unknown"}
                        {index < mainDevelopers.length - 2 && ", "}
                      </Link>
                    ))}
                  </div>
                )}
              </>
            ) : (
              <span className={styles.mainInfo__noInfo}>-</span>
            )}
          </div>
        </li>
        <li>
          <div className={styles.mainInfo__title}>
            <h4>Publisher</h4>
          </div>
          <div className={`${styles.moreParam}`}>
            {publishers.length > 0 ? (
              <>
                <div className={styles.moreParam__header}>
                  <Link to="/">
                    {publishers[0]?.name?.data?.attributes?.name || "Unknown"}
                  </Link>
                  <ArrowSeparatorIcon
                    style={{
                      transform: publisherActive ? "rotate(270deg)" : "",
                      display: publishers.length <= 1 ? "none" : "block",
                    }}
                    onClick={() => setPublisherActive(!publisherActive)}
                  />
                </div>
                {publishers.length > 1 && (
                  <div
                    className={`${styles.moreParam__body}`}
                    style={{
                      maxHeight: publisherHeight,
                      transition: "max-height 0.3s ease",
                    }}
                    ref={publisherRef}
                  >
                    {publishers.slice(1).map((item, index) => (
                      <Link key={item.id} to="/">
                        {item?.name?.data?.attributes?.name || "Unknown"}
                        {index < publishers.length - 2 && ", "}
                      </Link>
                    ))}
                  </div>
                )}
              </>
            ) : (
              <span className={styles.mainInfo__noInfo}>-</span>
            )}
          </div>
        </li>
        <li>
          <div className={styles.mainInfo__title}>
            <h4>Supporting Dev.</h4>
          </div>
          <div
            className={`${styles.moreParam}`}
            style={{
              gap: supportingDevActive ? "6px" : "0px",
            }}
          >
            {supportingDevelopers.length > 0 ? (
              <>
                <div className={styles.moreParam__header}>
                  <Link to="/">
                    {supportingDevelopers[0]?.name?.data?.attributes?.name ||
                      "Unknown"}
                  </Link>
                  <ArrowSeparatorIcon
                    style={{
                      transform: supportingDevActive ? "rotate(270deg)" : "",
                      display:
                        supportingDevelopers.length <= 1 ? "none" : "block",
                    }}
                    onClick={() => setSupportingDevActive(!supportingDevActive)}
                  />
                </div>
                {supportingDevelopers.length > 1 && (
                  <div
                    className={`${styles.moreParam__body}`}
                    style={{
                      maxHeight: supportingDevHeight,
                      transition: "max-height 0.3s ease",
                    }}
                    ref={supportingDevRef}
                  >
                    {supportingDevelopers.slice(1).map((item, index) => (
                      <Link key={item.id} to="/">
                        {item?.name?.data?.attributes?.name || "Unknown"}
                        {index < supportingDevelopers.length - 2 && ", "}
                      </Link>
                    ))}
                  </div>
                )}
              </>
            ) : (
              <span className={styles.mainInfo__noInfo}>-</span>
            )}
          </div>
        </li>
        <li>
          <div className={styles.mainInfo__title}>
            <h4>Porting Dev.</h4>
          </div>
          <div className={`${styles.moreParam}`}>
            {portingDevelopers.length > 0 ? (
              <>
                <div className={styles.moreParam__header}>
                  <Link to="/">
                    {portingDevelopers[0]?.name?.data?.attributes?.name ||
                      "Unknown"}
                  </Link>
                  <ArrowSeparatorIcon
                    style={{
                      transform: portingDevActive ? "rotate(270deg)" : "",
                      display: portingDevelopers.length <= 1 ? "none" : "block",
                    }}
                    onClick={() => setPortingDevActive(!portingDevActive)}
                  />
                </div>
                {portingDevelopers.length > 1 && (
                  <div
                    className={`${styles.moreParam__body}`}
                    style={{
                      maxHeight: portingDevHeight,
                      transition: "max-height 0.3s ease",
                    }}
                    ref={portingDevRef}
                  >
                    {portingDevelopers.slice(1).map((item, index) => (
                      <Link key={item.id} to="/">
                        {item?.name?.data?.attributes?.name || "Unknown"}
                        {index < portingDevelopers.length - 2 && ", "}
                      </Link>
                    ))}
                  </div>
                )}
              </>
            ) : (
              <span className={styles.mainInfo__noInfo}>-</span>
            )}
          </div>
        </li>
      </ul>
    </div>
  );
};
const ReleaseInfo = ({ releases }) => {
  const [active, setActive] = useState([]);
  const [heights, setHeights] = useState([]);
  const refs = useRef([]);

  useEffect(() => {
    setHeights(
      active.map((isActive, index) => {
        const ref = refs.current[index];
        return isActive && ref ? `${ref.scrollHeight}px` : "0px";
      })
    );
  }, [active]);

  const handleToggle = (index) => {
    setActive((prevActive) => {
      const newActive = [...prevActive];
      newActive[index] = !newActive[index];
      return newActive;
    });
  };

  useEffect(() => {
    refs.current = refs.current.slice(0, releases.length);
    setActive(Array(releases.length).fill(false));
  }, [releases]);

  return (
    <div className={styles.releaseInfo}>
      <div>
        <h2>Releases</h2>
      </div>

      {releases &&
        releases.map((release, releaseIndex) => {
          const isActive = active[releaseIndex];
          return (
            <div key={releaseIndex} className={styles.accordion}>
              <div
                className={`${styles.accordion__header} ${
                  isActive ? styles.accordion__headerActive : ""
                }`}
                onClick={() => handleToggle(releaseIndex)}
              >
                <Link
                  to="/"
                  style={{
                    backgroundColor:
                      release?.platform?.data?.attributes?.color ?? "#E6E6E6",
                    color: release?.platform?.data?.attributes?.color
                      ? "#f2f2f2"
                      : "#212221",
                  }}
                >
                  {release?.platform?.data?.attributes?.altName || "Platform"}
                </Link>
                <div>
                  <p>
                    {release?.platform?.data?.attributes?.name || "Platform"}
                  </p>
                  <ArrowSeparatorIcon />
                </div>
              </div>

              <div
                ref={(el) => (refs.current[releaseIndex] = el)}
                className={`${styles.accordionBody} ${
                  isActive ? styles.accordionActive : ""
                }`}
                style={{ height: heights[releaseIndex] }}
              >
                {release.releases.length === 0 ? (
                  <div className={styles.accordionBodyItemsEmpty}>
                    <span>No releases added.</span>
                  </div>
                ) : (
                  <div className={styles.accordionBodyItems}>
                    {release.releases
                      .reduce((groups, item) => {
                        const date = new Date(item.date);
                        const months = [
                          "January",
                          "February",
                          "March",
                          "April",
                          "May",
                          "June",
                          "July",
                          "August",
                          "September",
                          "October",
                          "November",
                          "December",
                        ];

                        const formattedDate = `${date.getDate()} ${
                          months[date.getMonth()]
                        }, ${date.getFullYear()}`;

                        let group = groups.find(
                          (group) => group.date === formattedDate
                        );
                        if (!group) {
                          group = { date: formattedDate, regions: [] };
                          groups.push(group);
                        }

                        const regionName =
                          item.region?.data?.attributes?.code ||
                          item.region?.data?.attributes?.name ||
                          "-";
                        group.regions.push(regionName);

                        return groups;
                      }, [])
                      .map((group, index) => (
                        <div key={index} className={styles.accordionBodyItem}>
                          <div className={styles.accordionBodyEllips}>
                            <div></div>
                          </div>
                          <div className={styles.bodyContent}>
                            <span>{group.regions.join(", ") || "-"}</span>
                            <Link
                              style={{
                                cursor: group.date ? "pointer" : "default",
                              }}
                            >
                              {group.date || "-"}
                            </Link>
                          </div>
                        </div>
                      ))}
                  </div>
                )}

                <span className={styles.accordionBody__split}></span>

                {release.technologies.length === 0 ? (
                  <div className={styles.accordionBodyItemsEmpty}>
                    <span>No technologies added.</span>
                  </div>
                ) : (
                  <div className={styles.accordionBodyItems}>
                    {release.technologies.map((technology, techIndex) => (
                      <div
                        key={technology.id}
                        className={styles.accordionBodyItem}
                      >
                        <div className={styles.accordionBodyEllips}>
                          <div></div>
                        </div>
                        <div className={styles.bodyContent}>
                          <span>
                            {technology?.name?.data?.attributes?.name || "-"}
                          </span>
                          <Link
                            style={{
                              cursor:
                                technology && technology.version
                                  ? "pointer"
                                  : "default",
                            }}
                          >
                            {technology && technology.version
                              ? technology.version
                              : "-"}
                          </Link>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};
const OpenSingleImage = ({ gameTitle, images, setImageOpen }) => {
  return (
    <div className={styles.openSingleImage}>
      <div
        className={styles.openSingleImage__overlay}
        onClick={() => setImageOpen(false)}
      />
      <button
        onClick={() => setImageOpen(false)}
        className={styles.openSingleImage__close}
      >
        <CrossIcon />
      </button>
      <div
        className={styles.openSingleImage__items}
        onClick={() => setImageOpen(false)}
      >
        {images &&
          images.map((item, index) => (
            <div key={index} className={styles.openSingleImage__item}>
              <ProgressiveImage
                src={`${imageUrl}${item?.attributes.url}`}
                placeholder={`${imageUrl}${item?.attributes.url}`}
              >
                {(src, loading) => (
                  <img
                    src={src}
                    alt={gameTitle || "Unknown"}
                    style={{
                      filter: loading ? "blur(70px)" : "none",
                      transition: "filter 0.1s ease-out",
                    }}
                  />
                )}
              </ProgressiveImage>
            </div>
          ))}
      </div>
    </div>
  );
};

const MemoizedImageCarousel = memo(ImageCarousel);
const MemoizedAssetDescription = memo(GameDescription);
const MemoizedAssetTags = memo(GameTags);
const MemoizedAssetHeader = memo(GameHeader);
const MemoizedMainInfo = memo(MainInfo);
const MemoizedReleaseInfo = memo(ReleaseInfo);
const MemoizedGameResources = memo(GameResources);
const MemoizedOpenSingleImage = memo(OpenSingleImage);

const GameDetailItem = ({ gameData }) => {
  const [openInfo, setOpenInfo] = useState(false);
  const [openInfoTab, setOpenInfoTab] = useState(null);
  const handleToggleInfo = (tab) => {
    if (tab === openInfoTab) {
      setOpenInfo((prevOpenInfo) => !prevOpenInfo);
    } else {
      setOpenInfoTab(tab);
      setOpenInfo(true);
    }
  };
  const handleChooseTab = (tab) => {
    if (tab !== openInfoTab) {
      setOpenInfoTab(tab);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1024) {
        setOpenInfoTab("info");
      } else {
        setOpenInfoTab(null);
      }
    };

    // Set the initial value based on the current window width
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const mainGame = gameData?.attributes?.gameRalation?.data;
  const [mainGameVerify, setMainGameVerify] = useState(false);
  const [gameDisplay, setGameDisplay] = useState(null);

  useEffect(() => {
    const typeName = gameData?.attributes?.type?.data?.attributes?.name;
    const normalizedTypeName = typeName
      ?.toLowerCase()
      .replace(/\s+/g, "")
      .trim();
    setMainGameVerify(normalizedTypeName === "maingame");
  }, [gameData]);

  useEffect(() => {
    if (mainGameVerify) {
      setGameDisplay({
        id: gameData?.id,
        title: gameData?.attributes?.title,
        cover: gameData?.attributes?.cover?.data?.attributes?.url,
        coverHeader: gameData?.attributes?.coverHeader?.data?.attributes?.url,
      });
    } else {
      setGameDisplay({
        id: mainGame?.id,
        title: mainGame?.attributes?.title,
        cover: mainGame?.attributes?.cover?.data?.attributes?.url,
        coverHeader: mainGame?.attributes?.coverHeader?.data?.attributes?.url,
      });
    }
  }, [mainGameVerify, mainGame, gameData]);

  const [imageOpen, setImageOpen] = useState(false);
  useEffect(() => {
    if (imageOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [imageOpen]);
  const { isUploader } = useSelector((state) => state.checker);

  return (
    <div
      className={styles.gameDetailItem}
      style={{
        "--backgroundImage":
          gameData?.attributes?.cover &&
          gameData?.attributes?.media?.data &&
          Array.isArray(gameData.attributes.media.data) &&
          gameData.attributes.media.data.length > 0 &&
          gameData?.attributes?.media?.data[0]?.attributes?.url
            ? `url(${imageUrl}${gameData?.attributes?.media?.data[0]?.attributes?.url})`
            : "none",
      }}
    >
      <div className="container">
        <div
          className={`${styles.gameDetailItem__wrapper} ${
            openInfo ? styles.gameDetailItem__wrapperActive : ""
          }`}
        >
          <div
            className={styles.leftPart}
            style={{
              transform: openInfo ? "none" : "translateX(25%)",
              ...(window.innerWidth <= 1024 && {
                transform: openInfo ? "none" : "translateX(0%)",
              }),
            }}
          >
            <MemoizedAssetHeader title={gameData?.attributes?.title} />

            {gameData &&
            gameData.attributes &&
            gameData.attributes.media &&
            gameData.attributes.media.data ? (
              <MemoizedImageCarousel
                images={gameData.attributes.media.data}
                gameName={gameData.attributes.title}
                setImageOpen={setImageOpen}
              />
            ) : (
              <NoImageToShow />
            )}

            <MemoizedGameResources
              assets={gameData.attributes.assets}
              releases={gameData?.attributes?.platformReleases}
            />

            <div className="mobContent">
              <div className={styles.statistics}>
                <span>
                  <ViewIcon />
                  <p>123</p>
                </span>
                <span>
                  <BookmarkIcon />
                  <p>234</p>
                </span>
              </div>
            </div>

            <div className="webContent">
              {gameData?.attributes?.description ? (
                <MemoizedAssetDescription
                  description={gameData.attributes.description}
                />
              ) : (
                <NoItems current="description" />
              )}
            </div>

            <div className="webContent">
              {gameData?.attributes.tags?.data.length > 0 ? (
                <MemoizedAssetTags tags={gameData?.attributes.tags?.data} />
              ) : (
                <NoItems current="tags" />
              )}
            </div>
          </div>

          <div
            className={styles.callPanel}
            style={{
              transition: openInfo
                ? "none"
                : "opacity 1s cubic-bezier(0.0, 0, 0.0, 1) 0.3s",
              opacity: openInfo ? "0" : "1",
            }}
          >
            <Link
              to={{
                pathname: `/game/${gameDisplay?.title}`,
                search: `?id=${gameDisplay?.id}`,
              }}
            >
              {gameDisplay?.cover ? (
                <span>
                  <ProgressiveImage
                    src={`${imageUrl}${gameDisplay?.cover}`}
                    placeholder={`${imageUrl}${gameDisplay?.cover}`}
                  >
                    {(src, loading) => (
                      <img
                        src={src}
                        alt={gameDisplay?.title || "Unknown"}
                        style={{
                          filter: loading ? "blur(70px)" : "none",
                          transition: "filter 0.1s ease-out",
                        }}
                      />
                    )}
                  </ProgressiveImage>
                </span>
              ) : (
                <span className={styles.noGameImage}>
                  <ImageIcon />
                </span>
              )}

              <p>{gameDisplay?.title || "Unknown"}</p>
            </Link>
            <div>
              <span onClick={() => handleToggleInfo("info")}>
                <CircleInfoIcon />
              </span>
              <p>Information</p>
            </div>
            <div>
              <span onClick={() => handleToggleInfo("comments")}>
                <MessageIcon />
              </span>
              <p>Comments</p>
            </div>
            <div>
              <span onClick={() => handleToggleInfo("history")}>
                <ListIcon />
              </span>
              <p>History</p>
            </div>
            <div style={{ display: !isUploader ? "none" : "" }}>
              <span>
                <EditIcon className={styles.callPanel__edit} />
              </span>
              <p>Edit</p>
            </div>
          </div>

          <div
            className={`${styles.rightPart} ${
              openInfo ? styles.rightPartOpen : ""
            }`}
          >
            <button
              className={styles.closeInfoBtn}
              onClick={() => setOpenInfo(false)}
              style={{ opacity: openInfo ? "1" : "0" }}
            >
              <CrossIcon />
            </button>

            <div className={`${styles.headerPanel}`}>
              <Link
                to={{
                  pathname: `/game/${gameDisplay?.title}`,
                  search: `?id=${gameDisplay?.id}`,
                }}
                className={styles.headerPanel__game}
              >
                {gameDisplay?.coverHeader ? (
                  <>
                    <ProgressiveImage
                      src={`${imageUrl}${gameDisplay?.coverHeader}`}
                      placeholder={`${imageUrl}${gameDisplay?.coverHeader}`}
                    >
                      {(src, loading) => (
                        <img
                          src={src}
                          alt={gameDisplay?.title || "Unknown"}
                          style={{
                            filter: loading ? "blur(70px)" : "none",
                            transition: "filter 0.1s ease-out",
                          }}
                        />
                      )}
                    </ProgressiveImage>
                    <p>{gameDisplay?.title || "Unknown"}</p>
                  </>
                ) : (
                  <>
                    <span className={styles.noGameImage}>
                      <ImageIcon />
                      <span>No image</span>
                    </span>
                    <p>{gameDisplay?.title || "Unknown"}</p>
                  </>
                )}
              </Link>

              <div className={styles.headerPanel__tools}>
                <div
                  className={
                    openInfoTab === "info" ? styles.headerPanel__activeTool : ""
                  }
                >
                  <span onClick={() => handleChooseTab("info")}>
                    <CircleInfoIcon />
                  </span>
                  <p>Information</p>
                </div>
                <div
                  className={
                    openInfoTab === "comments"
                      ? styles.headerPanel__activeTool
                      : ""
                  }
                >
                  <span onClick={() => handleChooseTab("comments")}>
                    <MessageIcon />
                  </span>
                  <p>Comments</p>
                </div>
                <div
                  className={
                    openInfoTab === "history"
                      ? styles.headerPanel__activeTool
                      : ""
                  }
                >
                  <span onClick={() => handleChooseTab("history")}>
                    <ListIcon />
                  </span>
                  <p>History</p>
                </div>
                <div style={{ display: !isUploader ? "none" : "" }}>
                  <span>
                    <EditIcon className={styles.callPanel__edit} />
                  </span>
                  <p>Edit</p>
                </div>
              </div>
            </div>

            {openInfoTab === "info" && (
              <div>
                <MemoizedMainInfo
                  type={gameData?.attributes?.type.data}
                  series={gameData?.attributes?.series.data}
                  mainGame={gameData?.attributes?.gameRalation.data}
                  company={gameData?.attributes?.company}
                />

                {gameData?.attributes?.platformReleases.length > 0 ? (
                  <MemoizedReleaseInfo
                    releases={gameData?.attributes?.platformReleases}
                  />
                ) : (
                  <NoItems current="release" />
                )}
              </div>
            )}
            {openInfoTab === "comments" && <div>comments</div>}
            {openInfoTab === "history" && <div>history</div>}

            <div className={`tableContent mobContent`}>
              {gameData?.attributes?.description ? (
                <MemoizedAssetDescription
                  description={gameData.attributes.description}
                />
              ) : (
                <NoItems current="description" />
              )}
            </div>
          </div>

          <div className={`tableContent mobContent`}>
            {gameData?.attributes.tags?.data.length > 0 ? (
              <MemoizedAssetTags tags={gameData?.attributes.tags?.data} />
            ) : (
              <div
                style={{
                  width: "100%",
                  ...(window.innerWidth <= 1024 && {
                    margin: "0 34px",
                  }),
                  ...(window.innerWidth <= 767 && {
                    margin: "0 14px",
                  }),
                }}
              >
                <NoItems current="tags" />
              </div>
            )}
          </div>
        </div>
        {imageOpen && (
          <MemoizedOpenSingleImage
            gameTitle={gameData?.attributes?.title}
            images={gameData?.attributes?.media?.data}
            setImageOpen={setImageOpen}
          />
        )}
      </div>
    </div>
  );
};

export default GameDetailItem;
