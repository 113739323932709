import React, { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";

import Header from "../Header";
import UploadResourceItem from "../UploadResourceItem";
import PreviewUploadItem from "../PreviewUploadItem";
import UnableUploadItem from "../UnableUploadItem";
import Footer from "../Footer";
import WidgetPageNavigation from "../mui/WidgetPageNavigation";

const UploadResource = () => {
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);
  const [previewOn, setPreviewOn] = useState(false);
  useEffect(() => {
    const checkScreenWidth = () => {
      const screenWidth = window.innerWidth;
      const mobileScreenWidth = 1025;
      setIsMobileOrTablet(screenWidth < mobileScreenWidth);
    };
    checkScreenWidth();
    window.addEventListener("resize", checkScreenWidth);
    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);
  const handlePreview = () => {
    setPreviewOn(!previewOn);
  };
  const [modelFiles, setModelFiles] = useState([]);
  const [modelTitle, setModelTitle] = useState("");
  const [modelTags, setModelTags] = useState([]);
  const [modelGame, setModelGame] = useState("");
  const [modelPlatform, setModelPlatform] = useState([]);
  const [modelTech, setModelTech] = useState([]);
  const [modelCopyrights, setModelCopyrights] = useState("");
  const [modelOriginalAuthor, setModelOriginalAuthor] = useState("");
  const [modelLicense, setModelLicense] = useState([]);
  const [modelFormats, setModelFormats] = useState([]);
  const [modelSkeleton, setModelSkeleton] = useState("");
  const [modelTextures, setModelTextures] = useState([]);
  const [modelQuality, setModelQuality] = useState([]);

  /* console.log(modelTitle); */
  /*   console.log("modelTags", modelTags); */
  return (
    <div className="main">
      <Header />
      <div className="main__contant">
        {isMobileOrTablet ? (
          <UnableUploadItem />
        ) : previewOn ? (
          <PreviewUploadItem
            handlePreview={handlePreview}
            modelTitle={modelTitle}
            setModelTitle={setModelTitle}
          />
        ) : (
          <UploadResourceItem
            handlePreview={handlePreview}
            modelTitle={modelTitle}
            setModelTitle={setModelTitle}
            setModelTags={setModelTags}
            modelTags={modelTags}
          />
        )}
      </div>
      <Footer />
      <WidgetPageNavigation />
    </div>
  );
};

export default UploadResource;
