import React, { useEffect, useState } from "react";
import styles from "../../scss/components/mui/widgetToUp.module.scss";

 const WidgetToUp = () => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div
      className={styles.widget}
      style={{ display: isVisible ? "block" : "none" }}
    >
      <svg
        onClick={scrollToTop}
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
      >
        <circle cx="24" cy="24" r="24" fill="white" />
        <path
          d="M24.7071 14.2929C24.3166 13.9024 23.6834 13.9024 23.2929 14.2929L16.9289 20.6569C16.5384 21.0474 16.5384 21.6805 16.9289 22.0711C17.3195 22.4616 17.9526 22.4616 18.3431 22.0711L24 16.4142L29.6569 22.0711C30.0474 22.4616 30.6805 22.4616 31.0711 22.0711C31.4616 21.6805 31.4616 21.0474 31.0711 20.6569L24.7071 14.2929ZM25 34L25 15L23 15L23 34L25 34Z"
          fill="#151615"
        />
      </svg>
    </div>
  );
};
export default WidgetToUp;