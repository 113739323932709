import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ProgressiveImage from "react-progressive-graceful-image";

import noImage from "../assets/noImage.png";

import styles from "../scss/components/advertisingBanner.module.scss";

const url = process.env.REACT_APP_SERVER_API;
const imageUrl = process.env.REACT_APP_IMAGE_URL;


const AdvertisingBanner = () => {
  const [advertisingData, setAdvertisingData] = useState([]);
  const [page, setPage] = useState(1);
  useEffect(() => {
    const fetchData = () => {
      axios
        .get(
          `${url}/api/banners?pagination[page]=1&pagination[pageSize]=100&fields[0]=title&fields[1]=subtitle&fields[2]=type&fields[3]=link&populate[background][fields][0]=url&populate[background][fields][1]=name`
        )
        .then((res) => {
          const newAdvertising = res.data.data;
          if (newAdvertising.length > 0) {
            setAdvertisingData((prevData) => {
              const newAdvertisingIds = newAdvertising.map((t) => t.id);
              const filteredData = prevData.filter(
                (prev) => !newAdvertisingIds.includes(prev.id)
              );
              return [...filteredData, ...newAdvertising];
            });
            if (newAdvertising.length === 100) {
              setPage((prevPage) => prevPage + 1);
            }
          }
        })
        .catch((error) => {
          console.error("Ошибка при запросе данных:", error);
        });
    };
    fetchData();
  }, [page]);

  return (
    <div
      className={
        advertisingData.length > 0 ? styles.advertisingBanner : styles.hidden
      }
    >
      <div className="container">
        {advertisingData.slice(0, 1).map((item) => (
          <Link
            to={item?.attributes?.link}
            className={styles.advertisingBanner__wrapper}
            key={item.id}
          >
            <div className={styles.descriptions}>
              <p className={styles.title}>{item?.attributes?.title}</p>
              <p className={styles.subtitle}>{item?.attributes?.subtitle}</p>
            </div>
            <button className={styles.followBtn}>
              Follow
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="9"
                viewBox="0 0 6 9"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1.18736 8.58008L0.401367 7.79605L3.82938 4.37662L0.401367 0.957194L1.18736 0.173165L5.40137 4.37662L1.18736 8.58008Z"
                  fill="#37456D"
                />
              </svg>
            </button>
            <button className={styles.advertisingBtn}>
              {item?.attributes?.type}
            </button>
            <ProgressiveImage
              src={
                item?.attributes?.background?.data?.attributes?.url
                  ? `${imageUrl}${item?.attributes?.background?.data?.attributes?.url}`
                  : noImage
              }
              placeholder={
                item?.attributes?.cover?.data?.attributes?.url
                  ? `${imageUrl}${item.attributes.cover.data.attributes.url}`
                  : noImage
              }
            >
              {(src, loading) => (
                <img
                  src={src}
                  alt={
                    item?.attributes?.background?.data?.attributes?.name ||
                    "noImage"
                  }
                  style={{
                    filter: loading ? "blur(70px)" : "none",
                    transition: "filter 0.3s ease-out",
                  }}
                />
              )}
            </ProgressiveImage>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default AdvertisingBanner;
