import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import {
  setSearchRequestData,
  setSelectCategory,
  setInputArea,
} from "../store/features/searchSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

import { ReactComponent as ArrowSeparatorIcon } from "../assets/icon/arrowSeparatorIcon.svg";
import styles from "../scss/components/carouselTags.module.scss";

const url = process.env.REACT_APP_SERVER_API;

const CarouselTags = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tagData, setTagData] = useState([]);
  const [page, setPage] = useState(1);
  useEffect(() => {
    const fetchData = () => {
      axios
        .get(
          `${url}/api/tags?pagination[page]=1&pagination[pageSize]=33&fields[0]=name&populate[background][fields][0]=url&populate[background][fields][1]=name`
        )
        .then((res) => {
          const newTag = res.data.data;
          if (newTag.length > 0) {
            setTagData((prevData) => {
              const newTagIds = newTag.map((t) => t.id);
              const filteredData = prevData.filter(
                (prev) => !newTagIds.includes(prev.id)
              );
              return [...filteredData, ...newTag];
            });
            if (newTag.length === 100) {
              setPage((prevPage) => prevPage + 1);
            }
          }
        })
        .catch((error) => {
          console.error("Ошибка при запросе данных:", error);
        });
    };
    fetchData();
  }, []);

  const handleItemClick = async (item) => {
    setTimeout(() => {
      navigate(`/search?query=${item.attributes.name}&category=all`);
    }, 0);

    dispatch(setInputArea(""));
    dispatch(setSearchRequestData(null));
    dispatch(setSelectCategory(null));
  };

  return (
    <div
      className={tagData.length > 0 ? styles.carouselChapter : styles.hidden}
    >
      <div className="container">
        <div className={styles.carouselChapter__wrapper}>
          <div className="swiper-button-prev">
            <ArrowSeparatorIcon />
          </div>

          <Swiper
            modules={[Navigation]}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            slidesPerView="auto"
            centeredSlides={false}
            breakpoints={{
              640: {
                slidesPerView: "auto",
              },
              768: {
                slidesPerView: "auto",
              },
              1024: {
                slidesPerView: "auto",
              },
            }}
            className={styles.carousel}
          >
            {tagData.slice(0, 33).map((item, index) => (
              <SwiperSlide
                key={index}
                className={styles.item}
                style={{ width: "fit-content" }}
              >
                <Link onClick={() => handleItemClick(item)} key={index}>
                  <p>{item?.attributes?.name}</p>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="swiper-button-next">
            <ArrowSeparatorIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarouselTags;
