import React from "react";
import ProgressiveImage from "react-progressive-graceful-image";

import styles from "../scss/components/newsletter.module.scss";
import image from "../assets/newsletter.png";

const Newsletter = () => {
  return (
    <div className={styles.newsletter}>
      <div className="container">
        <div className={styles.newsletter__wrapper}>
          <div className={styles.contant}>
            <div className={styles.descriptions}>
              <p className={styles.title}>Newsletter</p>
              <p className={styles.subtitle}>
                Subscribe to learn about new sections of the portal Stay up to
                date with the best 3D models and articles
              </p>
            </div>
            <div className={styles.sentEmail}>
              <input type="text" placeholder="E-mail" />
              <button>
                <span> Subscribe</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="7"
                  height="12"
                  viewBox="0 0 7 12"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.10039 12L-3.49416e-08 10.8809L4.79921 6L-3.39727e-07 1.11912L1.10039 -6.76767e-08L7 6L1.10039 12Z"
                    fill="#161619"
                  />
                </svg>
              </button>
            </div>
          </div>

          <ProgressiveImage src={image} placeholder={image}>
            {(src, loading) => (
              <img
                src={src}
                alt={image || "image"}
                style={{
                  filter: loading ? "blur(70px)" : "none",
                  transition: "filter 0.3s ease-out",
                }}
              />
            )}
          </ProgressiveImage>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
