import React, { useEffect, useRef, useState } from "react";
import { Canvas, extend, useThree, useFrame } from "react-three-fiber";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { useLoader } from "react-three-fiber";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { Box3, Vector3, TextureLoader } from "three";
import useEmblaCarousel from "embla-carousel-react";
import image from "../assets/anime.jpg";
import styles from "../scss/components/previewUploadItem.module.scss";

extend({ OrbitControls });
const Controls = () => {
  const {
    camera,
    gl: { domElement },
  } = useThree();
  const controls = useRef();

  useEffect(() => {
    if (controls.current) return;

    controls.current = new OrbitControls(camera, domElement);
    controls.current.autoRotate = false; // Если нужно, чтобы камера автоматически вращалась
    controls.current.maxPolarAngle = Math.PI / 2;
    controls.current.minPolarAngle = Math.PI / 2;

    // Очистка при размонтировании компонента
    return () => {
      controls.current.dispose();
    };
  }, [camera, domElement]);

  // Обновление controls каждый кадр
  useFrame(() => controls.current.update());

  return null;
};
const Model = () => {
  const fbx = useLoader(FBXLoader, "/exemple.fbx");
  const { camera, size } = useThree();

  useEffect(() => {
    const box = new Box3().setFromObject(fbx);
    const modelSize = new Vector3();
    box.getSize(modelSize);

    // Получаем пропорции модели
    const modelHeight = modelSize.y;
    const aspectRatio = size.height / size.width; // Пропорции канваса
    const cameraHeight =
      2 * Math.tan((camera.fov * Math.PI) / 360) * camera.position.z; // Видимая высота камеры на расстоянии

    // Рассчитываем масштаб так, чтобы модель поместилась по высоте, учитывая пропорции канваса
    let scale = (cameraHeight / modelHeight) * aspectRatio;

    // Проверяем, нужно ли корректировать масштаб по ширине
    if (modelSize.x * scale > size.width) {
      scale = (size.width / (modelSize.x * scale)) * scale;
    }

    fbx.scale.set(scale, scale, scale);

    // Центрируем модель
    const center = new Vector3();
    box.getCenter(center);
    fbx.position.sub(center).multiplyScalar(scale); // Применяем масштаб к смещению для центрирования
  }, [fbx, size, camera]);

  return <primitive object={fbx} />;
};
const Scene = () => {
  return (
    <Canvas style={{ flexGrow: 1 }} className={styles.canvas}>
      <ambientLight intensity={0.5} />
      <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
      <pointLight position={[-10, -10, -10]} />
      <Controls />
      <Model />
    </Canvas>
  );
};
const DataInfo = ({ handleReportToogle }) => {
  return (
    <div className={styles.dataContant}>
      <div className={styles.dataContant__items}>
        <div className={styles.dataContant__item}>
          <p className={styles.dataContant__itemTitle}>Common info</p>
          <div className={styles.commonInfo}>
            <ul>
              <li>
                <div className={styles.dataContant__criteria}>
                  <svg
                    width="18"
                    height="17"
                    viewBox="0 0 18 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.00011 1.5V4.15625M5.50012 9.5V12.5M7.00012 11H4.00012M12.4922 10H12.5001M11.4922 12H11.5001M13.5001 12H13.508M5.26271 6.8125H12.7375C13.341 6.8125 13.9386 6.92701 14.4963 7.14949C15.0539 7.37197 15.5605 7.69807 15.9873 8.10916C16.4141 8.52026 16.7526 9.00829 16.9836 9.54541C17.2145 10.0825 17.3334 10.6582 17.3334 11.2396C17.3334 11.821 17.2145 12.3966 16.9836 12.9338C16.7526 13.4709 16.4141 13.9589 15.9873 14.37C15.5605 14.7811 15.0539 15.1072 14.4963 15.3297C13.9386 15.5522 13.341 15.6667 12.7375 15.6667H5.26271C4.65916 15.6667 4.06152 15.5522 3.50391 15.3297C2.9463 15.1072 2.43965 14.7811 2.01287 14.37C1.5861 13.9589 1.24756 13.4709 1.01659 12.9338C0.785626 12.3966 0.666748 11.821 0.666748 11.2396C0.666748 10.6582 0.785626 10.0825 1.01659 9.54541C1.24756 9.00829 1.5861 8.52026 2.01287 8.10916C2.43965 7.69807 2.9463 7.37197 3.50391 7.14949C4.06152 6.92701 4.65916 6.8125 5.26271 6.8125Z"
                      stroke="#828282"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span>From game:</span>
                </div>
                <div>
                  <span className={styles.itemText}>
                    Mafia: Definitive Edition
                  </span>
                </div>
              </li>
              <li>
                <div className={styles.dataContant__criteria}>
                  <svg
                    width="14"
                    height="16"
                    viewBox="0 0 14 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 15L1 15L0.999999 1L3.5 1M6 15L13 14.5L13 2.5L6 1M6 15L6 1M6 1L3.5 1M3.5 12.5L3.5 12.4913M3.5 1L3.5 10"
                      stroke="#828282"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span>From platform:</span>
                </div>
                <div className={styles.elementItems}>
                  <span
                    className={styles.itemElement}
                    style={{ background: "rgb(59, 137, 95)" }}
                  >
                    xone
                  </span>
                  <span
                    className={styles.itemElement}
                    style={{ background: "rgb(57, 137, 92)" }}
                  >
                    xb360
                  </span>
                  <span
                    className={styles.itemElement}
                    style={{ background: "rgb(55, 103, 148)" }}
                  >
                    PS3
                  </span>

                  <span
                    className={styles.itemElement}
                    style={{ background: "rgb(113, 67, 57)" }}
                  >
                    PC
                  </span>
                  <span className={styles.itemElementMore}>
                    +5
                    <svg
                      width="7"
                      height="4"
                      viewBox="0 0 7 4"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.5 3.75L0.468911 0.75L6.53109 0.75L3.5 3.75Z"
                        fill="#D9D9D9"
                      />
                    </svg>
                  </span>
                </div>
              </li>
              <li>
                <div className={styles.dataContant__criteria}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.5 7.5H10M7.5 5.5V7.5M7.5 9.5V7.5M5.5 7.5H7.5M10 12.5H5.5M12.5 10.5V12.5M12.5 14.5V12.5M14.5 12.5H12.5M4 17H16C16.5523 17 17 16.5523 17 16V4C17 3.44772 16.5523 3 16 3H4C3.44772 3 3 3.44772 3 4V16C3 16.5523 3.44772 17 4 17Z"
                      stroke="#828282"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span>Technology:</span>
                </div>
                <div className={styles.elementItems}>
                  <span className={styles.itemLink}>2k Czeck Tech</span>
                  <span className={styles.itemLink}>UE5</span>
                </div>
              </li>
            </ul>
            <ul>
              <li>
                <div className={styles.dataContant__criteria}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.5 4.9C11.1686 4.9 10.9 5.16863 10.9 5.5C10.9 5.83137 11.1686 6.1 11.5 6.1V4.9ZM13.5 6.1C13.8314 6.1 14.1 5.83137 14.1 5.5C14.1 5.16863 13.8314 4.9 13.5 4.9V6.1ZM13.5 9.1C13.8314 9.1 14.1 8.83137 14.1 8.5C14.1 8.16863 13.8314 7.9 13.5 7.9V9.1ZM11.5 7.9C11.1686 7.9 10.9 8.16863 10.9 8.5C10.9 8.83137 11.1686 9.1 11.5 9.1V7.9ZM6.5 10.9C6.16863 10.9 5.9 11.1686 5.9 11.5C5.9 11.8314 6.16863 12.1 6.5 12.1V10.9ZM13.5 12.1C13.8314 12.1 14.1 11.8314 14.1 11.5C14.1 11.1686 13.8314 10.9 13.5 10.9V12.1ZM13.5 15.1C13.8314 15.1 14.1 14.8314 14.1 14.5C14.1 14.1686 13.8314 13.9 13.5 13.9V15.1ZM6.5 13.9C6.16863 13.9 5.9 14.1686 5.9 14.5C5.9 14.8314 6.16863 15.1 6.5 15.1V13.9ZM3.5 2.5V1.9C3.16863 1.9 2.9 2.16863 2.9 2.5H3.5ZM16.5 2.5H17.1C17.1 2.16863 16.8314 1.9 16.5 1.9V2.5ZM16.5 17.5V18.1C16.8314 18.1 17.1 17.8314 17.1 17.5H16.5ZM3.5 17.5H2.9C2.9 17.8314 3.16863 18.1 3.5 18.1V17.5ZM6.5 5.5V4.9H5.9V5.5H6.5ZM9.5 5.5H10.1V4.9H9.5V5.5ZM9.5 8.5V9.1H10.1V8.5H9.5ZM6.5 8.5H5.9V9.1H6.5V8.5ZM11.5 6.1H13.5V4.9H11.5V6.1ZM13.5 7.9H11.5V9.1H13.5V7.9ZM6.5 12.1H13.5V10.9H6.5V12.1ZM13.5 13.9H6.5V15.1H13.5V13.9ZM3.5 3.1H16.5V1.9H3.5V3.1ZM15.9 2.5V17.5H17.1V2.5H15.9ZM16.5 16.9H3.5V18.1H16.5V16.9ZM4.1 17.5V2.5H2.9V17.5H4.1ZM6.5 6.1H9.5V4.9H6.5V6.1ZM8.9 5.5V8.5H10.1V5.5H8.9ZM9.5 7.9H6.5V9.1H9.5V7.9ZM7.1 8.5V5.5H5.9V8.5H7.1Z"
                      fill="#828282"
                    />
                  </svg>
                  <span>Model copyrights:</span>
                </div>
                <div>
                  <span className={styles.itemText}>Frame 18</span>
                </div>
              </li>
              <li>
                <div className={styles.dataContant__criteria}>
                  <svg
                    width="17"
                    height="18"
                    viewBox="0 0 17 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 16.5H0.4V17.1H1V16.5ZM8.39896 12.7207C8.72559 12.7765 9.03563 12.5569 9.09143 12.2303C9.14724 11.9037 8.92768 11.5936 8.60104 11.5378L8.39896 12.7207ZM7 17.1C7.33137 17.1 7.6 16.8314 7.6 16.5C7.6 16.1686 7.33137 15.9 7 15.9V17.1ZM14 9.5L14.4243 9.07574L14 8.65147L13.5757 9.07574L14 9.5ZM16 11.5L16.4243 11.9243L16.8485 11.5L16.4243 11.0757L16 11.5ZM11 16.5V17.1H11.2485L11.4243 16.9243L11 16.5ZM9 16.5H8.4V17.1H9V16.5ZM9 14.5L8.57574 14.0757L8.4 14.2515V14.5H9ZM4.26667 4.83333C4.26667 3.32376 5.49042 2.1 7 2.1V0.9C4.82768 0.9 3.06667 2.66101 3.06667 4.83333H4.26667ZM7 2.1C8.50958 2.1 9.73333 3.32376 9.73333 4.83333H10.9333C10.9333 2.66101 9.17232 0.9 7 0.9V2.1ZM3.06667 5.5C3.06667 7.67232 4.82768 9.43333 7 9.43333V8.23333C5.49042 8.23333 4.26667 7.00958 4.26667 5.5H3.06667ZM7 9.43333C9.17232 9.43333 10.9333 7.67232 10.9333 5.5H9.73333C9.73333 7.00958 8.50958 8.23333 7 8.23333V9.43333ZM9.73333 4.83333V5.5H10.9333V4.83333H9.73333ZM3.06667 4.83333V5.5H4.26667V4.83333H3.06667ZM1.6 16.5C1.6 15.0097 2.36177 14.0636 3.42535 13.4591C4.51751 12.8384 5.90606 12.6 7 12.6V11.4C5.77093 11.4 4.15948 11.6616 2.8324 12.4159C1.47672 13.1864 0.4 14.4903 0.4 16.5H1.6ZM8.60104 11.5378C8.03974 11.4419 7.49289 11.4 7 11.4V12.6C7.42698 12.6 7.90617 12.6365 8.39896 12.7207L8.60104 11.5378ZM1 17.1H7V15.9H1V17.1ZM13.5757 9.92426L15.5757 11.9243L16.4243 11.0757L14.4243 9.07574L13.5757 9.92426ZM15.5757 11.0757L10.5757 16.0757L11.4243 16.9243L16.4243 11.9243L15.5757 11.0757ZM11 15.9H9V17.1H11V15.9ZM9.6 16.5V14.5H8.4V16.5H9.6ZM9.42426 14.9243L14.4243 9.92426L13.5757 9.07574L8.57574 14.0757L9.42426 14.9243Z"
                      fill="#828282"
                    />
                  </svg>
                  <span>Original author:</span>
                </div>
                <div>
                  <span className={styles.itemText}>-</span>
                </div>
              </li>
              <li>
                <div className={styles.dataContant__criteria}>
                  <svg
                    width="17"
                    height="18"
                    viewBox="0 0 17 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 16.5H0.4V17.1H1V16.5ZM10 17.1C10.3314 17.1 10.6 16.8314 10.6 16.5C10.6 16.1686 10.3314 15.9 10 15.9V17.1ZM10 12.9H9.4V14.1H10V12.9ZM16 14.1H16.6V12.9H16V14.1ZM13.6 10.5V9.9H12.4V10.5H13.6ZM12.4 16.5V17.1H13.6V16.5H12.4ZM8.11006 12.6765C8.43843 12.721 8.74071 12.4908 8.78521 12.1625C8.82971 11.8341 8.59959 11.5318 8.27122 11.4873L8.11006 12.6765ZM4.26667 4.83333C4.26667 3.32376 5.49042 2.1 7 2.1V0.9C4.82768 0.9 3.06667 2.66101 3.06667 4.83333H4.26667ZM7 2.1C8.50958 2.1 9.73333 3.32376 9.73333 4.83333H10.9333C10.9333 2.66101 9.17232 0.9 7 0.9V2.1ZM3.06667 5.5C3.06667 7.67232 4.82768 9.43333 7 9.43333V8.23333C5.49042 8.23333 4.26667 7.00958 4.26667 5.5H3.06667ZM7 9.43333C9.17232 9.43333 10.9333 7.67232 10.9333 5.5H9.73333C9.73333 7.00958 8.50958 8.23333 7 8.23333V9.43333ZM9.73333 4.83333V5.5H10.9333V4.83333H9.73333ZM3.06667 4.83333V5.5H4.26667V4.83333H3.06667ZM1.6 16.5C1.6 15.0097 2.36177 14.0636 3.42535 13.4591C4.51751 12.8384 5.90606 12.6 7 12.6V11.4C5.77093 11.4 4.15948 11.6616 2.8324 12.4159C1.47672 13.1864 0.4 14.4903 0.4 16.5H1.6ZM1 17.1H10V15.9H1V17.1ZM10 14.1H13V12.9H10V14.1ZM13 14.1H16V12.9H13V14.1ZM12.4 10.5V13.5H13.6V10.5H12.4ZM12.4 13.5V16.5H13.6V13.5H12.4ZM8.27122 11.4873C7.8267 11.4271 7.39615 11.4 7 11.4V12.6C7.34262 12.6 7.71932 12.6235 8.11006 12.6765L8.27122 11.4873Z"
                      fill="#828282"
                    />
                  </svg>
                  <span>Uploaded by:</span>
                </div>
                <div>
                  <span className={styles.itemLink}>Tosyk</span>
                </div>
              </li>
              <li>
                <div className={styles.dataContant__criteria}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.5 10H14.5M11.5 13H14.5M2.5 4H5.5V5C5.5 5.55228 5.94772 6 6.5 6H7.5C8.05228 6 8.5 5.55228 8.5 5V4H11.5V5C11.5 5.55228 11.9477 6 12.5 6H13.5C14.0523 6 14.5 5.55228 14.5 5V4H17.5V16H2.5V4ZM5.5 13L6.5 12H7.5L8.5 13H5.5ZM7.5 9.5V9.5C7.5 9.77614 7.27614 10 7 10V10C6.72386 10 6.5 9.77614 6.5 9.5V9.5C6.5 9.22386 6.72386 9 7 9V9C7.27614 9 7.5 9.22386 7.5 9.5Z"
                      stroke="#828282"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span>License:</span>
                </div>
                <div>
                  <span className={styles.itemText}>
                    Reference Purposes Only
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.dataContant__actions}>
          <div className={styles.downloadBtn}>
            <div className={styles.downloadBtn__contant}>
              <div>
                <p>Download</p>
                <span>&#x2022;</span>
                <p>126 Mb</p>
              </div>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.7559 9.58933C14.0813 9.26392 14.0813 8.73625 13.7559 8.41083C13.4305 8.08542 12.9028 8.08542 12.5774 8.41083L9.83333 11.1549V1.50008C9.83333 1.03985 9.46025 0.666748 9 0.666748C8.53975 0.666748 8.16667 1.03985 8.16667 1.50008V11.1549L5.42259 8.41083C5.09715 8.08542 4.56952 8.08542 4.24408 8.41083C3.91864 8.73625 3.91864 9.26392 4.24408 9.58933L8.41075 13.756C8.567 13.9122 8.779 14.0001 9 14.0001C9.221 14.0001 9.433 13.9122 9.58925 13.756L13.7559 9.58933Z"
                  fill="#0D0D0D"
                />
                <path
                  d="M15.6667 13.1668C15.6667 12.7066 16.0398 12.3335 16.5001 12.3335C16.9603 12.3335 17.3334 12.7066 17.3334 13.1668V16.5002C17.3334 16.9604 16.9603 17.3335 16.5001 17.3335H1.50008C1.03985 17.3335 0.666748 16.9604 0.666748 16.5002V13.1668C0.666748 12.7066 1.03985 12.3335 1.50008 12.3335C1.96031 12.3335 2.33341 12.7066 2.33341 13.1668V15.6668H15.6667V13.1668Z"
                  fill="#0D0D0D"
                />
              </svg>
            </div>
          </div>

          <div onClick={handleReportToogle} className={styles.reportBtn}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_1609_9944)">
                <path
                  d="M14.3224 0.853322C14.0238 0.81976 13.7125 0.858166 13.4133 0.986072L4.08162 4.98492C3.64778 4.55514 3.05466 4.2867 2.40244 4.2867C1.08681 4.2867 0 5.37351 0 6.6891V9.88973C0 11.2054 1.08681 12.2859 2.40244 12.2859C3.0745 12.2859 3.68225 12.0016 4.11912 11.5502L5.60306 11.9751V12.3344C5.60306 13.6092 6.47347 14.729 7.71025 15.0383L7.89925 15.0851C9.14353 15.3962 10.4363 14.6901 10.8468 13.4762L13.4367 14.2166C14.6981 14.577 16 13.5978 16 12.2859V2.68717C16 1.71035 15.218 0.954135 14.3224 0.853322ZM14.3989 2.68717V12.2859C14.3989 12.5772 14.1603 12.7564 13.8803 12.6764L4.77988 10.0787C4.85722 9.03595 4.85678 7.5302 4.77353 6.42979L14.0396 2.45595C14.2864 2.3646 14.4084 2.5257 14.3988 2.68714L14.3989 2.68717ZM2.40244 5.88623C2.85362 5.88623 3.19909 6.23798 3.19909 6.6891V9.88973C3.19909 10.3409 2.85362 10.6864 2.40244 10.6864C1.95125 10.6864 1.59953 10.3409 1.59953 9.88973V6.6891C1.59953 6.23798 1.95125 5.88623 2.40244 5.88623ZM7.21353 12.4359L9.28947 13.0294C8.992 13.568 8.62509 13.6347 8.10075 13.4856C7.60253 13.361 7.25756 12.9387 7.21353 12.4359Z"
                  fill="#616161"
                />
              </g>
              <defs>
                <clipPath id="clip0_1609_9944">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span>Report for the broken link</span>
          </div>
        </div>
        <div className={styles.dataContant__item}>
          <p className={styles.dataContant__itemTitle}>Model properties</p>
          <div className={styles.modelProperties}>
            <ul>
              <li>
                <div className={styles.dataContant__criteria}>
                  <svg
                    width="17"
                    height="18"
                    viewBox="0 0 17 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.99988 1.5L10.4999 1.5L14.6666 5.66667V6M9.99988 1.5L1.33325 1.5V16.5H14.6666V15.5M9.99988 1.5V6H14.6666M14.6666 6V8.5M8.49988 10.5H15.4999V13.5H8.49988V10.5Z"
                      stroke="#828282"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span>Format(s):</span>
                </div>
                <div className={styles.elementItems}>
                  <span className={styles.itemElementFormat}>.fbx</span>

                  <span className={styles.itemElementFormat}>.blender</span>
                </div>
              </li>
              <li>
                <div className={styles.dataContant__criteria}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 6.5V10.5M10 10.5L12 11.5L12.5 17.5M10 10.5L8 11.5L7.5 17.5M14.5 10.5L12.5 6.5L10 6L7.5 6.5L5.5 10.5M10 4.16667C10.221 4.16667 10.433 4.07887 10.5893 3.92259C10.7455 3.76631 10.8333 3.55435 10.8333 3.33333C10.8333 3.11232 10.7455 2.90036 10.5893 2.74408C10.433 2.5878 10.221 2.5 10 2.5C9.77899 2.5 9.56702 2.5878 9.41074 2.74408C9.25446 2.90036 9.16667 3.11232 9.16667 3.33333C9.16667 3.55435 9.25446 3.76631 9.41074 3.92259C9.56702 4.07887 9.77899 4.16667 10 4.16667Z"
                      stroke="#828282"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span>Skeleton/Armature:</span>
                </div>
                <div>
                  <span className={styles.itemText}>False</span>
                </div>
              </li>
              <li>
                <div className={styles.dataContant__criteria}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.75 16.6666L11.6667 8.74992L17.5 14.5833M7.5 7.49992C7.5 7.96016 7.1269 8.33325 6.66667 8.33325C6.20643 8.33325 5.83333 7.96016 5.83333 7.49992C5.83333 7.03968 6.20643 6.66659 6.66667 6.66659C7.1269 6.66659 7.5 7.03968 7.5 7.49992ZM2.5 3.33325H17.5V16.6666H2.5V3.33325Z"
                      stroke="#828282"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span>Textures:</span>
                </div>
                <div className={styles.elementItems}>
                  <span className={styles.itemText}>×25</span>
                  <span
                    className={styles.itemElement}
                    style={{ background: "#fff", color: "rgb(33, 34, 33)" }}
                  >
                    traditional
                  </span>
                  <span className={styles.itemElement}>1024</span>
                </div>
              </li>
              <li>
                <div className={styles.dataContant__criteria}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.5 10H4M4 10H5.5M4 10V11.5M4 10V8.5M13 5.5H14.5M14.5 5.5H16M14.5 5.5V7M14.5 5.5V4M11 15.5H12.5M12.5 15.5H14M12.5 15.5V17M12.5 15.5V14"
                      stroke="#828282"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                    />
                  </svg>

                  <span>Model quality:</span>
                </div>
                <div className={styles.elementItems}>
                  <span className={styles.itemText}>26.5k Vertices</span>
                  <span
                    className={styles.itemElement}
                    style={{ background: "#fff", color: "rgb(33, 34, 33)" }}
                  >
                    med-poly
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.dataContant__item}>
          <p className={styles.dataContant__itemTitle}>Description</p>
          <div className={styles.dataContant__itemDescriptions}>
            <p>
              Conceived as a full remake of the original, Mafia: Definitive
              Edition was built from the ground up with new assets and an
              expanded story, although missions and arcs from the original game
              are carried over. As with the 2002 game, players control Tommy
              Angelo throughout the game's single-player campaign, and its world
              is navigated on foot or by vehicle. Conceived as a full remake of
              the original, Mafia: Definitive Edition was built from the ground
              up with new assets and an expanded story, although missions and
              arcs from the original game are carried over. As with the 2002
              game, players control Tommy Angelo throughout the game's
              single-player campaign, and its world is navigated on foot or by
              vehicle.
            </p>
          </div>
        </div>
        <div className={styles.dataContant__item}>
          <p className={styles.dataContant__itemTitle}>License</p>
          <div className={styles.dataContant__itemDescriptions}>
            <p>
              A 'Classic Difficulty' setting is also included, serving as the
              game's hardest difficulty setting that changes some gameplay
              mechanics such as ammunition management and police response to
              crimes, bringing the game closer to the original 2002 game. A
              'Classic Difficulty' setting is also included, serving as the
              game's hardest difficulty setting that changes some gameplay
              mechanics such as ammunition management and police response to
              crimes, bringing the game closer to the original 2002 game.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
const DataComment = () => {
  return (
    <div className={styles.commentContant}>
      <div className={styles.commentContant__items}>
        <div className={styles.commentContant__addComment}>
          <input type="text" placeholder="Add a comment" />
          <button>
            <svg
              width="7"
              height="12"
              viewBox="0 0 7 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.10039 12L-3.49416e-08 10.8809L4.79921 6L-3.39727e-07 1.11912L1.10039 -6.76767e-08L7 6L1.10039 12Z"
                fill="#161619"
              />
            </svg>
          </button>
        </div>
        <span className={styles.commentContant__countComment}>11 comments</span>
        <div className={styles.commentContant__commentElements}>
          <div className={styles.commentContant__commentElement}>
            <div className={styles.commentCard}>
              <div className={styles.commentCard__header}>
                <img src={image} alt="image" />
                <p>Michael Bowman</p>
                <span>2 days ago</span>
              </div>
              <div className={styles.commentCard__content}>
                Conceived as a full remake of the original, Mafia: Definitive
                Edition was built from the ground up with new assets and an
                expanded story, although missions and arcs from the original
                game are carried over. Conceived as a full remake of the
                original, Mafia: Definitive Edition was built from the ground up
                with new assets and an expanded story, although missions and
                arcs from the original game are carried over.
              </div>
              <div className={styles.commentCard__footer}>
                <button>Reply</button>
                <div>
                  <div>
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_1609_12108)">
                        <path
                          d="M6.00013 4.36377C5.0974 4.36377 4.36377 5.0974 4.36377 6.00013C4.36377 6.90287 5.0974 7.6365 6.00013 7.6365C6.90287 7.6365 7.6365 6.90287 7.6365 6.00013C7.6365 5.0974 6.90285 4.36377 6.00013 4.36377Z"
                          fill="#616161"
                        />
                        <path
                          d="M6.00001 1.90918C3.27273 1.90918 0.943646 3.60553 0 6.00009C0.943646 8.39463 3.27273 10.091 6.00001 10.091C8.73001 10.091 11.0564 8.39463 12 6.00009C11.0564 3.60553 8.73001 1.90918 6.00001 1.90918ZM6.00001 8.72735C4.49456 8.72735 3.27273 7.50552 3.27273 6.00007C3.27273 4.49461 4.49456 3.27281 6.00001 3.27281C7.50547 3.27281 8.7273 4.49464 8.7273 6.00009C8.7273 7.50555 7.50547 8.72735 6.00001 8.72735Z"
                          fill="#616161"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1609_12108">
                          <rect width="12" height="12" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span>87</span>
                  </div>
                  <div>
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_1609_12116)">
                        <path
                          d="M10.5 0.75H1.5C0.87975 0.75 0.375 1.25475 0.375 1.875V7.875C0.375 8.49525 0.87975 9 1.5 9H2.625V10.875C2.625 11.0194 2.70788 11.1502 2.83725 11.2129C2.88937 11.2376 2.94488 11.25 3 11.25C3.08363 11.25 3.1665 11.2222 3.23438 11.1679L5.94413 9H10.5C11.1202 9 11.625 8.49525 11.625 7.875V1.875C11.625 1.25475 11.1202 0.75 10.5 0.75ZM6 6H3C2.79262 6 2.625 5.832 2.625 5.625C2.625 5.418 2.79262 5.25 3 5.25H6C6.20738 5.25 6.375 5.418 6.375 5.625C6.375 5.832 6.20738 6 6 6ZM9 4.5H3C2.79262 4.5 2.625 4.332 2.625 4.125C2.625 3.918 2.79262 3.75 3 3.75H9C9.20737 3.75 9.375 3.918 9.375 4.125C9.375 4.332 9.20737 4.5 9 4.5Z"
                          fill="#616161"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1609_12116">
                          <rect width="12" height="12" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span>2</span>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.commentCardRespond}>
              <div className={styles.commentCardRespond__toddler}>
                <div>
                  <span></span>
                </div>
                <div></div>
              </div>
              <div className={styles.commentCardRespond__items}>
                <div className={styles.commentCard}>
                  <div className={styles.commentCard__header}>
                    <img src={image} alt="image" />
                    <p>Michael Bowman</p>
                    <div className={styles.commentCard__role}>
                      <span>Author</span>
                    </div>
                    <span>2 days ago</span>
                  </div>
                  <div className={styles.commentCard__content}>
                    Conceived as a full remake of the original, Mafia:
                    Definitive Edition was built from the ground up with new
                    assets.
                  </div>
                  <div className={styles.commentCardRespond__footer}>
                    <div>
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_1609_12116)">
                          <path
                            d="M10.5 0.75H1.5C0.87975 0.75 0.375 1.25475 0.375 1.875V7.875C0.375 8.49525 0.87975 9 1.5 9H2.625V10.875C2.625 11.0194 2.70788 11.1502 2.83725 11.2129C2.88937 11.2376 2.94488 11.25 3 11.25C3.08363 11.25 3.1665 11.2222 3.23438 11.1679L5.94413 9H10.5C11.1202 9 11.625 8.49525 11.625 7.875V1.875C11.625 1.25475 11.1202 0.75 10.5 0.75ZM6 6H3C2.79262 6 2.625 5.832 2.625 5.625C2.625 5.418 2.79262 5.25 3 5.25H6C6.20738 5.25 6.375 5.418 6.375 5.625C6.375 5.832 6.20738 6 6 6ZM9 4.5H3C2.79262 4.5 2.625 4.332 2.625 4.125C2.625 3.918 2.79262 3.75 3 3.75H9C9.20737 3.75 9.375 3.918 9.375 4.125C9.375 4.332 9.20737 4.5 9 4.5Z"
                            fill="#616161"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1609_12116">
                            <rect width="12" height="12" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <span>2</span>
                    </div>
                  </div>
                </div>
                <div className={styles.commentCard}>
                  <div className={styles.commentCard__header}>
                    <img src={image} alt="image" />
                    <p>Michael Bowman</p>

                    <span>2 days ago</span>
                  </div>
                  <div className={styles.commentCard__content}>
                    Conceived as a full remake of the original, Mafia:
                    Definitive Edition was built from the ground up with new
                    assets.
                  </div>
                  <div className={styles.commentCardRespond__footer}>
                    <div>
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_1609_12116)">
                          <path
                            d="M10.5 0.75H1.5C0.87975 0.75 0.375 1.25475 0.375 1.875V7.875C0.375 8.49525 0.87975 9 1.5 9H2.625V10.875C2.625 11.0194 2.70788 11.1502 2.83725 11.2129C2.88937 11.2376 2.94488 11.25 3 11.25C3.08363 11.25 3.1665 11.2222 3.23438 11.1679L5.94413 9H10.5C11.1202 9 11.625 8.49525 11.625 7.875V1.875C11.625 1.25475 11.1202 0.75 10.5 0.75ZM6 6H3C2.79262 6 2.625 5.832 2.625 5.625C2.625 5.418 2.79262 5.25 3 5.25H6C6.20738 5.25 6.375 5.418 6.375 5.625C6.375 5.832 6.20738 6 6 6ZM9 4.5H3C2.79262 4.5 2.625 4.332 2.625 4.125C2.625 3.918 2.79262 3.75 3 3.75H9C9.20737 3.75 9.375 3.918 9.375 4.125C9.375 4.332 9.20737 4.5 9 4.5Z"
                            fill="#616161"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1609_12116">
                            <rect width="12" height="12" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <span>2</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.commentContant__commentElement}>
            <div className={styles.commentCard}>
              <div className={styles.commentCard__header}>
                <img src={image} alt="image" />
                <p>Michael Bowman</p>
                <span>2 days ago</span>
              </div>
              <div className={styles.commentCard__content}>
                Conceived as a full remake of the original, Mafia: Definitive
                Edition was built from the ground up with new assets and an
                expanded story, although missions and arcs from the original
                game are carried over. Conceived as a full remake of the
                original, Mafia: Definitive Edition was built from the ground up
                with new assets and an expanded story, although missions and
                arcs from the original game are carried over.
              </div>
              <div className={styles.commentCard__footer}>
                <button>Reply</button>
                <div>
                  <div>
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_1609_12108)">
                        <path
                          d="M6.00013 4.36377C5.0974 4.36377 4.36377 5.0974 4.36377 6.00013C4.36377 6.90287 5.0974 7.6365 6.00013 7.6365C6.90287 7.6365 7.6365 6.90287 7.6365 6.00013C7.6365 5.0974 6.90285 4.36377 6.00013 4.36377Z"
                          fill="#616161"
                        />
                        <path
                          d="M6.00001 1.90918C3.27273 1.90918 0.943646 3.60553 0 6.00009C0.943646 8.39463 3.27273 10.091 6.00001 10.091C8.73001 10.091 11.0564 8.39463 12 6.00009C11.0564 3.60553 8.73001 1.90918 6.00001 1.90918ZM6.00001 8.72735C4.49456 8.72735 3.27273 7.50552 3.27273 6.00007C3.27273 4.49461 4.49456 3.27281 6.00001 3.27281C7.50547 3.27281 8.7273 4.49464 8.7273 6.00009C8.7273 7.50555 7.50547 8.72735 6.00001 8.72735Z"
                          fill="#616161"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1609_12108">
                          <rect width="12" height="12" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span>87</span>
                  </div>
                  <div>
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_1609_12116)">
                        <path
                          d="M10.5 0.75H1.5C0.87975 0.75 0.375 1.25475 0.375 1.875V7.875C0.375 8.49525 0.87975 9 1.5 9H2.625V10.875C2.625 11.0194 2.70788 11.1502 2.83725 11.2129C2.88937 11.2376 2.94488 11.25 3 11.25C3.08363 11.25 3.1665 11.2222 3.23438 11.1679L5.94413 9H10.5C11.1202 9 11.625 8.49525 11.625 7.875V1.875C11.625 1.25475 11.1202 0.75 10.5 0.75ZM6 6H3C2.79262 6 2.625 5.832 2.625 5.625C2.625 5.418 2.79262 5.25 3 5.25H6C6.20738 5.25 6.375 5.418 6.375 5.625C6.375 5.832 6.20738 6 6 6ZM9 4.5H3C2.79262 4.5 2.625 4.332 2.625 4.125C2.625 3.918 2.79262 3.75 3 3.75H9C9.20737 3.75 9.375 3.918 9.375 4.125C9.375 4.332 9.20737 4.5 9 4.5Z"
                          fill="#616161"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1609_12116">
                          <rect width="12" height="12" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span>2</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.commentContant__commentElement}>
            <div className={styles.commentCard}>
              <div className={styles.commentCard__header}>
                <img src={image} alt="image" />
                <p>Michael Bowman</p>
                <span>2 days ago</span>
              </div>
              <div className={styles.commentCard__content}>
                Conceived as a full remake of the original, Mafia: Definitive
                Edition was built from the ground up with new assets and an
                expanded story, although missions and arcs from the original
                game are carried over. Conceived as a full remake of the
                original, Mafia: Definitive Edition was built from the ground up
                with new assets and an expanded story, although missions and
                arcs from the original game are carried over.
              </div>
              <div className={styles.commentCard__footer}>
                <button>Reply</button>
                <div>
                  <div>
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_1609_12108)">
                        <path
                          d="M6.00013 4.36377C5.0974 4.36377 4.36377 5.0974 4.36377 6.00013C4.36377 6.90287 5.0974 7.6365 6.00013 7.6365C6.90287 7.6365 7.6365 6.90287 7.6365 6.00013C7.6365 5.0974 6.90285 4.36377 6.00013 4.36377Z"
                          fill="#616161"
                        />
                        <path
                          d="M6.00001 1.90918C3.27273 1.90918 0.943646 3.60553 0 6.00009C0.943646 8.39463 3.27273 10.091 6.00001 10.091C8.73001 10.091 11.0564 8.39463 12 6.00009C11.0564 3.60553 8.73001 1.90918 6.00001 1.90918ZM6.00001 8.72735C4.49456 8.72735 3.27273 7.50552 3.27273 6.00007C3.27273 4.49461 4.49456 3.27281 6.00001 3.27281C7.50547 3.27281 8.7273 4.49464 8.7273 6.00009C8.7273 7.50555 7.50547 8.72735 6.00001 8.72735Z"
                          fill="#616161"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1609_12108">
                          <rect width="12" height="12" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span>87</span>
                  </div>
                  <div>
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_1609_12116)">
                        <path
                          d="M10.5 0.75H1.5C0.87975 0.75 0.375 1.25475 0.375 1.875V7.875C0.375 8.49525 0.87975 9 1.5 9H2.625V10.875C2.625 11.0194 2.70788 11.1502 2.83725 11.2129C2.88937 11.2376 2.94488 11.25 3 11.25C3.08363 11.25 3.1665 11.2222 3.23438 11.1679L5.94413 9H10.5C11.1202 9 11.625 8.49525 11.625 7.875V1.875C11.625 1.25475 11.1202 0.75 10.5 0.75ZM6 6H3C2.79262 6 2.625 5.832 2.625 5.625C2.625 5.418 2.79262 5.25 3 5.25H6C6.20738 5.25 6.375 5.418 6.375 5.625C6.375 5.832 6.20738 6 6 6ZM9 4.5H3C2.79262 4.5 2.625 4.332 2.625 4.125C2.625 3.918 2.79262 3.75 3 3.75H9C9.20737 3.75 9.375 3.918 9.375 4.125C9.375 4.332 9.20737 4.5 9 4.5Z"
                          fill="#616161"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1609_12116">
                          <rect width="12" height="12" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span>2</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.commentContant__commentElement}>
            <div className={styles.commentCard}>
              <div className={styles.commentCard__header}>
                <img src={image} alt="image" />
                <p>Michael Bowman</p>
                <span>2 days ago</span>
              </div>
              <div className={styles.commentCard__content}>
                Conceived as a full remake of the original, Mafia: Definitive
                Edition was built from the ground up with new assets and an
                expanded story, although missions and arcs from the original
                game are carried over. Conceived as a full remake of the
                original, Mafia: Definitive Edition was built from the ground up
                with new assets and an expanded story, although missions and
                arcs from the original game are carried over.
              </div>
              <div className={styles.commentCard__footer}>
                <button>Reply</button>
                <div>
                  <div>
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_1609_12108)">
                        <path
                          d="M6.00013 4.36377C5.0974 4.36377 4.36377 5.0974 4.36377 6.00013C4.36377 6.90287 5.0974 7.6365 6.00013 7.6365C6.90287 7.6365 7.6365 6.90287 7.6365 6.00013C7.6365 5.0974 6.90285 4.36377 6.00013 4.36377Z"
                          fill="#616161"
                        />
                        <path
                          d="M6.00001 1.90918C3.27273 1.90918 0.943646 3.60553 0 6.00009C0.943646 8.39463 3.27273 10.091 6.00001 10.091C8.73001 10.091 11.0564 8.39463 12 6.00009C11.0564 3.60553 8.73001 1.90918 6.00001 1.90918ZM6.00001 8.72735C4.49456 8.72735 3.27273 7.50552 3.27273 6.00007C3.27273 4.49461 4.49456 3.27281 6.00001 3.27281C7.50547 3.27281 8.7273 4.49464 8.7273 6.00009C8.7273 7.50555 7.50547 8.72735 6.00001 8.72735Z"
                          fill="#616161"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1609_12108">
                          <rect width="12" height="12" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span>87</span>
                  </div>
                  <div>
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_1609_12116)">
                        <path
                          d="M10.5 0.75H1.5C0.87975 0.75 0.375 1.25475 0.375 1.875V7.875C0.375 8.49525 0.87975 9 1.5 9H2.625V10.875C2.625 11.0194 2.70788 11.1502 2.83725 11.2129C2.88937 11.2376 2.94488 11.25 3 11.25C3.08363 11.25 3.1665 11.2222 3.23438 11.1679L5.94413 9H10.5C11.1202 9 11.625 8.49525 11.625 7.875V1.875C11.625 1.25475 11.1202 0.75 10.5 0.75ZM6 6H3C2.79262 6 2.625 5.832 2.625 5.625C2.625 5.418 2.79262 5.25 3 5.25H6C6.20738 5.25 6.375 5.418 6.375 5.625C6.375 5.832 6.20738 6 6 6ZM9 4.5H3C2.79262 4.5 2.625 4.332 2.625 4.125C2.625 3.918 2.79262 3.75 3 3.75H9C9.20737 3.75 9.375 3.918 9.375 4.125C9.375 4.332 9.20737 4.5 9 4.5Z"
                          fill="#616161"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1609_12116">
                          <rect width="12" height="12" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span>2</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <span className={styles.commentContant__showMore}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.49991 5.58594L9.99991 11.0859L15.4999 5.58594L16.9141 7.00015L9.99991 13.9144L3.08569 7.00015L4.49991 5.58594Z"
              fill="#F2F2F2"
            />
          </svg>
          Show more comments
        </span>
      </div>
    </div>
  );
};
const DataHistory = () => {
  return (
    <div className={styles.historyContant}>
      <span className={styles.historyContant__countHistory}>3 events</span>

      <div className={styles.historyContant__items}>
        <div className={styles.historyContant__item}>
          <div className={styles.historyContant__toddler}>
            <svg
              width="26"
              height="10"
              viewBox="0 0 26 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line y1="5" x2="8" y2="5" stroke="#616161" strokeWidth="2" />
              <line
                x1="18"
                y1="5"
                x2="26"
                y2="5"
                stroke="#616161"
                strokeWidth="2"
              />
              <circle cx="13" cy="5" r="4" stroke="#616161" strokeWidth="2" />
            </svg>
            <div></div>
          </div>
          <div className={styles.historyContant__element}>
            <span className={styles.historyContant__action}>
              Commented on 07-06-2023
            </span>
            <div className={styles.historyContantCard}>
              <div className={styles.historyContantCard__header}>
                <img src={image} alt="image" />
                <p>Michael Bowman</p>
                <div></div>
              </div>
              <div className={styles.historyContantCard__content}>
                <span>File link changed</span>
                <span>old: https://cgig.com/Klv9890.jpg</span>
                <span>new: https://cgig.com/PhxEbjv.jpg</span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.historyContant__item}>
          <div className={styles.historyContant__toddler}>
            <svg
              width="26"
              height="10"
              viewBox="0 0 26 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line y1="5" x2="8" y2="5" stroke="#616161" strokeWidth="2" />
              <line
                x1="18"
                y1="5"
                x2="26"
                y2="5"
                stroke="#616161"
                strokeWidth="2"
              />
              <circle cx="13" cy="5" r="4" stroke="#616161" strokeWidth="2" />
            </svg>
            <div></div>
          </div>
          <div className={styles.historyContant__element}>
            <span className={styles.historyContant__action}>
              Commented on 07-06-2023
            </span>
            <div className={styles.historyContantCard}>
              <div className={styles.historyContantCard__header}>
                <img src={image} alt="image" />
                <p>Michael Bowman</p>
                <div>
                  <span>Author</span>
                </div>
              </div>
              <div className={styles.historyContantCard__content}>
                <span>Following images were removed:</span>
                <span>— Xfmzqwf.png</span>
                <span>— gta4_smd_to_fbx_single.jpg </span>
                <span>— The Zodiac Age.jpg</span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.historyContant__item}>
          <div className={styles.historyContant__toddler}>
            <span></span>
          </div>
          <div className={styles.historyContant__element}>
            <span className={styles.historyContant__action}>
              Commented on 07-06-2023
            </span>
            <div className={styles.historyContantCard}>
              <div className={styles.historyContantCard__header}>
                <img src={image} alt="image" />
                <p>Tosyk</p>
                <div>
                  <span>Author</span>
                </div>
              </div>
              <div className={styles.historyContantCard__content}>
                <span>File uploaded</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button className={styles.historyContant__showMore}>Show more</button>
    </div>
  );
};
const ReportModal = ({ handleReportToogle }) => {
  return (
    <div className={styles.reportModal}>
      <div
        onClick={handleReportToogle}
        className={styles.reportModal__wrapper}
      ></div>
      <svg
        onClick={handleReportToogle}
        className={styles.reportModal__closeMb}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.68807 0L0 1.68807L10.3119 12L0 22.3118L1.68807 24L12 13.688L22.3118 24L24 22.3118L13.688 12L24 1.68807L22.3118 0L12 10.3119L1.68807 0Z"
          fill="white"
        />
      </svg>
      <div className={styles.reportModal__contant}>
        <p className={styles.reportModal__title}>Report for the broken link</p>
        <div className={styles.reportModal__actions}>
          <div>
            <span>Describe the problem</span>
            <div>
              <textarea name="" id="" cols="30" rows="10"></textarea>
            </div>
          </div>
          <button className={styles.reportModal__actionSend}>Send</button>
          <button>Log in</button>
        </div>

        <svg
          onClick={handleReportToogle}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.68807 0L0 1.68807L10.3119 12L0 22.3118L1.68807 24L12 13.688L22.3118 24L24 22.3118L13.688 12L24 1.68807L22.3118 0L12 10.3119L1.68807 0Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  );
};
const GamesModal = ({ handleViewingGamesToogle, dataBase }) => {
  const [gamesData, setGamesData] = useState(dataBase);
  return (
    <div className={styles.gamesModal}>
      <div
        onClick={handleViewingGamesToogle}
        className={styles.gamesModal__wrapper}
      ></div>
      <div className={styles.gamesModal__content}>
        <div className={styles.gamesModal__items}>
          {gamesData.map((item) => (
            <div className={styles.gamesModal__item}>
              <img src={item.image} alt="image" />
            </div>
          ))}

          <div className={styles.gamesModal__outInfo}>
            <div>
              <div>
                <img src={image} alt="image" />
              </div>
              <div>
                <img src={image} alt="image" />
              </div>
              <div>
                <img src={image} alt="image" />
              </div>

              <span>
                +33
                <svg
                  width="7"
                  height="4"
                  viewBox="0 0 7 4"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.5 0.75L6.53109 3.75L0.468911 3.75L3.5 0.75Z"
                    fill="#D9D9D9"
                  />
                </svg>
              </span>
            </div>
            <svg
              onClick={handleViewingGamesToogle}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.68807 0L0 1.68807L10.3119 12L0 22.3118L1.68807 24L12 13.688L22.3118 24L24 22.3118L13.688 12L24 1.68807L22.3118 0L12 10.3119L1.68807 0Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};
const PreviewUploadItem = ({handlePreview}) => {
  const [emblaMainRef, emblaMainApi] = useEmblaCarousel();
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    if (!emblaMainApi) return;
    const onSelect = () => {
      setCurrentIndex(emblaMainApi.selectedScrollSnap());
    };
    emblaMainApi.on("select", onSelect);
    return () => emblaMainApi.off("select", onSelect);
  }, [emblaMainApi]);
  const handleDotClick = (index) => {
    if (emblaMainApi) {
      emblaMainApi.scrollTo(index);
    }
  };
  const dataBase = [
    {
      type: "resource",
      title: "sport",
      image:
        "https://static.mash.ru/unsafe/rs:fit:800:450/czM6Ly9tYXNoL2ltYWdlLzIwMjMtMDctMTYvNDkzNGMyM2EtYzk0ZC00OWU0LWIxZDQtYzFjMzQ3NzZlYjEwL2ExNzAzYWExLWM2YzItNWIyMC05NTlhLTI1ODViZTllNTE2MS5qcGVn",
      resource: "model",
    },
    {
      type: "resource",
      image:
        "https://staticctf.ubisoft.com/J3yJr34U2pZ2Ieem48Dwy9uqj5PNUQTn/1Ts0MCxAqlTV2AcfoLV1CY/f6b9543e95898f42b0b152d11630b149/BGE2_Dakini_close-up.jpg",
      resource: "model",
      title: "cars",
    },
    {
      type: "resource",
      image: "https://creativshik.com/wp-content/uploads/2019/10/3-768x576.jpg",
      resource: "model",
      title: "stalker",
    },
    {
      type: "resource",
      image: "https://cdn.shazoo.ru/c576x256/97502_PCzjKGLXad_shablon.jpg",
      resource: "model",
      title: "anime",
    },
    {
      type: "games",
      image:
        "https://wa11papers.ru/hdwallpapers/games/Wa11papers.ru-game-07-11-2013_1920x1080_078.jpg",
      title: "magic",
      gameplay: "",
    },
    {
      type: "resource",
      image:
        "https://staticctf.ubisoft.com/J3yJr34U2pZ2Ieem48Dwy9uqj5PNUQTn/1Ts0MCxAqlTV2AcfoLV1CY/f6b9543e95898f42b0b152d11630b149/BGE2_Dakini_close-up.jpg",
      resource: "model",
      title: "cars",
    },
    {
      type: "resource",
      image: "https://creativshik.com/wp-content/uploads/2019/10/3-768x576.jpg",
      resource: "model",
      title: "stalker",
    },
    {
      type: "resource",
      image: "https://cdn.shazoo.ru/c576x256/97502_PCzjKGLXad_shablon.jpg",
      resource: "model",
      title: "anime",
    },
    {
      type: "games",
      image:
        "https://wa11papers.ru/hdwallpapers/games/Wa11papers.ru-game-07-11-2013_1920x1080_078.jpg",
      title: "magic",
      gameplay: "",
    },

    {
      type: "formats",
      title: "fighting",
      image:
        "https://winzoro.net/uploads/posts/2022-11/1668276728_cyber-samurai-straw-hat_preview.jpg",
      format: ".exe",
    },
    {
      type: "games",
      image:
        "https://hips.hearstapps.com/hmg-prod/images/forzahorizon5-launch-preview-04-1x1-wm-1635946511.jpg?crop=1.00xw:1.00xh;0,0&resize=640:*",
      title: "post-apocalypse",
      gameplay: "",
    },
    {
      type: "engine",
      image:
        "https://hips.hearstapps.com/hmg-prod/images/forzahorizon5-launch-preview-04-1x1-wm-1635946511.jpg?crop=1.00xw:1.00xh;0,0&resize=640:*",
      title: "fighting",
      gameplay: "epic",
    },
    {
      type: "formats",
      image: "https://coop-land.ru/uploads/posts/2019-08/1566142720_big.jpg",
      format: ".colodda",
      title: "stalker",
    },
    {
      type: "tech",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTo5EiFoZXs034Fo_sVu7BLKNjfHT2sB_d67Q&usqp=CAU",
      tech: "Unreal Engine 5",
      title: "action",
    },
    {
      type: "resource",
      image:
        "https://preview.free3d.com/img/2020/01/2279688275562071647/gjq63a53.jpg",
      resource: "model",
      title: "post-apocalypse",
    },
    {
      type: "games",
      image: "https://i.playground.ru/p/h3d5ZkNjI4nICwfthJRh4A.jpeg",
      title: "action",
      gameplay: "",
    },
    {
      type: "resource",
      title: "sport",
      image:
        "https://static.mash.ru/unsafe/rs:fit:800:450/czM6Ly9tYXNoL2ltYWdlLzIwMjMtMDctMTYvNDkzNGMyM2EtYzk0ZC00OWU0LWIxZDQtYzFjMzQ3NzZlYjEwL2ExNzAzYWExLWM2YzItNWIyMC05NTlhLTI1ODViZTllNTE2MS5qcGVn",
      resource: "model",
    },
    {
      type: "resource",
      image:
        "https://staticctf.ubisoft.com/J3yJr34U2pZ2Ieem48Dwy9uqj5PNUQTn/1Ts0MCxAqlTV2AcfoLV1CY/f6b9543e95898f42b0b152d11630b149/BGE2_Dakini_close-up.jpg",
      resource: "model",
      title: "cars",
    },
    {
      type: "resource",
      image: "https://creativshik.com/wp-content/uploads/2019/10/3-768x576.jpg",
      resource: "model",
      title: "stalker",
    },
    {
      type: "resource",
      image: "https://cdn.shazoo.ru/c576x256/97502_PCzjKGLXad_shablon.jpg",
      resource: "model",
      title: "anime",
    },
    {
      type: "games",
      image:
        "https://preview.free3d.com/img/2020/01/2279688275562071647/gjq63a53.jpg",
      title: "fighting",
      gameplay: "",
    },
    {
      type: "guides",
      title: "magic",
      description:
        "This guide provides step-by-step instructions on creating stunning 3D models of dinosaurs. From initial concept to final rendering, learn the techniques and tools used by professionals in the industry. Dive into the world of digital sculpting and texturing, and unleash your creativity with this comprehensive guide.",
    },
    {
      type: "games",
      image:
        "https://wa11papers.ru/hdwallpapers/games/Wa11papers.ru-game-07-11-2013_1920x1080_078.jpg",
      title: "magic",
      gameplay: "",
    },
    {
      type: "guides",
      title: "stalker",
      description:
        "This 3D model depicts a realistic portrait of a dinosaur, created using cutting-edge technologies. This 3D model depicts a realistic portrait of a dinosaur, created using cutting-edge technologies.",
    },
    {
      type: "formats",
      title: "fighting",
      image:
        "https://winzoro.net/uploads/posts/2022-11/1668276728_cyber-samurai-straw-hat_preview.jpg",
      format: ".exe",
    },
    {
      type: "games",
      image:
        "https://hips.hearstapps.com/hmg-prod/images/forzahorizon5-launch-preview-04-1x1-wm-1635946511.jpg?crop=1.00xw:1.00xh;0,0&resize=640:*",
      title: "post-apocalypse",
      gameplay: "",
    },
    {
      type: "engine",
      image:
        "https://hips.hearstapps.com/hmg-prod/images/forzahorizon5-launch-preview-04-1x1-wm-1635946511.jpg?crop=1.00xw:1.00xh;0,0&resize=640:*",
      title: "fighting",
      gameplay: "epic",
    },
    {
      type: "formats",
      image: "https://coop-land.ru/uploads/posts/2019-08/1566142720_big.jpg",
      format: ".colodda",
      title: "stalker",
    },
    {
      type: "tech",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTo5EiFoZXs034Fo_sVu7BLKNjfHT2sB_d67Q&usqp=CAU",
      tech: "Unreal Engine 5",
      title: "action",
    },
    {
      type: "resource",
      image:
        "https://preview.free3d.com/img/2020/01/2279688275562071647/gjq63a53.jpg",
      resource: "model",
      title: "post-apocalypse",
    },
    {
      type: "games",
      image: "https://i.playground.ru/p/h3d5ZkNjI4nICwfthJRh4A.jpeg",
      title: "action",
      gameplay: "",
    },
    {
      type: "resource",
      title: "sport",
      image:
        "https://static.mash.ru/unsafe/rs:fit:800:450/czM6Ly9tYXNoL2ltYWdlLzIwMjMtMDctMTYvNDkzNGMyM2EtYzk0ZC00OWU0LWIxZDQtYzFjMzQ3NzZlYjEwL2ExNzAzYWExLWM2YzItNWIyMC05NTlhLTI1ODViZTllNTE2MS5qcGVn",
      resource: "model",
    },
    {
      type: "resource",
      image:
        "https://staticctf.ubisoft.com/J3yJr34U2pZ2Ieem48Dwy9uqj5PNUQTn/1Ts0MCxAqlTV2AcfoLV1CY/f6b9543e95898f42b0b152d11630b149/BGE2_Dakini_close-up.jpg",
      resource: "model",
      title: "cars",
    },
    {
      type: "resource",
      image: "https://creativshik.com/wp-content/uploads/2019/10/3-768x576.jpg",
      resource: "model",
      title: "stalker",
    },
    {
      type: "resource",
      image: "https://cdn.shazoo.ru/c576x256/97502_PCzjKGLXad_shablon.jpg",
      resource: "model",
      title: "anime",
    },
    {
      type: "games",
      image:
        "https://preview.free3d.com/img/2020/01/2279688275562071647/gjq63a53.jpg",
      title: "fighting",
      gameplay: "",
    },
    {
      type: "guides",
      title: "magic",
      description:
        "This guide provides step-by-step instructions on creating stunning 3D models of dinosaurs. From initial concept to final rendering, learn the techniques and tools used by professionals in the industry. Dive into the world of digital sculpting and texturing, and unleash your creativity with this comprehensive guide.",
    },
  ];
  const [selectedTab, setSelectedTab] = useState("dataInfo");
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };
  const [isReport, setIsReport] = useState(false);
  const handleReportToogle = () => {
    setIsReport(!isReport);
  };
  const [viewingGames, setViewingGames] = useState(false);
  const handleViewingGamesToogle = () => {
    setViewingGames(!viewingGames);
  };
  return (
    <div className={styles.previewUploadItem}>
      <div className={styles.container}>
        <div className={styles.previewUploadItem__wrapper}>
          <div className={styles.leftPart}>
            <div className={styles.leftPart__header}>
              <p className={styles.leftPart__title}>Demon 3D model</p>
              <div className={styles.leftPart__intelligence}>
                <div>
                  <svg
                    width="16"
                    height="12"
                    viewBox="0 0 16 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.00003 3.89844C6.79638 3.89844 5.81821 4.87661 5.81821 6.08026C5.81821 7.28391 6.79638 8.26208 8.00003 8.26208C9.20368 8.26208 10.1818 7.28391 10.1818 6.08026C10.1818 4.87661 9.20364 3.89844 8.00003 3.89844Z"
                      fill="#C4C4C4"
                    />
                    <path
                      d="M8.00002 0.625488C4.36364 0.625488 1.25819 2.88729 0 6.08004C1.25819 9.27276 4.36364 11.5346 8.00002 11.5346C11.64 11.5346 14.7418 9.27276 16 6.08004C14.7418 2.88729 11.64 0.625488 8.00002 0.625488ZM8.00002 9.71638C5.99274 9.71638 4.36364 8.08728 4.36364 6.08C4.36364 4.07273 5.99274 2.44366 8.00002 2.44366C10.0073 2.44366 11.6364 4.07276 11.6364 6.08004C11.6364 8.08731 10.0073 9.71638 8.00002 9.71638Z"
                      fill="#C4C4C4"
                    />
                  </svg>
                  <span>125</span>
                </div>
                <div>
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_1796_10735)">
                      <path
                        d="M12.6668 0.0800781H3.38343C2.62736 0.0800781 1.9635 0.702059 1.9635 1.43828V15.1712C1.9635 15.4177 2.03209 15.623 2.14263 15.7817C2.27483 15.9714 2.48769 16.0802 2.71595 16.0801C2.93175 16.0801 3.16151 15.984 3.37369 15.8028L7.52707 12.2764C7.65535 12.1668 7.83961 12.104 8.03119 12.104C8.2227 12.104 8.40659 12.1668 8.53524 12.2767L12.6747 15.8023C12.8877 15.984 13.1016 16.0802 13.317 16.0802C13.6813 16.0802 14.0364 15.7992 14.0364 15.1713V1.43828C14.0364 0.702059 13.4229 0.0800781 12.6668 0.0800781Z"
                        fill="#C4C4C4"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1796_10735">
                        <rect
                          width="16"
                          height="16"
                          fill="white"
                          transform="translate(0 0.0800781)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <span>16</span>
                </div>
                <div>
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.8047 8.55164C12.0651 8.29131 12.0651 7.86917 11.8047 7.60884C11.5444 7.34851 11.1223 7.34851 10.8619 7.60884L8.66667 9.80411V2.08024C8.66667 1.71205 8.3682 1.41357 8 1.41357C7.6318 1.41357 7.33333 1.71205 7.33333 2.08024V9.80411L5.13807 7.60884C4.87772 7.34851 4.45561 7.34851 4.19526 7.60884C3.93491 7.86917 3.93491 8.29131 4.19526 8.55164L7.5286 11.885C7.6536 12.01 7.8232 12.0802 8 12.0802C8.1768 12.0802 8.3464 12.01 8.4714 11.885L11.8047 8.55164Z"
                      fill="#C4C4C4"
                    />
                    <path
                      d="M13.3333 11.4137C13.3333 11.0455 13.6318 10.7471 14 10.7471C14.3682 10.7471 14.6667 11.0455 14.6667 11.4137V14.0804C14.6667 14.4486 14.3682 14.7471 14 14.7471H2.00001C1.63182 14.7471 1.33334 14.4486 1.33334 14.0804V11.4137C1.33334 11.0455 1.63182 10.7471 2.00001 10.7471C2.3682 10.7471 2.66668 11.0455 2.66668 11.4137V13.4137H13.3333V11.4137Z"
                      fill="#C4C4C4"
                    />
                  </svg>
                  <span>12</span>
                </div>
              </div>
            </div>
            <div className={styles.carousel} ref={emblaMainRef}>
              <div className={styles.carousel__wrapper}>
                {dataBase.slice(0, 8).map((item, index) => (
                  <div key={index} className={styles.carousel__slide}>
                    <img src={item.image} alt="image" />
                  </div>
                ))}
              </div>
              <div className={styles.carousel__dots}>
                {dataBase.slice(0, 8).map((_, index) => (
                  <div
                    key={index}
                    className={`${styles.carousel__dot} ${
                      currentIndex === index ? styles.carousel__dotActive : ""
                    }`}
                    onClick={() => handleDotClick(index)}
                  >
                    <span></span>
                  </div>
                ))}
              </div>
            </div>

            <div className={styles.leftPart__categories}>
              {dataBase.map((item, index) => (
                <div key={index}>
                  <img src={item.image} alt="image" />
                  <span>{item.title}</span>
                </div>
              ))}
            </div>
            <div className={styles.intelligenceMb}>
              <div>
                <svg
                  width="16"
                  height="12"
                  viewBox="0 0 16 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.00003 3.89844C6.79638 3.89844 5.81821 4.87661 5.81821 6.08026C5.81821 7.28391 6.79638 8.26208 8.00003 8.26208C9.20368 8.26208 10.1818 7.28391 10.1818 6.08026C10.1818 4.87661 9.20364 3.89844 8.00003 3.89844Z"
                    fill="#C4C4C4"
                  />
                  <path
                    d="M8.00002 0.625488C4.36364 0.625488 1.25819 2.88729 0 6.08004C1.25819 9.27276 4.36364 11.5346 8.00002 11.5346C11.64 11.5346 14.7418 9.27276 16 6.08004C14.7418 2.88729 11.64 0.625488 8.00002 0.625488ZM8.00002 9.71638C5.99274 9.71638 4.36364 8.08728 4.36364 6.08C4.36364 4.07273 5.99274 2.44366 8.00002 2.44366C10.0073 2.44366 11.6364 4.07276 11.6364 6.08004C11.6364 8.08731 10.0073 9.71638 8.00002 9.71638Z"
                    fill="#C4C4C4"
                  />
                </svg>
                <span>125</span>
              </div>
              <div>
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_1796_10735)">
                    <path
                      d="M12.6668 0.0800781H3.38343C2.62736 0.0800781 1.9635 0.702059 1.9635 1.43828V15.1712C1.9635 15.4177 2.03209 15.623 2.14263 15.7817C2.27483 15.9714 2.48769 16.0802 2.71595 16.0801C2.93175 16.0801 3.16151 15.984 3.37369 15.8028L7.52707 12.2764C7.65535 12.1668 7.83961 12.104 8.03119 12.104C8.2227 12.104 8.40659 12.1668 8.53524 12.2767L12.6747 15.8023C12.8877 15.984 13.1016 16.0802 13.317 16.0802C13.6813 16.0802 14.0364 15.7992 14.0364 15.1713V1.43828C14.0364 0.702059 13.4229 0.0800781 12.6668 0.0800781Z"
                      fill="#C4C4C4"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1796_10735">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0 0.0800781)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <span>16</span>
              </div>
              <div>
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.8047 8.55164C12.0651 8.29131 12.0651 7.86917 11.8047 7.60884C11.5444 7.34851 11.1223 7.34851 10.8619 7.60884L8.66667 9.80411V2.08024C8.66667 1.71205 8.3682 1.41357 8 1.41357C7.6318 1.41357 7.33333 1.71205 7.33333 2.08024V9.80411L5.13807 7.60884C4.87772 7.34851 4.45561 7.34851 4.19526 7.60884C3.93491 7.86917 3.93491 8.29131 4.19526 8.55164L7.5286 11.885C7.6536 12.01 7.8232 12.0802 8 12.0802C8.1768 12.0802 8.3464 12.01 8.4714 11.885L11.8047 8.55164Z"
                    fill="#C4C4C4"
                  />
                  <path
                    d="M13.3333 11.4137C13.3333 11.0455 13.6318 10.7471 14 10.7471C14.3682 10.7471 14.6667 11.0455 14.6667 11.4137V14.0804C14.6667 14.4486 14.3682 14.7471 14 14.7471H2.00001C1.63182 14.7471 1.33334 14.4486 1.33334 14.0804V11.4137C1.33334 11.0455 1.63182 10.7471 2.00001 10.7471C2.3682 10.7471 2.66668 11.0455 2.66668 11.4137V13.4137H13.3333V11.4137Z"
                    fill="#C4C4C4"
                  />
                </svg>
                <span>12</span>
              </div>
            </div>

            <div className={styles.backEdit}>
              <button onClick={handlePreview}>Back to edit</button>
              <div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_1798_11135)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M22.8624 17.4562V5.56077C22.8624 4.56565 22.092 3.75146 21.1502 3.75146H2.84977C1.90802 3.75146 1.13757 4.56565 1.13757 5.56077V17.4562H22.8624ZM21.1213 15.7151V5.56077C21.1213 5.542 21.1165 5.51266 21.1049 5.49256H2.89506C2.88349 5.51266 2.87867 5.542 2.87867 5.56077V15.7151H21.1213Z"
                      fill="white"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1.18181 20.2484H22.8182C23.4682 20.2484 24 19.7167 24 19.0666V17.8848H0V19.0666C0 19.7167 0.531774 20.2484 1.18181 20.2484ZM14.2922 18.1526H9.70775V18.591C9.70775 18.8322 9.89571 19.0294 10.1255 19.0294H13.8745C14.1043 19.0294 14.2922 18.8322 14.2922 18.591V18.1526Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1798_11135">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
          <div className={styles.rightPart}>
            <div className={styles.rightPartHeader}>
              <div className={styles.rightPartHeaderGames}>
                <div
                  onClick={handleViewingGamesToogle}
                  className={styles.rightPartHeader__games}
                >
                  <img src={image} alt="image" />
                </div>
                <div className={styles.rightPartHeaderGames__info}>
                  <span className={styles.rightPartHeaderGames__category}>
                    From game:
                  </span>
                  <span className={styles.rightPartHeaderGames__gameName}>
                    Mafia: Definitive Edition
                  </span>
                </div>
              </div>
              <div className={styles.rightPartHeader__data}>
                <div
                  className={`${styles.dataInfo} ${
                    selectedTab === "dataInfo" && styles.activeTab
                  }`}
                  onClick={() => handleTabClick("dataInfo")}
                >
                  <svg
                    width="18"
                    height="17"
                    viewBox="0 0 18 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.16667 8.5H9V11.8333H9.83333M9 5.16667H9.00875M16.5 8.5C16.5 12.6421 13.1421 16 9 16C4.85786 16 1.5 12.6421 1.5 8.5C1.5 4.35786 4.85786 1 9 1C13.1421 1 16.5 4.35786 16.5 8.5Z"
                      stroke="#F2F2F2"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <p>INFO</p>
                </div>
                <div
                  className={`${styles.dataComment} ${
                    selectedTab === "dataComment" && styles.activeTab
                  }`}
                  onClick={() => handleTabClick("dataComment")}
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.00001 16.5C10.4834 16.5 11.9334 16.0601 13.1668 15.236C14.4002 14.4119 15.3614 13.2406 15.9291 11.8701C16.4968 10.4997 16.6453 8.99168 16.3559 7.53683C16.0665 6.08197 15.3522 4.7456 14.3033 3.6967C13.2544 2.64781 11.918 1.9335 10.4632 1.64411C9.00833 1.35472 7.50033 1.50325 6.12988 2.07091C4.75944 2.63856 3.5881 3.59986 2.76399 4.83323C1.93988 6.0666 1.50001 7.51664 1.50001 9C1.49835 10.1565 1.76536 11.2976 2.28001 12.3333L1.50001 16.5L5.66667 15.72C6.70241 16.2346 7.84349 16.5016 9.00001 16.5V16.5Z"
                      stroke="#828282"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span>12</span>
                  <p>COMMENTS</p>
                </div>
                <div
                  className={`${styles.dataHistory} ${
                    selectedTab === "dataHistory" && styles.activeTab
                  }`}
                  onClick={() => handleTabClick("dataHistory")}
                >
                  <svg
                    width="17"
                    height="18"
                    viewBox="0 0 17 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.9999 11.5V1.5H0.666591V14.8333C0.666591 15.7538 1.41278 16.5 2.33326 16.5M2.33326 16.5C3.2059 16.5 3.9219 15.8293 3.99396 14.9753M2.33326 16.5H14.0001C14.9206 16.5 15.6668 15.7538 15.6668 14.8333V14H3.99396V14.9753M3.99992 14.8333C3.99992 14.8812 3.99791 14.9285 3.99396 14.9753M3.50005 4.5H11M3.50005 7.5H11M3.50005 10.5H11"
                      stroke="#828282"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <p>HISTORY</p>
                </div>
              </div>
            </div>
            {selectedTab === "dataInfo" && (
              <DataInfo
                isReport={isReport}
                handleReportToogle={handleReportToogle}
              />
            )}
            {selectedTab === "dataComment" && <DataComment />}
            {selectedTab === "dataHistory" && <DataHistory />}

            <div className={styles.actionsMb}>
              <div className={styles.downloadBtnMb}>
                <div className={styles.downloadBtnMb__contant}>
                  <div>
                    <p>share link</p>
                  </div>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.7559 9.58933C14.0813 9.26392 14.0813 8.73625 13.7559 8.41083C13.4305 8.08542 12.9028 8.08542 12.5774 8.41083L9.83333 11.1549V1.50008C9.83333 1.03985 9.46025 0.666748 9 0.666748C8.53975 0.666748 8.16667 1.03985 8.16667 1.50008V11.1549L5.42259 8.41083C5.09715 8.08542 4.56952 8.08542 4.24408 8.41083C3.91864 8.73625 3.91864 9.26392 4.24408 9.58933L8.41075 13.756C8.567 13.9122 8.779 14.0001 9 14.0001C9.221 14.0001 9.433 13.9122 9.58925 13.756L13.7559 9.58933Z"
                      fill="#0D0D0D"
                    />
                    <path
                      d="M15.6667 13.1668C15.6667 12.7066 16.0398 12.3335 16.5001 12.3335C16.9603 12.3335 17.3334 12.7066 17.3334 13.1668V16.5002C17.3334 16.9604 16.9603 17.3335 16.5001 17.3335H1.50008C1.03985 17.3335 0.666748 16.9604 0.666748 16.5002V13.1668C0.666748 12.7066 1.03985 12.3335 1.50008 12.3335C1.96031 12.3335 2.33341 12.7066 2.33341 13.1668V15.6668H15.6667V13.1668Z"
                      fill="#0D0D0D"
                    />
                  </svg>
                </div>
              </div>

              <div onClick={handleReportToogle} className={styles.reportBtnMb}>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_1609_9944)">
                    <path
                      d="M14.3224 0.853322C14.0238 0.81976 13.7125 0.858166 13.4133 0.986072L4.08162 4.98492C3.64778 4.55514 3.05466 4.2867 2.40244 4.2867C1.08681 4.2867 0 5.37351 0 6.6891V9.88973C0 11.2054 1.08681 12.2859 2.40244 12.2859C3.0745 12.2859 3.68225 12.0016 4.11912 11.5502L5.60306 11.9751V12.3344C5.60306 13.6092 6.47347 14.729 7.71025 15.0383L7.89925 15.0851C9.14353 15.3962 10.4363 14.6901 10.8468 13.4762L13.4367 14.2166C14.6981 14.577 16 13.5978 16 12.2859V2.68717C16 1.71035 15.218 0.954135 14.3224 0.853322ZM14.3989 2.68717V12.2859C14.3989 12.5772 14.1603 12.7564 13.8803 12.6764L4.77988 10.0787C4.85722 9.03595 4.85678 7.5302 4.77353 6.42979L14.0396 2.45595C14.2864 2.3646 14.4084 2.5257 14.3988 2.68714L14.3989 2.68717ZM2.40244 5.88623C2.85362 5.88623 3.19909 6.23798 3.19909 6.6891V9.88973C3.19909 10.3409 2.85362 10.6864 2.40244 10.6864C1.95125 10.6864 1.59953 10.3409 1.59953 9.88973V6.6891C1.59953 6.23798 1.95125 5.88623 2.40244 5.88623ZM7.21353 12.4359L9.28947 13.0294C8.992 13.568 8.62509 13.6347 8.10075 13.4856C7.60253 13.361 7.25756 12.9387 7.21353 12.4359Z"
                      fill="#616161"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1609_9944">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span>Report for the broken link</span>
              </div>
            </div>
          </div>

          <div className={styles.footerContentMb}>
            <div className={styles.leftPart__categoriesMb}>
              {dataBase.slice(0, 10).map((item, index) => (
                <div key={index}>
                  <img src={item.image} alt="image" />
                  <span>{item.title}</span>
                </div>
              ))}
            </div>
            <span className={styles.leftPart__showMoreCategories}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.50015 5.58594L10.0002 11.0859L15.5002 5.58594L16.9144 7.00015L10.0002 13.9144L3.08594 7.00015L4.50015 5.58594Z"
                  fill="#F2F2F2"
                />
              </svg>
              Show all tags
            </span>
          </div>
        </div>
      </div>
      {viewingGames && (
        <GamesModal
          dataBase={dataBase}
          handleViewingGamesToogle={handleViewingGamesToogle}
        />
      )}
      {isReport && <ReportModal handleReportToogle={handleReportToogle} />}
    </div>
  );
};

export default PreviewUploadItem;
