import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProgressiveImage from "react-progressive-graceful-image";

import backgroundNumbers from "../assets/backgroundNumbers.png";
import { ReactComponent as ArrowSeparatorIcon } from "../assets/icon/arrowSeparatorIcon.svg";

import styles from "../scss/components/formatsCarousel.module.scss";

const url = process.env.REACT_APP_SERVER_API;
const imageUrl = process.env.REACT_APP_IMAGE_URL;

const FormatsCarousel = () => {
  const [formatData, setFormatData] = useState([]);
  const [page, setPage] = useState(1);
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef();

  useEffect(() => {
    const fetchData = () => {
      axios
        .get(
          `${url}/api/formats?pagination[page]=${page}&pagination[pageSize]=100&fields[0]=name&populate[background][fields][0]=url&populate[background][fields][1]=name`
        )
        .then((res) => {
          const newFormat = res.data.data;
          if (newFormat.length > 0) {
            setFormatData((prevData) => {
              const newFormatIds = newFormat.map((t) => t.id);
              const filteredData = prevData.filter(
                (prev) => !newFormatIds.includes(prev.id)
              );
              return [...filteredData, ...newFormat];
            });
            if (newFormat.length === 100) {
              setPage((prevPage) => prevPage + 1);
            }
          }
        })
        .catch((error) => {
          console.error("Ошибка при запросе данных:", error);
        });
    };
    fetchData();
  }, [page]);

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: null,
    prevArrow: null,
    draggable: true,
    swipe: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 2,
          dots: false,
          infinite: false,
          swipe: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.25,
          slidesToScroll: 1,
          swipe: true,
          /* initialSlide: 2, */
        },
      },
    ],
    afterChange: (current) => {
      setCurrentSlide(current);
    },
  };

  const formatItems = formatData.slice(0, 4).map((item, index) => (
    <div className={styles.item} key={index}>
      <div>
        <p>{item?.attributes?.name}</p>
      </div>
      <ProgressiveImage src={backgroundNumbers} placeholder={backgroundNumbers}>
        {(src, loading) => (
          <img
            src={src}
            alt={item?.attributes?.name || "noImage"}
            style={{
              filter: loading ? "blur(70px)" : "none",
              transition: "filter 0.3s ease-out",
            }}
          />
        )}
      </ProgressiveImage>
    </div>
  ));

  return (
    <div className={formatItems.length > 0 ? styles.formats : styles.hidden}>
      <div className="container">
        <div className={styles.formats__wrapper}>
          <div className={styles.header}>
            <h2 className={styles.title}>Formats</h2>
            <Link className={styles.more} to="/">
              <p>See all</p>
              <ArrowSeparatorIcon />
            </Link>
          </div>
          <div className={styles.carousel}>
            {formatItems.length > 0 ? (
              <Slider
                ref={sliderRef}
                className={styles.carousel}
                {...sliderSettings}
              >
                {formatItems}
              </Slider>
            ) : (
              <div className={styles.loadingContainer}></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormatsCarousel;
