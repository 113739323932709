import React, { memo } from "react";
import { Link } from "react-router-dom";
import ProgressiveImage from "react-progressive-graceful-image";

import noImage from "../../../assets/noImage.png";
import BackgroundImageEmpty from "../../../assets/backgroundImageEmpty.png";
import { ReactComponent as GameIcon } from "../../../assets/icon/gameIcon.svg";

import styles from "../../../scss/components/mui/masaryCard/gameCard.module.scss";

const imageUrl = process.env.REACT_APP_IMAGE_URL;

const GameCard = ({
  gameTitle,
  gameId,
  gameImage,
  companyImage,
  developerType,
}) => {
  return (
    <Link
      to={{
        pathname: `/game/${gameTitle || "Unknown game"}`,
        search: `?id=${gameId || "Unknown game id"}`,
      }}
      className={styles.game}
      style={gameImage ? {} : { border: "1px solid #3F3F3F" }}
    >
      <div className={styles.resource}>
        <ProgressiveImage
          src={gameImage ? `${imageUrl}${gameImage}` : noImage}
          placeholder={gameImage ? `${imageUrl}${gameImage}` : noImage}
        >
          {(src, loading) => (
            <img
              src={src}
              alt={gameTitle || "Unknown game"}
              style={{
                filter: loading ? "blur(70px)" : "none",
                transition: "filter 0.3s ease-out",
              }}
            />
          )}
        </ProgressiveImage>
      </div>
      <div className={styles.itemOverlay}>
        <div className={styles.itemOverlay__contant}>
          <div className={styles.itemOverlay__header}>
            <div className={styles.itemOverlay__headerObj}>
              <GameIcon />
            </div>
          </div>
          <div className={styles.itemOverlay__footer}>
            <div className={styles.itemOverlay__footerImage}>
              <ProgressiveImage
                src={
                  companyImage
                    ? `${imageUrl}${companyImage}`
                    : BackgroundImageEmpty
                }
                placeholder={
                  companyImage
                    ? `${imageUrl}${companyImage}`
                    : BackgroundImageEmpty
                }
              >
                {(src, loading) => (
                  <img
                    src={src}
                    alt={developerType || "Unknown developer"}
                    style={{
                      filter: loading ? "blur(70px)" : "none",
                      transition: "filter 0.3s ease-out",
                    }}
                  />
                )}
              </ProgressiveImage>
            </div>
            <div className={styles.itemOverlay__footerInfo}>
              <p>{gameTitle || "Unknown game"}</p>
              <span>{developerType || "Unknown developer"}</span>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};
export default memo(GameCard);
