import React from "react";

import { ReactComponent as ErrorIcon } from "../assets/icon/404.svg";
import styles from "../scss/components/notFoundError.module.scss";

const NotFoundError = () => {
  return (
    <div className={styles.notFoundError}>
      <div className="container">
        <div className={styles.notFoundError__wrapper}>
          <div className={styles.contant}>
            <ErrorIcon />
            <p>Page not found.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundError;
