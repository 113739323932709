import React, { memo } from "react";
import { Link } from "react-router-dom";
import ProgressiveImage from "react-progressive-graceful-image";

import noImage from "../../../assets/noImage.png";
import { ReactComponent as CubIcon } from "../../../assets/icon/cubIcon.svg";
import { ReactComponent as YouTubeIcon } from "../../../assets/icon/youTubeIcon.svg";
import { ReactComponent as UpdateIcon } from "../../../assets/icon/updateIcon.svg";

import styles from "../../../scss/components/mui/masaryCard/assetCard.module.scss";

const imageUrl = process.env.REACT_APP_IMAGE_URL;

const AssetCard = ({
  assetId,
  assetTitle,
  assetImage,
  uploaderName,
  uploaderImage,
}) => {
  return (
    <Link
      to={{
        pathname: `/models/${assetTitle || "Unknown asset"}`,
        search: `?id=${assetId || "Unknown asset id"}`,
      }}
      className={styles.asset}
      key={assetId || "Unknown asset id"}
    >
      <div className={styles.resource}>
        <ProgressiveImage
          src={assetImage ? `${imageUrl}${assetImage}` : noImage}
          placeholder={assetImage ? `${imageUrl}${assetImage}` : noImage}
        >
          {(src, loading) => (
            <img
              src={src}
              alt={assetTitle || "Unknown asset"}
              style={{
                filter: loading ? "blur(70px)" : "none",
                transition: "filter 0.3s ease-out",
              }}
            />
          )}
        </ProgressiveImage>
      </div>
      <div className={styles.itemOverlay}>
        <div className={styles.itemOverlay__contant}>
          <div className={styles.itemOverlay__header}>
            <div className={styles.itemOverlay__headerObj}>
              <CubIcon />
            </div>
            <div className={styles.itemOverlay__headerTools}>
              <div>
                <YouTubeIcon />
              </div>
              <div>
                <UpdateIcon />
              </div>
            </div>
          </div>
          <div className={styles.itemOverlay__footer}>
            <div className={styles.itemOverlay__footerImage}>
              <ProgressiveImage
                src={uploaderImage ? `${imageUrl}${uploaderImage}` : noImage}
                placeholder={
                  uploaderImage ? `${imageUrl}${uploaderImage}` : noImage
                }
              >
                {(src, loading) => (
                  <img
                    src={src}
                    alt={uploaderName || "Unknown uploader"}
                    style={{
                      filter: loading ? "blur(70px)" : "none",
                      transition: "filter 0.3s ease-out",
                    }}
                  />
                )}
              </ProgressiveImage>
            </div>
            <div className={styles.itemOverlay__footerInfo}>
              <p className={styles.itemOverlay__footerTopic}>
                {assetTitle || "Unknown"}
              </p>
              <span>{uploaderName || "Unknown uploader"}</span>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default memo(AssetCard);
