import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

const url = process.env.REACT_APP_SERVER_API;

const AuthLogin = () => {
  const [text, setText] = useState("Loading...");
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const authenticateUser = async () => {
      try {
        const response = await fetch(
          `${url}/api/auth/${params.providerName}/callback${location.search}`
        );

        if (response.status !== 200) {
          throw new Error(
            `Couldn't login to Strapi. Status: ${response.status}`
          );
        }

        const result = await response.json();
        const { jwt, user } = result;

        if (!jwt || !user || !user.username) {
          throw new Error("Invalid response format from Strapi.");
        }

        localStorage.setItem("jwt", JSON.stringify(jwt));

        setText(
          "You have been successfully logged in. You will be redirected in a few seconds..."
        );

        setTimeout(() => {
          navigate("/");
          window.location.reload();
        }, 3000);
      } catch (error) {
        console.error(error);
        setText("An error occurred, please see the developer console.");
      }
    };

    authenticateUser();
  }, [navigate, location.search, params.providerName]);

  return;
};

export default AuthLogin;
