import React from "react";

import Header from "../Header";
import Footer from "../Footer";
import WidgetPageNavigation from "../mui/WidgetPageNavigation";

const Tools = () => {
  return (
    <div className="main">
      <Header />
      <div className="main__contant"></div>
      <Footer />
      <WidgetPageNavigation />
    </div>
  );
};

export default Tools;
