import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDebounce } from "use-debounce";
import { Link, useNavigate } from "react-router-dom";
import {
  setUsername,
  setPassword,
  setRepeatPassword,
  setCustomUserId,
  setEmail,
  setIsApprovedPassword,
  checkEmail,
  checkCustomUserId,
  registerUser,
} from "../../store/features/registrationSlice";

import styles from "../../scss/components/pages/registration.module.scss";

const Registration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    username,
    password,
    customUserId,
    email,
    repeatPassword,

    isBusyCustomUserId,
    isUsedMail,
    isApprovedPassword,
  } = useSelector((state) => state.registration);

  // Username
  const [inputUsernameValue, setInputUsernameValue] = useState(username);
  const [debouncedInputUsernameValue] = useDebounce(inputUsernameValue, 300);
  useEffect(() => {
    if (debouncedInputUsernameValue !== username) {
      dispatch(setUsername(debouncedInputUsernameValue));
    }
  }, [debouncedInputUsernameValue, dispatch, username]);
  const handleUsernameChange = (e) => {
    setInputUsernameValue(e.target.value);
  };

  // Custom User ID
  const [inputCustomUserIdValue, setInputCustomUserIdValue] =
    useState(customUserId);
  const [debouncedInputCustomUserIdValue] = useDebounce(
    inputCustomUserIdValue,
    300
  );
  useEffect(() => {
    if (debouncedInputCustomUserIdValue !== "") {
      dispatch(
        checkCustomUserId({ customUserId: debouncedInputCustomUserIdValue })
      );
    }
  }, [debouncedInputCustomUserIdValue, dispatch]);

  useEffect(() => {
    if (debouncedInputCustomUserIdValue !== customUserId) {
      dispatch(setCustomUserId(debouncedInputCustomUserIdValue));
    }
  }, [debouncedInputCustomUserIdValue, dispatch, customUserId]);
  const handleCustomUserIdChange = (e) => {
    setInputCustomUserIdValue(e.target.value);
  };

  // Email
  const [inputEmailValue, setInputEmailValue] = useState(email);
  const [debouncedInputEmailValue] = useDebounce(inputEmailValue, 300);
  useEffect(() => {
    if (debouncedInputEmailValue !== email) {
      dispatch(setEmail(debouncedInputEmailValue));
    }
  }, [debouncedInputEmailValue, dispatch, email]);
  useEffect(() => {
    if (debouncedInputEmailValue !== "") {
      dispatch(checkEmail({ email: debouncedInputEmailValue }));
    }
  }, [debouncedInputEmailValue, dispatch]);
  const handleEmailChange = (e) => {
    setInputEmailValue(e.target.value);
  };

  // Password
  const [inputPasswordValue, setInputPasswordValue] = useState(password);
  const [debouncedInputPasswordValue] = useDebounce(inputPasswordValue, 300);
  useEffect(() => {
    if (debouncedInputPasswordValue !== password) {
      dispatch(setPassword(debouncedInputPasswordValue));
    }
  }, [debouncedInputPasswordValue, dispatch, password]);
  const handlePasswordChange = (e) => {
    const value = e.target.value;
    const sanitizedValue = value.replace(/[^a-zA-Z0-9!*]/g, "");
    setInputPasswordValue(sanitizedValue);
  };
  const handlePasswordPaste = (e) => {
    e.preventDefault();
    const paste = (e.clipboardData || window.clipboardData).getData("text");
    const sanitizedValue = paste.replace(/[^a-zA-Z0-9!*]/g, "");
    setInputPasswordValue((prevValue) => prevValue + sanitizedValue);
  };

  // Repeat Password
  const [inputRepeatPasswordValue, setInputRepeatPasswordValue] =
    useState(repeatPassword);
  const [debouncedInputRepeatPasswordValue] = useDebounce(
    inputRepeatPasswordValue,
    300
  );
  useEffect(() => {
    if (debouncedInputRepeatPasswordValue !== repeatPassword) {
      dispatch(setRepeatPassword(debouncedInputRepeatPasswordValue));
    }
  }, [debouncedInputRepeatPasswordValue, dispatch, repeatPassword]);
  const handleRepeatPasswordChange = (e) => {
    const value = e.target.value;
    const sanitizedValue = value.replace(/[^a-zA-Z0-9!*]/g, "");
    setInputRepeatPasswordValue(sanitizedValue);
  };
  const handlePasswordPress = (e) => {
    const allowedCharacters = /^[a-zA-Z0-9!*]+$/;
    if (!allowedCharacters.test(e.key)) {
      e.preventDefault();
    }
  };
  const handleRepeatPasswordPaste = (e) => {
    e.preventDefault();
    const paste = (e.clipboardData || window.clipboardData).getData("text");
    const sanitizedValue = paste.replace(/[^a-zA-Z0-9!*]/g, "");
    setInputRepeatPasswordValue((prevValue) => prevValue + sanitizedValue);
  };

  // Check if passwords match
  useEffect(() => {
    if (
      debouncedInputPasswordValue !== "" &&
      debouncedInputRepeatPasswordValue !== "" &&
      debouncedInputPasswordValue === debouncedInputRepeatPasswordValue
    ) {
      dispatch(setIsApprovedPassword(true));
    } else {
      dispatch(setIsApprovedPassword(false));
    }
  }, [
    debouncedInputPasswordValue,
    debouncedInputRepeatPasswordValue,
    dispatch,
  ]);

  const handleSubmitForm = () => {
    dispatch(registerUser());
  };
  const handleClick = () => {
    if (!(!isBusyCustomUserId && !isUsedMail && !isApprovedPassword)) {
      handleSubmitForm();
    }
  };
  const handleGoBack = () => {
    if (window.history.length <= 1) {
      navigate("/");
    } else {
      navigate(-1);
    }
  };

  return (
    <div className={styles.modal}>
      <div onClick={handleGoBack} className={styles.modalOverlay}></div>
      <svg
        onClick={handleGoBack}
        className={styles.modalCloseMb}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M1.68807 0L0 1.68807L10.3119 12L0 22.3118L1.68807 24L12 13.688L22.3118 24L24 22.3118L13.688 12L24 1.68807L22.3118 0L12 10.3119L1.68807 0Z"
          fill="white"
        />
      </svg>
      <div className={styles.modalContent}>
        <div className={styles.modalEllips}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="49"
            height="48"
            viewBox="0 0 49 48"
            fill="none"
          >
            <circle cx="24.5" cy="24" r="24" fill="#D9D9D9" />
          </svg>
        </div>

        <div className={styles.modalDescriptions}>
          <p className={styles.modalDescriptions__title}>Welcome to CGIG!</p>
          <p className={styles.modalDescriptions__subtitle}>
            Enjoy our resours
          </p>
        </div>
        <div className={styles.modalForm}>
          <div className={styles.modalForm__login}>
            <p>Name</p>
            <input
              type="text"
              placeholder="Alex"
              value={inputUsernameValue}
              onChange={handleUsernameChange}
            />
          </div>
          <div className={styles.modalForm__login}>
            <p style={{ color: isBusyCustomUserId ? "red" : "" }}>
              Custom user ID
            </p>
            <input
              type="text"
              placeholder="@cgig"
              value={inputCustomUserIdValue}
              onChange={handleCustomUserIdChange}
            />
          </div>
          <div className={styles.modalForm__login}>
            <p style={{ color: isUsedMail ? "red" : "" }}>E-mail</p>
            <input
              type="text"
              placeholder="email@gmail.com"
              value={inputEmailValue}
              onChange={handleEmailChange}
            />
          </div>

          <div className={styles.modalForm__login}>
            <p>Password</p>
            <input
              type="password"
              placeholder="********"
              value={inputPasswordValue}
              onChange={handlePasswordChange}
              onKeyPress={handlePasswordPress}
              onPaste={handlePasswordPaste}
            />
          </div>
          <div className={styles.modalForm__login}>
            <p>Return password</p>
            <input
              type="password"
              placeholder="********"
              value={inputRepeatPasswordValue}
              onChange={handleRepeatPasswordChange}
              onKeyPress={handlePasswordPress}
              onPaste={handleRepeatPasswordPaste}
            />
          </div>

          <button
            onClick={handleClick}
            disabled={!isBusyCustomUserId && !isUsedMail && !isApprovedPassword}
            className={styles.modalForm__btnLogin}
          >
            Registration
          </button>

          <p className={styles.modalForm__note}>
            Already registered? <Link to="/login">Sign in </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Registration;
