import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { getMyInfo } from "../../store/features/myProfileSlice";

import Header from "../Header";
import ProfileMyContent from "../ProfileMyContent";
import Footer from "../Footer";
import WidgetPageNavigation from "../mui/WidgetPageNavigation";
import WidgetProfileNavigation from "../mui/WidgetProfileNavigation";

const ProfileMe = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { isAuthorization } = useSelector((state) => state.authorization);

  useEffect(() => {
    dispatch(getMyInfo()).finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {isAuthorization ? (
        <div className="main">
          <Header />
          <div className="main__contant">
            <ProfileMyContent />
          </div>
          <Footer />
          <div className="buttons-navigate">
            <span>
              <WidgetPageNavigation />
            </span>
            <span>
              <WidgetProfileNavigation />
            </span>
          </div>
        </div>
      ) : (
        <Navigate to="/login" replace />
      )}
    </>
  );
};

export default ProfileMe;
