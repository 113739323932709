import React, { useCallback, useEffect, useRef, useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import image from "../assets/anime.jpg";
import styles from "../scss/components/uploadResourceItem.module.scss";

const UploadResourceItem = ({
  handlePreview,
  modelTitle,
  setModelTitle,
  modelTags,
  setModelTags,
}) => {
  const [searchActive, setSearchActive] = useState(false);
  const [isCheckedSwitch, setIsCheckedSwitch] = useState(false);
  const [isDropDownSelect, setIsDropDownSelect] = useState(false);
  const [isLoaded, setisLoaded] = useState(false);
  const handleInputChange = (event) => {
    const isInputEmpty = event.target.value.trim() === "";
    setSearchActive(!isInputEmpty);
  };
  const toggleSwitch = () => {
    setIsCheckedSwitch(!isCheckedSwitch);
  };
  const handleDropDownSelect = () => {
    setIsDropDownSelect(!isDropDownSelect);
  };
  const ItemType = {
    IMAGE: "image",
  };

  const DraggableImage = ({ img, onDelete, onDrop }) => {
    const [{ isDragging }, drag] = useDrag({
      type: ItemType.IMAGE,
      item: { img },
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult();
        if (item && dropResult) {
          onDrop(item.img);
        }
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    });

    return (
      <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }}>
        <img src={img} alt="Draggable item" className={styles.image} />
        <svg
          onClick={onDelete}
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M6 6L10 10M10 6L6 10M14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8Z"
            stroke="#F2F2F2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    );
  };
  const MainImageBlock = ({ img, onDelete, setMainImage }) => {
    const [, drop] = useDrop({
      accept: ItemType.IMAGE,
      drop: (item, monitor) => {
        if (monitor.canDrop()) {
          setMainImage(item.img);
        }
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    });

    return (
      <div ref={drop} className={styles.withContent__main}>
        <img src={img} alt="image" />
        <svg
          onClick={onDelete}
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M6 6L10 10M10 6L6 10M14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8Z"
            stroke="#F2F2F2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    );
  };

  const [selectedFiles, setSelectedFiles] = useState([]);
  const fileInputRef = useRef(null);
  const handleFileSelect = () => {
    fileInputRef.current.click();
  };
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  };
  const [toTopScroll, setToTopScroll] = useState(false);
  useEffect(() => {
    if (selectedFiles.length !== 0) {
      setisLoaded(true);
      scrollToTop();
    }
  }, [selectedFiles]);
  /*  useEffect(() => {
    if (isLoaded == false) {
      scrollToTop();
      setToTopScroll(true);
    }
  }, [isLoaded]); */

  const scrollToTop = () => {
    if (selectedFiles.length !== 0) {
      window.scrollTo({
        top: 30,
        behavior: "smooth",
      });
    }
  };

  const handleTitleChange = (event) => {
    setModelTitle(event.target.value);
  };

  const NotUploaded = ({
    fileInputRef,
    handleFileChange,
    handleInputChange,
    searchActive,
    setModelTitle,
    modelTitle,
    setModelTags,
    modelTags,
  }) => {
    const [inpuTitletValue, setInputTitleValue] = useState(modelTitle);
    const [inputTagstValue, setInputTagsValue] = useState("");
    useEffect(() => {
      setInputTitleValue(modelTitle);
    }, [modelTitle]);
  /*   useEffect(() => {
      setModelTags([...modelTags, inputTagstValue]);
    }, [inputTagstValue, modelTags]); */

    const handleTagInputChange = (e) => {
      setInputTagsValue(e.target.value);
    };

    return (
      <div className={styles.leftPart__notLoaded}>
        <div onClick={handleFileSelect} className={styles.leftPart__load}>
          <input
            type="file"
            id="avatar"
            name="avatar"
            accept="image/png, image/jpeg"
            style={{ display: "none" }}
            ref={fileInputRef}
            onChange={handleFileChange}
            multiple
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.58588 0.64426C10.0185 0.211679 10.7198 0.211679 11.1524 0.64426L17.0601 6.55195C17.4927 6.98453 17.4927 7.68588 17.0601 8.11847C16.6275 8.55105 15.9262 8.55105 15.4936 8.11847L11.4768 4.10172V13.4891C11.4768 14.1008 10.9809 14.5967 10.3691 14.5967C9.75737 14.5967 9.26144 14.1008 9.26144 13.4891V4.10172L5.2447 8.11847C4.81212 8.55105 4.11076 8.55105 3.67818 8.11847C3.2456 7.68588 3.2456 6.98453 3.67818 6.55195L9.58588 0.64426ZM1.50759 12.8737C2.11936 12.8737 2.61529 13.3696 2.61529 13.9814V17.3044H18.123V13.9814C18.123 13.3696 18.6189 12.8737 19.2307 12.8737C19.8424 12.8737 20.3384 13.3696 20.3384 13.9814V18.4121C20.3384 19.0239 19.8424 19.5198 19.2307 19.5198H1.50759C0.895833 19.5198 0.399902 19.0239 0.399902 18.4121V13.9814C0.399902 13.3696 0.895833 12.8737 1.50759 12.8737Z"
              fill="#616161"
            />
          </svg>
          <button>Select images</button>
          <p>JPEG, JPG, PNG or GIF up to 215 MB</p>
        </div>
        <div className={styles.leftPart__footer}>
          <input
            className={styles.leftPart__title}
            type="text"
            placeholder="Add a title"
            value={inpuTitletValue}
            onChange={(e) => setInputTitleValue(e.target.value)}
            onBlur={() => setModelTitle(inpuTitletValue)}
          />
          <div className={styles.leftPart__tag}>
            <p>Tags</p>
            <div>
              <span>
                Action-adventure
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    d="M8.54558 2.60613L9.39411 3.45466L3.45442 9.39436L2.60589 8.54583L8.54558 2.60613Z"
                    fill="#1C1D1B"
                  />
                  <path
                    d="M9.39411 8.54583L8.54558 9.39436L2.60589 3.45466L3.45442 2.60613L9.39411 8.54583Z"
                    fill="#1C1D1B"
                  />
                </svg>
              </span>
              <span>
                Anime
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    d="M8.54558 2.60613L9.39411 3.45466L3.45442 9.39436L2.60589 8.54583L8.54558 2.60613Z"
                    fill="#1C1D1B"
                  />
                  <path
                    d="M9.39411 8.54583L8.54558 9.39436L2.60589 3.45466L3.45442 2.60613L9.39411 8.54583Z"
                    fill="#1C1D1B"
                  />
                </svg>
              </span>
              <span>
                graphic design
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    d="M8.54558 2.60613L9.39411 3.45466L3.45442 9.39436L2.60589 8.54583L8.54558 2.60613Z"
                    fill="#1C1D1B"
                  />
                  <path
                    d="M9.39411 8.54583L8.54558 9.39436L2.60589 3.45466L3.45442 2.60613L9.39411 8.54583Z"
                    fill="#1C1D1B"
                  />
                </svg>
              </span>
              <span>
                Action-adventure
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    d="M8.54558 2.60613L9.39411 3.45466L3.45442 9.39436L2.60589 8.54583L8.54558 2.60613Z"
                    fill="#1C1D1B"
                  />
                  <path
                    d="M9.39411 8.54583L8.54558 9.39436L2.60589 3.45466L3.45442 2.60613L9.39411 8.54583Z"
                    fill="#1C1D1B"
                  />
                </svg>
              </span>
              <span>
                Action-adventure
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    d="M8.54558 2.60613L9.39411 3.45466L3.45442 9.39436L2.60589 8.54583L8.54558 2.60613Z"
                    fill="#1C1D1B"
                  />
                  <path
                    d="M9.39411 8.54583L8.54558 9.39436L2.60589 3.45466L3.45442 2.60613L9.39411 8.54583Z"
                    fill="#1C1D1B"
                  />
                </svg>
              </span>
              <span>
                Action-adventure
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    d="M8.54558 2.60613L9.39411 3.45466L3.45442 9.39436L2.60589 8.54583L8.54558 2.60613Z"
                    fill="#1C1D1B"
                  />
                  <path
                    d="M9.39411 8.54583L8.54558 9.39436L2.60589 3.45466L3.45442 2.60613L9.39411 8.54583Z"
                    fill="#1C1D1B"
                  />
                </svg>
              </span>
            </div>
          </div>
          <input
            className={styles.leftPart__search}
            type="text"
            placeholder="Start typing..."
            value={inputTagstValue}
            onChange={handleTagInputChange}
            onBlur={() => setInputTagsValue(inputTagstValue)}
          />
        </div>
        {searchActive && (
          <div className={styles.leftPart__searchDropDown}>
            <ul>
              <li>graphic designer</li>
              <li>graphic design</li>
              <li>graphics</li>
              <li>graphic</li>
              <li>game</li>
            </ul>
          </div>
        )}
      </div>
    );
  };

  const IsUploaded = () => {
    const [addedItems, setAddedItems] = useState(selectedFiles) || [];
    const complementFileInputRef = useRef(null);
    const [fileUrls, setFileUrls] = useState([]);
    const handleComplementFileChange = (event) => {
      const files = Array.from(event.target.files);

      // Фильтруем файлы, оставляя только те, которые не существуют в массиве addedItems
      const filteredFiles = files.filter((file) => {
        return !addedItems.some(
          (addedFile) => addedFile.lastModified === file.lastModified
        );
      });

      // Добавляем только уникальные файлы в состояние
      const newItems = [...addedItems, ...filteredFiles];
      const newUrls = filteredFiles.map((file) => URL.createObjectURL(file));

      setAddedItems(newItems);
      setFileUrls((prevUrls) => [...prevUrls, ...newUrls]);
    };

    const handleComplementFileSelect = () => {
      complementFileInputRef.current.click();
    };
    const [mainImage, setMainImage] = useState(
      addedItems.length > 0 ? URL.createObjectURL(addedItems[0]) : null
    );
    useEffect(() => {
      if (selectedFiles.length > 0) {
        setAddedItems(selectedFiles);
        setMainImage(URL.createObjectURL(selectedFiles[0]));
      } else {
        setAddedItems([]);
        setMainImage(null);
      }
    }, [selectedFiles]);

    const handleDeleteItem = (indexToRemove) => {
      const newItems = addedItems.filter((_, index) => index !== indexToRemove);
      const newUrls = fileUrls.filter((_, index) => index !== indexToRemove);

      setAddedItems(newItems);
      setFileUrls(newUrls);

      if (indexToRemove === 0 && newItems.length > 0) {
        setMainImage(URL.createObjectURL(newItems[0]));
      } else if (indexToRemove === 0 && newItems.length === 0) {
        setMainImage(null);
      }

      if (newItems.length < 1) {
        setisLoaded(false);
        setSelectedFiles([]);
      }
    };
    const handleDrop = useCallback((droppedImg) => {
      setMainImage(droppedImg);
      /* setAddedItems((currentItems) =>
        currentItems.filter((item) => item.img !== droppedImg)
      ); */
    }, []);

    return (
      <>
        <p className={styles.leftPart__note}>Add a model publication</p>
        <div className={styles.leftPart__uploaded}>
          <input
            className={styles.leftPart__title}
            type="text"
            value="Add a tittle"
            readOnly
          />
          <div className={styles.tagHeader}>
            <p>Tags</p>
            <div>
              <span>Red colour</span>
              <span>Not found</span>
              <span className={styles.tagHeader__active}>Add tag</span>
            </div>
          </div>
          <div className={styles.categoryItems}>
            <div>
              <div>
                <span>fighting</span>
                <img src={image} alt="image" />
              </div>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="10"
                viewBox="0 0 8 10"
                fill="none"
              >
                <path
                  d="M4.56 4.8259L7.88 1.34199C8.04 1.17409 8.04 0.922242 7.88 0.754342C7.72 0.586443 7.48 0.586443 7.32 0.754342L4 4.23825L0.68 0.754342C0.52 0.586443 0.28 0.586443 0.12 0.754342C-0.04 0.922242 -0.04 1.17409 0.12 1.34199L3.44 4.8259L0.12 8.3098C-0.04 8.4777 -0.04 8.72955 0.12 8.89745C0.2 8.9814 0.28 9.02338 0.4 9.02338C0.52 9.02338 0.6 8.9814 0.68 8.89745L4 5.41354L7.32 8.89745C7.4 8.9814 7.52 9.02338 7.6 9.02338C7.68 9.02338 7.8 8.9814 7.88 8.89745C8.04 8.72955 8.04 8.4777 7.88 8.3098L4.56 4.8259Z"
                  fill="white"
                />
              </svg>
            </div>
            <div>
              <div>
                <span>magic</span>
                <img src={image} alt="image" />
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="10"
                viewBox="0 0 8 10"
                fill="none"
              >
                <path
                  d="M4.56 4.8259L7.88 1.34199C8.04 1.17409 8.04 0.922242 7.88 0.754342C7.72 0.586443 7.48 0.586443 7.32 0.754342L4 4.23825L0.68 0.754342C0.52 0.586443 0.28 0.586443 0.12 0.754342C-0.04 0.922242 -0.04 1.17409 0.12 1.34199L3.44 4.8259L0.12 8.3098C-0.04 8.4777 -0.04 8.72955 0.12 8.89745C0.2 8.9814 0.28 9.02338 0.4 9.02338C0.52 9.02338 0.6 8.9814 0.68 8.89745L4 5.41354L7.32 8.89745C7.4 8.9814 7.52 9.02338 7.6 9.02338C7.68 9.02338 7.8 8.9814 7.88 8.89745C8.04 8.72955 8.04 8.4777 7.88 8.3098L4.56 4.8259Z"
                  fill="white"
                />
              </svg>
            </div>
            <div>
              <div>
                <span>stalker</span>
                <img src={image} alt="image" />
              </div>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="10"
                viewBox="0 0 8 10"
                fill="none"
              >
                <path
                  d="M4.56 4.8259L7.88 1.34199C8.04 1.17409 8.04 0.922242 7.88 0.754342C7.72 0.586443 7.48 0.586443 7.32 0.754342L4 4.23825L0.68 0.754342C0.52 0.586443 0.28 0.586443 0.12 0.754342C-0.04 0.922242 -0.04 1.17409 0.12 1.34199L3.44 4.8259L0.12 8.3098C-0.04 8.4777 -0.04 8.72955 0.12 8.89745C0.2 8.9814 0.28 9.02338 0.4 9.02338C0.52 9.02338 0.6 8.9814 0.68 8.89745L4 5.41354L7.32 8.89745C7.4 8.9814 7.52 9.02338 7.6 9.02338C7.68 9.02338 7.8 8.9814 7.88 8.89745C8.04 8.72955 8.04 8.4777 7.88 8.3098L4.56 4.8259Z"
                  fill="white"
                />
              </svg>
            </div>
            <div>
              <div>
                <span>anime</span>
                <img src={image} alt="image" />
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="10"
                viewBox="0 0 8 10"
                fill="none"
              >
                <path
                  d="M4.56 4.8259L7.88 1.34199C8.04 1.17409 8.04 0.922242 7.88 0.754342C7.72 0.586443 7.48 0.586443 7.32 0.754342L4 4.23825L0.68 0.754342C0.52 0.586443 0.28 0.586443 0.12 0.754342C-0.04 0.922242 -0.04 1.17409 0.12 1.34199L3.44 4.8259L0.12 8.3098C-0.04 8.4777 -0.04 8.72955 0.12 8.89745C0.2 8.9814 0.28 9.02338 0.4 9.02338C0.52 9.02338 0.6 8.9814 0.68 8.89745L4 5.41354L7.32 8.89745C7.4 8.9814 7.52 9.02338 7.6 9.02338C7.68 9.02338 7.8 8.9814 7.88 8.89745C8.04 8.72955 8.04 8.4777 7.88 8.3098L4.56 4.8259Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
          <DndProvider backend={HTML5Backend}>
            <div className={styles.withContent}>
              <MainImageBlock
                img={mainImage}
                setMainImage={setMainImage}
                onDelete={() => handleDeleteItem(0)}
              />
              <div className={styles.withContent__footer}>
                <div onClick={handleComplementFileSelect}>
                  <input
                    type="file"
                    id="complementAvatar"
                    name="complementAvatar"
                    accept="image/png, image/jpeg"
                    style={{ display: "none" }}
                    ref={complementFileInputRef}
                    onChange={handleComplementFileChange}
                    multiple
                  />
                  <span>
                    <span>+</span>
                    <p>Add images</p>
                  </span>
                </div>
                {addedItems.map((item, index) => {
                  const imageUrl = URL.createObjectURL(item);
                  return (
                    <DraggableImage
                      key={index}
                      img={imageUrl}
                      index={index}
                      onDelete={() => handleDeleteItem(index)}
                      onDrop={handleDrop}
                    />
                  );
                })}
              </div>
            </div>
          </DndProvider>
        </div>
      </>
    );
  };

  return (
    <div className={styles.uploadResourceItem}>
      <div className="container">
        <div
          onClick={() => (toTopScroll === true ? scrollToTop() : () => {})}
          className={styles.uploadResourceItem__wrapper}
        >
          <div className={styles.leftPart}>
            {isLoaded ? (
              <IsUploaded />
            ) : (
              <NotUploaded
                modelTitle={modelTitle}
                setModelTitle={setModelTitle}
                fileInputRef={fileInputRef}
                handleInputChange={handleInputChange}
                searchActive={searchActive}
                handleFileChange={handleFileChange}
              />
            )}
          </div>

          <div className={styles.rightPart}>
            <div className={styles.rightPart__btns}>
              <button onClick={handlePreview} className={styles.preview}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clipPath="url(#clip0_1740_13411)">
                    <path
                      d="M12 4.84668C7.41454 4.84668 3.25621 7.35543 0.187788 11.4303C-0.0625959 11.7641 -0.0625959 12.2305 0.187788 12.5644C3.25621 16.6442 7.41454 19.1529 12 19.1529C16.5855 19.1529 20.7438 16.6442 23.8122 12.5693C24.0626 12.2354 24.0626 11.769 23.8122 11.4352C20.7438 7.35543 16.5855 4.84668 12 4.84668ZM12.3289 17.0369C9.28506 17.2284 6.7714 14.7196 6.96287 11.6709C7.11998 9.1572 9.15741 7.11977 11.6711 6.96267C14.7149 6.7712 17.2286 9.27994 17.0371 12.3287C16.8751 14.8375 14.8377 16.8749 12.3289 17.0369ZM12.1767 14.7098C10.537 14.8129 9.18196 13.4628 9.28997 11.8231C9.37343 10.468 10.4732 9.37322 11.8282 9.28485C13.4679 9.18175 14.823 10.5319 14.7149 12.1716C14.6266 13.5316 13.5268 14.6264 12.1767 14.7098Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1740_13411">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
              <button className={styles.publication}>Publish now</button>
            </div>

            <div className={styles.rightPart__contant}>
              <div className={styles.rightPart__items}>
                <span className={styles.rightPart__sectionTitle}>
                  Common info
                </span>

                <div className={styles.rightPart__item}>
                  <div className={styles.rightPart__itemTitle}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M9.99999 2.5V5.15625M6.5 10.5V13.5M8 12H5M13.4921 11H13.5M12.4921 13H12.5M14.5 13H14.5079M6.26259 7.8125H13.7373C14.3409 7.8125 14.9385 7.92701 15.4961 8.14949C16.0537 8.37197 16.5604 8.69807 16.9872 9.10916C17.4139 9.52026 17.7525 10.0083 17.9834 10.5454C18.2144 11.0825 18.3333 11.6582 18.3333 12.2396C18.3333 12.821 18.2144 13.3966 17.9834 13.9338C17.7525 14.4709 17.4139 14.9589 16.9872 15.37C16.5604 15.7811 16.0537 16.1072 15.4961 16.3297C14.9385 16.5522 14.3409 16.6667 13.7373 16.6667H6.26259C5.65904 16.6667 5.0614 16.5522 4.50379 16.3297C3.94618 16.1072 3.43953 15.7811 3.01275 15.37C2.58598 14.9589 2.24744 14.4709 2.01647 13.9338C1.7855 13.3966 1.66663 12.821 1.66663 12.2396C1.66663 11.6582 1.7855 11.0825 2.01647 10.5454C2.24744 10.0083 2.58598 9.52026 3.01275 9.10916C3.43953 8.69807 3.94618 8.37197 4.50379 8.14949C5.0614 7.92701 5.65904 7.8125 6.26259 7.8125Z"
                        stroke="#828282"
                        strokeWidth="1.2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span>From game:</span>
                  </div>
                  <input type="text" placeholder="Start typing..." />
                </div>
                <div className={styles.rightPart__item}>
                  <div className={styles.rightPart__itemTitle}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M14.5 7.5H10M7.5 5.5V7.5M7.5 9.5V7.5M5.5 7.5H7.5M10 12.5H5.5M12.5 10.5V12.5M12.5 14.5V12.5M14.5 12.5H12.5M4 17H16C16.5523 17 17 16.5523 17 16V4C17 3.44772 16.5523 3 16 3H4C3.44772 3 3 3.44772 3 4V16C3 16.5523 3.44772 17 4 17Z"
                        stroke="#828282"
                        strokeWidth="1.2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span>Technology:</span>
                  </div>
                  <div className={styles.dropDownList}>
                    <div
                      onClick={handleDropDownSelect}
                      className={`${styles.rightPart__dropDownMain} ${
                        isDropDownSelect
                          ? styles.rightPart__dropDownMainActive
                          : ""
                      }`}
                    >
                      <span>Choose from the list one and more</span>
                      {isDropDownSelect ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M2 14L10 6L18 14"
                            stroke="#F2F2F2"
                            strokeWidth="1.8"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M18 6L10 14L2 6"
                            stroke="#616161"
                            strokeWidth="1.8"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                    </div>
                    {isDropDownSelect && (
                      <div className={styles.rightPart__dropDownChild}>
                        <ul>
                          <li onClick={() => setIsDropDownSelect(false)}>
                            Version 2 0.3b (2020 feb 10)
                          </li>
                          <li onClick={() => setIsDropDownSelect(false)}>
                            Version 2 0.4b (2020 feb 8)
                          </li>
                          <li onClick={() => setIsDropDownSelect(false)}>
                            Version 2 0.6b (2020 feb 9)
                          </li>
                          <li onClick={() => setIsDropDownSelect(false)}>
                            Version 2 0.1b (2020 feb 12)
                          </li>
                          <li onClick={() => setIsDropDownSelect(false)}>
                            Version 2 0.1b (2023 feb 2)
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.rightPart__item}>
                  <div className={styles.rightPart__itemTitle}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M9 17L4 17L4 3L6.5 3M9 17L16 16.5L16 4.5L9 3M9 17L9 3M9 3L6.5 3M6.5 14.5L6.5 14.4913M6.5 3L6.5 12"
                        stroke="#828282"
                        strokeWidth="1.2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span>From platform:</span>
                  </div>
                  <span className={styles.rightPart__itemSubtitle}>
                    Select a few from the list
                  </span>
                  <div className={styles.platformItems}>
                    <span className={styles.platformItemsSelect}>xone</span>
                    <span className={styles.platformItemsSelect}>xb360</span>
                    <span>PS3</span>
                    <span>PC</span>
                    <span>VITA</span>
                    <span>NES</span>
                    <span>PS4</span>
                    <span>IPhone</span>
                    <span>Android</span>
                    <span>PS3</span>
                    <span>PC</span>
                    <span>NES</span>
                    <span>PS4</span>
                    <span>IPhone</span>
                    <span>Android</span>
                    <span>PS3</span>
                    <span>PC</span>
                    <span>VITA</span>
                    <span>NES</span>
                    <span>PS4</span>
                    <span>IPhone</span>
                    <span>Android</span>
                  </div>
                </div>
                <div className={styles.rightPart__item}>
                  <div className={styles.rightPart__itemTitle}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M11.5 4.9C11.1686 4.9 10.9 5.16863 10.9 5.5C10.9 5.83137 11.1686 6.1 11.5 6.1V4.9ZM13.5 6.1C13.8314 6.1 14.1 5.83137 14.1 5.5C14.1 5.16863 13.8314 4.9 13.5 4.9V6.1ZM13.5 9.1C13.8314 9.1 14.1 8.83137 14.1 8.5C14.1 8.16863 13.8314 7.9 13.5 7.9V9.1ZM11.5 7.9C11.1686 7.9 10.9 8.16863 10.9 8.5C10.9 8.83137 11.1686 9.1 11.5 9.1V7.9ZM6.5 10.9C6.16863 10.9 5.9 11.1686 5.9 11.5C5.9 11.8314 6.16863 12.1 6.5 12.1V10.9ZM13.5 12.1C13.8314 12.1 14.1 11.8314 14.1 11.5C14.1 11.1686 13.8314 10.9 13.5 10.9V12.1ZM13.5 15.1C13.8314 15.1 14.1 14.8314 14.1 14.5C14.1 14.1686 13.8314 13.9 13.5 13.9V15.1ZM6.5 13.9C6.16863 13.9 5.9 14.1686 5.9 14.5C5.9 14.8314 6.16863 15.1 6.5 15.1V13.9ZM3.5 2.5V1.9C3.16863 1.9 2.9 2.16863 2.9 2.5H3.5ZM16.5 2.5H17.1C17.1 2.16863 16.8314 1.9 16.5 1.9V2.5ZM16.5 17.5V18.1C16.8314 18.1 17.1 17.8314 17.1 17.5H16.5ZM3.5 17.5H2.9C2.9 17.8314 3.16863 18.1 3.5 18.1V17.5ZM6.5 5.5V4.9H5.9V5.5H6.5ZM9.5 5.5H10.1V4.9H9.5V5.5ZM9.5 8.5V9.1H10.1V8.5H9.5ZM6.5 8.5H5.9V9.1H6.5V8.5ZM11.5 6.1H13.5V4.9H11.5V6.1ZM13.5 7.9H11.5V9.1H13.5V7.9ZM6.5 12.1H13.5V10.9H6.5V12.1ZM13.5 13.9H6.5V15.1H13.5V13.9ZM3.5 3.1H16.5V1.9H3.5V3.1ZM15.9 2.5V17.5H17.1V2.5H15.9ZM16.5 16.9H3.5V18.1H16.5V16.9ZM4.1 17.5V2.5H2.9V17.5H4.1ZM6.5 6.1H9.5V4.9H6.5V6.1ZM8.9 5.5V8.5H10.1V5.5H8.9ZM9.5 7.9H6.5V9.1H9.5V7.9ZM7.1 8.5V5.5H5.9V8.5H7.1Z"
                        fill="#828282"
                      />
                    </svg>
                    <span>Model copyrights:</span>
                  </div>
                  <input type="text" placeholder="Start typing..." />
                </div>
                <div className={styles.rightPart__item}>
                  <div className={styles.rightPart__itemTitle}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M4 17.5H3.4V18.1H4V17.5ZM11.399 13.7207C11.7256 13.7765 12.0356 13.5569 12.0914 13.2303C12.1472 12.9037 11.9277 12.5936 11.601 12.5378L11.399 13.7207ZM10 18.1C10.3314 18.1 10.6 17.8314 10.6 17.5C10.6 17.1686 10.3314 16.9 10 16.9V18.1ZM17 10.5L17.4243 10.0757L17 9.65147L16.5757 10.0757L17 10.5ZM19 12.5L19.4243 12.9243L19.8485 12.5L19.4243 12.0757L19 12.5ZM14 17.5V18.1H14.2485L14.4243 17.9243L14 17.5ZM12 17.5H11.4V18.1H12V17.5ZM12 15.5L11.5757 15.0757L11.4 15.2515V15.5H12ZM7.26667 5.83333C7.26667 4.32376 8.49042 3.1 10 3.1V1.9C7.82768 1.9 6.06667 3.66101 6.06667 5.83333H7.26667ZM10 3.1C11.5096 3.1 12.7333 4.32376 12.7333 5.83333H13.9333C13.9333 3.66101 12.1723 1.9 10 1.9V3.1ZM6.06667 6.5C6.06667 8.67232 7.82768 10.4333 10 10.4333V9.23333C8.49042 9.23333 7.26667 8.00958 7.26667 6.5H6.06667ZM10 10.4333C12.1723 10.4333 13.9333 8.67232 13.9333 6.5H12.7333C12.7333 8.00958 11.5096 9.23333 10 9.23333V10.4333ZM12.7333 5.83333V6.5H13.9333V5.83333H12.7333ZM6.06667 5.83333V6.5H7.26667V5.83333H6.06667ZM4.6 17.5C4.6 16.0097 5.36177 15.0636 6.42535 14.4591C7.51751 13.8384 8.90606 13.6 10 13.6V12.4C8.77093 12.4 7.15948 12.6616 5.8324 13.4159C4.47672 14.1864 3.4 15.4903 3.4 17.5H4.6ZM11.601 12.5378C11.0397 12.4419 10.4929 12.4 10 12.4V13.6C10.427 13.6 10.9062 13.6365 11.399 13.7207L11.601 12.5378ZM4 18.1H10V16.9H4V18.1ZM16.5757 10.9243L18.5757 12.9243L19.4243 12.0757L17.4243 10.0757L16.5757 10.9243ZM18.5757 12.0757L13.5757 17.0757L14.4243 17.9243L19.4243 12.9243L18.5757 12.0757ZM14 16.9H12V18.1H14V16.9ZM12.6 17.5V15.5H11.4V17.5H12.6ZM12.4243 15.9243L17.4243 10.9243L16.5757 10.0757L11.5757 15.0757L12.4243 15.9243Z"
                        fill="#828282"
                      />
                    </svg>
                    <span>Original author:</span>
                  </div>
                  <input type="text" placeholder="Add author name" />
                </div>
                <div className={styles.rightPart__item}>
                  <div className={styles.rightPart__itemTitle}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M10.5 10H14.5M11.5 13H14.5M2.5 4H5.5V5C5.5 5.55228 5.94772 6 6.5 6H7.5C8.05228 6 8.5 5.55228 8.5 5V4H11.5V5C11.5 5.55228 11.9477 6 12.5 6H13.5C14.0523 6 14.5 5.55228 14.5 5V4H17.5V16H2.5V4ZM5.5 13L6.5 12H7.5L8.5 13H5.5ZM7.5 9.5V9.5C7.5 9.77614 7.27614 10 7 10V10C6.72386 10 6.5 9.77614 6.5 9.5V9.5C6.5 9.22386 6.72386 9 7 9V9C7.27614 9 7.5 9.22386 7.5 9.5Z"
                        stroke="#828282"
                        strokeWidth="1.2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span>License:</span>
                  </div>
                  <div className={styles.dropDownList}>
                    <div
                      /* onClick={handleDropDownSelect} */ className={
                        styles.rightPart__dropDownMain
                      }
                    >
                      <span>Choose from the list</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M14.4 4.7998L8.00002 11.1998L1.60003 4.7998"
                          stroke="#F2F2F2"
                          strokeWidth="1.8"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    {/*  {isDropDownSelect && (
                    <div className={styles.rightPart__dropDownChild}>
                      <ul>
                        <li onClick={() => setIsDropDownSelect(false)}>
                          Version 2 0.3b (2020 feb 10)
                        </li>
                        <li onClick={() => setIsDropDownSelect(false)}>
                          Version 2 0.4b (2020 feb 8)
                        </li>
                        <li onClick={() => setIsDropDownSelect(false)}>
                          Version 2 0.6b (2020 feb 9)
                        </li>
                        <li onClick={() => setIsDropDownSelect(false)}>
                          Version 2 0.1b (2020 feb 12)
                        </li>
                        <li onClick={() => setIsDropDownSelect(false)}>
                          Version 2 0.1b (2023 feb 2)
                        </li>
                      </ul>
                    </div>
                  )} */}
                  </div>
                </div>
              </div>
              <div className={styles.rightPart__items}>
                <span className={styles.rightPart__sectionTitle}>
                  Model properties
                </span>
                <div className={styles.rightPart__item}>
                  <div className={styles.rightPart__itemTitle}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M12 2.5L12.5 2.5L16.6667 6.66667V7M12 2.5L3.33337 2.5V17.5H16.6667V16.5M12 2.5V7H16.6667M16.6667 7V9.5M10.5 11.5H17.5V14.5H10.5V11.5Z"
                        stroke="#828282"
                        strokeWidth="1.2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span>Format(s):</span>
                  </div>
                  <div className={styles.dropDownList}>
                    <div
                      /* onClick={handleDropDownSelect} */
                      className={styles.rightPart__dropDownMain}
                    >
                      <span>Choose from the list</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M14.4 4.7998L8.00002 11.1998L1.60003 4.7998"
                          stroke="#F2F2F2"
                          strokeWidth="1.8"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    {/* {isDropDownSelect && (
                    <div className={styles.rightPart__dropDownChild}>
                      <ul>
                        <li onClick={() => setIsDropDownSelect(false)}>
                          Version 2 0.3b (2020 feb 10)
                        </li>
                        <li onClick={() => setIsDropDownSelect(false)}>
                          Version 2 0.4b (2020 feb 8)
                        </li>
                        <li onClick={() => setIsDropDownSelect(false)}>
                          Version 2 0.6b (2020 feb 9)
                        </li>
                        <li onClick={() => setIsDropDownSelect(false)}>
                          Version 2 0.1b (2020 feb 12)
                        </li>
                        <li onClick={() => setIsDropDownSelect(false)}>
                          Version 2 0.1b (2023 feb 2)
                        </li>
                      </ul>
                    </div>
                  )} */}
                  </div>
                </div>
                <div className={styles.itemSwitch}>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M10 6.5V10.5M10 10.5L12 11.5L12.5 17.5M10 10.5L8 11.5L7.5 17.5M14.5 10.5L12.5 6.5L10 6L7.5 6.5L5.5 10.5M10 4.16667C10.221 4.16667 10.433 4.07887 10.5893 3.92259C10.7455 3.76631 10.8333 3.55435 10.8333 3.33333C10.8333 3.11232 10.7455 2.90036 10.5893 2.74408C10.433 2.5878 10.221 2.5 10 2.5C9.77899 2.5 9.56702 2.5878 9.41074 2.74408C9.25446 2.90036 9.16667 3.11232 9.16667 3.33333C9.16667 3.55435 9.25446 3.76631 9.41074 3.92259C9.56702 4.07887 9.77899 4.16667 10 4.16667Z"
                        stroke="#828282"
                        strokeWidth="1.2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <p>Skeleton/Armature:</p>
                  </div>
                  <label className={styles.switch}>
                    <input
                      type="checkbox"
                      checked={isCheckedSwitch}
                      onChange={toggleSwitch}
                    />
                    <span className={`${styles.slider} ${styles.round}`}></span>
                  </label>
                </div>
                <div className={styles.selection}>
                  <div className={styles.rightPart__itemTitle}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                    >
                      <path
                        d="M3.75 17.1666L11.6667 9.24992L17.5 15.0833M7.5 7.99992C7.5 8.46016 7.1269 8.83325 6.66667 8.83325C6.20643 8.83325 5.83333 8.46016 5.83333 7.99992C5.83333 7.53968 6.20643 7.16659 6.66667 7.16659C7.1269 7.16659 7.5 7.53968 7.5 7.99992ZM2.5 3.83325H17.5V17.1666H2.5V3.83325Z"
                        stroke="#828282"
                        strokeWidth="1.2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span>Number of textures:</span>
                  </div>
                  <div className={styles.selection__items}>
                    <div className={styles.selection__item}>
                      <label className={styles.customRadio}>
                        <input type="radio" name="radio" />
                        <span className={styles.checkmark}></span>
                      </label>
                      <span> &gt; 512</span>
                    </div>
                    <div className={styles.selection__item}>
                      <label className={styles.customRadio}>
                        <input type="radio" name="radio" />
                        <span className={styles.checkmark}></span>
                      </label>
                      <span>1024</span>
                    </div>
                    <div className={styles.selection__item}>
                      <label className={styles.customRadio}>
                        <input type="radio" name="radio" />
                        <span className={styles.checkmark}></span>
                      </label>
                      <span>2048</span>
                    </div>
                    <div className={styles.selection__item}>
                      <label className={styles.customRadio}>
                        <input type="radio" name="radio" />
                        <span className={styles.checkmark}></span>
                      </label>
                      <span>4096+</span>
                    </div>
                    <div className={styles.selection__item}>
                      <label className={styles.customRadio}>
                        <input type="radio" name="radio" />
                        <span className={styles.checkmark}></span>
                      </label>
                      <span>traditional</span>
                    </div>
                    <div className={styles.selection__item}>
                      <label className={styles.customRadio}>
                        <input type="radio" name="radio" />
                        <span className={styles.checkmark}></span>
                      </label>
                      <span>PBR</span>
                    </div>
                  </div>
                </div>
                <div className={styles.selection}>
                  <div className={styles.rightPart__itemTitle}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                    >
                      <path
                        d="M2.5 10.5H4M4 10.5H5.5M4 10.5V12M4 10.5V9M13 6H14.5M14.5 6H16M14.5 6V7.5M14.5 6V4.5M11 16H12.5M12.5 16H14M12.5 16V17.5M12.5 16V14.5"
                        stroke="#828282"
                        strokeWidth="1.2"
                        strokeLinecap="round"
                      />
                    </svg>
                    <span>Model quality:</span>
                  </div>
                  <div className={styles.selection__items}>
                    <div className={styles.selection__item}>
                      <label className={styles.customRadio}>
                        <input type="radio" name="radio" />
                        <span className={styles.checkmark}></span>
                      </label>
                      <span>&gt;500 (LOW-POLY)</span>
                    </div>
                    <div className={styles.selection__item}>
                      <label className={styles.customRadio}>
                        <input type="radio" name="radio" />
                        <span className={styles.checkmark}></span>
                      </label>
                      <span>501-3000 (MED-POLY)</span>
                    </div>
                    <div className={styles.selection__item}>
                      <label className={styles.customRadio}>
                        <input type="radio" name="radio" />
                        <span className={styles.checkmark}></span>
                      </label>
                      <span>3001-10000 (MED-POLY+)</span>
                    </div>
                    <div className={styles.selection__item}>
                      <label className={styles.customRadio}>
                        <input type="radio" name="radio" />
                        <span className={styles.checkmark}></span>
                      </label>
                      <span>10000-300000 (HIGH-POLY)</span>
                    </div>
                    <div className={styles.selection__item}>
                      <label className={styles.customRadio}>
                        <input type="radio" name="radio" />
                        <span className={styles.checkmark}></span>
                      </label>
                      <span>300001+ (ULTRA-HIGH-POLY)</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.rightPart__items}>
                <span className={styles.rightPart__sectionTitle}>
                  Description
                </span>
                <div className={styles.description}>
                  <textarea
                    name=""
                    id=""
                    placeholder="Type a description..."
                  ></textarea>
                </div>
              </div>
              <div className={styles.rightPart__items}>
                <span className={styles.rightPart__sectionTitle}>License</span>
                <div className={styles.description}>
                  <textarea
                    name=""
                    id=""
                    placeholder="Specify the usage rights..."
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadResourceItem;
