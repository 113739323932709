import { createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import {
  setUserId,
  setUserCustomId,
  setUsername,
  setUserRole,
  setUserAvatar,
  setPublishWorks,
  setPublishWorksCount,
  setDraftWorks,
} from "./myProfileSlice";

import { setIsUploader } from "./checkerSlice";

const url = process.env.REACT_APP_SERVER_API;
const proxy = process.env.REACT_APP_PROXY_URL;

const initialState = {
  isLogin: false,
  isRegistration: false,
  isAuthorization: false,
  stayLogin: false,
  login: "",
  password: "",
  user: null,
};

const authorizationSlice = createSlice({
  name: "authorization",
  initialState,
  reducers: {
    setLoginOpen: (state) => {
      state.isLogin = true;
      state.isRegistration = false;
    },
    setLoginClose: (state) => {
      state.isLogin = false;
    },
    setRegistrationOpen: (state) => {
      state.isRegistration = true;
      state.isLogin = false;
    },
    setRegistrationClose: (state) => {
      state.isRegistration = false;
    },

    setLogin: (state, action) => {
      state.login = action.payload;
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    },
    setAuthorization: (state, action) => {
      state.isAuthorization = action.payload;
    },
    setStayLogin: (state) => {
      state.stayLogin = true;
    },
    logout: (state) => {
      localStorage.removeItem("jwt");
      state.userData = null;
      state.isAuthorization = false;
    },
  },
});

export const {
  setLoginOpen,
  setLoginClose,
  setRegistrationOpen,
  setRegistrationClose,
  setUserData,
  setLogin,
  setPassword,
  setAuthorization,
  setStayLogin,
  logout,
} = authorizationSlice.actions;
export default authorizationSlice.reducer;

export const loginUser = () => async (dispatch, getState) => {
  const { login, password, stayLogin } = getState().authorization;
  const queryParams = { login, password };

  try {
    const response = await axios.post(`${proxy}/api/verify-me`, queryParams);
    if (response.status === 200) {
      if (stayLogin) {
        localStorage.setItem("jwt", JSON.stringify(response.data));
        sessionStorage.removeItem("jwt");
      } else {
        sessionStorage.setItem("jwt", JSON.stringify(response.data));
        localStorage.removeItem("jwt");
      }

      dispatch(setAuthorization(true));
      dispatch(setLogin(""));
      dispatch(setPassword(""));
      dispatch(setLoginClose());
      window.location.href = "/";
    } else {
      dispatch(setLogin(""));
      dispatch(setPassword(""));
    }
  } catch (error) {
    dispatch(setLogin(""));
    dispatch(setPassword(""));
  }
};
export const getUserInfo = () => async (dispatch, getState) => {
  const storedToken =
    localStorage.getItem("jwt") || sessionStorage.getItem("jwt");
  const token = storedToken ? JSON.parse(storedToken) : null;

  if (!token) {
    return;
  }

  try {
    const response = await axios.get(
      `${proxy}/api/get-me/${encodeURIComponent(token)}`
    );

    const { data } = response.data;
    const jwtToken = data;
    const decodedData = jwtDecode(jwtToken);
    dispatch(setAuthorization(true));
    dispatch(setUserId(decodedData.id));
    dispatch(setUserCustomId(decodedData.userID));
    dispatch(setUsername(decodedData.username));
    dispatch(setUserRole(decodedData.role.name));
    dispatch(setUserAvatar(decodedData.avatar.url));
  } catch (error) {
  }
};
export const performLogout = () => async (dispatch) => {
  dispatch(logout());
  dispatch(setIsUploader(false));
  dispatch(setUserId(null));
  dispatch(setUserCustomId(null));
  dispatch(setUsername(null));
  dispatch(setUserRole(null));
  dispatch(setUserAvatar(null));
  dispatch(setPublishWorks({ count: 0, data: null }));
  dispatch(setDraftWorks({ count: 0, data: null }));
};
