import { createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { setAuthorization, setUserData } from "./authorizationSlice";

const proxy = process.env.REACT_APP_PROXY_URL;

export const getMyInfo = () => async (dispatch, getState) => {
  const storedToken =
    localStorage.getItem("jwt") || sessionStorage.getItem("jwt");
  const token = storedToken ? JSON.parse(storedToken) : null;

  if (!token) {
    return;
  }

  try {
    const response = await axios.get(
      `${proxy}/api/get-profile-me/${encodeURIComponent(token)}`
    );
    const { data } = response.data;
    const jwtToken = data;
    const decodedData = jwtDecode(jwtToken);
    dispatch(setAuthorization(true));
    dispatch(setUserId(decodedData.id));
    dispatch(setUserCustomId(decodedData.userID));
    dispatch(setUsername(decodedData.username));
    dispatch(setUserRole(decodedData.role.name));
    dispatch(setUserAvatar(decodedData.avatar.url));
  } catch (error) {
    console.error("Ошибка при запросе к прокси-серверу:", error.message);
  }
};
export const getMyDraftWork = () => async (dispatch, getState) => {
  const storedToken =
    localStorage.getItem("jwt") || sessionStorage.getItem("jwt");
  const token = storedToken ? JSON.parse(storedToken) : null;

  if (!token) {
    return;
  }

  try {
    const response = await axios.get(
      `${proxy}/api/get-my-draft-work/${encodeURIComponent(token)}`
    );
    const { data } = response.data;
    const decryptedData = jwtDecode(data);

    const items = decryptedData.data;
    const count = decryptedData.data.length;
    dispatch(setDraftWorks({ count, items }));
  } catch (error) {
    console.error("Ошибка при запросе к прокси-серверу:", error.message);
  }
};

const initialNavigation = [
  { name: "publications" },
  { name: "drafts" },
  { name: "mentions" },
  { name: "comments" },
  { name: "chats" },
  { name: "saved" },
  { name: "history" },
  { name: "about" },
];
const myProfileSlice = createSlice({
  name: "my-profile",
  initialState: {
    userId: null,
    userCustomId: null,
    username: null,
    userRole: null,
    userAvatar: null,
    publishWorks: {
      count: 0,
      data: null,
    },
    draftWorks: {
      count: 0,
      data: null,
    },
    navigation: initialNavigation,
    navTab: initialNavigation[0].name,
    previousNavTab: "",
  },
  reducers: {
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setUserCustomId: (state, action) => {
      state.userCustomId = action.payload;
    },
    setUsername: (state, action) => {
      state.username = action.payload;
    },
    setUserRole: (state, action) => {
      state.userRole = action.payload;
    },
    setUserAvatar: (state, action) => {
      state.userAvatar = action.payload;
    },
    setPublishWorks: (state, action) => {
      state.publishWorks = {
        count: action.payload.count,
        data: action.payload.items,
      };
    },
    setPublishWorksCount: (state, action) => {
      state.publishWorks.count = action.payload.count;
    },
    setDraftWorks: (state, action) => {
      state.draftWorks = {
        count: action.payload.count,
        data: action.payload.items,
      };
    },
    setDraftWorksCount: (state, action) => {
      state.draftWorks.count = action.payload.count;
    },
    toggleNavTab: (state, action) => {
      const tab = action.payload;
      if (tab !== state.previousNavTab) {
        state.previousNavTab = state.navTab;
        state.navTab = tab;
      } else {
        state.navTab = tab;
        state.previousNavTab = tab;
      }
    },
  },
});

export const {
  setUserId,
  setUserCustomId,
  setUsername,
  setUserRole,
  setUserAvatar,
  setPublishWorks,
  setPublishWorksCount,
  setDraftWorks,
  setDraftWorksCount,
  toggleNavTab,
} = myProfileSlice.actions;
export default myProfileSlice.reducer;
