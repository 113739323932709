import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import axios from "axios";
import ProgressiveImage from "react-progressive-graceful-image";

import noImage from "../assets/noImage.png";
import { ReactComponent as ArrowSeparatorIcon } from "../assets/icon/arrowSeparatorIcon.svg";
import { ReactComponent as ArrowChevronCarousel } from "../assets/icon/arrowChevronCarousel.svg";

import styles from "../scss/components/gamesCarousel.module.scss";

const url = process.env.REACT_APP_SERVER_API;
const imageUrl = process.env.REACT_APP_IMAGE_URL;

const GamesCarousel = () => {
  const [gamesData, setGamesData] = useState([]);
  const [page, setPage] = useState(1);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderLength, setSliderLength] = useState(0);
  const sliderRef = useRef();
  useEffect(() => {
    const fetchData = () => {
      axios
        .get(
          `${url}/api/games?pagination[page]=${page}&pagination[pageSize]=15&fields[0]=title&populate[cover][fields][0]=url&populate[cover][fields][1]=name`
        )
        .then((res) => {
          const games = res.data.data;
          if (games.length > 0) {
            setGamesData((prevData) => {
              const filteredData = prevData.filter(
                (prev) => !games.some((newGame) => newGame.id === prev.id)
              );
              return [...filteredData, ...games];
            });
            if (games.length === 100) {
              setPage((prevPage) => prevPage + 1);
            }
          } else {
          }
        })
        .catch((error) => {
          console.error("Ошибка при запросе данных:", error);
        });
    };

    fetchData();
  }, [page]);
  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2.7,
    slidesToScroll: 1,
    nextArrow: null,
    prevArrow: null,
    draggable: true,
    swipe: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.3,
          slidesToScroll: 2,
          dots: false,
          infinite: false,
          swipe: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          swipe: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.25,
          slidesToScroll: 1,
          swipe: true,
        },
      },
    ],
    afterChange: (current) => {
      setCurrentSlide(current);
    },
  };
  const goToNext = () => {
    if (!isEnd) {
      sliderRef.current.slickNext();
    }
  };
  const goToPrev = () => {
    if (!isStart) {
      sliderRef.current.slickPrev();
    }
  };
  useEffect(() => {
    const updateSlidesToShow = () => {
      const width = window.innerWidth;
      if (width < 480) {
        setSlidesToShow(sliderSettings.responsive[2].settings.slidesToShow);
      } else if (width >= 480 && width < 768) {
        setSlidesToShow(sliderSettings.responsive[1].settings.slidesToShow);
      } else if (width >= 768 && width < 1024) {
        setSlidesToShow(sliderSettings.responsive[0].settings.slidesToShow);
      } else {
        setSlidesToShow(sliderSettings.slidesToShow);
      }
    };
    window.addEventListener("resize", updateSlidesToShow);
    updateSlidesToShow();
    return () => {
      window.removeEventListener("resize", updateSlidesToShow);
    };
  }, []);

  const [slidesToShow, setSlidesToShow] = useState(sliderSettings.slidesToShow);
  useEffect(() => {
    if (sliderRef.current) {
      setSliderLength(sliderRef.current.props.children.length);
    }
  }, [sliderRef.current]);
  const isLargeScreen = window.innerWidth > 768;
  const itemsRendered = [];
  const maxItems = 15;
  let itemsCount = 0;
  if (isLargeScreen) {
    if (gamesData.length > 0 && itemsCount < maxItems) {
      const firstItem = gamesData[0];
      const firstBlock = (
        <Link
          to={{
            pathname: `/game/${firstItem.attributes.title}`,
            search: `?id=${firstItem.id}`,
          }}
          key={0}
          className={styles.itemFirst}
        >
          <div className={styles.item}>
            <ProgressiveImage
              src={
                firstItem?.attributes?.cover?.data?.attributes?.url
                  ? `${imageUrl}${firstItem.attributes.cover.data.attributes.url}`
                  : noImage
              }
              placeholder={
                firstItem?.attributes?.cover?.data?.attributes?.url
                  ? `${imageUrl}${firstItem.attributes.cover.data.attributes.url}`
                  : noImage
              }
            >
              {(src, loading) => (
                <img
                  src={src}
                  alt={
                    firstItem?.attributes?.cover?.data?.attributes?.name ||
                    "noImage"
                  }
                  style={{
                    filter: loading ? "blur(70px)" : "none",
                    transition: "filter 0.3s ease-out",
                  }}
                />
              )}
            </ProgressiveImage>
          </div>
        </Link>
      );
      itemsRendered.push(firstBlock);
      itemsCount += 1;
    }
    for (let i = 1; i < gamesData.length && itemsCount < maxItems; i += 2) {
      const currentItems = gamesData.slice(i, i + 2);
      const currentBlock = (
        <div key={i} className={styles.itemOther}>
          {currentItems.map((item, index) => (
            <Link
              to={{
                pathname: `/game/${item.attributes.title}`,
                search: `?id=${item.id}`,
              }}
              className={styles.item}
              key={index}
            >
              <ProgressiveImage
                src={
                  item?.attributes?.cover?.data?.attributes?.url
                    ? `${imageUrl}${item.attributes.cover.data.attributes.url}`
                    : noImage
                }
                placeholder={
                  item?.attributes?.cover?.data?.attributes?.url
                    ? `${imageUrl}${item.attributes.cover.data.attributes.url}`
                    : noImage
                }
              >
                {(src, loading) => (
                  <img
                    src={src}
                    alt={
                      item?.attributes?.cover?.data?.attributes?.name ||
                      "noImage"
                    }
                    style={{
                      filter: loading ? "blur(70px)" : "none",
                      transition: "filter 0.3s ease-out",
                    }}
                  />
                )}
              </ProgressiveImage>
            </Link>
          ))}
        </div>
      );
      itemsRendered.push(currentBlock);
      itemsCount += currentItems.length;
    }
  } else {
    for (let i = 0; i < gamesData.length && itemsCount < maxItems; i++) {
      const item = gamesData[i];
      const currentBlock = (
        <Link
          to={{
            pathname: `/game/${item.attributes.title}`,
            search: `?id=${item.id}`,
          }}
          key={i}
          className={styles.itemFirst}
        >
          <div className={styles.item}>
            <ProgressiveImage
              src={
                item?.attributes?.cover?.data?.attributes?.url
                  ? `${imageUrl}${item.attributes.cover.data.attributes.url}`
                  : noImage
              }
              placeholder={
                item?.attributes?.cover?.data?.attributes?.url
                  ? `${imageUrl}${item.attributes.cover.data.attributes.url}`
                  : noImage
              }
            >
              {(src, loading) => (
                <img
                  src={src}
                  alt={
                    item?.attributes?.cover?.data?.attributes?.name || "noImage"
                  }
                  style={{
                    filter: loading ? "blur(70px)" : "none",
                    transition: "filter 0.3s ease-out",
                  }}
                />
              )}
            </ProgressiveImage>
          </div>
        </Link>
      );
      itemsRendered.push(currentBlock);
      itemsCount += 1;
    }
  }
  const isEnd = currentSlide >= sliderLength - slidesToShow;
  const isStart = currentSlide === 0;

  return (
    <div className={itemsRendered.length > 0 ? styles.games : styles.hidden}>
      <div className="container">
        <div className={styles.games__wrapper}>
          <div className={styles.header}>
            <h2 className={styles.title}>Games</h2>
            <Link className={styles.more} to="/games">
              <p>See all</p>
              <ArrowSeparatorIcon />
            </Link>
          </div>
          <div className={styles.carousel}>
            <div
              className={`${styles.prevArrow} ${isStart ? styles.hidden : ""}`}
            >
              <button onClick={goToPrev}>
                <ArrowChevronCarousel />
              </button>
            </div>
            {itemsRendered.length > 0 ? (
              <Slider
                ref={sliderRef}
                className={styles.carousel}
                {...sliderSettings}
              >
                {itemsRendered}
              </Slider>
            ) : null}
            <div
              className={`${styles.nextArrow} ${isEnd ? styles.hidden : ""}`}
            >
              <button onClick={goToNext}>
                <ArrowChevronCarousel />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GamesCarousel;
