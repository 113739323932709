import { createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";
import axios from "axios";

const proxy = process.env.REACT_APP_PROXY_URL;

const headerDataSlice = createSlice({
  name: "headerData",
  initialState: {
    logo: "",
    navigation: [],
  },
  reducers: {
    setLogo: (state, action) => {
      state.logo = action.payload;
    },
    setNavigation: (state, action) => {
      state.navigation = action.payload;
    },
  },
});

export const { setLogo, setNavigation } = headerDataSlice.actions;
export default headerDataSlice.reducer;

export const fetchHeaderData = () => async (dispatch, getState) => {
  try {
    const response = await axios.get(`${proxy}/api/get-header-data`);
    const { data } = response;

    const jwtToken = data.data;
    const decodedData = jwtDecode(jwtToken);

    const { logoSvg, menuLinks } = decodedData.responseData.data.attributes;

    dispatch(setLogo(logoSvg));
    dispatch(setNavigation(menuLinks));
  } catch (error) {
    console.error("Ошибка при запросе данных:", error);
  }
};
