import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

const proxy = process.env.REACT_APP_PROXY_URL;

const checkerSlice = createSlice({
  name: "checker",
  initialState: {
    isUploader: false,
  },
  reducers: {
    setIsUploader: (state, action) => {
      state.isUploader = action.payload;
    },
  },
});

export const { setIsUploader } = checkerSlice.actions;

export default checkerSlice.reducer;

export const checkUploadedResource =
  ({ chapter, resourceId, resourceTitle }) =>
  async (dispatch, getState) => {
    const storedToken =
      localStorage.getItem("jwt") || sessionStorage.getItem("jwt");
    const token = storedToken ? JSON.parse(storedToken) : null;

    if (!token || !chapter || !resourceId || !resourceTitle) {
      return;
    }

    try {
      const profileResponse = await axios.get(
        `${proxy}/api/get-profile-me/${encodeURIComponent(token)}`
      );
      const { data: profileData } = profileResponse.data;
      const jwtToken = profileData;
      const decodedProfileData = jwtDecode(jwtToken);

      const username = decodedProfileData.username;
      const userId = decodedProfileData.id;
      const userData = { username, userId };

      try {
        if (!userData || !chapter || !resourceId || !resourceTitle) {
          return;
        }

        const checkResponse = await axios.get(
          `${proxy}/api/checking-uploaded-user`,
          {
            params: {
              chapter,
              resourceTitle,
              resourceId,
              username: userData.username,
              userId: userData.userId,
            },
          }
        );

        const checkData = checkResponse.data;
        if (checkData && checkData.response) {
          const decodedCheckData = jwtDecode(checkData.response);
          dispatch(setIsUploader(decodedCheckData.exists));
        }
      } catch (error) {
        dispatch(setIsUploader(false));
      }
    } catch (error) {
      dispatch(setIsUploader(false));
    }
  };
