import React, { useEffect, useState, useCallback, useMemo, memo } from "react";
import { useLocation } from "react-router-dom";
import qs from "qs";
import { useDispatch } from "react-redux";
import { checkUploadedResource } from "../../store/features/checkerSlice";

import Header from "../Header";
import GameDetailItem from "../GameDetailItem";
import NotFoundError from "../NotFoundError";
import Footer from "../Footer";
import WidgetPageNavigation from "../mui/WidgetPageNavigation";

const proxy = process.env.REACT_APP_PROXY_URL;

const GameDetail = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [data, setData] = useState(null);

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const gameId = useMemo(
    () => searchParams.get("id") || "defaultId",
    [searchParams]
  );

  const gameName = useMemo(() => {
    const pathParts = location.pathname.split("/");
    return pathParts.length > 2 && pathParts[2]
      ? decodeURIComponent(pathParts[2])
      : "defaultGame";
  }, [location.pathname]);

  const queryParams = useMemo(
    () => qs.stringify({ gameName, gameId }, { encode: false }),
    [gameName, gameId]
  );

  const fetchGameData = useCallback(async () => {
    try {
      const response = await fetch(`${proxy}/api/get-game-info?${queryParams}`);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      setData(responseData.data);
    } catch (error) {
      console.error("Ошибка при запросе данных:", error);
    }
  }, [queryParams]);

  const checkerParam = {
    chapter: "game",
    resourceTitle: gameName,
    resourceId: gameId,
  };

  useEffect(() => {
    fetchGameData();
    dispatch(checkUploadedResource(checkerParam));
  }, [fetchGameData]);

  return (
    <div className="main">
      <Header />
      <div className="main__contant" style={{ marginBottom: 0 }}>
        {data?.length > 0 ? (
          <GameDetailItem gameData={data[0]} />
        ) : (
          <NotFoundError />
        )}
      </div>
      <Footer />
      <WidgetPageNavigation />
    </div>
  );
};

export default memo(GameDetail);
