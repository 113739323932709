import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import { useSelector, useDispatch } from "react-redux";

import logo from "../assets/headerLogo.png";
import styles from "../scss/components/footer.module.scss";

const url = process.env.REACT_APP_SERVER_API;
const imageUrl = process.env.REACT_APP_IMAGE_URL;

const Footer = () => {
  const year = new Date();
  const { logo, navigation } = useSelector((state) => state.headerData);

  const [isMoreOpen, setIsMoreOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const handleMoreOpenToogle = () => {
    setIsMoreOpen(!isMoreOpen);
  };
  const handleMoreOpenClose = () => {
    setIsMoreOpen(false);
  };
  const handleSearchToogle = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  return (
    <div className={styles.footer}>
      <div className="container">
        <div className={styles.footer__wrapper}>
          <div className={styles.blockLeft}>
            <Link className={styles.logo} to="/">
              <div dangerouslySetInnerHTML={{ __html: logo }} />
            </Link>
            <p className={styles.copyright}>
              All rights © {year.getFullYear()}
            </p>
          </div>
          <div className={styles.blockCenter}>
            <div className={styles.navBar}>
              {navigation.map((item) => (
                <Link
                  key={item.id}
                  to={item.url}
                  className={styles.navBar__item}
                >
                  <p>{item.title}</p>
                </Link>
              ))}
            </div>
            <div className={styles.social}>
              <Link to="/">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 13 17"
                  fill="none"
                >
                  <path
                    d="M6.35781 9.39468H4.66307C4.38939 9.39468 4.30518 9.28942 4.30518 9.03678C4.30518 8.35257 4.30518 7.64731 4.30518 6.9631C4.30518 6.68942 4.41044 6.58415 4.66307 6.58415H6.35781V5.07889C6.33675 4.41573 6.49465 3.74205 6.82097 3.14205C7.16833 2.54205 7.7157 2.09994 8.34728 1.87889C8.77886 1.72099 9.19991 1.65784 9.65254 1.65784H11.3262C11.5683 1.65784 11.6736 1.7631 11.6736 2.00521V3.95257C11.6736 4.19468 11.5683 4.29994 11.3262 4.29994C10.8631 4.29994 10.3999 4.29994 9.93675 4.32099C9.4736 4.34205 9.23149 4.54205 9.23149 5.02626C9.21044 5.54205 9.23149 6.03678 9.23149 6.5631H11.1999C11.4736 6.5631 11.5789 6.66836 11.5789 6.94205V9.01573C11.5789 9.28942 11.4946 9.39468 11.1999 9.39468H9.25254V14.9631C9.25254 15.2578 9.14728 15.3526 8.86307 15.3526H6.72623C6.4736 15.3526 6.36833 15.2473 6.36833 14.9947L6.35781 9.39468Z"
                    fill="#828282"
                  />
                </svg>
                <p>Facebook</p>
              </Link>
              <Link to="/">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="15"
                  viewBox="0 0 14 15"
                  fill="none"
                >
                  <g clipPath="url(#clip0_1883_7389)">
                    <path
                      d="M5.49335 9.35554L5.26177 12.6129C5.5931 12.6129 5.7366 12.4705 5.90869 12.2996L7.4621 10.815L10.6809 13.1723C11.2713 13.5013 11.6872 13.328 11.8464 12.6292L13.9593 2.72887L13.9599 2.72829C14.1471 1.85562 13.6443 1.51437 13.0691 1.72845L0.649937 6.4832C-0.197646 6.8122 -0.184813 7.2847 0.505854 7.49879L3.68094 8.48637L11.056 3.87162C11.4031 3.64179 11.7187 3.76895 11.4591 3.99879L5.49335 9.35554Z"
                      fill="#828282"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1883_7389">
                      <rect
                        width="14"
                        height="14"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <p>Telegram</p>
              </Link>
            </div>
          </div>
          <div className={styles.blockRight}>
            <Link className={styles.donate} to="/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="18"
                viewBox="0 0 19 18"
                fill="none"
              >
                <g clipPath="url(#clip0_1883_7326)">
                  <path
                    d="M9.02033 1.20223e-05C13.9859 0.00807293 18.1211 4.06271 18.0244 9.00404C17.9196 14.5661 13.8891 18.0081 9.00421 18C4.05482 17.992 0.0888523 14.4774 0.000182374 8.98792C-0.0804267 4.03853 4.05482 -0.00804888 9.02033 1.20223e-05ZM9.00421 0.685189C4.44174 0.733554 0.725664 3.93373 0.645055 9.07659C0.572507 13.6632 4.41756 17.4196 9.02033 17.4116C13.6231 17.4035 17.4682 13.6391 17.3795 9.06047C17.2747 3.84506 13.5022 0.717432 9.00421 0.685189Z"
                    fill="white"
                  />
                  <path
                    d="M8.68976 8.42354C8.68976 8.15753 8.6817 7.88346 8.68976 7.61745C8.69782 7.48041 8.65752 7.41593 8.51242 7.36756C7.86755 7.14992 7.44838 6.53729 7.48063 5.86823C7.51287 5.17499 7.9401 4.63491 8.60109 4.45757C9.41524 4.23993 10.2294 4.67522 10.4632 5.44906C10.713 6.27934 10.3019 7.10961 9.47167 7.38368C9.36688 7.41593 9.32657 7.46429 9.32657 7.57714C9.33463 8.14141 9.32657 8.70567 9.33463 9.26993C9.33463 9.34248 9.36688 9.43115 9.41524 9.47952C9.83441 9.91481 10.2697 10.334 10.6889 10.7693C10.7856 10.8741 10.8501 10.8337 10.9468 10.7854C11.6642 10.4307 12.5187 10.6725 12.9379 11.3577C13.349 12.0268 13.1797 12.9296 12.559 13.381C11.9947 13.7921 11.1645 13.7679 10.6324 13.3246C10.1085 12.8812 9.94726 12.1074 10.2697 11.4786C10.3261 11.3658 10.3181 11.3093 10.2294 11.2287C9.85859 10.866 9.49585 10.5033 9.13311 10.1325C9.04444 10.0438 8.99608 10.0196 8.89935 10.1244C8.53661 10.5113 8.17386 10.8982 7.80306 11.2771C7.72245 11.3577 7.71439 11.4141 7.76276 11.5189C8.03683 12.1154 7.92398 12.8006 7.48869 13.2359C7.0534 13.6792 6.34404 13.8082 5.75559 13.5583C5.17521 13.3084 4.83665 12.7522 4.86083 12.0913C4.90114 10.995 6.11027 10.3098 7.10176 10.8257C7.23074 10.8902 7.30329 10.8902 7.40002 10.7773C7.78694 10.3662 8.18193 9.96317 8.56079 9.55207C8.62527 9.47952 8.67364 9.3586 8.6817 9.25381C8.69782 8.95556 8.68976 8.68955 8.68976 8.42354ZM11.6481 13.0183C12.1318 13.0102 12.5268 12.6071 12.5187 12.1235C12.5106 11.6479 12.1156 11.2529 11.6401 11.2529C11.1645 11.2529 10.7614 11.664 10.7614 12.1477C10.7614 12.6233 11.1645 13.0263 11.6481 13.0183ZM6.3924 13.0183C6.87606 13.0102 7.27104 12.5991 7.26298 12.1235C7.25492 11.6479 6.85994 11.2529 6.38434 11.2529C5.90875 11.2529 5.50571 11.664 5.50571 12.1477C5.51377 12.6233 5.90875 13.0263 6.3924 13.0183ZM9.02026 5.03796C8.53661 5.03796 8.1255 5.43294 8.1255 5.9166C8.1255 6.39219 8.52854 6.79524 9.0122 6.79524C9.48779 6.79524 9.89084 6.40025 9.89084 5.93272C9.8989 5.44906 9.49585 5.04602 9.02026 5.03796Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1883_7326">
                    <rect width="18.0242" height="18" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <p>Donate us</p>
            </Link>
            <Link to="/">
              <p>Privacy Policy</p>
            </Link>
          </div>
          <div className={styles.footerMb}>
            {isSearchOpen && <input className={styles.search} type="text" />}
            <div className={styles.footerMb__btn}>
              <svg
                onClick={handleSearchToogle}
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.6387 2.25816C8.26897 2.25816 5.53725 4.9879 5.53725 8.3552C5.53725 10.0615 6.23716 11.6023 7.36863 12.7103C8.47026 13.789 9.97585 14.4522 11.6387 14.4522C15.0085 14.4522 17.7402 11.7225 17.7402 8.3552C17.7402 4.9879 15.0085 2.25816 11.6387 2.25816ZM3.27744 8.3552C3.27744 3.74075 7.02092 0 11.6387 0C16.2565 0 20 3.74075 20 8.3552C20 12.9697 16.2565 16.7104 11.6387 16.7104C9.76108 16.7104 8.02669 16.0909 6.63119 15.0468L1.58332 20L0 18.3888L5.02076 13.4621C3.92851 12.0504 3.27744 10.2781 3.27744 8.3552Z"
                  fill="white"
                />
              </svg>
              <Link to="/uploadResource">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.586 0.72446C10.0186 0.291879 10.7199 0.291879 11.1525 0.72446L17.0602 6.63215C17.4928 7.06473 17.4928 7.76609 17.0602 8.19867C16.6276 8.63125 15.9263 8.63125 15.4937 8.19867L11.4769 4.18192V13.5693C11.4769 14.181 10.981 14.6769 10.3693 14.6769C9.75749 14.6769 9.26156 14.181 9.26156 13.5693V4.18192L5.24482 8.19867C4.81224 8.63125 4.11089 8.63125 3.67831 8.19867C3.24573 7.76609 3.24573 7.06473 3.67831 6.63215L9.586 0.72446ZM1.50772 12.9539C2.11948 12.9539 2.61541 13.4498 2.61541 14.0616V17.3846H18.1231V14.0616C18.1231 13.4498 18.619 12.9539 19.2308 12.9539C19.8426 12.9539 20.3385 13.4498 20.3385 14.0616V18.4923C20.3385 19.1041 19.8426 19.6 19.2308 19.6H1.50772C0.895955 19.6 0.400024 19.1041 0.400024 18.4923V14.0616C0.400024 13.4498 0.895955 12.9539 1.50772 12.9539Z"
                    fill="#F2F2F2"
                  />
                </svg>
              </Link>

              <div className={styles.notify}>
                <p className={styles.notify__count}>15</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 21 20"
                  fill="none"
                >
                  <path
                    d="M10.625 4C8.125 4 6.125 5.9 6.125 9.5C6.125 13.1 4.79167 14.5 4.125 14.5H10.625H17.125C16.4583 14.5 15.125 13.1 15.125 9.5C15.125 5.9 13.125 4 10.625 4ZM10.625 4V2M9.125 17.5H12.125L10.625 18L9.125 17.5Z"
                    stroke="#F2F2F2"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <Link to="/searchResult">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M16.9419 3.76946C15.6279 3.16787 14.2407 2.74108 12.8158 2.5C12.6208 2.84857 12.4443 3.20721 12.2872 3.5744C10.7694 3.34568 9.22584 3.34568 7.70801 3.5744C7.55079 3.20725 7.37437 2.84861 7.17946 2.5C5.75361 2.74312 4.3655 3.17092 3.05016 3.77261C0.43887 7.63606 -0.269009 11.4036 0.0849305 15.1176C1.61417 16.2474 3.32582 17.1067 5.14548 17.6581C5.55522 17.107 5.91778 16.5224 6.22933 15.9104C5.63759 15.6894 5.06646 15.4167 4.52255 15.0955C4.6657 14.9917 4.8057 14.8847 4.94098 14.7809C6.52364 15.5252 8.25103 15.9111 9.99996 15.9111C11.7489 15.9111 13.4763 15.5252 15.0589 14.7809C15.1958 14.8926 15.3358 14.9996 15.4774 15.0955C14.9324 15.4172 14.3602 15.6904 13.7675 15.912C14.0786 16.5236 14.4412 17.1078 14.8513 17.6581C16.6725 17.1089 18.3855 16.25 19.915 15.1191C20.3303 10.8121 19.2055 7.0792 16.9419 3.76946ZM6.67765 12.8335C5.69134 12.8335 4.87649 11.9384 4.87649 10.8372C4.87649 9.7361 5.66302 8.83316 6.6745 8.83316C7.68598 8.83316 8.49454 9.7361 8.47724 10.8372C8.45993 11.9384 7.68284 12.8335 6.67765 12.8335ZM13.3223 12.8335C12.3344 12.8335 11.5227 11.9384 11.5227 10.8372C11.5227 9.7361 12.3092 8.83316 13.3223 8.83316C14.3353 8.83316 15.1376 9.7361 15.1203 10.8372C15.103 11.9384 14.3275 12.8335 13.3223 12.8335Z"
                    fill="white"
                  />
                </svg>
              </Link>

              <svg
                onClick={handleMoreOpenToogle}
                className={isMoreOpen ? styles.moreActive : ""}
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 20 20"
                fill="none"
                style={{ transform: "rotate(90deg)" }}
              >
                <path
                  d="M10.061 3H10.0686M10.061 10H10.0686M10.061 17H10.0686"
                  stroke="#F2F2F2"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              {isMoreOpen && (
                <div className={styles.moreInfo}>
                  <ul>
                    <li onClick={handleMoreOpenClose}>
                      <Link to="/">About CGIG</Link>
                    </li>
                    <li onClick={handleMoreOpenClose}>
                      <Link to="/">Help</Link>
                    </li>
                    <li onClick={handleMoreOpenClose}>
                      <Link to="/">Terms of use</Link>
                    </li>
                    <li onClick={handleMoreOpenClose}>
                      <Link to="/">Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
