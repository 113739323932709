import React, {
  memo,
  useMemo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Masonry from "react-masonry-css";
import InfiniteScroll from "react-infinite-scroll-component";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useDispatch, useSelector } from "react-redux";
import { setSearchRequestDataCount } from "../store/features/searchSlice";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

import { ReactComponent as CloseIcon } from "../assets/icon/closeIcon.svg";
import { ReactComponent as DisplayColumn } from "../assets/icon/displayColumn.svg";
import { ReactComponent as BurgerIcon } from "../assets/icon/burgerIcon.svg";

import { ReactComponent as ArrowChevron } from "../assets/icon/arrowChevron.svg";
import { ReactComponent as SearchIcon } from "../assets/icon/search.svg";
import { ReactComponent as CheckMarkIconReg } from "../assets/icon/chechMarkIconReg.svg";

import WidgetToUp from "./mui/WidgetToUp";
import SkeletonMosaryItem from "./mui/SkeletonMosaryItem";
import GameCard from "./mui/masaryCard/GameCard";
import AssetCard from "./mui/masaryCard/AssetCard";

import styles from "../scss/components/searchResultItems.module.scss";

const proxy = process.env.REACT_APP_PROXY_URL;
const url = process.env.REACT_APP_SERVER_API;

export function HeaderSearchDataCheckAndParseUrl() {
  const urlNow = new URL(window.location.href);

  // Проверка, что мы находимся на странице поиска
  if (urlNow.pathname.includes("/search")) {
    const searchParams = new URLSearchParams(urlNow.search);

    // Получаем все значения для 'query' и превращаем в массив уникальных значений
    const queryValues = searchParams.getAll("query");
    const uniqueQueryValues = [...new Set(queryValues)]; // Удаление дубликатов с помощью Set

    const category = searchParams.get("category");

    return {
      query: uniqueQueryValues,
      selectedCategory: category || null,
    };
  }

  return null;
}
const SearchResultItems = () => {
  const dispatch = useDispatch();
  const { searchRequestDataCount } = useSelector(
    (state) => state.search
  );

  const SearchResult = ({}) => {
    const filterItems = ["popular", "the newest", "the old"];
    const [filterActive, setFilterActive] = useState(false);
    const dropdownRef = useRef(null);
    const [selectedFilterItem, setSelectedFilterItem] = useState(null);
    const handleFilterClick = (event) => {
      event.stopPropagation(); // Остановить всплытие события
      setFilterActive(!filterActive);
    };
    const handleSelectedFilterItemClick = (item, event) => {
      setSelectedFilterItem(item);
    };
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target)
        ) {
          setFilterActive(false);
        }
      };
      document.addEventListener("click", handleClickOutside);
      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }, []);

    // Вытаскиваем searchRequest из pathname
    // const urlNow = new URL(window.location.href);
    // const searchParams = new URLSearchParams(urlNow.search);
    // const searchRequest = searchParams.get('query') || searchParams.get('tag');
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);

    // Извлекаем все параметры запроса
    const params = {};
    for (const [key, value] of searchParams.entries()) {
      if (!params[key]) {
        params[key] = [];
      }
      params[key].push(value);
    }

    const handleParamDelete = (item) => {
      // Создаем новый URLSearchParams для обновления параметров
      const newSearchParams = new URLSearchParams();
      let itemDeleted = false;

      for (const [key, value] of searchParams.entries()) {
        // Удаляем только первое вхождение item
        if (value === item && !itemDeleted) {
          itemDeleted = true;
        } else {
          newSearchParams.append(key, value);
        }
      }

      // Проверяем, остались ли параметры query
      const remainingQueryParams = newSearchParams.getAll("query");

      if (remainingQueryParams.length === 0) {
        // Если не осталось параметров query, переходим на главную страницу
        navigate("/");
      } else {
        // Обновляем URL с новыми параметрами
        navigate(`?${newSearchParams.toString()}`);
      }
    };

    return (
      <div className={styles.searchResult}>
        <div className={styles.searchResult__result}>
          {params && Array.isArray(params.query)
            ? params.query.map((item, index) => {
                return (
                  <div key={index}>
                    <p>{item || "unknown"}</p>
                    <CloseIcon onClick={() => handleParamDelete(item)} />
                  </div>
                );
              })
            : null}
        </div>
      </div>
    );
  };
  const FilterSearch = () => {
    const characters = [
      "All",
      "Games",
      "Models",
      "Tech",
      "Formats",
      "Tools",
      "Animation",
      "Guides",
    ];
    const [charactersActive, setCharactersActive] = useState(false);
    const handleCharactersToogle = () => {
      setCharactersActive(!charactersActive);
    };

    return (
      <div className={styles.filterSearch}>
        <div className={styles.filterSearch__search}>
          <input type="text" placeholder="Filter publications" />
          <ul>
            <li>
              <CheckMarkIconReg />
              <p>13123</p>
            </li>
            <li>
              <CheckMarkIconReg />
              <p>13123</p>
            </li>
            <li>
              <CheckMarkIconReg />
              <p>13123</p>
            </li>
          </ul>
        </div>

        <div className={styles.filterSearch__selectResourse}>
          <div onClick={handleCharactersToogle}>
            <SearchIcon />
            <span>All</span>
            <ArrowChevron />
          </div>
          <ul className={`${charactersActive ? styles.active : ""}`}>
            <li>
              <CheckMarkIconReg />
              <p>13123</p>
            </li>
            <li>
              <CheckMarkIconReg />
              <p>13123</p>
            </li>
            <li>
              <CheckMarkIconReg />
              <p>13123</p>
            </li>
            <li>
              <CheckMarkIconReg />
              <p>13123</p>
            </li>
          </ul>
        </div>
      </div>
    );
  };
  const MasaryHeader = () => {
    return (
      <div className={`${styles.masaryHeader} `}>
        <div className={styles.masaryHeader__wrapper}>
          <span className={styles.masaryHeader__count}>
            {searchRequestDataCount || 0} Results
          </span>
          <div className={styles.masaryHeader__content}>
            <FilterSearch />
            <div className={styles.masaryHeader__display}>
              <DisplayColumn />
              <BurgerIcon />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const InfiniteMasary = () => {
    const dispatch = useDispatch();
    const breakpointColumnsObj = {
      default: 4,
      1224: 3,
      767: 2,
    };

    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const pageSize = 50;
    const [hasMore, setHasMore] = useState(true);
    const [noElements, setNoElements] = useState(false);
    const prevQueryParamsRef = useRef(null);

    const params = HeaderSearchDataCheckAndParseUrl();

    const queryParams = new URLSearchParams();
    params.query.forEach((item) => queryParams.append("query", item));
    queryParams.append("selectedCategory", params.selectedCategory);
    queryParams.append("requestPage", page);
    queryParams.append("pageSize", pageSize);

    const fetchData = async () => {
      try {
        prevQueryParamsRef.current = queryParams.toString();

        if (!hasMore) {
          return;
        }

        const response = await axios.get(
          `${proxy}/api/get-header-search?${queryParams}`
        );
        const dataEncrypted = response.data.dataEncrypted;
        const decodedData = jwtDecode(dataEncrypted);

        const newData = decodedData.data.newData || [];
        const count = decodedData.data.countItems || 0;

        if (count !== searchRequestDataCount) {
          dispatch(setSearchRequestDataCount(count));
        }

        if (newData.length < pageSize) {
          setHasMore(false);
        }

        if (data && data.length >= 0) {
          setNoElements(true);
        } else {
          setNoElements(false);
        }

        setData((prevData) => [
          ...new Map(
            [...prevData, ...newData].map((item) => [
              `${item.id}-${item.mark}`,
              item,
            ])
          ).values(),
        ]);

        setPage((prevPage) => prevPage + 1);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    useEffect(() => {
        fetchData();
    }, []);
  
    return (
      <div className={styles.infiniteMasary}>
        <div className={styles.infiniteScrollContainer}>
          <InfiniteScroll
            dataLength={data?.length || 0}
            next={fetchData}
            hasMore={hasMore}
            scrollThreshold={0.6}
            endMessage={
              <p
                className={`${styles.masonry__noMoreItems} ${
                  noElements && data?.length <= 0 ? styles.hidden : ""
                }`}
              >
                No more items
              </p>
            }
            style={{
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              gap: "40px",
            }}
          >
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className={`${styles.masonry} ${
                !noElements ? styles.skeleton : ""
              }`}
              columnClassName={styles.masonry__column}
            >
              {!noElements ? (
                Array.from({ length: 21 }, (_, index) => (
                  <SkeletonMosaryItem key={index} />
                ))
              ) : data && data.length > 0 ? (
                data.map((item) => (
                  <React.Fragment key={item.id}>
                  {item.mark === "game" ? (
                    <GameCard
                      gameId={item.id}
                      gameTitle={item.name}
                      gameImage={item.image}
                      companyImage={item.companyImage}
                      developerType={item.developerType}
                    />
                  ) : item.mark === "asset" ? (
                    <AssetCard
                      assetTitle={item.name}
                      assetId={item.id}
                      assetImage={item.image}
                      uploaderName={item.username}
                      uploaderImage={item.avatar}
                    />
                  ) : null}
                </React.Fragment>
                ))
              ) : (
                <span className={styles.masonry__noMoreItems}>NoItems</span>
              )}
            </Masonry>
          </InfiniteScroll>
        </div>
      </div>
    );
  };
  const MemoizedInfiniteMasary = memo(InfiniteMasary);

  return (
    <div className={styles.searchResultItems}>
      <div className="container">
        <div className={styles.searchResultItems__wrapper}>
          <SearchResult />
          <MasaryHeader />
          <MemoizedInfiniteMasary />
        </div>
        <WidgetToUp />
      </div>
    </div>
  );
};

export default SearchResultItems;
