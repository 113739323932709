import React, { useEffect, useRef, useState, forwardRef, memo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { useSelector, useDispatch } from "react-redux";
import ProgressiveImage from "react-progressive-graceful-image";

import { setSelectCategory, setInputArea } from "../store/features/searchSlice";
import { performLogout } from "../store/features/authorizationSlice";

import noImage from "../assets/noImage.png";

import { ReactComponent as SearchIcon } from "../assets/icon/search.svg";
import { ReactComponent as ArrowChevron } from "../assets/icon/arrowChevron.svg";
import { ReactComponent as ArrowChevronCarousel } from "../assets/icon/arrowChevronCarousel.svg";
import { ReactComponent as CloseIcon } from "../assets/icon/closeIcon.svg";
import { ReactComponent as UploadResourceIcon } from "../assets/icon/uploaderIcon.svg";
import { ReactComponent as UserIcon } from "../assets/icon/userIcon.svg";
import { ReactComponent as CircleInfoIcon } from "../assets/icon/circleInfoIcon.svg";
import { ReactComponent as PrivacyIcon } from "../assets/icon/privacyIcon.svg";
import { ReactComponent as DarkModeIcon } from "../assets/icon/darkModeIcon.svg";
import { ReactComponent as LogoutIcon } from "../assets/icon/logoutIcon.svg";
import { ReactComponent as GameIcon } from "../assets/icon/gameIcon.svg";
import { ReactComponent as CubIcon } from "../assets/icon/cubIcon.svg";
import { ReactComponent as CheckMarkIconReg } from "../assets/icon/chechMarkIconReg.svg";
import { ReactComponent as BurgerIcon } from "../assets/icon/burgerIcon.svg";
import { ReactComponent as DiscordIcon } from "../assets/icon/discordIcon.svg";
import { ReactComponent as RightToBracketIcon } from "../assets/icon/rightToBracketIcon.svg";

import styles from "../scss/components/header.module.scss";

const url = process.env.REACT_APP_SERVER_API;
const proxy = process.env.REACT_APP_PROXY_URL;
const imageUrl = process.env.REACT_APP_IMAGE_URL;

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { logo, navigation } = useSelector((state) => state.headerData);
  const { isAuthorization } = useSelector((state) => state.authorization);
  const { userAvatar, userCustomId } = useSelector((state) => state.myProfile);

  const Actions = () => {
    const [isMoreOpen, setIsMoreOpen] = useState(false);
    const MoreOpenToggleRef = useRef(null);
    const MoreOpenDropDownRef = useRef(null);

    const handleMoreOpenToogle = () => {
      setIsMoreOpen(!isMoreOpen);
    };
    const handleMoreOpenClose = () => {
      setIsMoreOpen(false);
    };
    // Эффект для обработки клика вне элемента
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          MoreOpenDropDownRef.current &&
          !MoreOpenDropDownRef.current.contains(event.target) &&
          MoreOpenToggleRef.current &&
          !MoreOpenToggleRef.current.contains(event.target)
        ) {
          // Проверяем, что клик был не на dropdown и не на toggle элементе
          setIsMoreOpen(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    const handleLogout = (e) => {
      e.preventDefault();
      dispatch(performLogout());
      navigate("/");
    };
    const handleMyProfile = () => {
      if (location.pathname !== "/me") {
        navigate("/me");
      } else setIsMoreOpen(false);
    };
    return (
      <>
        <div className={styles.actions}>
          <div className={styles.activity}>
            <Link className={styles.upload} to="/uploadResource">
              <UploadResourceIcon />
            </Link>
            <div className={styles.notify}>
              <p className={styles.notify__count}>15</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
              >
                <path
                  d="M10.625 4C8.125 4 6.125 5.9 6.125 9.5C6.125 13.1 4.79167 14.5 4.125 14.5H10.625H17.125C16.4583 14.5 15.125 13.1 15.125 9.5C15.125 5.9 13.125 4 10.625 4ZM10.625 4V2M9.125 17.5H12.125L10.625 18L9.125 17.5Z"
                  stroke="#F2F2F2"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            {isAuthorization ? (
              <div
                ref={MoreOpenToggleRef}
                onClick={handleMoreOpenToogle}
                className={styles.avatar}
                style={{
                  borderColor: location.pathname === "/me" ? " #F2994A" : "",
                }}
              >
                <img
                  src={userAvatar ? `${imageUrl}${userAvatar}` : noImage}
                  alt={userCustomId || "user"}
                />
              </div>
            ) : (
              <Link to="/login" className={styles.noLogin}>
                <RightToBracketIcon />
              </Link>
            )}
          </div>
        </div>
        {isMoreOpen && (
          <div ref={MoreOpenDropDownRef} className={styles.profileInfo}>
            <ul>
              <li onClick={handleMyProfile}>
                <span>
                  <UserIcon />
                  <p>Profile</p>
                </span>
              </li>
              <li onClick={handleMoreOpenClose}>
                <span to="/">
                  <CircleInfoIcon />
                  <p>About CGIG</p>
                </span>
              </li>
              <li onClick={handleMoreOpenClose}>
                <span to="/">
                  <PrivacyIcon />
                  <p>Privacy</p>
                </span>
              </li>
            </ul>
            <span className={styles.profileInfo__separate}></span>
            <ul>
              <li onClick={handleMoreOpenClose}>
                <span to="/">
                  <DarkModeIcon />
                  <p>Dark Mode</p>
                </span>
              </li>
            </ul>
            <span className={styles.profileInfo__separate}></span>
            <ul>
              <li onClick={(e) => handleLogout(e)}>
                <span>
                  <LogoutIcon />
                  <p> Log Out</p>
                </span>
              </li>
            </ul>
          </div>
        )}
      </>
    );
  };
  const Search = () => {
    const [searchData, setSearchData] = useState(null);
    const [searchInputValue, setSearchInputValue] = useState(null);
    const [debouncedSearchRequest] = useDebounce(searchInputValue, 500);
    const [isSearchRequest, setIsSearchRequest] = useState(false);
    const searchRequestRef = useRef();
    useEffect(() => {
      const inputElement = inputSearchRequest.current;
      const handleInput = () => {
        const trimmedValue = inputElement.value.trim();
        setSearchInputValue(trimmedValue);
        setIsSearchRequest(trimmedValue !== ""); // Устанавливаем флаг только если значение не пустое
      };

      inputElement.addEventListener("input", handleInput);

      return () => {
        inputElement.removeEventListener("input", handleInput);
      };
    }, []);
    useEffect(() => {
      if (isSearchRequest && debouncedSearchRequest) {
        const fetchData = async () => {
          try {
            const response = await fetch(
              `${proxy}/api/get-search?value=${debouncedSearchRequest}`
            );

            if (!response.ok) {
              throw new Error("Network response was not ok");
            }

            const responseData = await response.json();
            setSearchData(responseData);
          } catch (error) {
            console.error("Ошибка при запросе данных:", error);
          }
        };

        fetchData();
      }
    }, [isSearchRequest, debouncedSearchRequest]);
    // Эффект для обработки клика вне элемента
    const handleSearchRequestOutsideClick = (e) => {
      if (
        inputSearchRequest.current &&
        !inputSearchRequest.current.contains(e.target) &&
        searchRequestRef.current &&
        !searchRequestRef.current.contains(e.target)
      ) {
        setIsSearchRequest(false);
      }
    };
    useEffect(() => {
      document.addEventListener("click", handleSearchRequestOutsideClick);
      return () => {
        document.removeEventListener("click", handleSearchRequestOutsideClick);
      };
    }, []);

    const characters = [
      "All",
      "Games",
      "Models",
      /* "Tech",
      "Formats",
      "Tools",
      "Animation",
      "Guides", */
    ];
    const [charactersChange, setCharactersChange] = useState(false);
    const dropDownCharactersRef = useRef(null);
    const dropDownMainCharactersRef = useRef(null);

    const inputSearchRequest = useRef(null);
    const [inputSearchClear, setInputSearchClear] = useState(false);
    const [searchInputArea, setSearchInputArea] = useState("");
    const [searchSelectedCategory, setSearchSelectedCategory] = useState(null);
    const { inputArea, selectedCategory } = useSelector(
      (state) => state.search
    );
    const handleSearchKeyPress = (e) => {
      if (
        e.key === "Enter" &&
        inputSearchRequest !== null &&
        inputSearchRequest.current.value.trim() !== ""
      ) {
        const searchRequestCategory =
          searchSelectedCategory !== null
            ? searchSelectedCategory.toLowerCase()
            : "all";
        const searchRequestInputArea = searchInputArea.toLowerCase();

        if (location.pathname === "/search") {
          // Разбираем текущий query и category из URL
          const params = new URLSearchParams(location.search);
          const currentQuery = params.getAll("query");
          const currentCategory = params.get("category");

          // Проверяем условия для добавления нового query
          if (
            currentQuery.length === 0 || // если текущих query нет
            (currentQuery.length > 0 &&
              currentCategory === searchRequestCategory)
          ) {
            // Добавляем новый параметр query
            params.append("query", searchRequestInputArea);
          } else {
            // Обнуляем текущий query и добавляем новый первым
            params.set("query", searchRequestInputArea);
          }

          // Обновляем или добавляем параметр category
          params.set("category", searchRequestCategory);

          // Выполняем навигацию с новыми параметрами
          navigate(`/search?${params.toString()}`);
        } else {
          // Если не находимся на /search, выполняем обычную навигацию
          navigate(
            `/search?query=${searchRequestInputArea}&category=${searchRequestCategory}`
          );
        }

        setIsSearchRequest(false);
        dispatch(setSelectCategory(searchSelectedCategory));
        // Очищаем input
        inputSearchRequest.current.value = "";
      }
    };
    // Эффект для обработки клика вне элемента
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          dropDownCharactersRef.current &&
          !dropDownCharactersRef.current.contains(event.target) &&
          dropDownMainCharactersRef.current &&
          !dropDownMainCharactersRef.current.contains(event.target)
        ) {
          // Проверяем, что клик был не на dropdown и не на toggle элементе
          setCharactersChange(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);
    useEffect(() => {
      const checkInput = () => {
        const currentInput = inputSearchRequest.current;

        if (currentInput && currentInput.value.trim() === "") {
          setInputSearchClear(false);
        } else if (currentInput) {
          setInputSearchClear(true);
        }
      };

      checkInput();

      // Add event listener for input changes to update state
      if (inputSearchRequest.current) {
        inputSearchRequest.current.addEventListener("input", checkInput);
      }

      // Cleanup event listener on unmount
      return () => {
        if (inputSearchRequest.current) {
          inputSearchRequest.current.removeEventListener("input", checkInput);
        }
      };
    }, []);

    function handleSearchInputChange(e) {
      const newValue = e.target.value;
      // Регулярное выражение, пропускающее буквы, цифры и пробелы (латинские и кириллические)
      const validChars = /^[a-zA-Zа-яА-Я0-9\s]*$/;

      if (newValue === "") {
        // Если поле ввода пустое, очистить состояние
        setSearchData(null);
        setSearchInputArea("");
      } else if (validChars.test(newValue)) {
        // Если ввод соответствует разрешённым символам
        setSearchInputArea(newValue);
      } else {
        // Если ввод содержит запрещённые символы не обновляем значение поля
        e.preventDefault();
      }
    }
    const handleSearchRequest = () => {
      setIsSearchRequest(true);
      setCharactersChange(false);
    };
    const handleSearchClear = () => {
      if (inputSearchRequest.current) {
        inputSearchRequest.current.value = "";
      }
      setSearchInputArea("");
      setSearchInputValue(null);
      setSearchData(null);
      setIsSearchRequest(false);
      setInputSearchClear(false);
      setCharactersChange(false);
    };

    const handleSelectCategory = (item) => {
      setSearchSelectedCategory(item);
      setCharactersChange(false);
    };
    const handleCharactersChangeToggle = (event) => {
      event.stopPropagation();
      setCharactersChange((prev) => !prev);
      setIsSearchRequest(false);
    };
    useEffect(() => {
      // Проверяем, внутри ли клик или снаружи
      const handleClickOutside = (event) => {
        if (
          dropDownCharactersRef.current &&
          !dropDownCharactersRef.current.contains(event.target)
        ) {
          setCharactersChange(!charactersChange);
        }
      };
      // Добавляем слушателя на весь документ
      document.addEventListener("mousedown", handleClickOutside);
      // Удаляем слушателя, когда компонент будет размонтирован
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

   
    const mainClassName = `${styles.searchBox} ${
      location.pathname === '/search' 
        ? styles.activeSearchBox 
        : inputSearchRequest.current?.value 
        ? styles.activeSearchBox 
        : ''
    } ${
      charactersChange === true && inputSearchClear === false && location.pathname !== '/search'
        ? styles.notActiveSearchBox
        : ''
    }`;
    const categoryClassName = `${styles.searchChDropDownMain} ${
      location.pathname === '/search' 
        ? styles.charactersChangedActive 
        : inputSearchRequest.current?.value.trim() !== ''
        ? styles.searchChDropDownMain__active
        : ''
    } ${
      location.pathname !== '/search' &&
      searchSelectedCategory !== 'All' &&
      searchSelectedCategory !== null &&
      inputSearchRequest.current?.value.trim() !== ''
        ? styles.charactersChangedActive
        : ''
    } ${
      location.pathname !== '/search' &&
      searchSelectedCategory !== 'All' &&
      searchSelectedCategory !== null &&
      inputSearchRequest.current?.value.trim() === ''
        ? styles.charactersChangedNotActive
        : ''
    }`;

    return (
      <div
        className={mainClassName}
      >
        <div
          className={categoryClassName}
          ref={dropDownMainCharactersRef}
          onClick={handleCharactersChangeToggle}
        >
          <SearchIcon />
          <p>
            {searchSelectedCategory || selectedCategory !== null
              ? searchSelectedCategory || selectedCategory
              : characters[0]}
          </p>
          {charactersChange ? (
            <ArrowChevron className={styles.searchArrowChevron__active} />
          ) : (
            <ArrowChevron className={styles.searchArrowChevron} />
          )}
        </div>
        <span></span>
        <input
          type="text"
          ref={inputSearchRequest}
          placeholder="Search files"
          onChange={handleSearchInputChange}
          onKeyPress={handleSearchKeyPress}
          onClick={handleSearchRequest}
        />
        {charactersChange && (
          <div
            ref={dropDownCharactersRef}
            className={styles.searchDropDownCharacters}
          >
            <ul>
              {characters.map((item, index) => {
                let className = "";

                const activeCategory =
                  searchSelectedCategory ?? selectedCategory;

                if (activeCategory === item) {
                  className = styles.activeCharacters;
                } else if (activeCategory === "All" && item === "All") {
                  className = styles.activeCharacters;
                } else if (!activeCategory && index === 0) {
                  className = styles.activeCharacters;
                }

                return (
                  <li
                    className={className}
                    onClick={() => handleSelectCategory(item)}
                    key={index}
                  >
                    <CheckMarkIconReg />
                    <p>{item}</p>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        {isSearchRequest && Array.isArray(searchData?.result) && (
          <div ref={searchRequestRef} className={styles.searchDropDownResults}>
            {searchData.result && searchData.result.length > 0 ? (
              <ul>
                {searchData.result.map((item, index) => {
                  const getPath = () => {
                    if (item?.mark === "asset") {
                      return {
                        pathname: `/models/${item?.title}`,
                        search: `?id=${item?.id}`,
                      };
                    } else if (item?.mark === "game") {
                      return {
                        pathname: `/game/${item?.title}`,
                        search: `?id=${item?.id}`,
                      };
                    } else {
                      return "/";
                    }
                  };

                  return (
                    <li
                      key={item.id}
                      className={`${
                        item.mark === "game" || item.mark === "asset"
                          ? styles.searchDropDownResults__certain
                          : ""
                      }`}
                    >
                      <Link to={getPath()}>
                        {item.title || `Unknown ${item.mark}`}
                      </Link>
                      {item.mark &&
                        (item.mark === "game" ? (
                          <GameIcon />
                        ) : item.mark === "asset" ? (
                          <CubIcon />
                        ) : null)}
                    </li>
                  );
                })}
              </ul>
            ) : (
              <p className={styles.searchDropDownResults__notFound}>
                Nothing was found
              </p>
            )}
          </div>
        )}

        {inputSearchRequest.current &&
          inputSearchRequest.current.value.trim() !== "" && (
            <div className={styles.searchClear} onClick={handleSearchClear}>
              <CloseIcon />
              <p>Clear search</p>
            </div>
          )}
      </div>
    );
  };

  const MobDropDownInfo = forwardRef(({ menuTop }, ref) => {
    return (
      <div
        ref={ref}
        style={{
          top: `${menuTop}px`,
          transition: "top 0.3s ease-in-out",
        }}
        className={`${styles.mobDropDownInfo}`}
      >
        <div className={styles.mobDropDownInfo__mobActions}>
          <div className={styles.notifyMb}>
            <p className={styles.notifyMb__count}>15</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
            >
              <path
                d="M10.625 4C8.125 4 6.125 5.9 6.125 9.5C6.125 13.1 4.79167 14.5 4.125 14.5H10.625H17.125C16.4583 14.5 15.125 13.1 15.125 9.5C15.125 5.9 13.125 4 10.625 4ZM10.625 4V2M9.125 17.5H12.125L10.625 18L9.125 17.5Z"
                stroke="#F2F2F2"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <Link className={styles.upload} to="/uploadResource">
            <UploadResourceIcon />
          </Link>
          <Link className={styles.discord} to="/">
            <DiscordIcon />
          </Link>
          <div className={styles.lang}>
            <span>en</span>
            <ArrowChevronCarousel />
          </div>
        </div>

        <div className={styles.mobDropDownInfo__search}>
          <Search />
        </div>
      </div>
    );
  });

  const HeaderTop = () => {
    const [isOpenBurger, setIsOpenBurger] = useState(false);
    const burgerMenuRef = useRef(null);
    const mainRef = useRef(null);
    const [menuTop, setMenuTop] = useState(-136);

    // Обновление menuTop при изменении isOpenBurger
    useEffect(() => {
      if (burgerMenuRef.current) {
        const menuHeight = 136;
        setMenuTop(isOpenBurger ? 45 : -menuHeight);
      }
    }, [isOpenBurger]);

    // Управление состоянием бургер-меню
    const handleToggleBurger = () => {
      if (isOpenBurger) {
        setMenuTop(-136);
        setTimeout(() => {
          setIsOpenBurger(false);
        }, 300);
      } else {
        setIsOpenBurger(true);
      }
    };

    return (
      <>
        <div ref={mainRef} className={styles.top}>
          <div className="container">
            <div className={styles.top__wrapper}>
              <div className={styles.burger}>
                {isOpenBurger ? (
                  <CloseIcon onClick={handleToggleBurger} />
                ) : (
                  <BurgerIcon onClick={handleToggleBurger} />
                )}
              </div>
              <Link className={styles.logo} to="/">
                <div dangerouslySetInnerHTML={{ __html: logo }} />
              </Link>
              <div className={`webContent tableContent`}>
                <Search />
              </div>
              <Actions />
            </div>
          </div>
        </div>
        <MobDropDownInfo ref={burgerMenuRef} menuTop={menuTop} />
      </>
    );
  };
  const HeaderButtom = ({ navigation }) => {
    return (
      <div className={styles.buttom}>
        <div className="container">
          <div className={styles.buttom__wrapper}>
            <div className={styles.buttom__items}>
              {navigation.map((item) => (
                <Link
                  to={item.url}
                  key={item.id}
                  className={`${styles.buttom__item} ${
                    location.pathname !== "/" && location.pathname !== item.url
                      ? styles.notActiveUrl
                      : ""
                  } ${location.pathname === item.url ? styles.activeUrl : ""}`}
                >
                  <div dangerouslySetInnerHTML={{ __html: item.icon }} />
                  <p>{item.title}</p>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.header}>
      <div className={`${styles.header__wrapper}`}>
        <HeaderTop />
        <HeaderButtom navigation={navigation} />
      </div>
    </div>
  );
};

export default memo(Header);
