import { createSlice } from "@reduxjs/toolkit";

const url = process.env.REACT_APP_SERVER_API;
const proxy = process.env.REACT_APP_PROXY_URL;

const gamesItemsSlice = createSlice({
  name: "gamesItems",
  initialState: {
    gamesCount: 0,
    viewPosition: 0,
  },
  reducers: {
    setGamesCount: (state, action) => {
      state.gamesCount = action.payload;
    },

    setViewPosition: (state, action) => {
      state.viewPosition = action.payload;
    },
  },
});

export const { setGamesCount, setViewPosition } = gamesItemsSlice.actions;
export default gamesItemsSlice.reducer;
