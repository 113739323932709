import React, { useEffect, useRef, useState } from "react";
import Masonry from "react-masonry-css";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import {
  toggleNavTab,
  getMyPublishedWork,
} from "../store/features/myProfileSlice";

import avatar from "../assets/avatar.png";
import noImage from "../assets/noImage.png";
import backgroundProfile from "../assets/backgroundProfile.png";
import { ReactComponent as ImageIcon } from "../assets/icon/imageIcon.svg";
import { ReactComponent as ArrowChevron } from "../assets/icon/arrowChevron.svg";
import { ReactComponent as ViewIcon } from "../assets/icon/viewIcon.svg";
import { ReactComponent as BookmarkIcon } from "../assets/icon/bookmarkIcon.svg";
import { ReactComponent as DownloadIcon } from "../assets/icon/downloadIcon.svg";
import { ReactComponent as DiscordIcon } from "../assets/icon/discordIcon.svg";
import { ReactComponent as CloseIcon } from "../assets/icon/closeIcon.svg";
import { ReactComponent as DisplayColumn } from "../assets/icon/displayColumn.svg";
import { ReactComponent as BurgerIcon } from "../assets/icon/burgerIcon.svg";
import { ReactComponent as SearchIcon } from "../assets/icon/search.svg";
import { ReactComponent as CheckMarkIconReg } from "../assets/icon/chechMarkIconReg.svg";

import WidgetToUp from "./mui/WidgetToUp";

import PublicationSection from "./my-profile-sections/Publication";
import DraftSection from "./my-profile-sections/Draft";

import styles from "../scss/components/profileMyContent.module.scss";

const imageUrl = process.env.REACT_APP_IMAGE_URL;
const proxy = process.env.REACT_APP_PROXY_URL;

const ProfileMyContent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    userCustomId,
    username,
    userRole,
    navigation,
    userAvatar,
    navTab,
    draftWorks,
    publishWorks,
    publishWorksHasMore,
  } = useSelector((state) => state.myProfile);

  const Actions = () => {
    return (
      <div className={styles.actions}>
        <span>
          <ViewIcon />
          <p>123</p>
        </span>
        <span>
          <BookmarkIcon />
          <p>234</p>
        </span>
        <span>
          <DownloadIcon />
          <p>456</p>
        </span>
      </div>
    );
  };
  const Navigation = () => {
    const handleToggleInfo = (tabName) => {
      dispatch(toggleNavTab(tabName));
      /* if (tabName.toLowerCase() === "publications") {
        dispatch(getMyPublishedWork());
      } */
    };

    return (
      <div
        className={`${styles.navigation} ${
          !navigation || navigation.length === 0 ? styles.hidden : ""
        }`}
      >
        <div className="container">
          <ul className={styles.navigation__list}>
            {navigation && navigation.length > 0
              ? navigation.map((item, index) => {
                  return (
                    <li
                      className={
                        navTab.toLowerCase() === item.name
                          ? styles.activeTab
                          : ""
                      }
                      onClick={() => handleToggleInfo(item.name)}
                      key={index}
                    >
                      <p>{item.name}</p>
                    </li>
                  );
                })
              : null}
          </ul>
        </div>
      </div>
    );
  };

  const Header = () => {
    const [selectRoleActive, setSelectRoleActive] = useState(false);
    const toogleRoleselect = () => {
      setSelectRoleActive(!selectRoleActive);
    };
    return (
      <div
        style={{
          backgroundImage: `url(${backgroundProfile})`,
        }}
        className={styles.header}
      >
        <div className="container">
          <div className={styles.header__wrapper}>
            <div className={styles.header__top}>
              <div className={styles.avatar}>
                <img
                  src={userAvatar ? `${imageUrl}${userAvatar}` : noImage}
                  alt={userCustomId || username || "user"}
                />
                <div className={styles.editImage}>
                  <ImageIcon />
                </div>
              </div>

              <div className={styles.header__userInfo}>
                <h3>{username || "Unknow user"}</h3>
                <span className={styles.userRole}>
                  {userRole || "Unknow role"}
                </span>
                <div className={styles.header__action}>
                  <Actions />
                </div>
              </div>

              <div className={styles.header__end}>
                <div className={styles.editImage}>
                  <ImageIcon />
                </div>
                <div className={styles.userCustomId}>
                  <DiscordIcon />
                  <span>@{userCustomId || "No custom id"}</span>
                </div>
              </div>
            </div>
            <div className={styles.header__buttom}>
              <Actions />
              <div className={styles.userCustomId}>
                <DiscordIcon />
                <span>@{userCustomId || "No custom id"}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleGoBack = () => {
    if (window.history.length <= 1) {
      navigate("/");
    } else {
      navigate(-1);
    }
  };

  return (
    <div className={styles.myProfile}>
      <div className={`mobContent ${styles.location}`}>
        <h2>Profile</h2>
        <CloseIcon onClick={handleGoBack} />
      </div>
      <Header />
      <Navigation />
      {navTab.toLowerCase() === "publications" && (
        <div className={styles.myProfile__content}>
          <PublicationSection />
        </div>
      )}
      {navTab.toLowerCase() === "drafts" && (
        <div className={styles.myProfile__content}>
          <DraftSection />
        </div>
      )}
    </div>
  );
};

export default ProfileMyContent;

{
  /* <div
  className={`${styles.selectRole} ${
    selectRoleActive ? styles.selectRole__active : ""
  }`}
>
  <div onClick={toogleRoleselect}>
    <span>Author</span>
    <ArrowChevron />
  </div>
  <ul>
    <li>Moder</li>
    <li>Casual</li>
  </ul>
</div>; */
}
