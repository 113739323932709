import React from "react";
import styles from "../../scss/components/pages/home.module.scss";

import Header from "../Header";
import AdvertisingBanner from "../AdvertisingBanner";
import Newsletter from "../Newsletter";
import CarouselTags from "../CarouselTags";
import GamesCarousel from "../GamesCarousel";
import UsersCarousel from "../UsersCarousel";
import TechCarousel from "../TechCarousel";
import FormatsCarousel from "../FormatsCarousel";
import Footer from "../Footer";

import WidgetPageNavigation from "../mui/WidgetPageNavigation";

const Home = () => {
  return (
    <div className="main">
      <Header />
      <div className="main__contant">
        <CarouselTags />
        <GamesCarousel />
        <AdvertisingBanner />
        <TechCarousel />
        <FormatsCarousel />
        <UsersCarousel />
        <Newsletter />
      </div>
      <Footer />
      <WidgetPageNavigation />
    </div>
  );
};

export default Home;
