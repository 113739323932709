import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import Masonry from "react-masonry-css";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";

import {
  setAssetsCount,
  setViewPosition,
} from "../store/features/assetsItemsSlice";

import { ReactComponent as CheckMarkIconReg } from "../assets/icon/chechMarkIconReg.svg";
import { ReactComponent as ArrowSeparatorIcon } from "../assets/icon/arrowSeparatorIcon.svg";

import WidgetToUp from "./mui/WidgetToUp";
import SkeletonMosaryItem from "./mui/SkeletonMosaryItem";
import AssetCard from "./mui/masaryCard/AssetCard";

import styles from "../scss/components/modelsItems.module.scss";

const proxy = process.env.REACT_APP_PROXY_URL;

const ModelsItems = () => {
  const dispatch = useDispatch();
  const sortParam = ["Relevance", "Most Recent", "Popular"];
  const { assetsCount, viewPosition } = useSelector(
    (state) => state.assetsItems
  );

  const InfoHeader = () => {
    const [sortActive, setSortActive] = useState(false);
    const [selectedSortParam, setSelectedSortParam] = useState(sortParam[0]);
    const dropDownRef = useRef(null);
    const toggleRef = useRef(null);
    const handleSelect = (item) => {
      setSelectedSortParam(item);
      setSortActive(false);
    };
    const toogleSort = () => {
      setSortActive(!sortActive);
    };

    // Эффект для обработки клика вне элемента
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          dropDownRef.current &&
          !dropDownRef.current.contains(event.target) &&
          toggleRef.current &&
          !toggleRef.current.contains(event.target)
        ) {
          setSortActive(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    return (
      <div className={styles.infoHeader}>
        <span className={styles.infoHeader__count}>
          {assetsCount || 0} Models
        </span>
        <div
          ref={toggleRef}
          onClick={() => setSortActive(!sortActive)}
          className={`${styles.infoHeader__sort} ${
            sortActive ? styles.infoHeader__dropDownActive : ""
          }`}
        >
          <span>{selectedSortParam}</span>
          <ArrowSeparatorIcon />
        </div>
        {sortActive && (
          <div ref={dropDownRef} className={styles.infoHeader__sortDropDown}>
            <ul>
              {sortParam.map((item) => {
                const isActive = item === selectedSortParam;
                return (
                  <li
                    key={item}
                    onClick={() => handleSelect(item)}
                    className={`${
                      isActive ? styles.infoHeader__sortActive : ""
                    }`}
                  >
                    <CheckMarkIconReg />
                    <span>{item}</span>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    );
  };

  const InfiniteMasary = () => {
    const breakpointColumnsObj = {
      default: 4,
      1224: 3,
      767: 2,
    };

    const [data, setData] = useState([]);

    const [page, setPage] = useState(1);
    const pageSize = 50;
    const [hasMore, setHasMore] = useState(true);
    const [noElements, setNoElements] = useState(false);

    const fetchData = async () => {
      try {
        const response = await axios.get(`${proxy}/api/get-assets-data`, {
          params: { page, pageSize },
        });

        const dataEncrypted = response.data.dataEncrypted;
        const decodedData = jwtDecode(dataEncrypted);

        const newData = decodedData.data.assets;

        const count = decodedData.data.assetsCount;
        if (count !== assetsCount) {
          dispatch(setAssetsCount(count));
        }

        if (newData.length < pageSize) {
          setHasMore(false);
        }
        if (data && data.length >= 0) {
          setNoElements(true);
        } else {
          setNoElements(false);
        }

        setData((prevData) => [
          ...new Map(
            [...prevData, ...newData].map((item) => [item.assetId, item])
          ).values(),
        ]);

        setPage((prevPage) => prevPage + 1);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    useEffect(() => {
      fetchData();
    }, []);

    return (
      <div className={styles.infiniteMasary}>
        <div className={styles.infiniteScrollContainer}>
          <InfiniteScroll
            dataLength={data?.length || 0}
            next={fetchData}
            hasMore={hasMore}
            scrollThreshold={0.6}
            endMessage={
              <p
                className={`${styles.masonry__noMoreItems} ${
                  noElements && data && data.length <= 0 ? styles.hidden : ""
                }`}
              >
                No more items
              </p>
            }
            style={{
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              gap: "40px",
            }}
          >
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className={`${styles.masonry} ${
                !noElements ? styles.skeleton : ""
              }`}
              columnClassName={`${styles.masonry__column} `}
            >
              {!noElements ? (
                Array.from({ length: 21 }, (_, index) => (
                  <SkeletonMosaryItem key={index} />
                ))
              ) : data && data.length > 0 ? (
                data.map((item) => (
                  <AssetCard
                    key={item.assetId}
                    assetTitle={item.assetTitle}
                    assetId={item.assetId}
                    assetImage={item.assetImage}
                    uploaderName={item.uploaderName}
                    uploaderImage={item.uploaderImage}
                  />
                ))
              ) : (
                <span className={styles.masonry__noMoreItems}>NoItems</span>
              )}
            </Masonry>
          </InfiniteScroll>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.modelsItems}>
      <div className="container">
        <div className={styles.modelsItems__wrapper}>
          <InfoHeader />
          <InfiniteMasary />
        </div>
        <WidgetToUp />
      </div>
    </div>
  );
};

export default ModelsItems;
