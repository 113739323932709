import React, { useEffect, useState, useCallback, useMemo, memo } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { checkUploadedResource } from "../../store/features/checkerSlice";

import Header from "../Header";
import ModalDetailItem from "../ModalDetailItem";
import NotFoundError from "../NotFoundError";
import Footer from "../Footer";
import WidgetPageNavigation from "../mui/WidgetPageNavigation";

const proxy = process.env.REACT_APP_PROXY_URL;

const ModalDetail = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const modelId = useMemo(
    () => searchParams.get("id") || "defaultId",
    [searchParams]
  );

  const modelName = useMemo(() => {
    const pathParts = location.pathname.split("/");
    if (pathParts.length > 2 && pathParts[2]) {
      return decodeURIComponent(pathParts[2]);
    } else {
      return "defaultAsset";
    }
  }, [location.pathname]);

  const queryParams = useMemo(() => {
    return new URLSearchParams({ modelName, modelId }).toString();
  }, [modelName, modelId]);

  const fetchModelData = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `${proxy}/api/get-asset-info?${queryParams}`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      setData(responseData.data);
    } catch (error) {
      console.error("Ошибка при запросе данных:", error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [queryParams]);

  const checkerParam = {
    chapter: "asset",
    resourceTitle: modelName,
    resourceId: modelId,
  };

  useEffect(() => {
    fetchModelData();
    dispatch(checkUploadedResource(checkerParam));
  }, [fetchModelData]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="main">
      <Header />
      <div className="main__contant" style={{ marginBottom: 0 }}>
        {data?.length > 0 ? (
          <ModalDetailItem assetData={data[0]} />
        ) : (
          <NotFoundError />
        )}
      </div>
      <Footer />
      <WidgetPageNavigation />
    </div>
  );
};

export default memo(ModalDetail);
