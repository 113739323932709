import React from "react";
import styles from "../scss/components/unableUploadItem.module.scss";
import image from "../assets/unableUpload.png";

const UnableUploadItem = () => {
  return (
    <div className={styles.unableUploadItem}>
      <div className="container">
        <div className={styles.image}>
          <img src={image} alt="image" />
        </div>
      </div>
    </div>
  );
};

export default UnableUploadItem;
