import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

import { ReactComponent as ArrowSeparatorIcon } from "../assets/icon/arrowSeparatorIcon.svg";
import image from "../assets/tech.jpeg";

import styles from "../scss/components/usersCarousel.module.scss";

const UsersCarousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderLength, setSliderLength] = useState(0);
  const sliderRef = useRef();

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5.7,
    slidesToScroll: 1,
    nextArrow: null,
    prevArrow: null,
    draggable: true,
    swipe: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5.3,
          slidesToScroll: 3,
          dots: false,
          infinite: false,
          swipe: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 4.65,
          slidesToScroll: 3,
          dots: false,
          infinite: false,
          swipe: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2.65,
          slidesToScroll: 2,
          swipe: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2.65,
          slidesToScroll: 2,
          swipe: true,
        },
      },
    ],
    afterChange: (current) => {
      setCurrentSlide(current);
    },
  };
  const isEnd = currentSlide >= sliderLength - sliderSettings.slidesToShow;
  const isStart = currentSlide === 0;
  const goToNext = () => {
    if (!isEnd) {
      sliderRef.current.slickNext();
    }
  };
  const goToPrev = () => {
    if (!isStart) {
      sliderRef.current.slickPrev();
    }
  };
  useEffect(() => {
    if (sliderRef.current) {
      setSliderLength(sliderRef.current.props.children.length);
    }
  }, [sliderRef.current]);

  const stlData = [
    { name: "Alina Medvedeva", image: image },
    { name: "Alina Medvedeva", image: image },
    { name: "Alina Medvedeva", image: image },
    { name: "Alina Medvedeva", image: image },
    { name: "Alina Medvedeva", image: image },
    { name: "Alina Medvedeva", image: image },
    { name: "Alina Medvedeva", image: image },
    { name: "Alina Medvedeva", image: image },
    { name: "Alina Medvedeva", image: image },
    { name: "Alina Medvedeva", image: image },
    { name: "Alina Medvedeva", image: image },
    { name: "Alina Medvedeva", image: image },
    { name: "Alina Medvedeva", image: image },
    { name: "Alina Medvedeva", image: image },
    { name: "Alina Medvedeva", image: image },
    { name: "Alina Medvedeva", image: image },
  ];
  const users = stlData.map((item, index) => (
    <div className={styles.item} key={index}>
      <div className={styles.itemBg}></div>
      <div className={styles.discord}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.2477 3.39252C14.0651 2.85109 12.8166 2.46698 11.5342 2.25C11.3587 2.56372 11.1999 2.88649 11.0585 3.21696C9.69244 3.01112 8.30325 3.01112 6.93721 3.21696C6.79571 2.88652 6.63693 2.56375 6.46151 2.25C5.17825 2.46881 3.92895 2.85383 2.74514 3.39535C0.394983 6.87245 -0.242108 10.2632 0.0764374 13.6058C1.45275 14.6227 2.99324 15.396 4.63094 15.8923C4.9997 15.3963 5.326 14.8701 5.60639 14.3193C5.07383 14.1204 4.55982 13.875 4.07029 13.586C4.19913 13.4925 4.32513 13.3963 4.44689 13.3028C5.87128 13.9727 7.42593 14.32 8.99997 14.32C10.574 14.32 12.1287 13.9727 13.5531 13.3028C13.6762 13.4034 13.8022 13.4996 13.9296 13.586C13.4392 13.8755 12.9242 14.1214 12.3907 14.3208C12.6708 14.8713 12.9971 15.397 13.3662 15.8923C15.0053 15.398 16.5469 14.625 17.9235 13.6072C18.2973 9.73087 17.285 6.37128 15.2477 3.39252ZM6.00988 11.5501C5.1222 11.5501 4.38884 10.7446 4.38884 9.75353C4.38884 8.76249 5.09672 7.94985 6.00705 7.94985C6.91739 7.94985 7.64509 8.76249 7.62951 9.75353C7.61394 10.7446 6.91456 11.5501 6.00988 11.5501ZM11.9901 11.5501C11.101 11.5501 10.3704 10.7446 10.3704 9.75353C10.3704 8.76249 11.0783 7.94985 11.9901 7.94985C12.9018 7.94985 13.6238 8.76249 13.6083 9.75353C13.5927 10.7446 12.8947 11.5501 11.9901 11.5501Z"
            fill="white"
          />
        </svg>
      </div>
      <div className={styles.userPhoto}>
        <img src={item.image} alt="image" />
      </div>
      <div className={styles.descriptions}>
        <p className={styles.userName}>{item.name}</p>
        <div className={styles.info}>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11"
              height="11"
              viewBox="0 0 11 11"
              fill="none"
            >
              <g clipPath="url(#clip0_2056_4377)">
                <path
                  d="M5.72497 3.71645C4.97269 3.71645 4.36133 4.32781 4.36133 5.08009C4.36133 5.83237 4.97269 6.44373 5.72497 6.44373C6.47725 6.44373 7.0886 5.83237 7.0886 5.08009C7.0886 4.32781 6.47722 3.71645 5.72497 3.71645Z"
                  fill="#C4C4C4"
                />
                <path
                  d="M5.72486 1.671C3.45213 1.671 1.51122 3.08462 0.724854 5.08009C1.51122 7.07554 3.45213 8.48918 5.72486 8.48918C7.99986 8.48918 9.9385 7.07554 10.7249 5.08009C9.9385 3.08462 7.99986 1.671 5.72486 1.671ZM5.72486 7.35281C4.47032 7.35281 3.45213 6.33462 3.45213 5.08007C3.45213 3.82552 4.47032 2.80735 5.72486 2.80735C6.97941 2.80735 7.9976 3.82554 7.9976 5.08009C7.9976 6.33464 6.97941 7.35281 5.72486 7.35281Z"
                  fill="#C4C4C4"
                />
              </g>
              <defs>
                <clipPath id="clip0_2056_4377">
                  <rect
                    width="10"
                    height="10"
                    fill="white"
                    transform="translate(0.724854 0.0800781)"
                  />
                </clipPath>
              </defs>
            </svg>
            <span>125</span>
          </div>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11"
              height="11"
              viewBox="0 0 11 11"
              fill="none"
            >
              <g clipPath="url(#clip0_2056_4385)">
                <path
                  d="M8.64172 0.0800781H2.8396C2.36706 0.0800781 1.95215 0.468816 1.95215 0.928955V9.51204C1.95215 9.66606 1.99502 9.79443 2.06411 9.89361C2.14673 10.0122 2.27977 10.0801 2.42243 10.0801C2.5573 10.0801 2.7009 10.02 2.83352 9.90677L5.42938 7.70275C5.50955 7.63428 5.62472 7.59504 5.74446 7.59504C5.86415 7.59504 5.97908 7.63428 6.05948 7.70294L8.64667 9.90644C8.77976 10.02 8.91345 10.0801 9.0481 10.0801C9.27578 10.0801 9.49772 9.9045 9.49772 9.51209V0.928955C9.49772 0.468816 9.11426 0.0800781 8.64172 0.0800781Z"
                  fill="#C4C4C4"
                />
              </g>
              <defs>
                <clipPath id="clip0_2056_4385">
                  <rect
                    width="10"
                    height="10"
                    fill="white"
                    transform="translate(0.724854 0.0800781)"
                  />
                </clipPath>
              </defs>
            </svg>
            <span>16</span>
          </div>
        </div>
        <span className={styles.status}>Online</span>
      </div>
    </div>
  ));

  return (
    <div className={styles.users}>
      <div className="container">
        <div className={styles.users__wrapper}>
          <div className={styles.header}>
            <h2 className={styles.title}>Users</h2>
            <Link className={styles.more} to="/">
              <p>See all</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1.10549 11.9649L-4.82017e-08 10.8673L4.82145 6.08007L-4.68651e-07 1.29287L1.10549 0.195227L7.03243 6.08007L1.10549 11.9649Z"
                  fill="white"
                />
              </svg>
            </Link>
          </div>
          <div className={styles.carousel}>
            <button
              onClick={goToPrev}
              className={`${styles.prevArrow} ${isStart ? styles.hidden : ""}`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <circle cx="8" cy="8" r="8" fill="#D9D9D9" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.786 12.407L6 11.623L9.42801 8.20353L6 4.7841L6.786 4.00007L11 8.20353L6.786 12.407Z"
                  fill="#161619"
                />
              </svg>
            </button>
            <Slider
              ref={sliderRef}
              className={styles.carousel}
              {...sliderSettings}
            >
              {users}
            </Slider>
            <button
              onClick={goToNext}
              className={`${styles.nextArrow} ${isEnd ? styles.hidden : ""}`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <circle cx="8" cy="8" r="8" fill="#D9D9D9" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.786 12.407L6 11.623L9.42801 8.20353L6 4.7841L6.786 4.00007L11 8.20353L6.786 12.407Z"
                  fill="#161619"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersCarousel;
