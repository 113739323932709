import React from "react";

import Header from "../Header";
import NotFoundError from "../NotFoundError";
import Footer from "../Footer";
import WidgetPageNavigation from "../mui/WidgetPageNavigation";


const NotFound = () => {

  return (
    <div className="main">
      <Header  />
      <div className="main__contant">
        <NotFoundError />
      </div>
      <Footer />
      <WidgetPageNavigation />
    </div>
  );
};

export default NotFound;
