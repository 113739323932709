import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { useSelector, useDispatch } from "react-redux";
import {
  setLogin,
  setPassword,
  loginUser,
  setStayLogin,
  setLoginClose,
  setRegistrationOpen,
} from "../../store/features/authorizationSlice";

import avatar from "../../assets/avatar.png";

import styles from "../../scss/components/header.module.scss";

const url = process.env.REACT_APP_SERVER_API;
const proxy = process.env.REACT_APP_PROXY_URL;

const Login = () => {
  const { login, password } = useSelector((state) => state.authorization);

  const [debouncedLoginInput] = useDebounce(login, 100);
  const [debouncedPasswordInput] = useDebounce(password, 100);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setLogin(debouncedLoginInput));
  }, [debouncedLoginInput, dispatch]);
  useEffect(() => {
    dispatch(setPassword(debouncedPasswordInput));
  }, [debouncedPasswordInput, dispatch]);
  const handleLogin = async () => {
    dispatch(loginUser());
  };

  const handleStayLogined = () => {
    dispatch(setStayLogin());
  };
  const handleGoBack = () => {
    if (window.history.length <= 1) {
      navigate("/");
    } else {
      navigate(-1);
    }
  };
  return (
    <div className={styles.modal}>
      <div onClick={handleGoBack} className={styles.modalOverlay}></div>
      <div className={styles.modalContent}>
        <svg
          onClick={handleGoBack}
          className={styles.modalClose}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M1.68807 0L0 1.68807L10.3119 12L0 22.3118L1.68807 24L12 13.688L22.3118 24L24 22.3118L13.688 12L24 1.68807L22.3118 0L12 10.3119L1.68807 0Z"
            fill="white"
          />
        </svg>
        <div className={styles.modalEllips}>
          {/* <span></span> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="49"
            height="48"
            viewBox="0 0 49 48"
            fill="none"
          >
            <circle cx="24.5" cy="24" r="24" fill="#D9D9D9" />
          </svg>
        </div>

        <div className={styles.modalDescriptions}>
          <p className={styles.modalDescriptions__title}>Welcome to CGIG!</p>
          <p className={styles.modalDescriptions__subtitle}>
            Enjoy our resours
          </p>
        </div>
        <div className={styles.modalForm}>
          <div className={styles.modalForm__login}>
            <p>E-mail</p>
            <input
              onChange={(e) => dispatch(setLogin(e.target.value))}
              value={login}
              type="text"
              placeholder="mail@gmail.com"
            />
          </div>
          <div className={styles.modalForm__login}>
            <p>Password</p>
            <input
              value={password}
              onChange={(e) => dispatch(setPassword(e.target.value))}
              type="password"
              placeholder="********"
            />
          </div>
          <div className={styles.modalForm__confirm}>
            <input onClick={handleStayLogined} type="checkbox" />{" "}
            <p>Stay logged in</p>
          </div>
          <button onClick={handleLogin} className={styles.modalForm__btnLogin}>
            Log in
          </button>

          <Link
            to={`${proxy}/api/connect/discord`}
            className={styles.modalForm__otherAuthorization}
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M20.9844 0H4.01562C2.07727 0 0.5 1.57727 0.5 3.51562V20.4844C0.5 22.4227 2.07727 24 4.01562 24H11.0938V15.5156H8.28125V11.2969H11.0938V8.4375C11.0938 6.11115 12.9861 4.21875 15.3125 4.21875H19.5781V8.4375H15.3125V11.2969H19.5781L18.875 15.5156H15.3125V24H20.9844C22.9227 24 24.5 22.4227 24.5 20.4844V3.51562C24.5 1.57727 22.9227 0 20.9844 0Z"
                  fill="white"
                />
              </svg>
              <p>Continue as Discord</p>
            </div>
            <div>
              <img src={avatar} alt="avatar" />
            </div>
          </Link>

          <Link
            to={`${proxy}/api/connect/google`}
            className={styles.modalForm__otherAuthorizationGoogle}
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <g clipPath="url(#clip0_1743_4986)">
                  <path
                    d="M5.81891 14.5034L4.9835 17.6221L1.93011 17.6867C1.01759 15.9942 0.5 14.0577 0.5 11.9999C0.5 10.01 0.983938 8.1335 1.84175 6.4812H1.84241L4.56078 6.97958L5.75159 9.68164C5.50236 10.4082 5.36652 11.1882 5.36652 11.9999C5.36661 12.8808 5.52617 13.7247 5.81891 14.5034Z"
                    fill="#FBBB00"
                  />
                  <path
                    d="M24.2902 9.7583C24.428 10.4842 24.4999 11.2339 24.4999 12.0001C24.4999 12.8592 24.4095 13.6972 24.2375 14.5056C23.6533 17.2563 22.1269 19.6583 20.0124 21.3581L20.0118 21.3574L16.5878 21.1827L16.1032 18.1576C17.5063 17.3348 18.6028 16.0471 19.1804 14.5056H12.7637V9.7583H19.274H24.2902Z"
                    fill="#518EF8"
                  />
                  <path
                    d="M20.0119 21.3575L20.0126 21.3581C17.9561 23.0111 15.3438 24.0001 12.5001 24.0001C7.93018 24.0001 3.957 21.4458 1.93018 17.6869L5.81897 14.5037C6.83236 17.2083 9.44138 19.1336 12.5001 19.1336C13.8148 19.1336 15.0465 18.7781 16.1033 18.1577L20.0119 21.3575Z"
                    fill="#28B446"
                  />
                  <path
                    d="M20.1596 2.76262L16.2721 5.94525C15.1783 5.26153 13.8853 4.86656 12.5 4.86656C9.37213 4.86656 6.71431 6.88017 5.75169 9.68175L1.84245 6.48131H1.8418C3.83895 2.63077 7.86223 0 12.5 0C15.4117 0 18.0814 1.03716 20.1596 2.76262Z"
                    fill="#F14336"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1743_4986">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      transform="translate(0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <p>Continue with Google</p>
            </div>
            <div>
              <img src={avatar} alt="avatar" />
            </div>
          </Link>

          <p className={styles.modalForm__note}>
            Not registered yet? <Link to="/registration">Register</Link>
          </p>
        </div>
      </div>
      <svg
        onClick={handleGoBack}
        className={styles.modalCloseMb}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M1.68807 0L0 1.68807L10.3119 12L0 22.3118L1.68807 24L12 13.688L22.3118 24L24 22.3118L13.688 12L24 1.68807L22.3118 0L12 10.3119L1.68807 0Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default Login;

{
  /* <button
  className={login.length < 6 || password.length < 6 ? styles.disabled : ""}
  disabled={login.length < 6 || password.length < 6}
  onClick={handleLogin}
>
  Intră
</button>; */
}
